import AddOrUpdateClient from './components/Clients/AddOrUpdateClient'
import AddOrUpdateOrganisationFootprint from './components/Organisations/AddOrUpdateOrganisationFootprint'
import AddOrUpdateOrganisationFootprintItem from './components/Organisations/AddOrUpdateOrganisationFootprintItem'
import AddOrUpdateOrganisation from './components/Organisations/AddOrUpdateOrganisation'
import ViewClient from './components/Clients/ViewClient'
import ViewOrganisation from './components/Organisations/ViewOrganisation'
import ViewOrganisationFootprint from './components/Organisations/ViewOrganisationFootprint'
import ListClients from './components/Clients/ListClients'
import ListOrganisations from './components/Organisations/ListOrganisations'
import ListTimesheetEntries from './components/Projects/ListTimesheetEntries'
import AddOrUpdateTimesheetEntry from './components/Projects/AddOrUpdateTimesheetEntry'
import DashboardClientProjects from './components/Projects/DashboardClientProjects'
import DashboardClientProjectsInvoicing from './components/Projects/DashboardClientProjectsInvoicing'
import DashboardBREEAMAssessments from './components/Buildings/DashboardBREEAMAssessments'
import DashboardOccupantSatisfaction from './components/Buildings/DashboardOccupantSatisfaction'
import PerformanceTracker from './components/Projects/PerformanceTracker'

import ListProjects from './components/OffsetProjects/ListProjects'
import ListCredits from './components/OffsetProjects/ListCredits'
import AddOrUpdateProject from './components/OffsetProjects/AddOrUpdateProject'
import AddOrUpdateCredit from './components/OffsetProjects/AddOrUpdateCredit'
import ViewProject from './components/OffsetProjects/ViewProject'
import ViewCredit from './components/OffsetProjects/ViewCredit'

import ListProjectsPublic from './components/OffsetProjectsPublic/ListProjectsPublic'
import ListCreditsPublic from './components/OffsetProjectsPublic/ListCreditsPublic'
import ViewProjectPublic from './components/OffsetProjectsPublic/ViewProjectPublic'
import ViewCreditPublic from './components/OffsetProjectsPublic/ViewCreditPublic'

import AddOrUpdateBuilding from './components/Buildings/AddOrUpdateBuilding'
import ListBuildings from './components/Buildings/ListBuildings'
import ListBuildingPortfolios from './components/Buildings/ListBuildingPortfolios'
import ViewBuilding from './components/Buildings/ViewBuilding'
import ViewBREEAMSiteVisitReport from './components/Buildings/ViewBREEAMSiteVisitReport'
import ViewBREEAMComplianceLetter from './components/Buildings/ViewBREEAMComplianceLetter'
import ViewBREEAMInformationScheduleReport from './components/Buildings/ViewBREEAMInformationScheduleReport'

// import ViewBuildingLCA from './components/Buildings/ViewBuildingLCA'
import ViewBuildingOperationalCarbonEmissionsAssessment from './components/Buildings/ViewBuildingOperationalCarbonEmissionsAssessment'
import ViewBuildingBREEAMAssessment from './components/Buildings/ViewBuildingBREEAMAssessment'
import ViewBuildingConstructionSiteImpactAssessment from './components/Buildings/ViewBuildingConstructionSiteImpactAssessment'

// import AddOrUpdateBuildingLCA from './components/Buildings/AddOrUpdateBuildingLCA'
// import AddOrUpdateBuildingLCACCItem from './components/Buildings/AddOrUpdateBuildingLCACCItem'
import AddOrUpdateBuildingEPC from './components/Buildings/AddOrUpdateBuildingEPC'
import AddOrUpdateBuildingEnvironmentalAssessment from './components/Buildings/AddOrUpdateBuildingEnvironmentalAssessment'
import AddOrUpdateBuildingConstructionCarbonEmissions from './components/Buildings/AddOrUpdateBuildingConstructionCarbonEmissions'
import AddOrUpdateBuildingOperationalCarbonEmissions from './components/Buildings/AddOrUpdateBuildingOperationalCarbonEmissions'
import AddOrUpdateBuildingOperationalCarbonEmissionsAssessmentItem from './components/Buildings/AddOrUpdateBuildingOperationalCarbonEmissionsAssessmentItem'
import AddOrUpdateBuildingBREEAMAssessmentCredit from './components/Buildings/AddOrUpdateBuildingBREEAMAssessmentCredit'
import AddOrUpdateBuildingConstructionSiteImpactAssessment from './components/Buildings/AddOrUpdateBuildingConstructionSiteImpactAssessment'

import AddOrUpdateBuildingBREEAMAssessment from './components/Buildings/AddOrUpdateBuildingBREEAMAssessment'

import Dashboard from './components/Dashboard/Dashboard'
// import DashboardEvents from './components/Events/DashboardEvents'
// import DashboardOrganisations from './components/Organisations/DashboardOrganisations'
import DashboardOrganisationsWithoutTargetCharts from './components/Organisations/DashboardOrganisationsWithoutTargetCharts'
import ViewDataCollectionTracker from './components/Organisations/ViewDataCollectionTracker'
import DashboardOrganisationsInteractiveReport from './components/Organisations/DashboardOrganisationsInteractiveReport'
import DashboardOrganisationsTargetChartsOnly from './components/Organisations/DashboardOrganisationsTargetChartsOnly'
import DashboardEventsWithoutTargetCharts from './components/Events/DashboardEventsWithoutTargetCharts'
import DashboardEventsTargetChartsOnly from './components/Events/DashboardEventsTargetChartsOnly'
// import DashboardOrganisationsWithSubItems from './components/Organisations/DashboardOrganisationsWithSubItems'
import AddOrUpdateStore from './components/Stores/AddOrUpdateStore'
import ListStores from './components/Stores/ListStores'
import ViewStore from './components/Stores/ViewStore'
import AddOrUpdateSite from './components/Sites/AddOrUpdateSite'
import ListSites from './components/Sites/ListSites'
import ViewSite from './components/Sites/ViewSite'
import AddOrUpdateProduct from './components/Products/AddOrUpdateProduct'
import ListProducts from './components/Products/ListProducts'
import AddOrUpdateEvent from './components/Events/AddOrUpdateEvent'
import ListEvents from './components/Events/ListEvents'
import ViewEvent from './components/Events/ViewEvent'
import ViewEventFootprint from './components/Events/ViewEventFootprint'
import AddOrUpdateEventFootprint from './components/Events/AddOrUpdateEventFootprint'
import AddOrUpdateEventFootprintItem from './components/Events/AddOrUpdateEventFootprintItem'
import ViewEventFootprintItemHelperTool from './components/Events/ViewEventFootprintItemHelperTool'
import ViewOrganisationFootprintItemHelperTool from './components/Organisations/ViewOrganisationFootprintItemHelperTool'
import ViewOrganisationFootprintItemHelperToolAllSubItems from './components/Organisations/ViewOrganisationFootprintItemHelperToolAllSubItems'
import AddOrUpdateEventFootprintItemClientView from './components/Events/AddOrUpdateEventFootprintItemClientView'
import AddOrUpdateOrganisationFootprintItemClientView from './components/Organisations/AddOrUpdateOrganisationFootprintItemClientView'

import AddOrUpdateClientProject from './components/Projects/AddOrUpdateClientProject'
import AddOrUpdateClientProjectActivity from './components/Projects/AddOrUpdateClientProjectActivity'
import AddOrUpdateClientProjectService from './components/Projects/AddOrUpdateClientProjectService'
import AddOrUpdateClientProjectInvoiceDetails from './components/Projects/AddOrUpdateClientProjectInvoiceDetails'
import ListClientProjects from './components/Projects/ListClientProjects'
import ViewClientProject from './components/Projects/ViewClientProject'
import HubspotLink from './components/Projects/HubspotLink'
import Sandbox from './components/Projects/Sandbox'
import XeroConnectionWindow from './components/Projects/XeroConnectionWindow'

import AddOrUpdateContact from './components/Contacts/AddOrUpdateContact'
import ListContacts from './components/Contacts/ListContacts'

import AddOrUpdateUser from './components/Users/AddOrUpdateUser'
import ListUsers from './components/Users/ListUsers'
import SettingsPage from './components/Users/SettingsPage'
import NewUsers from './components/Users/NewUsers'

import ProjectsTrackerInvoicing from './components/Projects/ProjectsTrackerInvoicing'
import ProjectsTrackerActivities from './components/Projects/ProjectsTrackerActivities'
import ProjectsTrackerServices from './components/Projects/ProjectsTrackerServices'
import ProjectsTrackerOpportunities from './components/Projects/ProjectsTrackerOpportunities'
import ProjectsTrackerProjects from './components/Projects/ProjectsTrackerProjects'
import ProjectsTrackerIndividual from './components/Projects/ProjectsTrackerIndividual'

import MaintenanceScreen from './MaintenanceScreen'

import LoginNCS from './auth/LoginScreenNCS'
import ComingSoon from './auth/ComingSoon'
import ErrorPage from './auth/ErrorPage'
import Login from './auth/LoginScreen'
import Register from './auth/Register'
import RequireAuth from './auth/RequireAuth'
import ResetPassword from './auth/PasswordReset'
import ForgotPassword from './auth/ForgotPassword'
import ResetPasswordLoggedOut from './auth/PasswordResetLoggedOut'
import MFAValidateScreen from './auth/MFAValidateScreen'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

const ROLES = {
  'User': 1001,
  'Editor': 1984,
  'Admin': 2001,
  'Client': 3001,
  'Consultant': 4001,
  'Verifier': 5001,
  'ExternalConsultant': 6001,
  'ExternalVerifier' : 6002,
  'VersatilityAdmin': 2002,
  'VersatilityAdminVerifier': 2003,
  'VersatilityAdminConsultant': 2004
}

const App = () => {

  return (
    <div className="">
      <Router>
        <Routes>
          <Route element={<RequireAuth allowedRoles={[ROLES.Client, ROLES.Admin, ROLES.User, ROLES.Consultant, ROLES.Verifier, ROLES.ExternalConsultant, ROLES.VersatilityAdmin, ROLES.VersatilityAdminConsultant, ROLES.VersatilityAdminVerifier, ROLES.ExternalVerifier]} />}>
            <Route path="/account/settings" element={<SettingsPage />}></Route>
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Client, ROLES.Admin, ROLES.Consultant, ROLES.Verifier, ROLES.VersatilityAdminConsultant, ROLES.VersatilityAdminVerifier, ROLES.ExternalConsultant, ROLES.ExternalVerifier]} />}>

            <Route path="/viewClient/:clientId" element={<ViewClient />}></Route>

            <Route path="/listOrganisations" element={<ListOrganisations />}></Route>
            <Route path="/viewOrganisation/:organisationId" element={<ViewOrganisation />}></Route>
            <Route path="/viewOrganisationFootprint/:organisationId/:footprintId" element={<ViewOrganisationFootprint />}></Route>
            <Route path="/addOrUpdateOrganisation/:organisationId" element={<AddOrUpdateOrganisation />}></Route>
            <Route path="/addOrUpdateOrganisationFootprint/:organisationId/:footprintId" element={<AddOrUpdateOrganisationFootprint />}></Route>
            <Route path="/viewOrganisationFootprintItemHelperTool/:organisationId/:footprintId/:footprintItemId" element={<ViewOrganisationFootprintItemHelperTool />}></Route>
            <Route path="/addOrUpdateOrganisationFootprintItemClientView/:organisationId/:footprintId/:footprintItemId" element={<AddOrUpdateOrganisationFootprintItemClientView />}></Route>
            <Route path="/addOrUpdateOrganisationFootprintItem/:organisationId/:footprintId/:footprintItemId" element={<AddOrUpdateOrganisationFootprintItem />}></Route>

            <Route path="/resetPassword" element={<ResetPassword />}></Route>

          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Client, ROLES.Admin, ROLES.Consultant, ROLES.Verifier, ROLES.VersatilityAdminConsultant, ROLES.VersatilityAdminVerifier, ROLES.ExternalConsultant]} />}>

            <Route path="/viewClient/:clientId" element={<ViewClient />}></Route>

            <Route path="/listOrganisations" element={<ListOrganisations />}></Route>
            <Route path="/viewOrganisation/:organisationId" element={<ViewOrganisation />}></Route>
            <Route path="/viewOrganisationFootprint/:organisationId/:footprintId" element={<ViewOrganisationFootprint />}></Route>
            <Route path="/addOrUpdateOrganisation/:organisationId" element={<AddOrUpdateOrganisation />}></Route>
            <Route path="/addOrUpdateOrganisationFootprint/:organisationId/:footprintId" element={<AddOrUpdateOrganisationFootprint />}></Route>
            <Route path="/viewOrganisationFootprintItemHelperTool/:organisationId/:footprintId/:footprintItemId" element={<ViewOrganisationFootprintItemHelperTool />}></Route>
            <Route path="/addOrUpdateOrganisationFootprintItemClientView/:organisationId/:footprintId/:footprintItemId" element={<AddOrUpdateOrganisationFootprintItemClientView />}></Route>
            <Route path="/addOrUpdateOrganisationFootprintItem/:organisationId/:footprintId/:footprintItemId" element={<AddOrUpdateOrganisationFootprintItem />}></Route>
            <Route path="/dashboardOrganisationsWithoutTargetCharts" element={<DashboardOrganisationsWithoutTargetCharts />}></Route>
            <Route path="/viewDataCollectionTracker" element={<ViewDataCollectionTracker />}></Route>
            <Route path="/dashboardOrganisationsInteractiveReport" element={<DashboardOrganisationsInteractiveReport />}></Route>
            <Route path="/dashboardOrganisationsTargetChartsOnly" element={<DashboardOrganisationsTargetChartsOnly />}></Route>

            <Route path="/resetPassword" element={<ResetPassword />}></Route>

          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Consultant, ROLES.Verifier, ROLES.VersatilityAdmin, ROLES.VersatilityAdminConsultant, ROLES.VersatilityAdminVerifier]} />}>

            <Route path="/listClients" element={<ListClients />}></Route>
            <Route path="/listContacts" element={<ListContacts />}></Route>
            <Route path="/addOrUpdateContact/:clientId/:contactId" element={<AddOrUpdateContact />}></Route>
            <Route path="/addOrUpdateClientProject/:projectId" element={<AddOrUpdateClientProject />}></Route>
            <Route path="/addOrUpdateClientProjectActivity/:projectId/:serviceId/:activityId" element={<AddOrUpdateClientProjectActivity />}></Route>
            <Route path="/addOrUpdateClientProjectService/:projectId/:serviceId" element={<AddOrUpdateClientProjectService />}></Route>
            <Route path="/addOrUpdateClientProjectInvoiceDetails/:projectId/:invoiceId" element={<AddOrUpdateClientProjectInvoiceDetails />}></Route>
            <Route path="/listClientProjects" element={<ListClientProjects />}></Route>
            <Route path="/viewClientProject/:projectId" element={<ViewClientProject />}></Route>
            <Route path="/listTimesheetEntries" element={<ListTimesheetEntries />}></Route>
            <Route path="/addOrUpdateTimesheetEntry/:entryId" element={<AddOrUpdateTimesheetEntry />}></Route>
            <Route path="/" element={<ListClientProjects />}></Route>
            <Route path="/viewClient/:clientId" element={<ViewClient />}></Route>
            <Route path="/addOrUpdateClient/:clientId" element={<AddOrUpdateClient />}></Route>
            <Route path="/projectsTrackerInvoicing" element={<ProjectsTrackerInvoicing />}></Route>
            <Route path="/projectsTrackerActivities" element={<ProjectsTrackerActivities />}></Route>
            <Route path="/projectsTrackerServices" element={<ProjectsTrackerServices />}></Route>
            <Route path="/projectsTrackerOpportunities" element={<ProjectsTrackerOpportunities />}></Route>
            <Route path="/projectsTrackerProjects" element={<ProjectsTrackerProjects />}></Route>
            <Route path="/projectsTrackerIndividual" element={<ProjectsTrackerIndividual />}></Route>
            <Route path="/performanceTracker" element={<PerformanceTracker />}></Route>
            <Route path="/hubspotLink" element={<HubspotLink />}></Route>
            <Route path="/sandbox" element={<Sandbox />}></Route>
            <Route path="/xeroConnectionWindow" element={<XeroConnectionWindow />}></Route>
            <Route path="/maintenance" element={<MaintenanceScreen />}></Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Consultant, ROLES.VersatilityAdmin, ROLES.VersatilityAdminConsultant]} />}>
              <Route path="/newUsers" element={<NewUsers />}></Route>
            </Route>

          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Consultant, ROLES.Verifier, ROLES.ExternalConsultant, ROLES.VersatilityAdminConsultant, ROLES.VersatilityAdminVerifier]} />}>

            <Route path="/viewClient/:clientId" element={<ViewClient />}></Route>

            <Route path="/listOrganisations" element={<ListOrganisations />}></Route>
            <Route path="/viewOrganisation/:organisationId" element={<ViewOrganisation />}></Route>
            <Route path="/viewOrganisationFootprint/:organisationId/:footprintId" element={<ViewOrganisationFootprint />}></Route>
            <Route path="/addOrUpdateOrganisation/:organisationId" element={<AddOrUpdateOrganisation />}></Route>
            <Route path="/addOrUpdateOrganisationFootprint/:organisationId/:footprintId" element={<AddOrUpdateOrganisationFootprint />}></Route>
            <Route path="/addOrUpdateOrganisationFootprintItem/:organisationId/:footprintId/:footprintItemId" element={<AddOrUpdateOrganisationFootprintItem />}></Route>
            <Route path="/viewOrganisationFootprintItemHelperTool/:organisationId/:footprintId/:footprintItemId" element={<ViewOrganisationFootprintItemHelperTool />}></Route>
            <Route path="/viewOrganisationFootprintItemHelperToolAllSubItems/:organisationId/:footprintId" element={<ViewOrganisationFootprintItemHelperToolAllSubItems />}></Route>
            <Route path="/addOrUpdateOrganisationFootprintItemClientView/:organisationId/:footprintId/:footprintItemId" element={<AddOrUpdateOrganisationFootprintItemClientView />}></Route>
            <Route path="/dashboardOrganisationsWithoutTargetCharts" element={<DashboardOrganisationsWithoutTargetCharts />}></Route>
            <Route path="/viewDataCollectionTracker" element={<ViewDataCollectionTracker />}></Route>
            <Route path="/dashboardOrganisationsInteractiveReport" element={<DashboardOrganisationsInteractiveReport />}></Route>
            <Route path="/dashboardOrganisationsTargetChartsOnly" element={<DashboardOrganisationsTargetChartsOnly />}></Route>

            <Route path="/listEvents" element={<ListEvents />}></Route>
            <Route path="/viewEvent/:eventId" element={<ViewEvent />}></Route>
            <Route path="/viewEventFootprint/:eventId/:footprintId" element={<ViewEventFootprint />}></Route>
            <Route path="/addOrUpdateEvent/:eventId" element={<AddOrUpdateEvent />}></Route>
            <Route path="/addOrUpdateEventFootprint/:eventId/:footprintId" element={<AddOrUpdateEventFootprint />}></Route>
            <Route path="/viewEventFootprintItemHelperTool/:eventId/:footprintId/:footprintItemId" element={<ViewEventFootprintItemHelperTool />}></Route>
            <Route path="/addOrUpdateEventFootprintItemClientView/:eventId/:footprintId/:footprintItemId" element={<AddOrUpdateEventFootprintItemClientView />}></Route>
            <Route path="/addOrUpdateEventFootprintItem/:eventId/:footprintId/:footprintItemId" element={<AddOrUpdateEventFootprintItem />}></Route>
            <Route path="/dashboardEventsWithoutTargetCharts" element={<DashboardEventsWithoutTargetCharts />}></Route>
            <Route path="/dashboardEventsTargetChartsOnly" element={<DashboardEventsTargetChartsOnly />}></Route>

            <Route path="/viewBuilding/:buildingId" element={<ViewBuilding />}></Route>
            <Route path="/viewBREEAMSiteVisitReport/:buildingId/:assessmentId" element={<ViewBREEAMSiteVisitReport />}></Route>
            <Route path="/viewBREEAMComplianceLetter/:buildingId/:assessmentId/:stakeholder" element={<ViewBREEAMComplianceLetter />}></Route>
            <Route path="/viewBREEAMInformationScheduleReport/:buildingId/:assessmentId/:stakeholder" element={<ViewBREEAMInformationScheduleReport />}></Route>
            <Route path="/addOrUpdateBuilding/:id" element={<AddOrUpdateBuilding />}></Route>
            <Route path="/viewBuildingOperationalCarbonEmissionsAssessment/:buildingId/:buildingOperationalCarbonEmissionsAssessmentId" element={<ViewBuildingOperationalCarbonEmissionsAssessment />}></Route>
            <Route path="/viewBuildingBREEAMAssessment/:buildingId/:assessmentId" element={<ViewBuildingBREEAMAssessment />}></Route>
            <Route path="/viewBuildingConstructionSiteImpactAssessment/:buildingId/:siteLogId" element={<ViewBuildingConstructionSiteImpactAssessment />}></Route>
            <Route path="/addOrUpdateBuildingOperationalCarbonEmissionsAssessmentItem/:buildingId/:buildingOperationalCarbonEmissionsAssessmentId/:buildingOperationalCarbonEmissionItemId" element={<AddOrUpdateBuildingOperationalCarbonEmissionsAssessmentItem />}></Route>
            <Route path="/addOrUpdateBuildingBREEAMAssessmentCredit/:buildingId/:assessmentId/:BREEAMCreditId" element={<AddOrUpdateBuildingBREEAMAssessmentCredit />}></Route>
            <Route path="/dashboardBREEAMAssessments/:buildingId/:assessmentId" element={<DashboardBREEAMAssessments />}></Route>
            <Route path="/dashboardOccupantSatisfaction" element={<DashboardOccupantSatisfaction />}></Route>
            <Route path="/addOrUpdateBuildingEPC/:buildingId/:buildingEPCId" element={<AddOrUpdateBuildingEPC />}></Route>
            <Route path="/addOrUpdateBuildingConstructionSiteImpactAssessment/:buildingId/:buildingConstructionSiteImpactAssessmentId" element={<AddOrUpdateBuildingConstructionSiteImpactAssessment />}></Route>
            <Route path="/addOrUpdateBuildingEnvironmentalAssessment/:buildingId/:buildingEnvironmentalAssessmentId" element={<AddOrUpdateBuildingEnvironmentalAssessment />}></Route>
            <Route path="/addOrUpdateBuildingConstructionCarbonEmissions/:buildingId/:buildingConstructionCarbonEmissionsId" element={<AddOrUpdateBuildingConstructionCarbonEmissions />}></Route>
            <Route path="/addOrUpdateBuildingOperationalCarbonEmissions/:buildingId/:buildingOperationalCarbonEmissionsId" element={<AddOrUpdateBuildingOperationalCarbonEmissions />}></Route>
            <Route path="/addOrUpdateBuildingBREEAMAssessment/:buildingId/:buildingBREEAMAssessmentId" element={<AddOrUpdateBuildingBREEAMAssessment />}></Route>
            <Route path="/listBuildings" element={<ListBuildings />}></Route>
            <Route path="/listBuildingPortfolios" element={<ListBuildingPortfolios />}></Route>

          </Route>


          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.VersatilityAdminConsultant, ROLES.VersatilityAdminVerifier]} />}>

            <Route path="/listClients" element={<ListClients />}></Route>

            <Route path="/listOrganisations" element={<ListOrganisations />}></Route>
            <Route path="/viewOrganisation/:organisationId" element={<ViewOrganisation />}></Route>
            <Route path="/viewOrganisationFootprint/:organisationId/:footprintId" element={<ViewOrganisationFootprint />}></Route>
            <Route path="/addOrUpdateOrganisationFootprint/:organisationId/:footprintId" element={<AddOrUpdateOrganisationFootprint />}></Route>
            <Route path="/addOrUpdateOrganisationFootprintItem/:organisationId/:footprintId/:footprintItemId" element={<AddOrUpdateOrganisationFootprintItem />}></Route>
            <Route path="/listStores" element={<ListStores />}></Route>
            <Route path="/addOrUpdateOrganisation/:organisationId" element={<AddOrUpdateOrganisation />}></Route>
            <Route path="/addOrUpdateOrganisationFootprintItemClientView/:eventId/:footprintId/:footprintItemId" element={<AddOrUpdateOrganisationFootprintItemClientView />}></Route>

            <Route path="/listProjects" element={<ListProjects />}></Route>
            <Route path="/addOrUpdateProject/:projectId" element={<AddOrUpdateProject />}></Route>
            <Route path="/addOrUpdateCredit/:creditId" element={<AddOrUpdateCredit />}></Route>
            <Route path="/viewProject/:projectId" element={<ViewProject />}></Route>
            <Route path="/viewCredit/:creditId" element={<ViewCredit />}></Route>
            <Route path="/listCredits" element={<ListCredits />}></Route>

            <Route path="/listProjectsPublic" element={<ListProjectsPublic />}></Route>
            <Route path="/viewProjectPublic/:projectId" element={<ViewProjectPublic />}></Route>
            <Route path="/viewCreditPublic/:creditId" element={<ViewCreditPublic />}></Route>
            <Route path="/listCreditsPublic" element={<ListCreditsPublic />}></Route>

            <Route path="/listContacts" element={<ListContacts />}></Route>
            <Route path="/addOrUpdateContact/:clientId/:contactId" element={<AddOrUpdateContact />}></Route>

            <Route path="/listUsers" element={<ListUsers />}></Route>
            <Route path="/addOrUpdateUser/:userId" element={<AddOrUpdateUser />}></Route>
            {/* <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Consultant, ROLES.VersatilityAdmin, ROLES.VersatilityAdminConsultant]} />}>
              <Route path="/newUsers" element={<NewUsers />}></Route>
            </Route> */}

            <Route path="/viewBuilding/:buildingId" element={<ViewBuilding />}></Route>
            <Route path="/viewBREEAMSiteVisitReport/:buildingId/:assessmentId" element={<ViewBREEAMSiteVisitReport />}></Route>
            <Route path="/viewBREEAMComplianceLetter/:buildingId/:assessmentId/:stakeholder" element={<ViewBREEAMComplianceLetter />}></Route>
            <Route path="/viewBREEAMInformationScheduleReport/:buildingId/:assessmentId/:stakeholder" element={<ViewBREEAMInformationScheduleReport />}></Route>
            <Route path="/addOrUpdateBuilding/:id" element={<AddOrUpdateBuilding />}></Route>
            <Route path="/viewBuildingOperationalCarbonEmissionsAssessment/:buildingId/:buildingOperationalCarbonEmissionsAssessmentId" element={<ViewBuildingOperationalCarbonEmissionsAssessment />}></Route>
            <Route path="/viewBuildingBREEAMAssessment/:buildingId/:assessmentId" element={<ViewBuildingBREEAMAssessment />}></Route>
            <Route path="/viewBuildingConstructionSiteImpactAssessment/:buildingId/:siteLogId" element={<ViewBuildingConstructionSiteImpactAssessment />}></Route>
            <Route path="/addOrUpdateBuildingOperationalCarbonEmissionsAssessmentItem/:buildingId/:buildingOperationalCarbonEmissionsAssessmentId/:buildingOperationalCarbonEmissionItemId" element={<AddOrUpdateBuildingOperationalCarbonEmissionsAssessmentItem />}></Route>
            <Route path="/addOrUpdateBuildingBREEAMAssessmentCredit/:buildingId/:assessmentId/:BREEAMCreditId" element={<AddOrUpdateBuildingBREEAMAssessmentCredit />}></Route>
            <Route path="/dashboardBREEAMAssessments/:buildingId/:assessmentId" element={<DashboardBREEAMAssessments />}></Route>
            <Route path="/dashboardOccupantSatisfaction" element={<DashboardOccupantSatisfaction />}></Route>
            <Route path="/addOrUpdateBuildingEPC/:buildingId/:buildingEPCId" element={<AddOrUpdateBuildingEPC />}></Route>
            <Route path="/addOrUpdateBuildingEnvironmentalAssessment/:buildingId/:buildingEnvironmentalAssessmentId" element={<AddOrUpdateBuildingEnvironmentalAssessment />}></Route>
            <Route path="/addOrUpdateBuildingConstructionCarbonEmissions/:buildingId/:buildingConstructionCarbonEmissionsId" element={<AddOrUpdateBuildingConstructionCarbonEmissions />}></Route>
            <Route path="/addOrUpdateBuildingOperationalCarbonEmissions/:buildingId/:buildingOperationalCarbonEmissionsId" element={<AddOrUpdateBuildingOperationalCarbonEmissions />}></Route>
            <Route path="/addOrUpdateBuildingBREEAMAssessment/:buildingId/:buildingBREEAMAssessmentId" element={<AddOrUpdateBuildingBREEAMAssessment />}></Route>
            <Route path="/listBuildings" element={<ListBuildings />}></Route>
            <Route path="/listBuildingPortfolios" element={<listBuildingPortfolios />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/dashboardOrganisationsWithoutTargetCharts" element={<DashboardOrganisationsWithoutTargetCharts />}></Route>
            <Route path="/viewDataCollectionTracker" element={<ViewDataCollectionTracker />}></Route>
            <Route path="/dashboardOrganisationsInteractiveReport" element={<DashboardOrganisationsInteractiveReport />}></Route>
            <Route path="/dashboardOrganisationsTargetChartsOnly" element={<DashboardOrganisationsTargetChartsOnly />}></Route>
            <Route path="/dashboardEventsWithoutTargetCharts" element={<DashboardEventsWithoutTargetCharts />}></Route>
            <Route path="/dashboardEventsTargetChartsOnly" element={<DashboardEventsTargetChartsOnly />}></Route>
            <Route path="/addOrUpdateStore/:storeId" element={<AddOrUpdateStore />}></Route>

            <Route path="/" element={<ListClientProjects />}></Route>

            <Route path="/viewStore/:storeId" element={<ViewStore />}></Route>
            <Route path="/viewSite/:siteId" element={<ViewSite />}></Route>
            <Route path="/addOrUpdateSite/:siteId" element={<AddOrUpdateSite />}></Route>
            <Route path="/listSites" element={<ListSites />}></Route>
            <Route path="/addOrUpdateProduct/:productId" element={<AddOrUpdateProduct />}></Route>
            <Route path="/listProducts" element={<ListProducts />}></Route>
            <Route path="/addOrUpdateEvent/:eventId" element={<AddOrUpdateEvent />}></Route>
            <Route path="/listEvents" element={<ListEvents />}></Route>
            <Route path="/viewEvent/:eventId" element={<ViewEvent />}></Route>
            <Route path="/viewEventFootprint/:eventId/:footprintId" element={<ViewEventFootprint />}></Route>
            <Route path="/addOrUpdateEventFootprint/:eventId/:footprintId" element={<AddOrUpdateEventFootprint />}></Route>
            <Route path="/addOrUpdateEventFootprintItem/:eventId/:footprintId/:footprintItemId" element={<AddOrUpdateEventFootprintItem />}></Route>
            <Route path="/viewEventFootprintItemHelperTool/:eventId/:footprintId/:footprintItemId" element={<ViewEventFootprintItemHelperTool />}></Route>
            <Route path="/viewOrganisationFootprintItemHelperTool/:organisationId/:footprintId/:footprintItemId" element={<ViewOrganisationFootprintItemHelperTool />}></Route>
            <Route path="/viewOrganisationFootprintItemHelperToolAllSubItems/:organisationId/:footprintId" element={<ViewOrganisationFootprintItemHelperToolAllSubItems />}></Route>
            <Route path="/addOrUpdateEventFootprintItemClientView/:eventId/:footprintId/:footprintItemId" element={<AddOrUpdateEventFootprintItemClientView />}></Route>
    
            <Route path="/dashboardClientProjects" element={<DashboardClientProjects />}></Route>
            <Route path="/dashboardClientProjectsInvoicing" element={<DashboardClientProjectsInvoicing />}></Route>
            <Route path="/projectsTrackerActivities" element={<ProjectsTrackerActivities />}></Route>

          </Route>

          <Route path="/register" element={<Register />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/loginncs" element={<LoginNCS />}></Route>
          <Route path="/comingSoon" element={<ComingSoon />}></Route>
          <Route path="/errorPage" element={<ErrorPage />}></Route>
          <Route path='/forgot_password_step_1' element={<ForgotPassword />}></Route>
          <Route path="/forgot_password_step_2/:token" element={<ResetPasswordLoggedOut />}></Route>
          <Route path="/mfa_validate" element={<MFAValidateScreen />}></Route>

        </Routes>
      </Router>
    </div>
  );
}

export default App;


import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Options from '../UI/Options'
import ReactSelect from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import { useNavigate, useMatch } from 'react-router-dom'
import { Doughnut, Bar, Line } from 'react-chartjs-2'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import ReactTooltip from "react-tooltip"
import { v4 as uuidv4 } from 'uuid'
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

const ListOrganisationFootprintItems = (props) => {

  const match = useMatch('/viewOrganisationFootprint/:organisationId/:footprintId')

  const internationalNumberFormat = new Intl.NumberFormat('en-US')

  const [loading, setLoading] = useState(false)
  const [importFromRoadmapToggle, setImportFromRoadmapToggle] = useState(false)
  const [organisationFootprint, setOrganisationFootprint] = useState({})
  const [scopeItems, setScopeItems] = useState([])
  const [scopeItemsFiltered, setItemsFiltered] = useState([])
  const [scopeItemsFilteredWithGSD, setItemsFilteredWithGSD] = useState([])
  const [scopeItemsWithGSD, setItemsWithGSD] = useState([])

  const [selectedItemCategory, setSelectedItemCategory] = useState([{label: "", value: ""}])
  const [selectedItemSubCategory, setSelectedItemSubCategory] = useState([{label: "", value: ""}])
  const [selectedDescription, setSelectedDescription] = useState([{label: "", value: ""}])
  const [selectedVerified, setSelectedVerified] = useState("All")
  const [selectedSubmitted, setSelectedSubmitted] = useState("All")

  const [showModal, setShowModal] = useState(false)
  const [valueToSendToModal, setValueToSendToModal] = useState('')
  const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
  const [fieldToSendToModal, setFieldToSendToModal] = useState('')
  const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
  const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')
  const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')

  const [showToolTip, setShowToolTip] = useState(false)

  //   const [allTargetFootprints, setAllTargetFootprints] = useState([])
  //   const [allForecastFootprints, setAllForecastFootprints] = useState([])
  //   const [allActualFootprints, setAllActualFootprints] = useState([])
  const [footprintsAdded, setFootprintsAdded] = useState(false)

  const [years, setYears] = useState([])

  const [carbonEmissionsPerYearActuals, setCarbonEmissionsPerYearActuals] = useState([]);
  const [carbonEmissionsPerYearProjections, setCarbonEmissionsPerYearProjections] = useState([]);
  const [carbonEmissionsPerYearTargets, setCarbonEmissionsPerYearTargets] = useState([]);

  const [carbonEmissionsPerYearActualsScope3, setCarbonEmissionsPerYearActualsScope3] = useState([]);
  const [carbonEmissionsPerYearProjectionsScope3, setCarbonEmissionsPerYearProjectionsScope3] = useState([]);
  const [carbonEmissionsPerYearTargetsScope3, setCarbonEmissionsPerYearTargetsScope3] = useState([]);

  //   const [stackedLineChartCarbonEmissions, setStackedLineChartCarbonEmissions] = useState([]);
  const [animationForecast, setanimationForecast] = useState(false)
  const [animationTarget, setanimationTarget] = useState(false)
  const [animationReset, setanimationReset] = useState(false)

  const [showReductionPlan, setShowReductionPlan] = useState(false)
  const [showUncertaintyAssessment, setShowUncertaintyAssessment] = useState(false)
  const [showVerification, setShowVerification] = useState(false)

  const [targetForThisYear, setTargetForThisYear] = useState(0)
  const [targetForThisYearScope3, setTargetForThisYearScope3] = useState(0)
  const [targetGBPTurnover, setTargetGBPTurnover] = useState(0)

  const [file, setFile] = useState()
  const fileReader = new FileReader()

  const handleOnChange = (e) => {
      setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
      e.preventDefault();

      if (file) {
          fileReader.onload = function (organisation) {
              const text = organisation.target.result;
              csvFileToArray(text);
          };

          fileReader.readAsText(file);
      }
  };

  const csvFileToArray = async string => {
        const csvHeader = ["subCategory", "description", "year", "emissions"]
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

        //drop the last line (blank)
        csvRows.pop()

        const array = csvRows.map(i => {

            //const values = i.split(",")

            const values = i.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)

            var field

            for (field = 0; field < values.length; field++) {

                values[field] = values[field].replace(/^"|"$/g, '')
                values[field] = values[field].replace(/""/g, '"')
                values[field] = values[field].replace(/^"|"$/, '')

                //If the last field has any commas then a traling quotation mark (") still remains before the end of the line
                //This code checks for this and removes the quotation mark (")

                if (values[field].slice(values[field].length - 2, values[field].length - 1) === '"') {
                    values[field] = values[field].slice(0, values[field].length - 2)
                }
            }

            const obj = csvHeader.reduce((object, header, index) => {
                object[header] = values[index];
                return object;
            }, {});
            return obj;

        });

        var itemIndex

        //Add code here to add organisationId and organisationFootprintId

        for (itemIndex = 0; itemIndex < array.length; itemIndex++) {
            array[itemIndex].emissions = Number(array[itemIndex].emissions = Number(array[itemIndex].emissions)
            )
        }

        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        var year
        var subCategoryItem
        var finalTargetYear = array.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0)[array.length-1].year
        var newFootprint
        var baseFootprintYear = Number(organisationFootprint.organisationFootprintYear)
        var arrayItemsForThatYear = []
    
        await deleteFootprints("Forecast")
        setanimationForecast(true)
        for (year = baseFootprintYear; year <= finalTargetYear; year++) {
    
            newFootprint = { ...organisationFootprint, organisationFootprintRef: year + " Forecast", organisationFootprintYear: year.toString(), organisationFootprintType: "Forecast", organisationFootprintItems: [] }
    
            arrayItemsForThatYear = array.filter(item => Number(item.year) === year)

            for (subCategoryItem = 0; subCategoryItem < arrayItemsForThatYear.length; subCategoryItem++) {

                newFootprint.organisationFootprintItems.push({ ...organisationFootprint.organisationFootprintItems[0], itemCategory: arrayItemsForThatYear[subCategoryItem].subCategory.slice(0,1), itemSubCategory: arrayItemsForThatYear[subCategoryItem].subCategory, carbonEmissions: arrayItemsForThatYear[subCategoryItem].emissions, footprintSubItems: [], id: uuidv4() })
            }
    
          await addForm({ ...newFootprint })

        }
    
        setFootprintsAdded(true)
        setImportFromRoadmapToggle(false)

        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    }


  const aggregateCategoriesArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.itemCategory === val.itemCategory);
      if (index === -1) {
        acc.push({
          itemCategory: val.itemCategory,
        });
      };
      return acc.sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : 0);
    }, []);
  };

  const aggregateSubCategoriesArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.itemSubCategory === val.itemSubCategory);
      if (index === -1) {
        acc.push({
          itemSubCategory: val.itemSubCategory,
        });
      };
      return acc.sort((item1, item2) => item1.itemSubCategory < item2.itemSubCategory ? -1 : item1.itemSubCategory > item2.itemSubCategory ? 1 : 0);
    }, []);
  };

  const aggregateDescriptionsArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.itemDescription === val.itemDescription);
      if (index === -1) {
        acc.push({
            itemDescription: val.itemDescription,
        });
      };
      return acc.sort((item1, item2) => item1.itemDescription < item2.itemDescription ? -1 : item1.itemDescription > item2.itemDescription ? 1 : 0);
    }, []);
  };

  const aggregateVerifiedArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.verified === val.verified);
      if (index === -1) {
        acc.push({
            verified: val.verified,
        });
      };
      return acc.sort((item1, item2) => item1.verified < item2.verified ? -1 : item1.verified > item2.verified ? 1 : 0);
    }, []);
  };

  const aggregateSubmittedArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.submitted === val.submitted);
      if (index === -1) {
        acc.push({
            submitted: val.submitted,
        });
      };
      return acc.sort((item1, item2) => item1.submitted < item2.submitted ? -1 : item1.submitted > item2.submitted ? 1 : 0);
    }, []);
  };

  const styles = {
    option: provided => ({
      ...provided,
      color: 'black'
    }),
    control: provided => ({
      ...provided,
      color: 'black',
      width: 160,
      fontSize: 13
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black'
    }),
    menu: provided => ({
      ...provided,
      color: 'black',
      width: 160,
      fontSize: 13
    }),
  }

  const stylesThin = {
    option: provided => ({
      ...provided,
      color: 'black'
    }),
    control: provided => ({
      ...provided,
      color: 'black',
      width: 70,
      fontSize: 13
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black'
    }),
    menu: provided => ({
      ...provided,
      color: 'black',
      width: 70,
      fontSize: 13
    }),
  }

  const clearFilters = () => {

    // setSelectedItemCategory('All')
    setSelectedItemCategory(aggregateCategoriesArray(scopeItems).map((item) => {
        return {
          label: item.itemCategory,
          value: item.itemCategory
        }
      }))
    // setSelectedItemSubCategory('All')
    setSelectedItemSubCategory(aggregateSubCategoriesArray(scopeItems).map((item) => {
        return {
          label: item.itemSubCategory,
          value: item.itemSubCategory
        }
      }))

    setSelectedDescription(aggregateDescriptionsArray(scopeItems).map((item) => {
    return {
        label: item.itemDescription,
        value: item.itemDescription
    }
    }))

    setSelectedVerified('All')
    setSelectedSubmitted('All')

  }

  const chartColours = [
    "#0090CA",
    "#00A1AA",
    "#00518C",
    "#B9D3DC",
    "#05cfc0",
    "#0a4f4e",
    "#158a2c",
    "#78de4c",
    "#738a69",
    "#c2df7d",
    "#E89B26",
    "#FCE3BD",
    "#592c19",
    "#ff7276",
    "#FF7C40",
    "#FCF062",
    "#7BA7BC",
    "#5459C1",
    "#753fc2",
    "#827db8",
    "#90286b",
    "#ef66f0",
  ];
  const chartHoverColours = [
    "#10475C",
    "#007980",
    "#0E2343",
    "#96abb3",
    "#036860",
    "#0B4516",
    "#052827",
    "#3C6F26",
    "#3A4535",
    "#61703F",
    "#a16b1a",
    "#CEB691",
    "#2E211C",
    "#802741",
    "#732912",
    "#7A6009",
    "#4d6875",
    "#303372",
    "#3B2061",
    "#413F5C",
    "#481436",
    "#783378",
  ];

  const navigate = useNavigate()

  const cloneOrganisationFootprintItem = async (organisationFootprintItem) => {

    //Code here to clone the footprintItem

    var newFootprintItems = [ ...scopeItems ]

    var newDescription = organisationFootprintItem.itemDescription + " Copy"

    var newSubItems = []

    var subItem

    var newFootprintItemId = uuidv4()

    newFootprintItems.push({...organisationFootprintItem, id: newFootprintItemId, filesUploaded: 0, itemDescription: newDescription, commentThread: [], submitted: "", verified: "", submissionComment: "", verificationComment: "" })

    setScopeItems(newFootprintItems)
    setItemsFiltered(newFootprintItems)
    setOrganisationFootprint({...organisationFootprint, organisationFootprintItems: newFootprintItems})

    setSelectedDescription(aggregateDescriptionsArray(newFootprintItems).map((item) => {
        return {
            label: item.itemDescription,
            value: item.itemDescription
        }
        }))

    await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId, { ...organisationFootprint, organisationFootprintItems: newFootprintItems})

    //Code here to clone all the subItems for that footprint

    //Get all subItems
    axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems/' + organisationFootprintItem.id)
    .then((res) => {
        // console.log(res.data.filter(item => item.footprintItemId === match.params.footprintItemId))
        newSubItems = res.data
            //loop through new subItems and change uuids, footprintItemIds
        for (subItem = 0; subItem < newSubItems.length; subItem++) {

            newSubItems[subItem].footprintSubItemId = uuidv4()
            newSubItems[subItem].footprintItemId = newFootprintItemId

        }

        if (newSubItems.length > 0) {
        //add new subItems to DB
            axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems', newSubItems)
        }
    })
    .catch((err) => {
        console.log(err);
    })

  }

  const deleteOrganisationFootprintItem = async (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {

      //code here to get array index of scope item id=id

    //   console.log(organisationFootprint.organisationFootprintItems.find(scopeItem => scopeItem.id === id).submitted)

    //Code needed here to check if there are any subItems 
    //DO NOT allow delete if there are sub items

      var subItemsExist = false

      await axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems/' + id)
      .then((res) => {
        if (res.data.length > 0) {
            subItemsExist = true
        } else {
            subItemsExist = false
        }
      })
      .catch((err) => {
        console.log(err);
      })

      if (!subItemsExist) {

      if (organisationFootprint.organisationFootprintItems.find(scopeItem => scopeItem.id === id).submitted === "" && organisationFootprint.organisationFootprintItems.find(scopeItem => scopeItem.id === id).verified === "" && organisationFootprint.organisationFootprintItems.find(scopeItem => scopeItem.id === id).filesUploaded === 0) {

        const index = organisationFootprint.organisationFootprintItems.findIndex(scopeItem => scopeItem.id === id)

        organisationFootprint.organisationFootprintItems.splice(index, 1);

        // const organisationId = organisationFootprint.organisationId
        // const organisationFootprintRef = organisationFootprint.organisationFootprintRef
        // const organisationFootprintYear = organisationFootprint.organisationFootprintYear
        // const organisationFootprintDate = organisationFootprint.organisationFootprintDate
        // const organisationFootprintRecordConsolidation = organisationFootprint.organisationFootprintRecordConsolidation
        const organisationFootprintItems = organisationFootprint.organisationFootprintItems
        // const organisationFootprintType = organisationFootprint.organisationFootprintType
        //   const organisationFootprintNumberOfAttendees = organisationFootprint.organisationFootprintNumberOfAttendees
        //   const organisationFootprintLocation = organisationFootprint.organisationFootprintLocation
        // const organisationFootprintNotes = organisationFootprint.organisationFootprintNotes
        // const organisationFootprintSummaryComments = organisationFootprint.organisationFootprintSummaryComments
        // const organisationFootprintScope1Comments = organisationFootprint.organisationFootprintScope1Comments
        // const organisationFootprintScope2Comments = organisationFootprint.organisationFootprintScope2Comments
        // const organisationFootprintScope3Comments = organisationFootprint.organisationFootprintScope3Comments
        // const organisationFootprintCertification = organisationFootprint.organisationFootprintCertification
        // const organisationFootprintConsultant = organisationFootprint.organisationFootprintConsultant
        // const organisationFootprintClientContact = organisationFootprint.organisationFootprintClientContact
        // const organisationFootprintVerifier = organisationFootprint.organisationFootprintVerifier
        // const organisationFootprintTargetYear = organisationFootprint.organisationFootprintTargetYear
        // const organisationFootprintOverallReductionTargets = organisationFootprint.organisationFootprintOverallReductionTargets
        // const organisationTurnoverGBP = organisationFootprint.organisationTurnoverGBP
        // const organisationFootprintObjectives = organisationFootprint.organisationFootprintObjectives
        // const organisationFootprintScopeBoundaries = organisationFootprint.organisationFootprintScopeBoundaries
        // const organisationFootprintLevelOfAssurance = organisationFootprint.organisationFootprintLevelOfAssurance
        // const organisationFootprintMateriality = organisationFootprint.organisationFootprintMateriality
        // const organisationFootprintVerificationActivitiesAndTechniques = organisationFootprint.organisationFootprintVerificationActivitiesAndTechniques

        const form = { ...organisationFootprint, organisationFootprintItems}
        axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId, form)
        setItemsFiltered(scopeItemsFiltered.filter((organisationFootprintItem) => organisationFootprintItem.id !== id))
        //Code here to delete any subItems for this footprintItemId


      } else if (organisationFootprint.organisationFootprintItems.find(scopeItem => scopeItem.id === id).submitted !== "" || organisationFootprint.organisationFootprintItems.find(scopeItem => scopeItem.id === id).verified !== "") {

        window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

      }

      else if (organisationFootprint.organisationFootprintItems.find(scopeItem => scopeItem.id === id).filesUploaded > 0) {

        window.alert("This footprint item has documents uploaded against it.  Please ensure any uploaded documents have been deleted")

      }

    } else {

        window.alert("This footprint item has sub items associated to it.  Please ensure any sub items have been deleted")

    }

    }
  }

//   const editOrganisationFootprintItem = (id) => {
//     navigate(`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/${id}`);
//   }

  const uncertaintyRatingPerItem = (dataQualityTechnologyClassification, dataQualityTimeClassification, dataQualityGeographyClassification, dataQualityCompletenessClassification, dataQualityReliabilityClassification, emissionsFactorTechnologyClassification, emissionsFactorTimeClassification, emissionsFactorGeographyClassification, emissionsFactorCompletenessClassification, emissionsFactorReliabilityClassification) => {

    var dataQualityTechnologyRating, dataQualityTimeRating, dataQualityGeographyRating, dataQualityCompletenessRating, dataQualityReliabilityRating, emissionsFactorTechnologyRating, emissionsFactorTimeRating, emissionsFactorGeographyRating, emissionsFactorCompletenessRating, emissionsFactorReliabilityRating
    var overallRating

    if (dataQualityTechnologyClassification === "Very Good") { dataQualityTechnologyRating = 1 }
    if (dataQualityTechnologyClassification === "Good") { dataQualityTechnologyRating = 2 }
    if (dataQualityTechnologyClassification === "Fair") { dataQualityTechnologyRating = 3 }
    if (dataQualityTechnologyClassification === "Poor") { dataQualityTechnologyRating = 4 }

    if (dataQualityTimeClassification === "Very Good") { dataQualityTimeRating = 1 }
    if (dataQualityTimeClassification === "Good") { dataQualityTimeRating = 2 }
    if (dataQualityTimeClassification === "Fair") { dataQualityTimeRating = 3 }
    if (dataQualityTimeClassification === "Poor") { dataQualityTimeRating = 4 }

    if (dataQualityGeographyClassification === "Very Good") { dataQualityGeographyRating = 1 }
    if (dataQualityGeographyClassification === "Good") { dataQualityGeographyRating = 2 }
    if (dataQualityGeographyClassification === "Fair") { dataQualityGeographyRating = 3 }
    if (dataQualityGeographyClassification === "Poor") { dataQualityGeographyRating = 4 }

    if (dataQualityCompletenessClassification === "Very Good") { dataQualityCompletenessRating = 1 }
    if (dataQualityCompletenessClassification === "Good") { dataQualityCompletenessRating = 2 }
    if (dataQualityCompletenessClassification === "Fair") { dataQualityCompletenessRating = 3 }
    if (dataQualityCompletenessClassification === "Poor") { dataQualityCompletenessRating = 4 }

    if (dataQualityReliabilityClassification === "Very Good") { dataQualityReliabilityRating = 1 }
    if (dataQualityReliabilityClassification === "Good") { dataQualityReliabilityRating = 2 }
    if (dataQualityReliabilityClassification === "Fair") { dataQualityReliabilityRating = 3 }
    if (dataQualityReliabilityClassification === "Poor") { dataQualityReliabilityRating = 4 }

    if (emissionsFactorTechnologyClassification === "Very Good") { emissionsFactorTechnologyRating = 1 }
    if (emissionsFactorTechnologyClassification === "Good") { emissionsFactorTechnologyRating = 2 }
    if (emissionsFactorTechnologyClassification === "Fair") { emissionsFactorTechnologyRating = 3 }
    if (emissionsFactorTechnologyClassification === "Poor") { emissionsFactorTechnologyRating = 4 }

    if (emissionsFactorTimeClassification === "Very Good") { emissionsFactorTimeRating = 1 }
    if (emissionsFactorTimeClassification === "Good") { emissionsFactorTimeRating = 2 }
    if (emissionsFactorTimeClassification === "Fair") { emissionsFactorTimeRating = 3 }
    if (emissionsFactorTimeClassification === "Poor") { emissionsFactorTimeRating = 4 }

    if (emissionsFactorGeographyClassification === "Very Good") { emissionsFactorGeographyRating = 1 }
    if (emissionsFactorGeographyClassification === "Good") { emissionsFactorGeographyRating = 2 }
    if (emissionsFactorGeographyClassification === "Fair") { emissionsFactorGeographyRating = 3 }
    if (emissionsFactorGeographyClassification === "Poor") { emissionsFactorGeographyRating = 4 }

    if (emissionsFactorCompletenessClassification === "Very Good") { emissionsFactorCompletenessRating = 1 }
    if (emissionsFactorCompletenessClassification === "Good") { emissionsFactorCompletenessRating = 2 }
    if (emissionsFactorCompletenessClassification === "Fair") { emissionsFactorCompletenessRating = 3 }
    if (emissionsFactorCompletenessClassification === "Poor") { emissionsFactorCompletenessRating = 4 }

    if (emissionsFactorReliabilityClassification === "Very Good") { emissionsFactorReliabilityRating = 1 }
    if (emissionsFactorReliabilityClassification === "Good") { emissionsFactorReliabilityRating = 2 }
    if (emissionsFactorReliabilityClassification === "Fair") { emissionsFactorReliabilityRating = 3 }
    if (emissionsFactorReliabilityClassification === "Poor") { emissionsFactorReliabilityRating = 4 }

    overallRating = ((dataQualityTechnologyRating + dataQualityTimeRating + dataQualityGeographyRating + dataQualityCompletenessRating + dataQualityReliabilityRating) / 5) * ((emissionsFactorTechnologyRating + emissionsFactorTimeRating + emissionsFactorGeographyRating + emissionsFactorCompletenessRating + emissionsFactorReliabilityRating) / 5)

    if (overallRating <= 1) { return "Very Good" }
    if (overallRating <= 2) { return "Good" }
    if (overallRating <= 4) { return "Fair" }
    if (overallRating > 4) { return "Poor" }

  }

  const impactPercentage = (carbonEmissions) => {
    return ((carbonEmissions / scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100)
  }

  const impactCategory = (carbonEmissions) => {

    if ((carbonEmissions / scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100 >= 25) {
      return "High"
    }

    if ((carbonEmissions / scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100 >= (100 / scopeItemsFiltered.length)) {
      return "Medium"
    }

    if ((carbonEmissions / scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100 >= 1) {
      return "Low"
    }

    return "Negligible"

  }

  const priorityCategory = (controlCategory, impactCategory) => {

    if (controlCategory === "Full Control") {

      if (impactCategory === "High") {
        return "High"
      }

      if (impactCategory === "Medium") {
        return "High"
      }

      if (impactCategory === "Low") {
        return "High"
      }

      if (impactCategory === "Negligible") {
        return "Medium"
      }
    }

    if (controlCategory === "Partial Control") {

      if (impactCategory === "High") {
        return "High"
      }

      if (impactCategory === "Medium") {
        return "High"
      }

      if (impactCategory === "Low") {
        return "Medium"
      }

      if (impactCategory === "Negligible") {
        return "Low"
      }
    }

    if (controlCategory === "No Control") {

      if (impactCategory === "High") {
        return "Medium"
      }

      if (impactCategory === "Medium") {
        return "Medium"
      }

      if (impactCategory === "Low") {
        return "Low"
      }

      if (impactCategory === "Negligible") {
        return "-"
      }
    }

    if (controlCategory === "N/A") {

      return "-"
    }

  }

  const priorityCategoryDescription = (priorityCategory) => {

    if (priorityCategory === "High") {
      return "Reduction is required in the next year. Advised reduction target is around 5% per annum."
    }

    if (priorityCategory === "Medium") {
      return "Reduction should be prioritised and detailed plan should be developed on how to reduce emissions in the short term"
    }

    if (priorityCategory === "Low") {
      return "A long term reduction plan should be established to reduce emissions"
    }

    if (priorityCategory === "-") {
      return "No action is required"
    }

  }

  const GSD = (technologyFactor, timeFactor, geographyFactor, completenessFactor, reliabilityFactor, basicUncertaintyFactor) => {

    return Math.sqrt(Math.exp(Math.sqrt((Math.log(technologyFactor) ** 2) + (Math.log(timeFactor) ** 2) + (Math.log(geographyFactor) ** 2) + (Math.log(completenessFactor) ** 2) + (Math.log(reliabilityFactor) ** 2) + (Math.log(basicUncertaintyFactor) ** 2))))

  }

  const contribution = (GSD, carbonEmissionImpactPercentage) => {

    // console.log((carbonEmissionImpactPercentage / 100) ** 2)
    // console.log(Math.log(GSD) ** 2)

    return ((carbonEmissionImpactPercentage / 100) ** 2) * (Math.log(GSD) ** 2)

  }

  const qualitativeUncertainty = (GSD) => {

    var qualUncertainty = ""

    if (GSD > 1.25) {

        qualUncertainty = "Poor"

    }

    if (GSD <= 1.25) {

        qualUncertainty = "Fair"

    }

    if (GSD <= 1.15) {

        qualUncertainty = "Good"

    }

    if (GSD <= 1.05) {

        qualUncertainty = "Very Good"

    }

    return qualUncertainty


  }

//   const overallReductionPercentage = (priorityCategory) => {

//     if (priorityCategory === "High") {
//       return organisationFootprint.organisationFootprintOverallReductionTargets.High / 100
//     }

//     if (priorityCategory === "Medium") {
//       return organisationFootprint.organisationFootprintOverallReductionTargets.Medium / 100
//     }

//     if (priorityCategory === "Low") {
//       return organisationFootprint.organisationFootprintOverallReductionTargets.Low / 100
//     }

//     if (priorityCategory === "-") {
//       return 0
//     }
//   }

  const differenceInYears = (toYear, fromYear) => {

    if (toYear <= fromYear) {

      return 1

    } else {

      return toYear - fromYear

    }

  }

  const targetReductionPercentage = (priorityCategory, percentageOverride) => {

    if (priorityCategory === "High") {
      if (percentageOverride > 0) {
        return (((percentageOverride / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + 1)) * 100).toFixed(2)
      } else {
        return (((organisationFootprint.organisationFootprintOverallReductionTargets.High / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + 1)) * 100).toFixed(2)
      }
    }

    if (priorityCategory === "Medium") {
      if (percentageOverride > 0) {
        return (((percentageOverride / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 6)))) * 100).toFixed(2)
      } else {
        return (((organisationFootprint.organisationFootprintOverallReductionTargets.Medium / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 6)))) * 100).toFixed(2)
      }
    }

    if (priorityCategory === "Low") {
      if (percentageOverride > 0) {
        return (((percentageOverride / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 2)))) * 100).toFixed(2)
      } else {
        return (((organisationFootprint.organisationFootprintOverallReductionTargets.Low / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 2)))) * 100).toFixed(2)
      }
    }

    if (priorityCategory === "-") {
      return "0"
    }
  }

  const targetReductionYear = (priorityCategory) => {


    if (priorityCategory === "High") {
      if (Number(organisationFootprint.organisationFootprintYear) + 1 < Number(organisationFootprint.organisationFootprintTargetYear)) {
        return Number(organisationFootprint.organisationFootprintYear) + 1
      } else {
        return Number(organisationFootprint.organisationFootprintTargetYear)
      }
    }

    if (priorityCategory === "Medium") {
      if (Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 6)) < Number(organisationFootprint.organisationFootprintTargetYear)) {
        return Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 6))
      } else {
        return Number(organisationFootprint.organisationFootprintTargetYear)
      }
    }

    if (priorityCategory === "Low") {
      if (Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 2)) < Number(organisationFootprint.organisationFootprintTargetYear)) {
        return Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 2))
      } else {
        return Number(organisationFootprint.organisationFootprintTargetYear)
      }
    }

    if (priorityCategory === "-") {
      return Number(organisationFootprint.organisationFootprintTargetYear)
    }
  }

  const addForm = async (form) => {
    await axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints', form)
  }

  const deleteFootprints = async (footprintType) => {
    await axios.delete(process.env.REACT_APP_API_URL + '/organisations_footprints/' + organisationFootprint.organisationId + '/' + footprintType)
  }

  const resetForecastFigures = async () => {

    // console.log("resetting forecast figures")

    var baseFootprintItem

    setanimationReset(true)

    for (baseFootprintItem = 0; baseFootprintItem < organisationFootprint.organisationFootprintItems.length; baseFootprintItem++) {

      if (priorityCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionControlCategory, impactCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions)) === "High") {
        organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjectionYear = targetReductionYear("High").toString()
        if (organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionTargetPercentageOverride > 0) {
          organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjection = (((organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionTargetPercentageOverride / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + 1)) * 100).toString()
        } else {
          organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjection = (((organisationFootprint.organisationFootprintOverallReductionTargets.High / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + 1)) * 100).toString()
        }
      }

      if (priorityCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionControlCategory, impactCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions)) === "Medium") {
        organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjectionYear = targetReductionYear("Medium").toString()
        if (organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionTargetPercentageOverride > 0) {
          organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjection = (((organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionTargetPercentageOverride / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 6)))) * 100).toString()
        } else {
          organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjection = (((organisationFootprint.organisationFootprintOverallReductionTargets.Medium / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 6)))) * 100).toString()
        }
      }

      if (priorityCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionControlCategory, impactCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions)) === "Low") {
        organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjectionYear = targetReductionYear("Low").toString()
        if (organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionTargetPercentageOverride > 0) {
          organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjection = (((organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionTargetPercentageOverride / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 2)))) * 100).toString()
        } else {
          organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjection = (((organisationFootprint.organisationFootprintOverallReductionTargets.Low / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 2)))) * 100).toString()
        }
      }

      if (priorityCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionControlCategory, impactCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions)) === "-") {
        organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjectionYear = organisationFootprint.organisationFootprintTargetYear
        organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjection = "0"
      }

    }

    await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId, organisationFootprint)

    setFootprintsAdded(true)

  }

  const createForecastFootprints = async () => {

    var year
    var baseFootprintItem
    var finalTargetYear = Number(organisationFootprint.organisationFootprintTargetYear)
    var newFootprint
    var forecastEmissions = 0
    var baseFootprintYear = Number(organisationFootprint.organisationFootprintYear)
    var previousFootprint = {}

    await deleteFootprints("Forecast")
    setanimationForecast(true)
    for (year = baseFootprintYear; year <= finalTargetYear; year++) {

      newFootprint = { ...organisationFootprint, organisationFootprintRef: year + " Forecast", organisationFootprintYear: year.toString(), organisationFootprintType: "Forecast", organisationFootprintItems: [] }

      for (baseFootprintItem = 0; baseFootprintItem < organisationFootprint.organisationFootprintItems.length; baseFootprintItem++) {

        if (year < organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjectionYear) {
          forecastEmissions = organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions
        } else {
          forecastEmissions = previousFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions - (organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions * (organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionProjection / 100))
          if (forecastEmissions < 0) {
            forecastEmissions = 0
          }
        }

        newFootprint.organisationFootprintItems.push({ ...organisationFootprint.organisationFootprintItems[baseFootprintItem], carbonEmissions: forecastEmissions, footprintSubItems: [], id: uuidv4() })

      }

      await addForm({ ...newFootprint })
      previousFootprint = { ...newFootprint }


    }

    setFootprintsAdded(true)

  }

  const createTargetFootprints = async () => {

    var year
    var baseFootprintItem
    var finalTargetYear = Number(organisationFootprint.organisationFootprintTargetYear)
    var newFootprint
    var targetEmissions = 0
    var baseFootprintYear = Number(organisationFootprint.organisationFootprintYear)
    var previousFootprint = {}
    // var emissionsPerGBPTurnover = 0
    // var targetEmissionsPerGBPTurnover = 0


    await deleteFootprints("Target")
    setanimationTarget(true)

    for (year = baseFootprintYear; year <= finalTargetYear; year++) {

      newFootprint = { ...organisationFootprint, organisationFootprintRef: year + " Target", organisationFootprintYear: year.toString(), organisationFootprintType: "Target", organisationFootprintItems: [] }

      for (baseFootprintItem = 0; baseFootprintItem < organisationFootprint.organisationFootprintItems.length; baseFootprintItem++) {

        if (priorityCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionControlCategory, impactCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions)) === "High") {

          if (year < targetReductionYear("High")) {
            targetEmissions = organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions
          } else {

            //(Same logic as below needed in the resetForecastFigures function) AND when viewing the reduction percentages on the table!!!!!

            if (organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionTargetPercentageOverride > 0) {
              targetEmissions = previousFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions - (organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions * ((organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionTargetPercentageOverride / 100) / differenceInYears(finalTargetYear + 1, baseFootprintYear + 1)))
            } else {
              targetEmissions = previousFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions - (organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions * ((organisationFootprint.organisationFootprintOverallReductionTargets.High / 100) / differenceInYears(finalTargetYear + 1, baseFootprintYear + 1)))
            }
          }
        }

        if (priorityCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionControlCategory, impactCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions)) === "Medium") {

          if (year < targetReductionYear("Medium")) {
            targetEmissions = organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions
          } else {

            if (organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionTargetPercentageOverride > 0) {
              targetEmissions = previousFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions - (organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions * ((organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionTargetPercentageOverride / 100) / differenceInYears(finalTargetYear + 1, baseFootprintYear + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 6)))))
            } else {
              targetEmissions = previousFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions - (organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions * ((organisationFootprint.organisationFootprintOverallReductionTargets.Medium / 100) / differenceInYears(finalTargetYear + 1, baseFootprintYear + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 6)))))
            }
          }
        }

        if (priorityCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionControlCategory, impactCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions)) === "Low") {
          if (year < targetReductionYear("Low")) {
            targetEmissions = organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions
          } else {

            if (organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionTargetPercentageOverride > 0) {
              targetEmissions = previousFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions - (organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions * ((organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionTargetPercentageOverride / 100) / differenceInYears(finalTargetYear + 1, baseFootprintYear + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 2)))))
            } else {
              targetEmissions = previousFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions - (organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions * ((organisationFootprint.organisationFootprintOverallReductionTargets.Low / 100) / differenceInYears(finalTargetYear + 1, baseFootprintYear + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 2)))))
            }

          }
        }

        if (priorityCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].reductionControlCategory, impactCategory(organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions)) === "-") {

          targetEmissions = organisationFootprint.organisationFootprintItems[baseFootprintItem].carbonEmissions

        }

        newFootprint.organisationFootprintItems.push({ ...organisationFootprint.organisationFootprintItems[baseFootprintItem], carbonEmissions: targetEmissions, footprintSubItems: [], id: uuidv4() })

      }

      await addForm({ ...newFootprint })
      previousFootprint = { ...newFootprint }

    }

    setFootprintsAdded(true)

  }

  const dataChart7c = {
    labels: years,
    datasets: [
        {
            label: 'Target Emissions - Maximum acceptable carbon emissions in line with NCS protocol in order to achieve Net Zero Carbon as defined by SBTi',
            type: 'line',
            borderColor: chartColours[2],
            data: carbonEmissionsPerYearTargets,
            spanGaps: true,
            fill: 'origin'
        },
        {
            label: 'Forecast Emissions - Estimated carbon emissions in line with proposed reduction measures',
            type: 'line',
            borderColor: chartColours[1],
            data: carbonEmissionsPerYearProjections,
            spanGaps: true
        },
        {
            label: 'Actual Emissions - Verified carbon emissions per the reporting year in tCO2e',
            type: 'bar',
            fill: true,
            backgroundColor: chartColours[0],
            data: carbonEmissionsPerYearActuals
        },

    ]
  };

  const dataChart7d = {
    labels: years,
    datasets: [

      {
        label: 'Target Emissions per 1000 Base Currency Units of Turnover - Maximum acceptable carbon emissions in line with NCS protocol in order to achieve Net Zero Carbon as defined by SBTi',
        type: 'line',
        borderColor: chartColours[2],
        data: carbonEmissionsPerYearTargetsScope3,
        spanGaps: true,
        fill: 'origin'
    },
    {
        label: 'Forecast Emissions per 1000 Base Currency Units of Turnover - Estimated carbon emissions in line with proposed reduction measures',
        type: 'line',
        borderColor: chartColours[1],
        data: carbonEmissionsPerYearProjectionsScope3,
        spanGaps: true
    },
    {
        label: 'Actual Emissions per 1000 Base Currency Units of Turnover - Verified carbon emissions per the reporting year in tCO2e',
        type: 'bar',
        fill: true,
        backgroundColor: chartColours[0],
        data: carbonEmissionsPerYearActualsScope3
    },

    ]
  };

  const barAndLineOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Scope 1 and 2 Forecast vs Target vs Actual tCO2e',
        font: {
          size: 20
        }
      },
      legend: {
        display: true,
        maxWidth: 100,
        position: "bottom",
        labels: {
            font: {
                size: 15,
                family: 'TT Norms Pro'
            },
        },
        },
        datalabels: {
            display: false,
        }
    },
    responsive: true,
  }

  const barAndLineOptionsScope3 = {
    plugins: {
      title: {
        display: true,
        text: 'Scope 3 Forecast vs Target vs Actual tCO2e per 1000 Base Currency Units of Turnover',
        font: {
          size: 20
        }
      },
      legend: {
        display: true,
        maxWidth: 100,
        position: "bottom",
        labels: {
            font: {
                size: 15,
                family: 'TT Norms Pro'
            },
        },
        },
        datalabels: {
            display: false,
        }
    },
    responsive: true,
  }

  //Chart 2 (All s All Items)
  const itemDescriptionLabels = scopeItemsFiltered.map(item => item.itemDescription)
  const itemDescriptionData = scopeItemsFiltered.map(item => item.carbonEmissions / 1000)

  const dataChart2 = {
    labels: itemDescriptionLabels,
    datasets: [
      {
        data: itemDescriptionData,
        backgroundColor: chartColours,
        hoverBackgroundColor: chartHoverColours,
      }
    ]
  };


  //Chart 3 (All s by Sub Category)
  var result = [];
  scopeItemsFiltered.reduce(function (res, value) {
    if (!res[value.itemSubCategory]) {
      res[value.itemSubCategory] = { itemSubCategory: value.itemSubCategory, carbonEmissions: 0 };
      result.push(res[value.itemSubCategory])
    }
    res[value.itemSubCategory].carbonEmissions += value.carbonEmissions / 1000;
    return res;
  }, {});

  const scopeSubCategoryData = result.map(item => item.carbonEmissions)
  const scopeSubCategoryLabels = result.map(item => item.itemSubCategory)

  const dataChart3 = {
    labels: scopeSubCategoryLabels,
    datasets: [
      {
        data: scopeSubCategoryData,
        backgroundColor: chartColours,
        hoverBackgroundColor: chartHoverColours,
      }
    ]
  };

  result = [];
  scopeItemsFiltered.reduce(function (res, value) {
    if (!res[value.itemCategory]) {
      res[value.itemCategory] = { itemCategory: value.itemCategory, carbonEmissions: 0 };
      result.push(res[value.itemCategory])
    }
    res[value.itemCategory].carbonEmissions += value.carbonEmissions / 1000;
    return res;
  }, {});

  const scope3Data = result.map(item => item.carbonEmissions)
  const scope3Labels = result.map(item => item.itemCategory)

  const dataChart4 = {
    labels: scope3Labels,
    datasets: [
      {
        data: scope3Data,
        backgroundColor: chartColours,
        hoverBackgroundColor: chartHoverColours,
      }
    ]
  };

  const dataChart5 = {
    labels: [organisationFootprint.organisationFootprintYear],
    datasets: [
      {
        label: "Target",
        data: [targetForThisYear],
        borderColor: "rgba(0,0,0,1)",

        //important settings

        //set the width of the line ( or point )
        pointRadius: 100,
        // don´t show line betrween points
        showLine: false,
        //change points of line chart to line style ( little bit confusin why it´s named point anyway )
        pointStyle: 'line',

        rotation: 90,

        borderWidth: 5,

        //chart type
        type: "line",
      },
      {
        label: "Actual",
        data: [scopeItemsFiltered.filter(item => item.itemSubCategory.slice(0,2) === "1." || item.itemSubCategory.slice(0,2) === "2.").map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000],
        backgroundColor: chartColours[0],
        hoverBackgroundColor: chartHoverColours[0],
      },
      {
        label: "Estimated",
        data: [scopeItemsFiltered.filter(item => item.itemSubCategory.slice(0,2) === "1." || item.itemSubCategory.slice(0,2) === "2.").map(item => item.additionalEstimatedEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000],
        backgroundColor: chartColours[1],
        hoverBackgroundColor: chartHoverColours[1],
      }
    ]
  };

  const dataChart5Scope3 = {
    labels: [organisationFootprint.organisationFootprintYear],
    datasets: [
      {
        label: "Target",
        data: [targetForThisYearScope3 / (targetGBPTurnover / 1000)],
        borderColor: "rgba(0,0,0,1)",

        //important settings

        //set the width of the line ( or point )
        pointRadius: 100,
        // don´t show line betrween points
        showLine: false,
        //change points of line chart to line style ( little bit confusin why it´s named point anyway )
        pointStyle: 'line',

        rotation: 90,

        borderWidth: 5,

        //chart type
        type: "line",
      },
      {
        label: "Actual",
        data: [(scopeItemsFiltered.filter(item => item.itemSubCategory.slice(0,2) === "3.").map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000) / (organisationFootprint.organisationTurnoverGBP / 1000)],
        backgroundColor: chartColours[0],
        hoverBackgroundColor: chartHoverColours[0],
      },
      {
        label: "Estimated",
        data: [(scopeItemsFiltered.filter(item => item.itemSubCategory.slice(0,2) === "3.").map(item => item.additionalEstimatedEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000) / (organisationFootprint.organisationTurnoverGBP / 1000)],
        backgroundColor: chartColours[1],
        hoverBackgroundColor: chartHoverColours[1],
      }
    ]
  };

  const dataChart5AllScopes = {
    labels: [organisationFootprint.organisationFootprintYear],
    datasets: [
      {
        label: "Lower Bound",
        data: [(scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000) - ((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000) - ((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000) / ((Math.exp(((scopeItemsFilteredWithGSD.map(item => item.GSDValue).reduce((prev, curr) => Number(prev) + Number(curr), 0))) ** 0.5)) ** 2)))],
        borderColor: "rgba(60, 60, 60)",

        pointRadius: 100,

        pointStyle: 'line',

        rotation: 90,

        borderWidth: 5,

        //chart type
        type: "line",
      },
      {
        label: "Upper Bound",
        data: [((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * ((Math.exp(((scopeItemsFilteredWithGSD.map(item => item.GSDValue).reduce((prev, curr) => Number(prev) + Number(curr), 0))) ** 0.5)) ** 2)) / 1000],
        borderColor: "rgba(60, 60, 60)",

        pointRadius: 100,

        pointStyle: 'line',

        rotation: 90,

        borderWidth: 5,

        //chart type
        type: "line",
      },
      {
        label: "Actual",
        data: [(scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)],
        backgroundColor: chartColours[0],
        hoverBackgroundColor: chartHoverColours[0],
      }
    ]
  };


  const horizontalBarOptions = {
    indexAxis: 'y',
    scales: {
      y: {
        stacked: true,
      },
      x: {
        stacked: true,
      }
    },
    plugins: {
      title: {
        display: true,
        text: 'Scope 1 and 2 Actual and Estimated tCO2e',
        font: {
          size: 20
        }
      },
      datalabels: {
        display: false,
      }
    },
    responsive: true,
  }

  const horizontalBarOptionsScope3 = {
    indexAxis: 'y',
    scales: {
      y: {
        stacked: true,
      },
      x: {
        stacked: true,
      }
    },
    plugins: {
      title: {
        display: true,
        text: 'Scope 3 Actual and Estimated tCO2e per 1000 Base Currency Units of Turnover',
        font: {
          size: 20
        }
      },
      datalabels: {
        display: false,
      }
    },
    responsive: true,
  }

  const horizontalBarOptionsAllScopes = {
    indexAxis: 'y',
    scales: {
      y: {
        stacked: false,
      },
      x: {
        stacked: false,
      }
    },
    plugins: {
      title: {
        display: true,
        text: 'tCO2e Emissions - Uncertainty Analysis',
        font: {
          size: 20
        }
      },
      datalabels: {
        display: false,
      }
    },
    responsive: true,
  }

  //Pie Options
  const pieOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Carbon Emissions by Scope tCO2e',
      },
      legend: {
        display: true,
        maxWidth: 100,
        position: 'bottom',
        labels: {
          font: {
            size: 9,
          },
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map(data => {
                sum += data;
            });
            let percentage = (value*100 / sum).toFixed(0)+"%";
            if ((value*100 / sum) > 5) {
                return percentage;
            }

            return null
        },
        display: 'auto',
        color: 'white'

    },
    tooltip: {
        callbacks: {
          label: function(tooltipItem) {

            var total = tooltipItem.dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
              return previousValue + currentValue;
            });

            var valueAndpercentageLabel = tooltipItem.formattedValue + " (" + ((tooltipItem.raw / total) * 100).toFixed(2) + " %)"

            return valueAndpercentageLabel;
          }
        }
      } 
    }
  };

  const pieOptionsBySubCategory = {
    plugins: {
      title: {
        display: true,
        text: 'Carbon Emissions by Sub Category tCO2e',
      },
      legend: {
        display: true,
        maxWidth: 100,
        position: 'bottom',
        labels: {
          font: {
            size: 9,
          },
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map(data => {
                sum += data;
            });
            let percentage = (value*100 / sum).toFixed(0)+"%";
            if ((value*100 / sum) > 5) {
                return percentage;
            }

            return null
        },
        display: 'auto',
        color: 'white'

    },
    tooltip: {
        callbacks: {
          label: function(tooltipItem) {

            var total = tooltipItem.dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
              return previousValue + currentValue;
            });

            var valueAndpercentageLabel = tooltipItem.formattedValue + " (" + ((tooltipItem.raw / total) * 100).toFixed(2) + " %)"

            return valueAndpercentageLabel;
          }
        }
      } 
    },
  };

  const pieOptionsByItem = {
    plugins: {
      title: {
        display: true,
        text: 'Carbon Emissions by Item tCO2e',
      },
      legend: {
        display: true,
        maxWidth: 100,
        position: 'bottom',
        labels: {
          font: {
            size: 9,
          },
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map(data => {
                sum += data;
            });
            let percentage = (value*100 / sum).toFixed(0)+"%";
            if ((value*100 / sum) > 5) {
                return percentage;
            }

            return null
        },
        display: 'auto',
        color: 'white'

    },
    tooltip: {
        callbacks: {
          label: function(tooltipItem) {

            var total = tooltipItem.dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
              return previousValue + currentValue;
            });

            var valueAndpercentageLabel = tooltipItem.formattedValue + " (" + ((tooltipItem.raw / total) * 100).toFixed(2) + " %)"

            return valueAndpercentageLabel;
          }
        }
      } 
    },
  };

  useEffect(() => {

    if (props.role === 2003 || props.role === 5001 || props.role === 6002 || props.role === 6001) {

        setShowVerification(true)

    }

    setLoading(true)

    //OLD CODE
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    // axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
    //   .then((res) => {
    //     setItemsFiltered(res.data.organisationFootprintItems.sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0));
    //     setScopeItems(res.data.organisationFootprintItems.sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0));
    //     setOrganisationFootprint(res.data);

    //     setSelectedItemCategory(aggregateCategoriesArray(res.data.organisationFootprintItems).map((item) => {
    //         return {
    //           label: item.itemCategory,
    //           value: item.itemCategory
    //         }
    //       }))

    //     setSelectedItemSubCategory(aggregateSubCategoriesArray(res.data.organisationFootprintItems).map((item) => {
    //     return {
    //         label: item.itemSubCategory,
    //         value: item.itemSubCategory
    //     }
    //     }))

    //     setSelectedDescription(aggregateDescriptionsArray(res.data.organisationFootprintItems).map((item) => {
    //     return {
    //         label: item.itemDescription,
    //         value: item.itemDescription
    //     }
    //     }))

    //     //Code here to set array = scopeItemsFiltered, but with "Contribution to total uncertainty" field calculated and added
    //     //This array can then be used to calulate total contribution (and GSD of that total etc...)

    //     var arrayWithGSDField = []
    //     var GSDValue
    //     var item
    //     var impactPercentageValue
    //     var ratingResults
    //     var ratingResultsBasedOnImpact
    //     var dataQualityTechnologyRating, dataQualityTimeRating, dataQualityGeographyRating, dataQualityCompletenessRating, dataQualityReliabilityRating, emissionsFactorTechnologyRating, emissionsFactorTimeRating, emissionsFactorGeographyRating, emissionsFactorCompletenessRating, emissionsFactorReliabilityRating
    //     var footprintItems = res.data.organisationFootprintItems

    //     for (item = 0; item < footprintItems.length; item++) {

    //       impactPercentageValue = (footprintItems[item].carbonEmissions / footprintItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100

    //       if (footprintItems[item].dataQualityTechnologyClassification === "Very Good") { dataQualityTechnologyRating = 1 }
    //       if (footprintItems[item].dataQualityTechnologyClassification === "Good") { dataQualityTechnologyRating = 2 }
    //       if (footprintItems[item].dataQualityTechnologyClassification === "Fair") { dataQualityTechnologyRating = 3 }
    //       if (footprintItems[item].dataQualityTechnologyClassification === "Poor") { dataQualityTechnologyRating = 4 }

    //       if (footprintItems[item].dataQualityTimeClassification === "Very Good") { dataQualityTimeRating = 1 }
    //       if (footprintItems[item].dataQualityTimeClassification === "Good") { dataQualityTimeRating = 2 }
    //       if (footprintItems[item].dataQualityTimeClassification === "Fair") { dataQualityTimeRating = 3 }
    //       if (footprintItems[item].dataQualityTimeClassification === "Poor") { dataQualityTimeRating = 4 }

    //       if (footprintItems[item].dataQualityGeographyClassification === "Very Good") { dataQualityGeographyRating = 1 }
    //       if (footprintItems[item].dataQualityGeographyClassification === "Good") { dataQualityGeographyRating = 2 }
    //       if (footprintItems[item].dataQualityGeographyClassification === "Fair") { dataQualityGeographyRating = 3 }
    //       if (footprintItems[item].dataQualityGeographyClassification === "Poor") { dataQualityGeographyRating = 4 }

    //       if (footprintItems[item].dataQualityCompletenessClassification === "Very Good") { dataQualityCompletenessRating = 1 }
    //       if (footprintItems[item].dataQualityCompletenessClassification === "Good") { dataQualityCompletenessRating = 2 }
    //       if (footprintItems[item].dataQualityCompletenessClassification === "Fair") { dataQualityCompletenessRating = 3 }
    //       if (footprintItems[item].dataQualityCompletenessClassification === "Poor") { dataQualityCompletenessRating = 4 }

    //       if (footprintItems[item].dataQualityReliabilityClassification === "Very Good") { dataQualityReliabilityRating = 1 }
    //       if (footprintItems[item].dataQualityReliabilityClassification === "Good") { dataQualityReliabilityRating = 2 }
    //       if (footprintItems[item].dataQualityReliabilityClassification === "Fair") { dataQualityReliabilityRating = 3 }
    //       if (footprintItems[item].dataQualityReliabilityClassification === "Poor") { dataQualityReliabilityRating = 4 }

    //       if (footprintItems[item].emissionsFactorTechnologyClassification === "Very Good") { emissionsFactorTechnologyRating = 1 }
    //       if (footprintItems[item].emissionsFactorTechnologyClassification === "Good") { emissionsFactorTechnologyRating = 2 }
    //       if (footprintItems[item].emissionsFactorTechnologyClassification === "Fair") { emissionsFactorTechnologyRating = 3 }
    //       if (footprintItems[item].emissionsFactorTechnologyClassification === "Poor") { emissionsFactorTechnologyRating = 4 }

    //       if (footprintItems[item].emissionsFactorTimeClassification === "Very Good") { emissionsFactorTimeRating = 1 }
    //       if (footprintItems[item].emissionsFactorTimeClassification === "Good") { emissionsFactorTimeRating = 2 }
    //       if (footprintItems[item].emissionsFactorTimeClassification === "Fair") { emissionsFactorTimeRating = 3 }
    //       if (footprintItems[item].emissionsFactorTimeClassification === "Poor") { emissionsFactorTimeRating = 4 }

    //       if (footprintItems[item].emissionsFactorGeographyClassification === "Very Good") { emissionsFactorGeographyRating = 1 }
    //       if (footprintItems[item].emissionsFactorGeographyClassification === "Good") { emissionsFactorGeographyRating = 2 }
    //       if (footprintItems[item].emissionsFactorGeographyClassification === "Fair") { emissionsFactorGeographyRating = 3 }
    //       if (footprintItems[item].emissionsFactorGeographyClassification === "Poor") { emissionsFactorGeographyRating = 4 }

    //       if (footprintItems[item].emissionsFactorCompletenessClassification === "Very Good") { emissionsFactorCompletenessRating = 1 }
    //       if (footprintItems[item].emissionsFactorCompletenessClassification === "Good") { emissionsFactorCompletenessRating = 2 }
    //       if (footprintItems[item].emissionsFactorCompletenessClassification === "Fair") { emissionsFactorCompletenessRating = 3 }
    //       if (footprintItems[item].emissionsFactorCompletenessClassification === "Poor") { emissionsFactorCompletenessRating = 4 }

    //       if (footprintItems[item].emissionsFactorReliabilityClassification === "Very Good") { emissionsFactorReliabilityRating = 1 }
    //       if (footprintItems[item].emissionsFactorReliabilityClassification === "Good") { emissionsFactorReliabilityRating = 2 }
    //       if (footprintItems[item].emissionsFactorReliabilityClassification === "Fair") { emissionsFactorReliabilityRating = 3 }
    //       if (footprintItems[item].emissionsFactorReliabilityClassification === "Poor") { emissionsFactorReliabilityRating = 4 }

    //       ratingResults = ((dataQualityTechnologyRating + dataQualityTimeRating + dataQualityGeographyRating + dataQualityCompletenessRating + dataQualityReliabilityRating) / 5) * ((emissionsFactorTechnologyRating + emissionsFactorTimeRating + emissionsFactorGeographyRating + emissionsFactorCompletenessRating + emissionsFactorReliabilityRating) / 5)

    //       ratingResultsBasedOnImpact = ratingResults * (impactPercentageValue / 100)

    //     //   console.log(ratingResultsBasedOnImpact)

    //     //   console.log(footprintItems[item].itemDescription)
    //     //   console.log(GSD(footprintItems[item].dataQualityTechnologyFactor, footprintItems[item].dataQualityTimeFactor, footprintItems[item].dataQualityGeographyFactor, footprintItems[item].dataQualityCompletenessFactor, footprintItems[item].dataQualityReliabilityFactor, footprintItems[item].basicUncertaintyFactor))
    //     //   console.log(impactPercentageValue)
    //     //   console.log(contribution(GSD(footprintItems[item].dataQualityTechnologyFactor, footprintItems[item].dataQualityTimeFactor, footprintItems[item].dataQualityGeographyFactor, footprintItems[item].dataQualityCompletenessFactor, footprintItems[item].dataQualityReliabilityFactor, footprintItems[item].basicUncertaintyFactor), impactPercentageValue))

    //       GSDValue = contribution(GSD(footprintItems[item].dataQualityTechnologyFactor, footprintItems[item].dataQualityTimeFactor, footprintItems[item].dataQualityGeographyFactor, footprintItems[item].dataQualityCompletenessFactor, footprintItems[item].dataQualityReliabilityFactor, footprintItems[item].basicUncertaintyFactor), impactPercentageValue) + contribution(GSD(footprintItems[item].emissionsFactorTechnologyFactor, footprintItems[item].emissionsFactorTimeFactor, footprintItems[item].emissionsFactorGeographyFactor, footprintItems[item].emissionsFactorCompletenessFactor, footprintItems[item].emissionsFactorReliabilityFactor, footprintItems[item].basicUncertaintyFactor), impactPercentageValue)

    //       arrayWithGSDField.push({ ...footprintItems[item], GSDValue, ratingResultsBasedOnImpact })

    //     //   console.log(arrayWithGSDField)

    //     }

    //     var year
    //     var yearsArray = []

    //     for (year = Number(res.data.organisationFootprintYear); year <= Number(res.data.organisationFootprintTargetYear); year++) {

    //       yearsArray.push(year.toString())

    //     }
    //     setYears(yearsArray)

    //     setItemsFilteredWithGSD(arrayWithGSDField)
    //     setItemsWithGSD(arrayWithGSDField)

    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   }).finally(() => {
    //     setLoading(false);
    //   });

      //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    //NEW CODE
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

      setItemsFiltered(props.currentOrganisationFootprint.organisationFootprintItems.sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0));
      setScopeItems(props.currentOrganisationFootprint.organisationFootprintItems.sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0));
      setOrganisationFootprint(props.currentOrganisationFootprint);

      setSelectedItemCategory(aggregateCategoriesArray(props.currentOrganisationFootprint.organisationFootprintItems).map((item) => {
          return {
            label: item.itemCategory,
            value: item.itemCategory
          }
        }))

      setSelectedItemSubCategory(aggregateSubCategoriesArray(props.currentOrganisationFootprint.organisationFootprintItems).map((item) => {
      return {
          label: item.itemSubCategory,
          value: item.itemSubCategory
      }
      }))

      setSelectedDescription(aggregateDescriptionsArray(props.currentOrganisationFootprint.organisationFootprintItems).map((item) => {
      return {
          label: item.itemDescription,
          value: item.itemDescription
      }
      }))

      //Code here to set array = scopeItemsFiltered, but with "Contribution to total uncertainty" field calculated and added
      //This array can then be used to calulate total contribution (and GSD of that total etc...)

      var arrayWithGSDField = []
      var GSDValue
      var item
      var impactPercentageValue
      var ratingResults
      var ratingResultsBasedOnImpact
      var dataQualityTechnologyRating, dataQualityTimeRating, dataQualityGeographyRating, dataQualityCompletenessRating, dataQualityReliabilityRating, emissionsFactorTechnologyRating, emissionsFactorTimeRating, emissionsFactorGeographyRating, emissionsFactorCompletenessRating, emissionsFactorReliabilityRating
      var footprintItems = props.currentOrganisationFootprint.organisationFootprintItems

      for (item = 0; item < footprintItems.length; item++) {

        impactPercentageValue = (footprintItems[item].carbonEmissions / footprintItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100

        if (footprintItems[item].dataQualityTechnologyClassification === "Very Good") { dataQualityTechnologyRating = 1 }
        if (footprintItems[item].dataQualityTechnologyClassification === "Good") { dataQualityTechnologyRating = 2 }
        if (footprintItems[item].dataQualityTechnologyClassification === "Fair") { dataQualityTechnologyRating = 3 }
        if (footprintItems[item].dataQualityTechnologyClassification === "Poor") { dataQualityTechnologyRating = 4 }

        if (footprintItems[item].dataQualityTimeClassification === "Very Good") { dataQualityTimeRating = 1 }
        if (footprintItems[item].dataQualityTimeClassification === "Good") { dataQualityTimeRating = 2 }
        if (footprintItems[item].dataQualityTimeClassification === "Fair") { dataQualityTimeRating = 3 }
        if (footprintItems[item].dataQualityTimeClassification === "Poor") { dataQualityTimeRating = 4 }

        if (footprintItems[item].dataQualityGeographyClassification === "Very Good") { dataQualityGeographyRating = 1 }
        if (footprintItems[item].dataQualityGeographyClassification === "Good") { dataQualityGeographyRating = 2 }
        if (footprintItems[item].dataQualityGeographyClassification === "Fair") { dataQualityGeographyRating = 3 }
        if (footprintItems[item].dataQualityGeographyClassification === "Poor") { dataQualityGeographyRating = 4 }

        if (footprintItems[item].dataQualityCompletenessClassification === "Very Good") { dataQualityCompletenessRating = 1 }
        if (footprintItems[item].dataQualityCompletenessClassification === "Good") { dataQualityCompletenessRating = 2 }
        if (footprintItems[item].dataQualityCompletenessClassification === "Fair") { dataQualityCompletenessRating = 3 }
        if (footprintItems[item].dataQualityCompletenessClassification === "Poor") { dataQualityCompletenessRating = 4 }

        if (footprintItems[item].dataQualityReliabilityClassification === "Very Good") { dataQualityReliabilityRating = 1 }
        if (footprintItems[item].dataQualityReliabilityClassification === "Good") { dataQualityReliabilityRating = 2 }
        if (footprintItems[item].dataQualityReliabilityClassification === "Fair") { dataQualityReliabilityRating = 3 }
        if (footprintItems[item].dataQualityReliabilityClassification === "Poor") { dataQualityReliabilityRating = 4 }

        if (footprintItems[item].emissionsFactorTechnologyClassification === "Very Good") { emissionsFactorTechnologyRating = 1 }
        if (footprintItems[item].emissionsFactorTechnologyClassification === "Good") { emissionsFactorTechnologyRating = 2 }
        if (footprintItems[item].emissionsFactorTechnologyClassification === "Fair") { emissionsFactorTechnologyRating = 3 }
        if (footprintItems[item].emissionsFactorTechnologyClassification === "Poor") { emissionsFactorTechnologyRating = 4 }

        if (footprintItems[item].emissionsFactorTimeClassification === "Very Good") { emissionsFactorTimeRating = 1 }
        if (footprintItems[item].emissionsFactorTimeClassification === "Good") { emissionsFactorTimeRating = 2 }
        if (footprintItems[item].emissionsFactorTimeClassification === "Fair") { emissionsFactorTimeRating = 3 }
        if (footprintItems[item].emissionsFactorTimeClassification === "Poor") { emissionsFactorTimeRating = 4 }

        if (footprintItems[item].emissionsFactorGeographyClassification === "Very Good") { emissionsFactorGeographyRating = 1 }
        if (footprintItems[item].emissionsFactorGeographyClassification === "Good") { emissionsFactorGeographyRating = 2 }
        if (footprintItems[item].emissionsFactorGeographyClassification === "Fair") { emissionsFactorGeographyRating = 3 }
        if (footprintItems[item].emissionsFactorGeographyClassification === "Poor") { emissionsFactorGeographyRating = 4 }

        if (footprintItems[item].emissionsFactorCompletenessClassification === "Very Good") { emissionsFactorCompletenessRating = 1 }
        if (footprintItems[item].emissionsFactorCompletenessClassification === "Good") { emissionsFactorCompletenessRating = 2 }
        if (footprintItems[item].emissionsFactorCompletenessClassification === "Fair") { emissionsFactorCompletenessRating = 3 }
        if (footprintItems[item].emissionsFactorCompletenessClassification === "Poor") { emissionsFactorCompletenessRating = 4 }

        if (footprintItems[item].emissionsFactorReliabilityClassification === "Very Good") { emissionsFactorReliabilityRating = 1 }
        if (footprintItems[item].emissionsFactorReliabilityClassification === "Good") { emissionsFactorReliabilityRating = 2 }
        if (footprintItems[item].emissionsFactorReliabilityClassification === "Fair") { emissionsFactorReliabilityRating = 3 }
        if (footprintItems[item].emissionsFactorReliabilityClassification === "Poor") { emissionsFactorReliabilityRating = 4 }

        ratingResults = ((dataQualityTechnologyRating + dataQualityTimeRating + dataQualityGeographyRating + dataQualityCompletenessRating + dataQualityReliabilityRating) / 5) * ((emissionsFactorTechnologyRating + emissionsFactorTimeRating + emissionsFactorGeographyRating + emissionsFactorCompletenessRating + emissionsFactorReliabilityRating) / 5)

        ratingResultsBasedOnImpact = ratingResults * (impactPercentageValue / 100)

      //   console.log(ratingResultsBasedOnImpact)

      //   console.log(footprintItems[item].itemDescription)
      //   console.log(GSD(footprintItems[item].dataQualityTechnologyFactor, footprintItems[item].dataQualityTimeFactor, footprintItems[item].dataQualityGeographyFactor, footprintItems[item].dataQualityCompletenessFactor, footprintItems[item].dataQualityReliabilityFactor, footprintItems[item].basicUncertaintyFactor))
      //   console.log(impactPercentageValue)
      //   console.log(contribution(GSD(footprintItems[item].dataQualityTechnologyFactor, footprintItems[item].dataQualityTimeFactor, footprintItems[item].dataQualityGeographyFactor, footprintItems[item].dataQualityCompletenessFactor, footprintItems[item].dataQualityReliabilityFactor, footprintItems[item].basicUncertaintyFactor), impactPercentageValue))

        GSDValue = contribution(GSD(footprintItems[item].dataQualityTechnologyFactor, footprintItems[item].dataQualityTimeFactor, footprintItems[item].dataQualityGeographyFactor, footprintItems[item].dataQualityCompletenessFactor, footprintItems[item].dataQualityReliabilityFactor, footprintItems[item].basicUncertaintyFactor), impactPercentageValue) + contribution(GSD(footprintItems[item].emissionsFactorTechnologyFactor, footprintItems[item].emissionsFactorTimeFactor, footprintItems[item].emissionsFactorGeographyFactor, footprintItems[item].emissionsFactorCompletenessFactor, footprintItems[item].emissionsFactorReliabilityFactor, footprintItems[item].basicUncertaintyFactor), impactPercentageValue)

        arrayWithGSDField.push({ ...footprintItems[item], GSDValue, ratingResultsBasedOnImpact })

      //   console.log(arrayWithGSDField)

      }

      var year
      var yearsArray = []

      for (year = Number(props.currentOrganisationFootprint.organisationFootprintYear); year <= Number(props.currentOrganisationFootprint.organisationFootprintTargetYear); year++) {

        yearsArray.push(year.toString())

      }
      setYears(yearsArray)

      setItemsFilteredWithGSD(arrayWithGSDField)
      setItemsWithGSD(arrayWithGSDField)

      setLoading(false)

    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  }, [match.params.footprintId, props.currentOrganisationFootprint])

  useEffect(() => {

    axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_by_organisationId/' + match.params.organisationId)
      .then((res) => {
        // setAllForecastFootprints(res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast"))
        // setAllTargetFootprints(res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Target"))
        // setAllActualFootprints(res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType.slice(0, 6) === "Actual"))

        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        //Code here to calculate Actuals, Projections and Targets per year
        //Actuals

        var filteredResActuals
        var filteredResTargets
        var filteredResProjections
        var i
        var concatenatedListOfItems
        var year
        var k
        var emissions
        var carbonEmissionsByTurnoverGBP
        var targetYear = props.currentOrganisationFootprint.organisationFootprintTargetYear

        filteredResActuals = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType.slice(0, 6) === "Actual").filter(item => item.organisationFootprintCertification !== "")

        var carbonEmissionsPerYear = [];

        year = props.currentOrganisationFootprint.organisationFootprintYear

        for (i = 0; i < (Number(targetYear) - Number(props.currentOrganisationFootprint.organisationFootprintYear)) + 1; i++) {
          concatenatedListOfItems = [];

          for (k = 0; k < filteredResActuals.length; k++) {
            if (
              filteredResActuals[k].organisationFootprintYear === year
            ) {
              concatenatedListOfItems =
                concatenatedListOfItems.concat(
                  filteredResActuals[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
                );
            }
          }

          emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
          if (emissions === 0) {
            emissions = "N/A"
          }
          carbonEmissionsPerYear.push({
            year,
            emissions,
          });

          year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearActuals(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

        //Targets

        year = props.currentOrganisationFootprint.organisationFootprintYear

        filteredResTargets = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Target")

        carbonEmissionsPerYear = [];

        for (i = 0; i < filteredResTargets.length; i++) {
          concatenatedListOfItems = [];

          for (k = 0; k < filteredResTargets.length; k++) {
            if (
              filteredResTargets[k].organisationFootprintYear === year
            ) {
              concatenatedListOfItems =
                concatenatedListOfItems.concat(
                  filteredResTargets[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
                );
            }
          }

          emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
          if (emissions === 0) {
            emissions = "N/A"
          }
          carbonEmissionsPerYear.push({
            year,
            emissions,
          });

          year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearTargets(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));


        //Projections

        year = props.currentOrganisationFootprint.organisationFootprintYear

        filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast")

        carbonEmissionsPerYear = [];

        for (i = 0; i < filteredResProjections.length; i++) {
          concatenatedListOfItems = [];

          for (k = 0; k < filteredResProjections.length; k++) {
            if (
              filteredResProjections[k].organisationFootprintYear === year
            ) {
              concatenatedListOfItems =
                concatenatedListOfItems.concat(
                  filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
                );
            }
          }

          emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
          if (emissions === 0) {
            emissions = "N/A"
          }
          carbonEmissionsPerYear.push({
            year,
            emissions,
          });

          year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearProjections(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));


        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        //Code here to calculate Actuals, Projections and Targets per year
        //Actuals

        filteredResActuals = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType.slice(0, 6) === "Actual").filter(item => item.organisationFootprintCertification !== "")

        carbonEmissionsPerYear = [];

        year = props.currentOrganisationFootprint.organisationFootprintYear

        for (i = 0; i < (Number(targetYear) - Number(props.currentOrganisationFootprint.organisationFootprintYear)) + 1; i++) {
          concatenatedListOfItems = [];

          for (k = 0; k < filteredResActuals.length; k++) {
            if (
              filteredResActuals[k].organisationFootprintYear === year
            ) {
              concatenatedListOfItems =
                concatenatedListOfItems.concat(
                  filteredResActuals[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
                );
            }
          }

          carbonEmissionsByTurnoverGBP = 0

          emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;

          if (filteredResActuals.filter(item => item.organisationFootprintYear === year).length > 0) {
            carbonEmissionsByTurnoverGBP = emissions / (filteredResActuals.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
          }

          if (carbonEmissionsByTurnoverGBP === 0) {
            carbonEmissionsByTurnoverGBP = "N/A"
          }

          carbonEmissionsPerYear.push({
            year,
            emissions: carbonEmissionsByTurnoverGBP,
          });

          year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearActualsScope3(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

        //Targets

        year = props.currentOrganisationFootprint.organisationFootprintYear

        filteredResTargets = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Target")

        carbonEmissionsPerYear = [];

        for (i = 0; i < filteredResTargets.length; i++) {
          concatenatedListOfItems = [];

          for (k = 0; k < filteredResTargets.length; k++) {
            if (
              filteredResTargets[k].organisationFootprintYear === year
            ) {
              concatenatedListOfItems =
                concatenatedListOfItems.concat(
                  filteredResTargets[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
                );
            }
          }

          carbonEmissionsByTurnoverGBP = 0

          emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;

            if (filteredResTargets.filter(item => item.organisationFootprintYear === year).length > 0) {
                carbonEmissionsByTurnoverGBP = emissions / (filteredResTargets.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
            }

            if (carbonEmissionsByTurnoverGBP === 0) {
              carbonEmissionsByTurnoverGBP = "N/A"
            }
  
            carbonEmissionsPerYear.push({
              year,
              emissions: carbonEmissionsByTurnoverGBP,
            });

          year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearTargetsScope3(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));


        //Projections

        year = props.currentOrganisationFootprint.organisationFootprintYear

        filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast")

        carbonEmissionsPerYear = [];

        for (i = 0; i < filteredResProjections.length; i++) {
          concatenatedListOfItems = [];

          for (k = 0; k < filteredResProjections.length; k++) {
            if (
              filteredResProjections[k].organisationFootprintYear === year
            ) {
              concatenatedListOfItems =
                concatenatedListOfItems.concat(
                  filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
                );
            }
          }

          carbonEmissionsByTurnoverGBP = 0

          emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;

            if (filteredResProjections.filter(item => item.organisationFootprintYear === year).length > 0) {
                carbonEmissionsByTurnoverGBP = emissions / (filteredResProjections.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
            }

          if (carbonEmissionsByTurnoverGBP === 0) {
            carbonEmissionsByTurnoverGBP = "N/A"
          }

          carbonEmissionsPerYear.push({
            year,
            emissions: carbonEmissionsByTurnoverGBP,
          });

          year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearProjectionsScope3(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));


        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });

    setFootprintsAdded(false)
    setanimationForecast(false)
    setanimationTarget(false)
    setanimationReset(false)


  }, [footprintsAdded])

  useEffect(() => {

    setItemsFiltered(scopeItems)
    setItemsFilteredWithGSD(scopeItemsWithGSD)

    setItemsFiltered(scopeItemsFiltered => scopeItemsFiltered.filter((itemA) => {
        return selectedItemCategory.find((itemB) => {
            return itemA.itemCategory === itemB.value;
        })
        }).filter((itemA) => {
            return selectedItemSubCategory.find((itemB) => {
                return itemA.itemSubCategory === itemB.value;
            })
            }).filter((itemA) => {
                return selectedDescription.find((itemB) => {
                    return itemA.itemDescription === itemB.value;
                })
                }))

    // if (selectedItemCategory !== "All") {
    //   setItemsFiltered(scopeItemsFiltered => scopeItemsFiltered.filter((item) => item.itemCategory === selectedItemCategory.value))
    // }

    // if (selectedItemSubCategory !== "All") {
    //   setItemsFiltered(scopeItemsFiltered => scopeItemsFiltered.filter((item) => item.itemSubCategory === selectedItemSubCategory.value))
    // }

    if (selectedVerified !== "All") {
        setItemsFiltered(scopeItemsFiltered => scopeItemsFiltered.filter((item) => item.verified === selectedVerified.value))
    }

    if (selectedSubmitted !== "All") {
    setItemsFiltered(scopeItemsFiltered => scopeItemsFiltered.filter((item) => item.submitted === selectedSubmitted.value))
    }

    // if (selectedItemCategory !== "All") {
    //   setItemsFilteredWithGSD(scopeItemsFilteredWithGSD => scopeItemsFilteredWithGSD.filter((item) => item.itemCategory === selectedItemCategory.value))
    // }

    // if (selectedItemSubCategory !== "All") {
    //   setItemsFilteredWithGSD(scopeItemsFilteredWithGSD => scopeItemsFilteredWithGSD.filter((item) => item.itemSubCategory === selectedItemSubCategory.value))
    // }

    setItemsFilteredWithGSD(scopeItemsFilteredWithGSD => scopeItemsFilteredWithGSD.filter((itemA) => {
        return selectedItemCategory.find((itemB) => {
            return itemA.itemCategory === itemB.value;
        })
        }).filter((itemA) => {
            return selectedItemSubCategory.find((itemB) => {
                return itemA.itemSubCategory === itemB.value;
            })
            }).filter((itemA) => {
                return selectedDescription.find((itemB) => {
                    return itemA.itemDescription === itemB.value;
                })
                }))

    if (selectedVerified !== "All") {
        setItemsFilteredWithGSD(scopeItemsFilteredWithGSD => scopeItemsFilteredWithGSD.filter((item) => item.verified === selectedVerified.value))
    }

    if (selectedSubmitted !== "All") {
        setItemsFilteredWithGSD(scopeItemsFilteredWithGSD => scopeItemsFilteredWithGSD.filter((item) => item.submitted === selectedSubmitted.value))
    }
  

  }, [selectedItemCategory, selectedItemSubCategory, scopeItems, scopeItemsWithGSD, selectedVerified, selectedSubmitted, selectedDescription])


  useEffect(() => {

    if (organisationFootprint.organisationFootprintYear) {

      //Code here to go and get the target footprint for this year
      //so that it can be shown on the chart as the target
    //   axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_by_organisationId/' + match.params.organisationId)
    //     .then((res) => {
    //       setTargetForThisYear(res.data.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target").organisationFootprintItems.filter(item => item.itemSubCategory.slice(0,2) === "1." || item.itemSubCategory.slice(0,2) === "2.").map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)
    //       setTargetGBPTurnover(res.data.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target").organisationTurnoverGBP)
    //       setTargetForThisYearScope3(res.data.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target").organisationFootprintItems.filter(item => item.itemSubCategory.slice(0,2) === "3.").map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })

        if (props.footprintsForCurrentOrganisation.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target")) {

          setTargetForThisYear(props.footprintsForCurrentOrganisation.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target").organisationFootprintItems.filter(item => item.itemSubCategory.slice(0,2) === "1." || item.itemSubCategory.slice(0,2) === "2.").map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)
          setTargetGBPTurnover(props.footprintsForCurrentOrganisation.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target").organisationTurnoverGBP)
          setTargetForThisYearScope3(props.footprintsForCurrentOrganisation.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target").organisationFootprintItems.filter(item => item.itemSubCategory.slice(0,2) === "3.").map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)

        }
    }

  }, [organisationFootprint])


  if (scopeItems.length > 0 && !loading) {

    return (
      <div>
        <br />
        <br />
        <div>
          
          {/* <div className='flex p-2'>
          {(props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (
            <Button color="bondi-blue" text="Add New Footprint Item" onClick={() => navigate(`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/_add`)} />
          )}
            <Button color="bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
          </div> */}

          <div className="flex flex-col py-2">
            <div className="overflow-x-auto items-holder-8A">
              <div className="text-eight-text align-middle inline-block min-w-full px-3">
                {/* <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl"> */}
                <div className="shadow-md shadow-slate-300">
                  {organisationFootprint.organisationFootprintType === "Actual (Baseline)" && (
                    <div>
                      <table className="objects-8A min-w-full divide-y divide-gray-200">

                      <thead><tr className="h-20"><th className="bg-bondi-blue text-white p-5 whitespace-nowrap hover:cursor-pointer" onClick={() => {setShowReductionPlan(true); setShowUncertaintyAssessment(true); setShowVerification(true)}}>Footprint Items{!showUncertaintyAssessment || !showVerification || !showReductionPlan ? " +" : ""}</th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th> {showReductionPlan && <><th onClick = {() => setShowReductionPlan(false) } className="bg-eight-slate-600 text-white hover:cursor-pointer">- Reduction Plan</th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th></>}{showUncertaintyAssessment && <><th onClick = {() => setShowUncertaintyAssessment(false)} className="bg-bondi-blue text-white hover:cursor-pointer">- Uncertainty</th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th></>}{showVerification && <> <th onClick = {() => setShowVerification(false)} className="bg-eight-slate-600 text-white hover:cursor-pointer">- Verification</th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th></>}<th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th></tr></thead>

                      {/* {props.role !== 6002 && props.role !== 5001 &&
                        <thead><tr className="h-20"><th className="bg-bondi-blue text-white p-5 whitespace-nowrap hover:cursor-pointer" onClick={() => {setShowReductionPlan(true); setShowUncertaintyAssessment(true); setShowVerification(true)}}>Footprint Items{!showUncertaintyAssessment || !showVerification || !showReductionPlan ? " +" : ""}</th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th> {showReductionPlan && <><th onClick = {() => setShowReductionPlan(false) } className="bg-eight-slate-600 text-white hover:cursor-pointer">- Reduction Plan</th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th></>}{showUncertaintyAssessment && <><th onClick = {() => setShowUncertaintyAssessment(false)} className="bg-bondi-blue text-white hover:cursor-pointer">- Uncertainty</th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th></>}{showVerification && <> <th onClick = {() => setShowVerification(false)} className="bg-eight-slate-600 text-white hover:cursor-pointer">- Verification</th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th></>}<th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th></tr></thead>  
                      } */}
                      <thead className="bg-pacific-blue-50 z-20 sticky -top-1">
                          <tr>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Scope <div className="w-40"><MultiSelect
                              value={selectedItemCategory}
                              onChange={setSelectedItemCategory}
                              options={aggregateCategoriesArray(scopeItems).map((item) => {
                                return {
                                  label: item.itemCategory,
                                  value: item.itemCategory
                                }
                              })} 
                              labelledBy="Select"
                              /></div></th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Sub Category <div className="w-52"> <MultiSelect
                              styles={styles}
                              value={selectedItemSubCategory}
                              onChange={setSelectedItemSubCategory}
                              options={aggregateSubCategoriesArray(scopeItems).map((item) => {
                                return {
                                  label: item.itemSubCategory,
                                  value: item.itemSubCategory
                                }
                              })}
                              labelledBy="Select"
                              /></div></th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Description <div className="w-52"> <MultiSelect
                              styles={styles}
                              value={selectedDescription}
                              onChange={setSelectedDescription}
                              options={aggregateDescriptionsArray(scopeItems).map((item) => {
                                return {
                                  label: item.itemDescription,
                                  value: item.itemDescription
                                }
                              })}
                              labelledBy="Select"
                              /></div></th>
                            {/* {props.role !== 6002 && props.role !== 5001 &&
                            <>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consumption</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Unit</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emission Factor</th>
                            </>
                            } */}
                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> kgCO<sub>2</sub>e</th> */}
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> tCO<sub>2</sub>e</th>
                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Consultant Comment</th> */}
                            {showReductionPlan &&
                            <>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Control Category</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Comments on Control</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Impact %</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Impact Category</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Priority Category</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Priority</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Target %</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Target Year</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Forecast %</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Forecast Year</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Measures</th>
                            </>
                            }
                            {showUncertaintyAssessment &&
                            <>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Data Type</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emissions Factor - Source</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Geography</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Completeness</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Qualitative Uncertainty</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Quantitative Uncertainty</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emission Factor GSD</th>
                            </>
                            }
                            {showVerification && 
                            <>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Submitted<ReactSelect
                              styles={styles}
                              value={selectedSubmitted}
                              onChange={setSelectedSubmitted}
                              options={aggregateSubmittedArray(scopeItems).map((item) => {
                                return {
                                  label: item.submitted === "" ? "NOT SUBMITTED" : item.submitted,
                                  value: item.submitted
                                }
                              })} /></th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Verification Status<ReactSelect
                              styles={styles}
                              value={selectedVerified}
                              onChange={setSelectedVerified}
                              options={aggregateVerifiedArray(scopeItems).map((item) => {
                                return {
                                  label: item.verified === "" ? "NOT REVIEWED" : item.verified,
                                  value: item.verified
                                }
                              })} /></th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Submission Comment</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Verification Comment</th>
                            </>
                            }
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Documents Uploaded</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                          </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                          {scopeItemsFiltered.sort((item1, item2) => Number(item1.itemCategory) < Number(item2.itemCategory) ? -1 : Number(item1.itemCategory) > Number(item2.itemCategory) ? 1 : Number(item1.itemSubCategory.slice(2,4)) < Number(item2.itemSubCategory.slice(2,4)) ? -1 : Number(item1.itemSubCategory.slice(2,4)) > Number(item2.itemSubCategory.slice(2,4)) ? 1 : item1.itemDescription < item2.itemDescription ? -1 : item1.itemDescription > item2.itemDescription ? 1 : 0).map((organisationFootprintItem) => (
                            <tr key={organisationFootprintItem.id}>
                              <td className="px-4 md:px-6  py-2 w-6 whitespace-nowrap hover:cursor-pointer">
                              { props.role !== 3001 && props.role !== 5001 && props.role !== 6002 && props.role !== 2003 ? 
                                <Options position="left" cloneFunction={cloneOrganisationFootprintItem} cloneObject={organisationFootprintItem} deleteFunction={deleteOrganisationFootprintItem} deleteObject={organisationFootprintItem.id} edit={`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`} ></Options>
                              : <Options position="left" edit={`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`} ></Options>}
                                </td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.itemCategory}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"subCatTip" + organisationFootprintItem.id}> <p className="w-48 2xl:w-72 truncate">{organisationFootprintItem.itemSubCategory}</p>
                                {showToolTip && <ReactTooltip id={"subCatTip" + organisationFootprintItem.id} place="top" effect="solid">
                                  <div>{organisationFootprintItem.itemSubCategory === "1.1 Stationary Combustion" && <p>This category covers the direct emissions associated with stational fuel combustion across sites owned and operated by the organisation. <br></br> Stationary fuel combustion emission sources are typically associated with activities that combust solid, liquid or gaseous fuels.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "1.2 Mobile Combustion" && <p>1.2 Mobile Combustion covers the consumption of fuel from vehicles that are owned and operated by the organisation (including leased vehicles that are used on a daily basis). <br></br> The fuels used and distance travelled for the reporting year should be provided.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "1.3 Refrigerants" && <p>This category covers fugitive emissions associated with leaks across HVAC systems, chillers, refrigerators in operation across the organisation.<br></br>Most refrigerant gases contribute to global warming when leaked into the atmosphere. <br></br> The quantity of leaked gas is assumed to be equal to the amount of gas replaced in these systems by your HVAC or chiller maintenance company.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "2.1 Purchased Electricity" && <p>Scope 2 emissions cover the indirect emissions associated with electricity and other sources of energy purchased from your local utility. <br></br>These emissions are considered to be an indirect emissions source, because the emissions are a consequence of activities from your organisation, <br></br>but occur at sites controlled by your utility provider.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.1 Purchased Goods & Services" && <p>This category covers the upstream emissions associated with the extraction and production of goods and services purchased or acquired <br></br> by the reporting company across the reporting year (e.g., materials, components, key ingredients).<br></br> Where a product-level carbon footprint has been measured, please provide evidence of this footprint as per the information sent through from selected suppliers.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.2 Capital Goods" && <p>This category covers the upstream emissions associated with the extraction, production, and transportation of capital goods purchased or acquired <br></br>  by the reporting company across the reporting year. <br></br> Capital goods that are purchased during the reporting year but represent longer-term investments should be included in this category.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.3 Fuel & Energy" && <p>This category covers the upstream emissions associated with the extraction, production, and transportation of fuels and energy (gas and electricity) purchased or acquired  <br></br> by the reporting company across the reporting year.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.4 Upstream Transportation & Distribution" && <p>This category covers the emissions associated with the transportation and distribution of products purchased between a company’s tier 1 suppliers and its own operations<br></br> (in vehicles and facilities not owned or controlled by the reporting company). Data should cover the reporting year.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.5 Waste" && <p>This category covers the emissions associated with the disposal and treatment of waste, alongside the supply and treatment of water <br></br> across the reporting company's operations for the reporting year.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.6 Business Travel" && <p>This category covers the emissions associated with the transportation of employees for business-related activities during the reporting year <br></br> (in vehicles not owned or operated by the reporting company).</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.7 Employee Commuting" && <p>This category covers the emissions associated with the transportation of employees between their homes and their worksites during the reporting year <br></br> (in vehicles not owned or operated by the reporting company). </p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.8 Upstream Leased Assets" && <p>This category covers the emissions associated with the operation of assets across the organisation that were leased by the organisation during the reporting year <br></br> (e.g. leased buildings and vehicles).</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.9 Downstream Transportation & Distribution" && <p>This category covers the emissions associated with the transportation and distribution of sold products, after the point of sale <br></br> between the reporting company's operations and the end consumer across the reporting year.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.10 Processing of Sold Products" && <p></p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.11 Use of Sold Products" && <p>This category covers the emissions associated with the use of goods, services and products sold by the reporting company during the reporting year <br></br> (taking into consideration the Scope 1 and 2 emissions from end users who are using individual products). <br></br> This category includes either the direct or indirect use-phase emissions associated with sold products.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.12 End-of-Life Treatment of Sold Products" && <p>This category covers the emissions associated with the waste disposal and treatment of products sold by the reporting company <br></br> (during the reporting year), at the end of their life. </p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.13 Downstream Leased Assets" && <p>This category includes emissions from the operation of assets that are owned by the reporting company (acting as lessor) <br></br>and leased to other entities in the reporting year that are not already included in scope 1 or scope 2. <br></br> This category is applicable to lessors (i.e., companies that receive payments from lessees). <br></br>Companies that operate leased assets (i.e., lessees) should refer to category 8 (Upstream leased assets).</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.14 Franchises" && <p>Category 14 includes emissions from the operation of franchises not included in scope 1 or scope 2. <br></br>A franchise is a business operating under a license to sell or distribute another company’s goods or services within a certain location. <br></br>This category is applicable to franchisors (i.e., companies that grant licenses to other entities <br></br> to sell or distribute its goods or services in return for payments, such as royalties for the use of trademarks and other services). <br></br>Franchisors should account for emissions that occur from the operation of franchises <br></br>(i.e., the scope 1 and scope 2 emissions of franchisees) in this category.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.15 Investments" && <p>This category includes scope 3 emissions associated with the reporting company’s investments in the reporting year, not already included in scope 1 or scope 2.<br></br> This category is applicable to investors (i.e., companies that make an investment with the objective of making a profit) <br></br>and companies that provide financial services. This category also applies to investors that are not profit driven <br></br>(e.g. multilateral development banks), and the same calculation methods should be used. <br></br>Investments are categorized as a downstream scope 3 category because providing capital <br></br>or financing is a service provided by the reporting company.</p>}</div>
                                </ReactTooltip>}</td>
                                {((props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (organisationFootprintItem.verified !== "Compliant" && (organisationFootprintItem.submitted === "" || organisationFootprintItem.submitted === undefined))) ? 
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.itemDescription); setFieldToSendToModal("organisationFootprintItemDescription"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"descriptionTip" + organisationFootprintItem.id}> <p><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg>{organisationFootprintItem.itemDescription}</p>{showToolTip && <ReactTooltip id={"descriptionTip" + organisationFootprintItem.id} place="top" effect="solid"><div>{organisationFootprintItem.itemDescription}</div></ReactTooltip>}</td> : <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"descriptionTip" + organisationFootprintItem.id}> <p>{organisationFootprintItem.itemDescription}</p>{showToolTip && <ReactTooltip id={"descriptionTip" + organisationFootprintItem.id} place="top" effect="solid"><div>{organisationFootprintItem.itemDescription}</div></ReactTooltip>}</td>
                                }
                              {/* {props.role !== 6002 && props.role !== 5001 &&
                              <>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base text-right" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base text-right" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base text-right" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base text-right"}> {internationalNumberFormat.format(organisationFootprintItem.consumption.toFixed(2))} </td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.unit}</td>
                              {organisationFootprintItem.unit !== "various" &&
                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.emissionFactor.toFixed(4)}</td>
                              }
                              {organisationFootprintItem.unit === "various" &&
                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> various</td>
                              }
                              </>
                              } */}
                              {/* <td onClick={() => navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`)} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"openHelperToolTip"}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline mx-1 text-slate-600">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                </svg>
                                {internationalNumberFormat.format(organisationFootprintItem.carbonEmissions.toFixed(2))}
                                <ReactTooltip id={"openHelperToolTip"} place="top" effect="solid">
                                  <div>Click to Open Helper Tool and View Sub Items</div>
                                </ReactTooltip></td> */}
                              <td onClick={() => navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`)} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base text-right" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base text-right" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base text-right" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base text-right"} data-tip data-for={"openHelperToolTip"}>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline mx-1 text-slate-600">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                </svg> */}
                                {internationalNumberFormat.format((organisationFootprintItem.carbonEmissions / 1000).toFixed(2))}
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline mx-1 text-slate-600">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                </svg>
                                {showToolTip &&<ReactTooltip id={"openHelperToolTip"} place="top" effect="solid">
                                  <div>Click to Open Helper Tool and View Sub Items</div>
                                </ReactTooltip>}</td>
                              {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> <p className="w-48 2xl:w-72 truncate">{organisationFootprintItem.itemConsultantComment}</p></td> */}
                              {showReductionPlan &&
                                <>
                              {((props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (organisationFootprintItem.verified !== "Compliant" && (organisationFootprintItem.submitted === "" || organisationFootprintItem.submitted === undefined))) ? 
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.reductionControlCategory); setFieldToSendToModal("reductionControlCategory"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg> {organisationFootprintItem.reductionControlCategory}</td> : <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{organisationFootprintItem.reductionControlCategory}</td>}
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.reductionCommentsOnControl}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{impactPercentage(organisationFootprintItem.carbonEmissions).toFixed(2) + "%"}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {impactCategory(organisationFootprintItem.carbonEmissions)}</td>

                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {priorityCategory(organisationFootprintItem.reductionControlCategory, impactCategory(organisationFootprintItem.carbonEmissions))}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"> {priorityCategoryDescription(priorityCategory(organisationFootprintItem.reductionControlCategory, impactCategory(organisationFootprintItem.carbonEmissions)))}</p></td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {targetReductionPercentage(priorityCategory(organisationFootprintItem.reductionControlCategory, impactCategory(organisationFootprintItem.carbonEmissions)), organisationFootprintItem.reductionTargetPercentageOverride)}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {targetReductionYear(priorityCategory(organisationFootprintItem.reductionControlCategory, impactCategory(organisationFootprintItem.carbonEmissions)))}</td>
                              {((props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (organisationFootprintItem.verified !== "Compliant" && (organisationFootprintItem.submitted === "" || organisationFootprintItem.submitted === undefined))) ? 
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.reductionProjection); setFieldToSendToModal("reductionProjection"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg>{Number(organisationFootprintItem.reductionProjection).toFixed(2)}</td>
                                : <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.reductionProjection}</td>}

                              {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {internationalNumberFormat.format((((Number(organisationFootprintItem.reductionProjection) / 100) * organisationFootprintItem.carbonEmissions)).toFixed(2))}</td> */}
                              {((props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (organisationFootprintItem.verified !== "Compliant" && (organisationFootprintItem.submitted === "" || organisationFootprintItem.submitted === undefined))) ? 
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.reductionProjectionYear); setFieldToSendToModal("reductionProjectionYear"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg>{organisationFootprintItem.reductionProjectionYear}</td>
                               : <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.reductionProjectionYear}</td>}

                              {((props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (organisationFootprintItem.verified !== "Compliant" && (organisationFootprintItem.submitted === "" || organisationFootprintItem.submitted === undefined))) ? 
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.reductionMeasures); setFieldToSendToModal("reductionMeasures"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg> {organisationFootprintItem.reductionMeasures}</td>
                               : <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.reductionMeasures}</td>}           
                              </>
                              }
                            {showUncertaintyAssessment &&
                            <>
                             
                              {((props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (organisationFootprintItem.verified !== "Compliant" && (organisationFootprintItem.submitted === "" || organisationFootprintItem.submitted === undefined))) &&
                              <>
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.dataType); setFieldToSendToModal("dataType"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b 2xl:text-base"}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg> {organisationFootprintItem.dataType}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"> {organisationFootprintItem.emissionsFactorSource}</p></td>
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.emissionsFactorGeography); setFieldToSendToModal("emissionsFactorGeography"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"><svg className={organisationFootprintItem.emissionsFactorGeographyClassification === "Very Good" ? "inline-block fill-current text-lime" : organisationFootprintItem.emissionsFactorGeographyClassification === "Good" ? "inline-block fill-current text-indigo-400" : organisationFootprintItem.emissionsFactorGeographyClassification === "Poor" ? "inline-block fill-current text-red-500" : "inline-block fill-current text-amber-300"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg> {organisationFootprintItem.emissionsFactorGeography}</p></td>
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.emissionsFactorCompleteness); setFieldToSendToModal("emissionsFactorCompleteness"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"><svg className={organisationFootprintItem.emissionsFactorCompletenessClassification === "Very Good" ? "inline-block fill-current text-lime" : organisationFootprintItem.emissionsFactorCompletenessClassification === "Good" ? "inline-block fill-current text-indigo-400" : organisationFootprintItem.emissionsFactorCompletenessClassification === "Poor" ? "inline-block fill-current text-red-500" : "inline-block fill-current text-amber-300"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg> {organisationFootprintItem.emissionsFactorCompleteness}</p></td>
                              </>
                              }

                              {((props.role !== 2001 && props.role !== 4001 && props.role !== 6001 && props.role !== 2004) || (organisationFootprintItem.verified === "Compliant" || (organisationFootprintItem.submitted !== "" && organisationFootprintItem.submitted !== undefined))) &&
                              <>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b"}> <p className = "w-48 2xl:w-72"> {organisationFootprintItem.dataType}</p></td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"> {organisationFootprintItem.emissionsFactorSource}</p></td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"><svg className={organisationFootprintItem.emissionsFactorGeographyClassification === "Very Good" ? "inline-block fill-current text-lime" : organisationFootprintItem.emissionsFactorGeographyClassification === "Good" ? "inline-block fill-current text-indigo-400" : organisationFootprintItem.emissionsFactorGeographyClassification === "Poor" ? "inline-block fill-current text-red-500" : "inline-block fill-current text-amber-300"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg> {organisationFootprintItem.emissionsFactorGeography}</p></td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"><svg className={organisationFootprintItem.emissionsFactorCompletenessClassification === "Very Good" ? "inline-block fill-current text-lime" : organisationFootprintItem.emissionsFactorCompletenessClassification === "Good" ? "inline-block fill-current text-indigo-400" : organisationFootprintItem.emissionsFactorCompletenessClassification === "Poor" ? "inline-block fill-current text-red-500" : "inline-block fill-current text-amber-300"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg> {organisationFootprintItem.emissionsFactorCompleteness}</p></td>
                              </>
                              }
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{qualitativeUncertainty(GSD(organisationFootprintItem.emissionsFactorTechnologyFactor, organisationFootprintItem.emissionsFactorTimeFactor, organisationFootprintItem.emissionsFactorGeographyFactor, organisationFootprintItem.emissionsFactorCompletenessFactor, organisationFootprintItem.emissionsFactorReliabilityFactor, organisationFootprintItem.basicUncertaintyFactor))}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{(contribution(GSD(organisationFootprintItem.dataQualityTechnologyFactor, organisationFootprintItem.dataQualityTimeFactor, organisationFootprintItem.dataQualityGeographyFactor, organisationFootprintItem.dataQualityCompletenessFactor, organisationFootprintItem.dataQualityReliabilityFactor, organisationFootprintItem.basicUncertaintyFactor), impactPercentage(organisationFootprintItem.carbonEmissions)) + contribution(GSD(organisationFootprintItem.emissionsFactorTechnologyFactor, organisationFootprintItem.emissionsFactorTimeFactor, organisationFootprintItem.emissionsFactorGeographyFactor, organisationFootprintItem.emissionsFactorCompletenessFactor, organisationFootprintItem.emissionsFactorReliabilityFactor, organisationFootprintItem.basicUncertaintyFactor), impactPercentage(organisationFootprintItem.carbonEmissions))).toFixed(8)}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{GSD(organisationFootprintItem.emissionsFactorTechnologyFactor, organisationFootprintItem.emissionsFactorTimeFactor, organisationFootprintItem.emissionsFactorGeographyFactor, organisationFootprintItem.emissionsFactorCompletenessFactor, organisationFootprintItem.emissionsFactorReliabilityFactor, organisationFootprintItem.basicUncertaintyFactor).toFixed(8)}</td>
                              </>
                            }
                            {showVerification && 
                            <>
                              
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"submittedTip" + organisationFootprintItem.id}> {organisationFootprintItem.submitted !== "" ? <img className="h-10" src="/images/GreenTick.png" alt=""></img> : ""}
                                {showToolTip &&<ReactTooltip id={"submittedTip" + organisationFootprintItem.id} place="top" effect="solid">
                                  <div>{organisationFootprintItem.submitted}</div>
                                </ReactTooltip>}</td>
                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"verifiedTip" + organisationFootprintItem.id}> {organisationFootprintItem.verified === "Compliant" ? <img className="h-10" src="/images/GreenTick.png" alt=""></img>: organisationFootprintItem.verified === "Non Compliant" ? <img className="h-10" src="/images/Red-Cross.png" alt=""></img> : organisationFootprintItem.verified === "" ? "" : organisationFootprintItem.verified === "Recommendation" ? <img className="h-10" src="/images/BlueExclamation.png" alt=""></img> : <img className="h-10" src="/images/YellowExclamation.png" alt=""></img>}
                                {showToolTip &&<ReactTooltip id={"verifiedTip" + organisationFootprintItem.id} place="top" effect="solid">
                                    <div>{organisationFootprintItem.verified}</div>
                                </ReactTooltip>}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> <p className={organisationFootprintItem.commentThread.length > 0 && organisationFootprintItem.commentThread[organisationFootprintItem.commentThread.length-1].comment === organisationFootprintItem.submissionComment ? "w-48 2xl:w-72 truncate text-congress-blue font-extrabold" : "w-48 2xl:w-72 truncate"}>{organisationFootprintItem.submissionComment}</p></td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> <p className={organisationFootprintItem.commentThread.length > 0 && organisationFootprintItem.commentThread[organisationFootprintItem.commentThread.length-1].comment === organisationFootprintItem.verificationComment ? "w-48 2xl:w-72 truncate text-congress-blue font-extrabold" : "w-48 2xl:w-72 truncate"}>{organisationFootprintItem.verificationComment}</p></td>
                              </>
                              }
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"filesUploadedTip" + organisationFootprintItem.id}> {organisationFootprintItem.filesUploaded !== 0 ? <img className="h-10" src="/images/GreenTick.png" alt=""></img> : ""}
                                {showToolTip &&<ReactTooltip id={"filesUploadedTip" + organisationFootprintItem.id} place="top" effect="solid">
                                  <div>{organisationFootprintItem.filesUploaded}</div>
                                </ReactTooltip>}</td>
                              <td className="px-4 md:px-6  py-2 hover:cursor-pointer">
                              { props.role !== 3001 && props.role !== 5001 && props.role !== 6002 && props.role !== 2003 ? 
                                <Options cloneFunction={cloneOrganisationFootprintItem} cloneObject={organisationFootprintItem} deleteFunction={deleteOrganisationFootprintItem} deleteObject={organisationFootprintItem.id} edit={`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`} ></Options>
                              : <Options edit={`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`} ></Options>}
                              </td>
                            </tr>
                          ))}
                        </tbody>

                      </table>
                      <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToUpdate={collectionArrayToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} arrayToShow={scopeItems} onClose={() => setShowModal(false)} show={showModal} title={"Update Footprint Item"} />
                    </div>
                  )}

                  {organisationFootprint.organisationFootprintType === "Actual" && (
                    <div>
                      <table className="objects-8A min-w-full divide-y divide-gray-200">
                        <thead><tr className="h-20"><th className="bg-eight-slate-600 text-white whitespace-nowrap p-5 hover:cursor-pointer" onClick={() => {setShowUncertaintyAssessment(true); setShowVerification(true)}}>Footprint Items {!showUncertaintyAssessment || !showVerification ? " +" : ""}</th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th>{showUncertaintyAssessment && <><th onClick={() => setShowUncertaintyAssessment(false)} className="bg-bondi-blue text-white hover:cursor-pointer">- Uncertainty</th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th></>}{showVerification && <><th onClick={() => setShowVerification(false)} className="bg-eight-slate-600 text-white hover:cursor-pointer">- Verification</th><th className="bg-eight-slate-600 hover:cursor-pointer"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th></>}<th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th></tr></thead>
                        <thead className="bg-pacific-blue-50 z-20 sticky -top-1">
                          <tr>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Scope <div className="w-40"><MultiSelect
                              value={selectedItemCategory}
                              onChange={setSelectedItemCategory}
                              options={aggregateCategoriesArray(scopeItems).map((item) => {
                                return {
                                  label: item.itemCategory,
                                  value: item.itemCategory
                                }
                              })} 
                              labelledBy="Select"
                              /></div></th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Sub Category <div className="w-52"><MultiSelect
                              value={selectedItemSubCategory}
                              onChange={setSelectedItemSubCategory}
                              options={aggregateSubCategoriesArray(scopeItems).map((item) => {
                                return {
                                  label: item.itemSubCategory,
                                  value: item.itemSubCategory
                                }
                              })} 
                              labelledBy="Select"
                              /></div></th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Description <div className="w-52"><MultiSelect
                              value={selectedDescription}
                              onChange={setSelectedDescription}
                              options={aggregateDescriptionsArray(scopeItems).map((item) => {
                                return {
                                  label: item.itemDescription,
                                  value: item.itemDescription
                                }
                              })} 
                              labelledBy="Select"
                              /></div></th>
                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consumption</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Unit</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emission Factor</th> */}
                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> kgCO<sub>2</sub>e</th> */}
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> tCO<sub>2</sub>e</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> No Of Months</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Additional Estimated Emissions tCO<sub>2</sub>e</th>
                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Consultant Comment</th> */}
                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Control Category</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Comments on Control</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Impact %</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Impact Category</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Priority Category</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Priority</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Target %</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Target Year</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Forecast %</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Annual kgCO<sub>2</sub>e Reduction</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Forecast Year</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Measures</th> */}
                            {showUncertaintyAssessment && 
                            <>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Data Type</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emissions Factor - Source</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Geography</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Completeness</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Qualitative Uncertainty</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Quantitative Uncertainty</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emission Factor GSD</th>
                            </>
                            }
                            {showVerification &&
                            <>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Submitted<ReactSelect
                              styles={styles}
                              value={selectedSubmitted}
                              onChange={setSelectedSubmitted}
                              options={aggregateSubmittedArray(scopeItems).map((item) => {
                                return {
                                  label: item.submitted === "" ? "NOT SUBMITTED" : item.submitted,
                                  value: item.submitted
                                }
                              })} /></th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Verification Status<ReactSelect
                              styles={styles}
                              value={selectedVerified}
                              onChange={setSelectedVerified}
                              options={aggregateVerifiedArray(scopeItems).map((item) => {
                                return {
                                  label: item.verified === "" ? "NOT REVIEWED" : item.verified,
                                  value: item.verified
                                }
                              })} /></th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Submission Comment</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Verification Comment</th>
                            </>
                            }
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Documents Uploaded</th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                          </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                          {scopeItemsFiltered.sort((item1, item2) => Number(item1.itemCategory) < Number(item2.itemCategory) ? -1 : Number(item1.itemCategory) > Number(item2.itemCategory) ? 1 : Number(item1.itemSubCategory.slice(2,4)) < Number(item2.itemSubCategory.slice(2,4)) ? -1 : Number(item1.itemSubCategory.slice(2,4)) > Number(item2.itemSubCategory.slice(2,4)) ? 1 : item1.itemDescription < item2.itemDescription ? -1 : item1.itemDescription > item2.itemDescription ? 1 : 0).map((organisationFootprintItem) => (
                            <tr key={organisationFootprintItem.id + organisationFootprintItem.itemTag}>
                              <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">
                              { props.role !== 3001 && props.role !== 5001 && props.role !== 6002 && props.role !== 2003 ? 
                                <Options position="left" cloneFunction={cloneOrganisationFootprintItem} cloneObject={organisationFootprintItem} deleteFunction={deleteOrganisationFootprintItem} deleteObject={organisationFootprintItem.id} edit={`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`} ></Options>
                              : <Options position="left" edit={`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`} ></Options>}
                              </td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.itemCategory}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"subCatTip" + organisationFootprintItem.id}><p className="w-48 2xl:w-72 truncate">{organisationFootprintItem.itemSubCategory}</p>
                              {showToolTip && <ReactTooltip id={"subCatTip" + organisationFootprintItem.id} place="top" effect="solid">
                                  <div>{organisationFootprintItem.itemSubCategory === "1.1 Stationary Combustion" && <p>This category covers the direct emissions associated with stational fuel combustion across sites owned and operated by the organisation. <br></br> Stationary fuel combustion emission sources are typically associated with activities that combust solid, liquid or gaseous fuels.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "1.2 Mobile Combustion" && <p>1.2 Mobile Combustion covers the consumption of fuel from vehicles that are owned and operated by the organisation (including leased vehicles that are used on a daily basis). <br></br> The fuels used and distance travelled for the reporting year should be provided.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "1.3 Refrigerants" && <p>This category covers fugitive emissions associated with leaks across HVAC systems, chillers, refrigerators in operation across the organisation.<br></br>Most refrigerant gases contribute to global warming when leaked into the atmosphere. <br></br> The quantity of leaked gas is assumed to be equal to the amount of gas replaced in these systems by your HVAC or chiller maintenance company.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "2.1 Purchased Electricity" && <p>Scope 2 emissions cover the indirect emissions associated with electricity and other sources of energy purchased from your local utility. <br></br>These emissions are considered to be an indirect emissions source, because the emissions are a consequence of activities from your organisation, <br></br>but occur at sites controlled by your utility provider.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.1 Purchased Goods & Services" && <p>This category covers the upstream emissions associated with the extraction and production of goods and services purchased or acquired <br></br> by the reporting company across the reporting year (e.g., materials, components, key ingredients).<br></br> Where a product-level carbon footprint has been measured, please provide evidence of this footprint as per the information sent through from selected suppliers.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.2 Capital Goods" && <p>This category covers the upstream emissions associated with the extraction, production, and transportation of capital goods purchased or acquired <br></br>  by the reporting company across the reporting year. <br></br> Capital goods that are purchased during the reporting year but represent longer-term investments should be included in this category.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.3 Fuel & Energy" && <p>This category covers the upstream emissions associated with the extraction, production, and transportation of fuels and energy (gas and electricity) purchased or acquired  <br></br> by the reporting company across the reporting year.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.4 Upstream Transportation & Distribution" && <p>This category covers the emissions associated with the transportation and distribution of products purchased between a company’s tier 1 suppliers and its own operations<br></br> (in vehicles and facilities not owned or controlled by the reporting company). Data should cover the reporting year.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.5 Waste" && <p>This category covers the emissions associated with the disposal and treatment of waste, alongside the supply and treatment of water <br></br> across the reporting company's operations for the reporting year.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.6 Business Travel" && <p>This category covers the emissions associated with the transportation of employees for business-related activities during the reporting year <br></br> (in vehicles not owned or operated by the reporting company).</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.7 Employee Commuting" && <p>This category covers the emissions associated with the transportation of employees between their homes and their worksites during the reporting year <br></br> (in vehicles not owned or operated by the reporting company). </p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.8 Upstream Leased Assets" && <p>This category covers the emissions associated with the operation of assets across the organisation that were leased by the organisation during the reporting year <br></br> (e.g. leased buildings and vehicles).</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.9 Downstream Transportation & Distribution" && <p>This category covers the emissions associated with the transportation and distribution of sold products, after the point of sale <br></br> between the reporting company's operations and the end consumer across the reporting year.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.10 Processing of Sold Products" && <p></p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.11 Use of Sold Products" && <p>This category covers the emissions associated with the use of goods, services and products sold by the reporting company during the reporting year <br></br> (taking into consideration the Scope 1 and 2 emissions from end users who are using individual products). <br></br> This category includes either the direct or indirect use-phase emissions associated with sold products.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.12 End-of-Life Treatment of Sold Products" && <p>This category covers the emissions associated with the waste disposal and treatment of products sold by the reporting company <br></br> (during the reporting year), at the end of their life. </p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.13 Downstream Leased Assets" && <p>This category includes emissions from the operation of assets that are owned by the reporting company (acting as lessor) <br></br>and leased to other entities in the reporting year that are not already included in scope 1 or scope 2. <br></br> This category is applicable to lessors (i.e., companies that receive payments from lessees). <br></br>Companies that operate leased assets (i.e., lessees) should refer to category 8 (Upstream leased assets).</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.14 Franchises" && <p>Category 14 includes emissions from the operation of franchises not included in scope 1 or scope 2. <br></br>A franchise is a business operating under a license to sell or distribute another company’s goods or services within a certain location. <br></br>This category is applicable to franchisors (i.e., companies that grant licenses to other entities <br></br> to sell or distribute its goods or services in return for payments, such as royalties for the use of trademarks and other services). <br></br>Franchisors should account for emissions that occur from the operation of franchises <br></br>(i.e., the scope 1 and scope 2 emissions of franchisees) in this category.</p>}</div>
                                  <div>{organisationFootprintItem.itemSubCategory === "3.15 Investments" && <p>This category includes scope 3 emissions associated with the reporting company’s investments in the reporting year, not already included in scope 1 or scope 2.<br></br> This category is applicable to investors (i.e., companies that make an investment with the objective of making a profit) <br></br>and companies that provide financial services. This category also applies to investors that are not profit driven <br></br>(e.g. multilateral development banks), and the same calculation methods should be used. <br></br>Investments are categorized as a downstream scope 3 category because providing capital <br></br>or financing is a service provided by the reporting company.</p>}</div>

                                </ReactTooltip>}</td>
                                {((props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (organisationFootprintItem.verified !== "Compliant" && (organisationFootprintItem.submitted === "" || organisationFootprintItem.submitted === undefined))) ? 
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.itemDescription); setFieldToSendToModal("organisationFootprintItemDescription"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"descriptionTip" + organisationFootprintItem.id}> <p className="w-48 2xl:w-72 truncate"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg>{organisationFootprintItem.itemDescription}</p> {showToolTip && <ReactTooltip id={"descriptionTip" + organisationFootprintItem.id} place="top" effect="solid"><div>{organisationFootprintItem.itemDescription}</div></ReactTooltip>}</td> : <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"descriptionTip" + organisationFootprintItem.id}> <p className="w-48 2xl:w-72 truncate">{organisationFootprintItem.itemDescription}</p>{showToolTip && <ReactTooltip id={"descriptionTip" + organisationFootprintItem.id} place="top" effect="solid"><div>{organisationFootprintItem.itemDescription}</div></ReactTooltip>}</td>
                                }
                              {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {internationalNumberFormat.format(organisationFootprintItem.consumption)} </td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.unit}</td>
                              {organisationFootprintItem.unit !== "various" &&
                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.emissionFactor.toFixed(4)}</td>
                              }
                              {organisationFootprintItem.unit === "various" &&
                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> various</td>
                              } */}
                              {/* <td onClick={() => navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`)} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"openHelperToolTip"}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline mx-1 text-slate-600">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                </svg>
                                {internationalNumberFormat.format(organisationFootprintItem.carbonEmissions.toFixed(2))}
                                <ReactTooltip id={"openHelperToolTip"} place="top" effect="solid">
                                  <div>Click to Open Helper Tool and View Sub Items</div>
                                </ReactTooltip></td> */}
                              <td onClick={() => navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`)} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"openHelperToolTip"}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline mx-1 text-slate-600">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                </svg>
                                {internationalNumberFormat.format((organisationFootprintItem.carbonEmissions / 1000).toFixed(2))}
                                {showToolTip &&<ReactTooltip id={"openHelperToolTip"} place="top" effect="solid">
                                  <div>Click to Open Helper Tool and View Sub Items</div>
                                </ReactTooltip>}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.numberOfMonths}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {internationalNumberFormat.format((organisationFootprintItem.additionalEstimatedEmissions/1000).toFixed(2))}</td>
                              {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> <p className="w-48 2xl:w-72 truncate">{organisationFootprintItem.itemConsultantComment}</p></td> */}
                              {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {organisationFootprintItem.reductionControlCategory}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {organisationFootprintItem.reductionCommentsOnControl}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {impactPercentage(organisationFootprintItem.carbonEmissions).toFixed(2) + "%"}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {impactCategory(organisationFootprintItem.carbonEmissions)}</td>

                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {priorityCategory(organisationFootprintItem.reductionControlCategory, impactCategory(organisationFootprintItem.carbonEmissions))}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {priorityCategoryDescription(priorityCategory(organisationFootprintItem.reductionControlCategory, impactCategory(organisationFootprintItem.carbonEmissions)))}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {targetReductionPercentage(priorityCategory(organisationFootprintItem.reductionControlCategory, impactCategory(organisationFootprintItem.carbonEmissions)))}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {targetReductionYear(priorityCategory(organisationFootprintItem.reductionControlCategory, impactCategory(organisationFootprintItem.carbonEmissions)))}</td>
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.reductionProjection); setFieldToSendToModal("reductionProjection"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className="px-4 md:px-2 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg>{Number(organisationFootprintItem.reductionProjection).toFixed(2)}</td>

                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {internationalNumberFormat.format((((Number(organisationFootprintItem.reductionProjection) / 100) * organisationFootprintItem.carbonEmissions)).toFixed(2))}</td>

                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.reductionProjectionYear); setFieldToSendToModal("reductionProjectionYear"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className="px-4 md:px-2 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg>{organisationFootprintItem.reductionProjectionYear}</td>

                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {organisationFootprintItem.reductionMeasures}</td> */}
                              {showUncertaintyAssessment &&
                              <>
                              {((props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (organisationFootprintItem.verified !== "Compliant" && (organisationFootprintItem.submitted === "" || organisationFootprintItem.submitted === undefined))) ? 
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.dataType); setFieldToSendToModal("dataType"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg> {organisationFootprintItem.dataType}</td> : <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{organisationFootprintItem.dataType}</td>}
                              
                              {((props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (organisationFootprintItem.verified !== "Compliant" && (organisationFootprintItem.submitted === "" || organisationFootprintItem.submitted === undefined))) &&
                              <>

                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"> {organisationFootprintItem.emissionsFactorSource}</p></td>

                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.emissionsFactorGeography); setFieldToSendToModal("emissionsFactorGeography"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"><svg className={organisationFootprintItem.emissionsFactorGeographyClassification === "Very Good" ? "inline-block fill-current text-lime" : organisationFootprintItem.emissionsFactorGeographyClassification === "Good" ? "inline-block fill-current text-indigo-400" : organisationFootprintItem.emissionsFactorGeographyClassification === "Poor" ? "inline-block fill-current text-red-500" : "inline-block fill-current text-amber-300"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg> {organisationFootprintItem.emissionsFactorGeography}</p></td>
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.emissionsFactorCompleteness); setFieldToSendToModal("emissionsFactorCompleteness"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"><svg className={organisationFootprintItem.emissionsFactorCompletenessClassification === "Very Good" ? "inline-block fill-current text-lime" : organisationFootprintItem.emissionsFactorCompletenessClassification === "Good" ? "inline-block fill-current text-indigo-400" : organisationFootprintItem.emissionsFactorCompletenessClassification === "Poor" ? "inline-block fill-current text-red-500" : "inline-block fill-current text-amber-300"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg> {organisationFootprintItem.emissionsFactorCompleteness}</p></td>

                              </>
                              }

                              {((props.role !== 2001 && props.role !== 4001 && props.role !== 6001 && props.role !== 2004) || (organisationFootprintItem.verified === "Compliant" || (organisationFootprintItem.submitted !== "" && organisationFootprintItem.submitted !== undefined))) &&
                              <>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"> {organisationFootprintItem.emissionsFactorSource}</p></td>

                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"><svg className={organisationFootprintItem.emissionsFactorGeographyClassification === "Very Good" ? "inline-block fill-current text-lime" : organisationFootprintItem.emissionsFactorGeographyClassification === "Good" ? "inline-block fill-current text-indigo-400" : organisationFootprintItem.emissionsFactorGeographyClassification === "Poor" ? "inline-block fill-current text-red-500" : "inline-block fill-current text-amber-300"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg> {organisationFootprintItem.emissionsFactorGeography}</p></td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}> <p className = "w-48 2xl:w-72"><svg className={organisationFootprintItem.emissionsFactorCompletenessClassification === "Very Good" ? "inline-block fill-current text-lime" : organisationFootprintItem.emissionsFactorCompletenessClassification === "Good" ? "inline-block fill-current text-indigo-400" : organisationFootprintItem.emissionsFactorCompletenessClassification === "Poor" ? "inline-block fill-current text-red-500" : "inline-block fill-current text-amber-300"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg> {organisationFootprintItem.emissionsFactorCompleteness}</p></td>
                              </>
                              }



                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{qualitativeUncertainty(GSD(organisationFootprintItem.emissionsFactorTechnologyFactor, organisationFootprintItem.emissionsFactorTimeFactor, organisationFootprintItem.emissionsFactorGeographyFactor, organisationFootprintItem.emissionsFactorCompletenessFactor, organisationFootprintItem.emissionsFactorReliabilityFactor, organisationFootprintItem.basicUncertaintyFactor))}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{(contribution(GSD(organisationFootprintItem.dataQualityTechnologyFactor, organisationFootprintItem.dataQualityTimeFactor, organisationFootprintItem.dataQualityGeographyFactor, organisationFootprintItem.dataQualityCompletenessFactor, organisationFootprintItem.dataQualityReliabilityFactor, organisationFootprintItem.basicUncertaintyFactor), impactPercentage(organisationFootprintItem.carbonEmissions)) + contribution(GSD(organisationFootprintItem.emissionsFactorTechnologyFactor, organisationFootprintItem.emissionsFactorTimeFactor, organisationFootprintItem.emissionsFactorGeographyFactor, organisationFootprintItem.emissionsFactorCompletenessFactor, organisationFootprintItem.emissionsFactorReliabilityFactor, organisationFootprintItem.basicUncertaintyFactor), impactPercentage(organisationFootprintItem.carbonEmissions))).toFixed(8)}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{GSD(organisationFootprintItem.emissionsFactorTechnologyFactor, organisationFootprintItem.emissionsFactorTimeFactor, organisationFootprintItem.emissionsFactorGeographyFactor, organisationFootprintItem.emissionsFactorCompletenessFactor, organisationFootprintItem.emissionsFactorReliabilityFactor, organisationFootprintItem.basicUncertaintyFactor).toFixed(8)}</td>
                              </>
                              }
                              {showVerification &&
                              <>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"submittedTip" + organisationFootprintItem.id}> {organisationFootprintItem.submitted !== "" ? <img className="h-10" src="/images/GreenTick.png" alt=""></img> : ""}
                                {showToolTip &&<ReactTooltip id={"submittedTip" + organisationFootprintItem.id} place="top" effect="solid">
                                  <div>{organisationFootprintItem.submitted}</div>
                                </ReactTooltip>}</td>
                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"verifiedTip" + organisationFootprintItem.id}> {organisationFootprintItem.verified === "Compliant" ? <img className="h-10" src="/images/GreenTick.png" alt=""></img>: organisationFootprintItem.verified === "Non Compliant" ? <img className="h-10" src="/images/Red-Cross.png" alt=""></img> : organisationFootprintItem.verified === "" ? "" : organisationFootprintItem.verified === "Recommendation" ? <img className="h-10" src="/images/BlueExclamation.png" alt=""></img> : <img className="h-10" src="/images/YellowExclamation.png" alt=""></img>}
                                {showToolTip && <ReactTooltip id={"verifiedTip" + organisationFootprintItem.id} place="top" effect="solid">
                                    <div>{organisationFootprintItem.verified}</div>
                                </ReactTooltip>}</td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}><p className={organisationFootprintItem.commentThread.length > 0 && organisationFootprintItem.commentThread[organisationFootprintItem.commentThread.length-1].comment === organisationFootprintItem.submissionComment ? "w-48 2xl:w-72 truncate text-congress-blue font-extrabold" : "w-48 2xl:w-72 truncate"}>{organisationFootprintItem.submissionComment}</p></td>
                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}><p className={organisationFootprintItem.commentThread.length > 0 && organisationFootprintItem.commentThread[organisationFootprintItem.commentThread.length-1].comment === organisationFootprintItem.verificationComment ? "w-48 2xl:w-72 truncate text-congress-blue font-extrabold" : "w-48 2xl:w-72 truncate"}>{organisationFootprintItem.verificationComment}</p></td>
                              </>
                              }

                              <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"filesUploadedTip" + organisationFootprintItem.id}> {organisationFootprintItem.filesUploaded !== 0 ? <img className="h-10" src="/images/GreenTick.png" alt=""></img> : ""}
                                {showToolTip && <ReactTooltip id={"filesUploadedTip" + organisationFootprintItem.id} place="top" effect="solid">
                                  <div>{organisationFootprintItem.filesUploaded}</div>
                                </ReactTooltip>}</td>
                              <td className="px-4 md:px-6  py-2 hover:cursor-pointer">
                              { props.role !== 3001 && props.role !== 5001 && props.role !== 6002 && props.role !== 2003 ? 
                                <Options cloneFunction={cloneOrganisationFootprintItem} cloneObject={organisationFootprintItem} deleteFunction={deleteOrganisationFootprintItem} deleteObject={organisationFootprintItem.id} edit={`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`} ></Options>
                              : <Options edit={`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`} ></Options>}
                              </td>
                            </tr>
                          ))}
                        </tbody>

                      </table>
                      <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToUpdate={collectionArrayToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} arrayToShow={scopeItems} onClose={() => setShowModal(false)} show={showModal} title={"Update Footprint Item"} />
                    </div>
                  )}

                  {organisationFootprint.organisationFootprintType !== "Actual (Baseline)" && organisationFootprint.organisationFootprintType !== "Actual" && (
                    <table className="objects-8A min-w-full divide-y divide-gray-200">
                      <thead className="bg-pacific-blue-50 z-20 sticky -top-1">
                        <tr>
                          <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <div className="w-40">Scope <MultiSelect
                            value={selectedItemCategory}
                            onChange={setSelectedItemCategory}
                            options={aggregateCategoriesArray(scopeItems).map((item) => {
                              return {
                                label: item.itemCategory,
                                value: item.itemCategory
                              }
                            })} 
                            labelledBy="Select"
                            /></div></th>
                          <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Sub Category <div className="w-40"><MultiSelect
                            styles={styles}
                            value={selectedItemSubCategory}
                            onChange={setSelectedItemSubCategory}
                            options={aggregateSubCategoriesArray(scopeItems).map((item) => {
                              return {
                                label: item.itemSubCategory,
                                value: item.itemSubCategory
                              }
                            })}
                            labelledBy="Select" 
                            /></div></th>
                          <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Description <div className="w-40"><MultiSelect
                            styles={styles}
                            value={selectedDescription}
                            onChange={setSelectedDescription}
                            options={aggregateDescriptionsArray(scopeItems).map((item) => {
                              return {
                                label: item.itemDescription,
                                value: item.itemDescription
                              }
                            })}
                            labelledBy="Select" 
                            /></div></th>
                          <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consumption</th>
                          <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Unit</th>
                          <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emission Factor</th>
                          <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> kgCO<sub>2</sub>e</th>
                          <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> tCO<sub>2</sub>e</th>
                          {organisationFootprint.organisationFootprintType === "Actual" && <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> No of Months</th>}
                          {organisationFootprint.organisationFootprintType === "Actual" && <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Estimated Additional kgCO<sub>2</sub>e</th>}
                          {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consultant Comment</th> */}
                          <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                        </tr>
                      </thead>

                      <tbody className="bg-white divide-y divide-gray-200">
                        {scopeItemsFiltered.map((organisationFootprintItem) => (
                          <tr key={organisationFootprintItem.id + organisationFootprintItem.itemTag}>
                            <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprintItem.itemCategory}</td>
                            <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprintItem.itemSubCategory}</td>
                            {((props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (organisationFootprintItem.verified !== "Compliant" && (organisationFootprintItem.submitted === "" || organisationFootprintItem.submitted === undefined))) ? 
                              <td onClick={() => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.itemDescription); setFieldToSendToModal("organisationFootprintItemDescription"); setObjectIdToSendToModal(organisationFootprint.id); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"descriptionTip" + organisationFootprintItem.id}> <p className="w-48 2xl:w-72 truncate"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                              </svg>{organisationFootprintItem.itemDescription}</p>{showToolTip && <ReactTooltip id={"descriptionTip" + organisationFootprintItem.id} place="top" effect="solid"><div>{organisationFootprintItem.itemDescription}</div></ReactTooltip>}</td> : <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.verified === "Recommendation" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-pacific-blue-300 border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"descriptionTip" + organisationFootprintItem.id}> <p className="w-48 2xl:w-72 truncate">{organisationFootprintItem.itemDescription}</p>{showToolTip && <ReactTooltip id={"descriptionTip" + organisationFootprintItem.id} place="top" effect="solid"><div>{organisationFootprintItem.itemDescription}</div></ReactTooltip>}</td>
                                }
                            <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {internationalNumberFormat.format(organisationFootprintItem.consumption)} </td>
                            <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprintItem.unit}</td>
                            {organisationFootprintItem.unit !== "various" &&
                              <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprintItem.emissionFactor.toFixed(4)}</td>
                            }
                            {organisationFootprintItem.unit === "various" &&
                              <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> various</td>
                            }
                            <td onClick={() => navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer" data-tip data-for={"openHelperToolTip"}>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline mx-1 text-slate-600">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                              </svg>
                              {internationalNumberFormat.format(organisationFootprintItem.carbonEmissions.toFixed(2))}
                                {showToolTip && <ReactTooltip id={"openHelperToolTip"} place="top" effect="solid">
                                <div>Click to Open Helper Tool and View Sub Items</div>
                              </ReactTooltip>}</td>
                            <td onClick={() => navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer" data-tip data-for={"openHelperToolTip"}>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline mx-1 text-slate-600">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                              </svg>
                              {internationalNumberFormat.format((organisationFootprintItem.carbonEmissions / 1000).toFixed(2))}
                              {showToolTip && <ReactTooltip id={"openHelperToolTip"} place="top" effect="solid">
                                <div>Click to Open Helper Tool and View Sub Items</div>
                              </ReactTooltip>}</td>
                            {organisationFootprint.organisationFootprintType === "Actual" && <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprintItem.numberOfMonths}</td>}
                            {organisationFootprint.organisationFootprintType === "Actual" && <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprintItem.additionalEstimatedEmissions}</td>}
                            {/* <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprintItem.itemConsultantComment}</td> */}
                            <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">
                            { props.role !== 3001 && props.role !== 5001 && props.role !== 6002 && props.role !== 2003 ? 
                                <Options cloneFunction={cloneOrganisationFootprintItem} cloneObject={organisationFootprintItem} deleteFunction={deleteOrganisationFootprintItem} deleteObject={organisationFootprintItem.id} edit={`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`}></Options>
                              : <Options edit={`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`} ></Options>}
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </table>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className='flex p-2'>
          {(props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (
            <Button color="bondi-blue" text="Add New Footprint Item" onClick={() => navigate(`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/_add`)} />
          )}
            <Button color="bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
          </div>
        <br></br>
        <br></br>
        <div className="flex items-center bg-slate-100">
          <div className="w-full mx-auto mb-5 px-3">
            <div className="grid gap-2 sm:gap-7 sm:grid-cols-2 md:grid-cols-3">

              {/* <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                <div>
                  <div className="text-sm text-gray-500 ">Total Carbon Emissions (kgCO<sub>2</sub>e):  </div>
                  <div className="flex items-center pt-1">
                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{`${internationalNumberFormat.format((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}`}</p></div>
                  </div>
                </div>
                <div className="text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                  </svg>
                </div>
              </div> */}
              <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                <div>
                  <div className="text-sm text-gray-500 ">Total Carbon Emissions (tCO<sub>2</sub>e): </div>
                  <div className="flex items-center pt-1">
                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{`${internationalNumberFormat.format(((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / 1000).toFixed(2))}`}</p></div>
                  </div>
                </div>
                <div className="text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
              </div>

                {/* {(organisationFootprint.organisationFootprintType === "Actual (Baseline)" || organisationFootprint.organisationFootprintType === "Actual") && (
                <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                  <div>
                    <div className="text-sm text-gray-500 ">rattingResult</div>
                    <div className="flex items-center pt-1">
                      <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{scopeItemsFilteredWithGSD.map(item => item.ratingResultsBasedOnImpact).reduce((prev, curr) => Number(prev) + Number(curr), 0)}</p></div>
                    </div>
                  </div>
                  <div className="text-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </div>
                </div>
              )} */}

              <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                <div>
                  <div className="text-sm text-gray-500 ">Total Carbon Emissions (tCO<sub>2</sub>e) per Employee: </div>
                  <div className="flex items-center pt-1">
                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{`${internationalNumberFormat.format((((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / organisationFootprint.organisationNumberOfEmployees).toFixed(2)) / 1000)}`}</p></div>
                  </div>
                </div>
                <div className="text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
              </div>

              <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                <div>
                  <div className="text-sm text-gray-500 ">Total Carbon Emissions (tCO<sub>2</sub>e) per 1000 Base Currency Units of Turnover: </div>
                  <div className="flex items-center pt-1">
                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{`${internationalNumberFormat.format((((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / (organisationFootprint.organisationTurnoverGBP / 1000)).toFixed(2)) / 1000)}`}</p></div>
                  </div>
                </div>
                <div className="text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
              </div>

              {/*  <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                <div>
                <div className="text-sm text-gray-500 ">Lower Bar: </div>
                <div className="flex items-center pt-1">
                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / ((Math.exp(((scopeItemsFilteredWithGSD.map(item => item.GSDValue).reduce((prev, curr) => Number(prev) + Number(curr), 0))) ** 0.5)) ** 2)) / 1000}</p></div>
                </div>
                </div>
                <div className="text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                </div>
            </div>

            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                <div>
                <div className="text-sm text-gray-500 ">Upper Bar: </div>
                <div className="flex items-center pt-1">
                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{(scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000) * ((Math.exp(((scopeItemsFilteredWithGSD.map(item => item.GSDValue).reduce((prev, curr) => Number(prev) + Number(curr), 0))) ** 0.5)) ** 2) - (scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)}</p></div>
                </div>
                </div>
                <div className="text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                </div>
            </div> */}
            

            </div>
          </div>
        </div>

        <div className="flex items-center bg-slate-100">
          <div className="w-full mx-auto mb-5 px-3">
            <div className="grid gap-2 sm:gap-7 sm:grid-cols-2 md:grid-cols-3">

              <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                <Doughnut data={dataChart4} options={pieOptions} /></div>

              <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                <Doughnut data={dataChart3} options={pieOptionsBySubCategory} /></div>

              <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                <Doughnut data={dataChart2} options={pieOptionsByItem} /></div>

            </div>
          </div>
        </div>

        {organisationFootprint.organisationFootprintType === "Actual (Baseline)" && (props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004 || props.role === 2003) && (
            <div>

              <div className="flex">
                <div className="basis-full shadow-md shadow-slate-300 rounded-2xl m-3 p-3 bg-white">
                  <Line data={dataChart7c} options={barAndLineOptions} height={80} />
                </div>
              </div>
              <div className="flex">
                <div className="basis-full shadow-md shadow-slate-300 rounded-2xl m-3 p-3 bg-white">
                  <Line data={dataChart7d} options={barAndLineOptionsScope3} height={80} />
                </div>
              </div>

              <div className="grid gap-2 sm:gap-7 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-2">

                <button className="rounded-full m-1 p-2 text-white t-10 bg-pacific-blue-600 text-center" onClick={() => { createTargetFootprints() }} data-tip data-for="targetFootprints">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`float-left h-6 w-6 text-white ${animationTarget === true ? "animate-spin" : ""}`} >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                Generate Targets
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-1-circle-fill float-right" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312V4.002Z" />
                </svg>
                </button>

                <button className="rounded-full m-1 p-2 text-white t-10 bg-pacific-blue-600 text-center" onClick={() => { resetForecastFigures() }} data-tip data-for="ResetForecastFigures">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`float-left h-6 w-6 mr-3 text-white ${animationReset === true ? "animate-spin" : ""}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                Reset Forecast Figures
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-2-circle-fill float-right" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
                </svg>
                </button>

                <button className="rounded-full m-1 p-2 text-white t-10 bg-pacific-blue-600 text-center" onClick={() => { createForecastFootprints() }} data-tip data-for="forecastFootprints">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`float-left h-6 w-6 mr-3 text-white ${animationForecast === true ? "animate-spin" : ""}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                Generate Forecast
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-3-circle-fill float-right" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
                </svg>
                </button>

                {!importFromRoadmapToggle && 
                <button className="SubmitButton rounded-full m-1 p-2 text-white t-10 bg-pacific-blue-600 text-center" onClick={() => {setImportFromRoadmapToggle(true)}} data-tip data-for="forecastFootprints">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`float-left h-6 w-6 mr-3 text-white ${animationForecast === true ? "animate-spin" : ""}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                Import Forecast
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-3-circle-fill float-right" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
                </svg>
                </button>
                }

                {importFromRoadmapToggle && 
                <div className="text-center">
                <form>
                    <input id="csvFileInput" accept={".csv"} type={"file"} onChange={handleOnChange} />
                    <label className="p-10" htmlFor="csvFileInput">Choose File</label>
                    <Button color="bondi-blue" text="Import" onClick={(e) => { handleOnSubmit(e) }} />
                    <Button color="bondi-blue" text="Cancel" onClick={(e) => { setImportFromRoadmapToggle(false) }} />
                </form>
                </div>
                }


                <button className="pt-10 pl-10" onClick={() => { deleteFootprints("Target") }}>
                </button>

                <button className="pt-10 pl-10" onClick={() => { deleteFootprints("Forecast") }}>
                </button>


                </div>

            </div>

          )}

          {organisationFootprint.organisationFootprintType === "Actual" && (
            <div>
              <div className="flex">
                <div className="basis-full shadow-md shadow-slate-300 rounded-2xl m-3 p-3 bg-white">
                  <Bar data={dataChart5} options={horizontalBarOptions} height={70} />
                </div>
              </div>
              <div className="flex">
                <div className="basis-full shadow-md shadow-slate-300 rounded-2xl m-3 p-3 bg-white">
                  <Bar data={dataChart5Scope3} options={horizontalBarOptionsScope3} height={70} />
                </div>
              </div>
              <br></br>
              <br></br>
            </div>

          )}

    {(organisationFootprint.organisationFootprintType === "Actual (Baseline)" || organisationFootprint.organisationFootprintType === "Actual") && (
    <div>
         <div className="flex items-center bg-slate-100">
            <div className="w-full mx-auto mb-5 px-3">
                <div className="grid gap-2 sm:gap-7 sm:grid-cols-2 md:grid-cols-4">

                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                            <div>
                                <div className="text-sm text-gray-500 ">Quantitative Uncertainty</div>
                                <div className="flex items-center pt-1">
                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{(((Math.exp(((scopeItemsFilteredWithGSD.map(item => item.GSDValue).reduce((prev, curr) => Number(prev) + Number(curr), 0))) ** 0.5)) ** 2) * 100).toFixed(0)}%</p></div>
                                </div>
                            </div>
                            <div className="text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                            </div>

                        </div>

                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                            <div>
                                <div className="text-sm text-gray-500 ">Qualitative Uncertainty</div>
                                <div className="flex items-center pt-1">
                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{scopeItemsFilteredWithGSD.map(item => item.ratingResultsBasedOnImpact).reduce((prev, curr) => Number(prev) + Number(curr), 0) <= 1 ? "Very Good" : scopeItemsFilteredWithGSD.map(item => item.ratingResultsBasedOnImpact).reduce((prev, curr) => Number(prev) + Number(curr), 0) <= 2 ? "Good" : scopeItemsFilteredWithGSD.map(item => item.ratingResultsBasedOnImpact).reduce((prev, curr) => Number(prev) + Number(curr), 0) <= 4 ? "Fair" : "Poor"}</p></div>
                                </div>
                            </div>
                            <div className="text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                            </div>
                        </div>

                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                        <div>
                            <div className="text-sm text-gray-500 ">95% Lower bound: </div>
                            <div className="flex items-center pt-1">
                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{(((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / ((Math.exp(((scopeItemsFilteredWithGSD.map(item => item.GSDValue).reduce((prev, curr) => Number(prev) + Number(curr), 0))) ** 0.5)) ** 2)) / 1000).toFixed(2)}</p></div>
                            </div>
                            </div>
                            <div className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            </div>
                        </div>

                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                        <div>
                            <div className="text-sm text-gray-500 ">95% Upper bound: </div>
                            <div className="flex items-center pt-1">
                            <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{(((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * ((Math.exp(((scopeItemsFilteredWithGSD.map(item => item.GSDValue).reduce((prev, curr) => Number(prev) + Number(curr), 0))) ** 0.5)) ** 2)) / 1000).toFixed(2)}</p></div>
                            </div>
                            </div>
                            <div className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            </div>
                        </div>

                </div>
            </div>
        </div>

        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
        <Bar data={dataChart5AllScopes} options={horizontalBarOptionsAllScopes} height={70} />
        </div>
    </div>

    )}

    </div>
    )

  } else {

    return (
      <div>
        {/* <Header title="Organisation Footprint Items" section="" showLogo={false}/> */}
        <p>Footprint Items Loading...</p>
        {(props.role === 2001 || props.role === 4001 || props.role === 6001 || props.role === 2004) && (
        <Button color="bondi-blue" text="Add New Footprint Item" onClick={() => navigate(`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/_add`)} />
        )}
        </div>)

  }

}

export default ListOrganisationFootprintItems

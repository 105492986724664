import { useState, useEffect } from 'react'
import Button from './Button'
import axios from 'axios'
import Note from './Note'
import DOMPurify from 'dompurify';

import { Watch } from  'react-loader-spinner'

const ModalEmailInfo = (props) => {

    const [allNotes, setAllNotes] = useState([])
    const [loading, setLoading] = useState(false)
      
    const API_URL = process.env.REACT_APP_API_URL;

    var arrNoteData = []

    const getEmails = async () => {

        setLoading(true)
    
        await axios.post(process.env.REACT_APP_API_URL + "/emailSearch", {searchString: props.data})
        .then((response) => {
            console.log(response.data)
            setAllNotes(response.data.sort((item1, item2) => Date.parse(item1.datetime) < Date.parse(item2.datetime) ? -1 : 0))
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);                
        });

    }
    

    useEffect(() => {

        if (props.data) {

            getEmails()

        }

        
    }, [props.data])

    
    if (!props.show) {
        return null
    }
    return (
        <div className="update-value-modal z-50">
            <div className="update-value-modal-timesheet-content">
                <div className="update-value-modal-header mt-4">
                    <p className="update-value-modal-title text-congress-blue text-xl">{props.title}</p>
                </div>
                <div className="update-value-modal-body overflow-x-hidden">
                    {!loading &&
                        <div className="min-w-full mt-4 overflow-x-hidden">
                            <div className='overflow-y-scroll h-[700px] overflow-x-hidden'>                                
                                <table className="objects-8A divide-y divide-gray-200 table-fixed">
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '25px' }}>Date</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '50px' }}>Subject</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '150px' }}>Body</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {allNotes.map((item) => (
                                            <tr key={item.id}>
                                                <td className="pl-4 py-2 whitespace-normal font-bold align-top text-xs">{item.datetime}</td>
                                                <td className="pl-4 py-2 whitespace-normal font-bold align-top text-xs">{item.subject}</td>
                                                <td className="pl-4 py-2 whitespace-normal text-xs"><Note htmlContent={item.body} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {loading &&
                        <div className="min-w-full mt-4">
                            <div className='overflow-auto h-[400px]'>                                
                                <table className="min-w-full table-fixed">
                                    <tbody className="bg-white">
                                        <tr>
                                            <td className="pl-4 py-2 whitespace-normal flex justify-center items-center">
                                                <Watch 
                                                    height="100"
                                                    width="100"
                                                    radius="48"
                                                    color="#00518c"
                                                    ariaLabel="watch-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="loading-modal-header">
                                                    <p className="loading-modal-title text-congress-blue text-xl font-bold">Data Loading</p>
                                                </div>   
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    <Button text="Close" onClick={props.onClose}></Button>
                </div>
            </div>    
        </div>
    )
}

export default ModalEmailInfo
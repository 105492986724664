import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate, Navigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import Options from '../UI/Options'
import authHeader from '../../services/AuthHeader'
import ReactSelect from 'react-select'


const ListClientProjects = () => {

    const [projects, setProjects] = useState([])
    const [projectsFiltered, setProjectsFiltered] = useState([])

    const [projectsServices, setProjectsServices] = useState([])

    const [clients, setClients] = useState([])

    const [users, setUsers] = useState([])

    const [contacts, setContacts] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)
    const [clientsLoading, setClientsLoading] = useState(false)
    const [usersLoading, setUsersLoading] = useState(true)
    const [contactsLoading, setContactsLoading] = useState(true)
    const [projectServicesLoading, setProjectServicesLoading] = useState(true)
    const [redirecting, setRedirecting] = useState(false)

    const [selectedProjectName, setSelectedProjectName] = useState('All')
    const [selectedProjectNumber, setSelectedProjectNumber] = useState('All')
    const [selectedProjectManager, setSelectedProjectManager] = useState('All')
    const [selectedProjectStatus, setSelectedProjectStatus] = useState('All')
    const [selectedClientName, setSelectedClientName] = useState('All')
    const [selectedContactName, setSelectedContactName] = useState('All')

    const [projectNamesToSelect, setProjectNamesToSelect] = useState('All')
    const [projectNumbersToSelect, setProjectNumbersToSelect] = useState('All')
    const [projectManagersToSelect, setProjectManagersToSelect] = useState('All')
    const [projectStatusesToSelect, setProjectStatusesToSelect] = useState('All')
    const [clientNamesToSelect, setClientNamesToSelect] = useState('All')
    const [contactNamesToSelect, setContactNamesToSelect] = useState('All')

    const [firstRecord, setFirstRecord] = useState(0)
    const [lastRecord, setLastRecord] = useState(100)

    const API_URL = process.env.REACT_APP_API_URL

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
    }


    const prev100Records = () => {
        if (firstRecord > 99) {

            setFirstRecord(firstRecord - 100)
            setLastRecord(lastRecord - 100)

        }

    }

    const next100Records = () => {

        if (lastRecord <= projects.length) {

            setFirstRecord(firstRecord + 100)
            setLastRecord(lastRecord + 100)

        }

    }

    const clearFilters = () => {

        setSelectedProjectName('All')
        setSelectedProjectNumber('All')
        setSelectedProjectManager('All')
        setSelectedProjectStatus('All')
        setSelectedClientName('All')
        setSelectedContactName('All')

    }

    const navigate = useNavigate()

    const deleteProject = (id) => {
        if (window.confirm('Are you sure you want to delete Project?')) {

            axios.get(API_URL + '/projects_services_by_projectId/' + id)
                .then((res) => {
                    // setProjectsServices(res.data.filter((item) => item.toBeDeleted === "NO"))

                    // if (res.data.filter((item) => item.projectId === id).length > 0) {
                    //     window.alert("Please remove services from project in order to delete project")
                    // } else {
                    //     axios.delete(process.env.REACT_APP_API_URL + '/projects/' + id)
                    //     setProjects(projects.filter((project) => project.id !== id))
                    // }

                    if (res.data.length > 0) {
                        window.alert("Please remove services from project in order to delete project")
                    } else {
                        axios.delete(process.env.REACT_APP_API_URL + '/projects/' + id)
                        setProjects(projects.filter((project) => project.id !== id))
                    }

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setProjectServicesLoading(false);
                });

        }
    }

    const viewProject = (id) => {
        // navigate(`/viewClientProject/${id}`);
        window.open(`/viewClientProject/${id}`);
    }

    const aggregateProjectNameArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectName === val.projectName);
            if (index === -1) {
                acc.push({
                    projectName: val.projectName,
                });
            };
            return acc.sort((item1, item2) => item1.projectName.toLowerCase() < item2.projectName.toLowerCase() ? -1 : 0)
        }, []);
    };

    const aggregateProjectNumberArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectNumber === val.projectNumber);
            if (index === -1) {
                acc.push({
                    projectNumber: val.projectNumber,
                });
            };
            return acc.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)
        }, []);
    };

    const aggregateClientNameArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.clientId === val.clientId);
            if (index === -1) {
                acc.push({
                    clientName: clients.find(client => client.id === val.clientId).clientName,
                    clientId: val.clientId
                });
            };
            return acc.sort((item1, item2) => item1.clientName.toLowerCase() < item2.clientName.toLowerCase() ? -1 : 0)
        }, []);
    };

    const aggregateContactNameArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectClientContactId === val.projectClientContactId);
            if (index === -1) {
                acc.push({
                    contactName: contacts.find(contact => contact.id === val.projectClientContactId).contactName + " (" + clients.find(client => client.id === val.clientId).clientName + ")",
                    projectClientContactId: val.projectClientContactId
                });
            };
            return acc.sort((item1, item2) => item1.contactName.toLowerCase() < item2.contactName.toLowerCase() ? -1 : 0)
        }, []);
    };

    useEffect(() => {

        setLoading(true)
        setContactsLoading(true)
        setUsersLoading(true)
        setClientsLoading(true)

        var tempProjects

        axios.get(API_URL + '/projects')
        .then((res) => {
            tempProjects = res.data.sort((item1, item2) => item1.projectNumber > item2.projectNumber ? -1 : 0).filter((item) => item.toBeDeleted === "NO" && item.projectNumber !== 0)
            setProjects(res.data.sort((item1, item2) => item1.projectNumber > item2.projectNumber ? -1 : 0).filter((item) => item.toBeDeleted === "NO" && item.projectNumber !== 0))
            
            setProjectNumbersToSelect(
            aggregateProjectNumberArray(tempProjects).map((item) => {
                return {
                    label: item.projectNumber,
                    value: item.projectNumber
                }
            }))

            setProjectNamesToSelect(
                aggregateProjectNameArray(tempProjects).map((item) => {
                    return {
                        label: item.projectName,
                        value: item.projectName
                    }
            }))

            axios.get(API_URL + "/clients")
            .then((res2) => {
                setClients(res2.data);

                const clientList = tempProjects.reduce((acc, val) => {
                    const index = acc.findIndex(obj => obj.value === val.clientId);
                    if (index === -1) {
                        acc.push({
                            label: res2.data.find(client => client.id === val.clientId).clientName,
                            value: val.clientId
                        });
                    };
                    return acc.sort((item1, item2) => item1.label.toLowerCase() < item2.label.toLowerCase() ? -1 : 0)
                }, [])

                setClientNamesToSelect(clientList)

                axios.get(API_URL + '/contacts')
                .then((res3) => {
                    setContacts(res3.data)
    
                    const contactList = tempProjects.reduce((acc, val) => {
                        const index = acc.findIndex(obj => obj.value === val.projectClientContactId);
                        if (index === -1) {
                            acc.push({
                                label: res3.data.find(contact => contact.id === val.projectClientContactId).contactName + " (" + res2.data.find(client => client.id === val.clientId).clientName + ")",
                                value: val.projectClientContactId
                            });
                        };
                        return acc.sort((item1, item2) => item1.label.toLowerCase() < item2.label.toLowerCase() ? -1 : 0)
                    }, [])
    
                    setContactNamesToSelect(contactList)
    
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setContactsLoading(false);
                });


            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(_content);
                    setRedirecting(true)
                },
            ).finally(() => {
                setClientsLoading(false);
            });
        
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });

        
        axios.get(API_URL + '/users')
            .then((res) => {
                setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
                setProjectManagersToSelect(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0).map((item) => {
                    return {
                        label: item.firstName + ' ' + item.lastName,
                        value: item.id
                    }
                }))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

    }, [])

    useEffect(() => {

        setProjectsFiltered(projects)

        if (selectedProjectName !== "All") {
            console.log("gone into filter code")
            setProjectsFiltered(projectsFiltered => projectsFiltered.filter((item) => item.projectName === selectedProjectName.value))
        }

        if (selectedProjectNumber !== "All") {
            setProjectsFiltered(projectsFiltered => projectsFiltered.filter((item) => item.projectNumber === selectedProjectNumber.value))
        }

        if (selectedProjectManager !== "All") {
            setProjectsFiltered(projectsFiltered => projectsFiltered.filter((item) => item.projectManagerId === selectedProjectManager.value))
        }

        if (selectedProjectStatus !== "All") {
            setProjectsFiltered(projectsFiltered => projectsFiltered.filter((item) => item.projectStatus === selectedProjectStatus.value))
        }

        if (selectedClientName !== "All") {
            setProjectsFiltered(projectsFiltered => projectsFiltered.filter((item) => item.clientId === selectedClientName.value))
        }

        if (selectedContactName !== "All") {
            setProjectsFiltered(projectsFiltered => projectsFiltered.filter((item) => item.projectClientContactId === selectedContactName.value))
        }

        setFirstRecord(0)
        setLastRecord(100)

    }, [selectedProjectName, selectedProjectNumber, selectedProjectManager, selectedProjectStatus, selectedClientName, selectedContactName, projects])


    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (projects.length > 0 && clients.length > 0 && users.length > 0 && contacts.length > 0 && !loading && !clientsLoading && !usersLoading && !contactsLoading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                            <Header title="Projects" section="Select Project" showUserMenu='true' />

                            <hr></hr>
                            <br></br>


                            <div className="flex items-center">
                                <div className="w-full mx-auto px-3">
                                    <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7">

                                        <div>
                                            <p className="text-xs">Project Number</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedProjectNumber}
                                                onChange={setSelectedProjectNumber}
                                                options={projectNumbersToSelect} />
                                        </div>
                                        <div>
                                            <p className="text-xs">Project Name</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedProjectName}
                                                onChange={setSelectedProjectName}
                                                options={projectNamesToSelect} />
                                        </div>
                                        <div>
                                            <p className="text-xs">Project Status</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedProjectStatus}
                                                onChange={setSelectedProjectStatus}
                                                options={[
                                                    { value: 'Live', label: 'Live' },
                                                    { value: 'Quote', label: 'Quote' },
                                                    { value: 'On-hold', label: 'On-hold' },
                                                    { value: 'Closed-completed', label: 'Closed-completed' },
                                                    { value: 'Closed-cancelled', label: 'Closed-cancelled' },
                                                    { value: 'Closed-lost', label: 'Closed-lost' }
                                                ]} />
                                        </div>
                                        <div>
                                            <p className="text-xs">Project Client</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedClientName}
                                                onChange={setSelectedClientName}
                                                options={clientNamesToSelect} />
                                        </div>
                                        <div>
                                            <p className="text-xs">Project Client Contact</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedContactName}
                                                onChange={setSelectedContactName}
                                                options={contactNamesToSelect} />
                                        </div>
                                        <div>
                                            <p className="text-xs">Project Manager</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedProjectManager}
                                                onChange={setSelectedProjectManager}
                                                options={projectManagersToSelect} />
                                        </div>
                                        <div>
                                            <p className="text-xs font-bold">Filters</p>
                                            <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <br></br>

                            <hr></hr>

                            <br></br>
                            <Button text="Add New Project" onClick={() => navigate('/addOrUpdateClientProject/_add')} />
                            <br></br>

                            <div className="flex flex-col">
                                <div className="overflow-x-auto">
                                    <div className="text-eight-text align-middle inline-block min-w-full">
                                        <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl">
                                            <table className="objects-8A min-w-full divide-y divide-gray-200">

                                                <thead className="bg-pacific-blue-50">
                                                    <tr>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Number</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Name</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Status</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Client</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Client Contact</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Manager</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                                    </tr>
                                                </thead>

                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {projectsFiltered.slice(firstRecord, lastRecord).map((project) => (
                                                        <tr key={project.id}>
                                                            <td onClick={() => viewProject(project.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {project.projectNumber}</td>
                                                            <td onClick={() => viewProject(project.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {project.projectName}</td>
                                                            <td onClick={() => viewProject(project.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {project.projectStatus}</td>
                                                            <td onClick={() => viewProject(project.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {clients.find(client => client.id === project.clientId).clientName}</td>
                                                            <td onClick={() => viewProject(project.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {contacts.find(contact => contact.id === project.projectClientContactId).contactName} </td>
                                                            <td onClick={() => viewProject(project.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {users.find(user => user.id === project.projectManagerId).firstName + ' ' + users.find(user => user.id === project.projectManagerId).lastName}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right">
                                                                <Options edit={`/addOrUpdateClientProject/${project.id}`} deleteFunction={deleteProject} deleteObject={project.id}></Options>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <Button color="bg-bondi-blue" text="Add New Project" onClick={() => navigate('/addOrUpdateClientProject/_add')} />
                        </div>
                        <div className="grid gap-7 sm:grid-cols-3 pt-5">
                            <Button text="<< Previous 100" onClick={() => prev100Records()}></Button>
                            <div className="text-sm text-gray-500 text-center pt-2 ">{firstRecord + " to " + lastRecord + " of " + projectsFiltered.length}</div>
                            <Button text="Next 100 >>" onClick={() => next100Records()}></Button>
                        </div>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Projects" section="Select Project" showUserMenu='true' />
                        <hr></hr>
                        <br></br>
                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7">

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >
                                    </div>

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >
                                    </div>

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >
                                    </div>

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >
                                    </div>

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >
                                    </div>

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >
                                    </div>

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >
                                    </div>

                                </div>
                            </div>
                        </div>

                        <br></br>
                        <hr></hr>

                        <div className="h-8 bg-slate-300 rounded-2xl w-full mx-3 mb-5 animate-pulse" >
                        </div>
                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                    <div role="status" className=" bg-white w-full p-4 space-y-4 border border-slate-200 divide-y divide-slate-200 rounded-2xl shadow-slate-300 shadow-md animate-pulse ">

                                        <div className="flex items-center justify-between">
                                            <div>
                                                <div className="h-5 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-5 bg-slate-300 rounded-full w-48"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )

    }

}

export default ListClientProjects

import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Options from '../UI/Options'
import ReactSelect from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import { useNavigate, useMatch } from 'react-router-dom'
import { Doughnut, Bar, Line } from 'react-chartjs-2'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import ReactTooltip from "react-tooltip"
import { v4 as uuidv4 } from 'uuid'
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);


const ListOrganisationFootprintItems = (props) => {

  const match = useMatch('/viewOrganisationFootprint/:organisationId/:footprintId')

  const internationalNumberFormat = new Intl.NumberFormat('en-US')

  const [footprintsAdded, setFootprintsAdded] = useState(false)

  const [loading, setLoading] = useState(false)
  const [organisationFootprint, setOrganisationFootprint] = useState({})
  const [scopeItems, setScopeItems] = useState([])
  const [scopeItemsFiltered, setItemsFiltered] = useState([])
  const [scopeItemsFilteredWithGSD, setItemsFilteredWithGSD] = useState([])
  const [scopeItemsWithGSD, setItemsWithGSD] = useState([])

  const [selectedItemCategory, setSelectedItemCategory] = useState([{label: "", value: ""}])
  const [selectedItemSubCategory, setSelectedItemSubCategory] = useState([{label: "", value: ""}])
  const [selectedDescription, setSelectedDescription] = useState([{label: "", value: ""}])
  const [selectedVerified, setSelectedVerified] = useState("All")
  const [selectedSubmitted, setSelectedSubmitted] = useState("All")

  const [selectedScenario, setSelectedScenario] = useState("")
  const [selectedGrowthRate, setSelectedGrowthRate] = useState("")
  const [selectedMeasure, setSelectedMeasure] = useState("")
  const [selectedForecast, setSelectedForecast] = useState({label: "Forecast 1", value: "Forecast"})

  const [scenarioDataset, setScenarioDataset] = useState([])

  const [showToolTip, setShowToolTip] = useState(false)

  const [carbonEmissionsPerYearActuals, setCarbonEmissionsPerYearActuals] = useState([]);
  const [carbonEmissionsPerYearProjections, setCarbonEmissionsPerYearProjections] = useState([]);
  const [carbonEmissionsPerYearProjections2, setCarbonEmissionsPerYearProjections2] = useState([]);
  const [carbonEmissionsPerYearProjections3, setCarbonEmissionsPerYearProjections3] = useState([]);
  const [carbonEmissionsPerYearTargets, setCarbonEmissionsPerYearTargets] = useState([]);

  const [carbonEmissionsPerYearProjectionsLabel, setCarbonEmissionsPerYearProjectionsLabel] = useState("Forecast 1 Emissions");
  const [carbonEmissionsPerYearProjectionsLabel2, setCarbonEmissionsPerYearProjectionsLabel2] = useState("Forecast 2 Emissions");
  const [carbonEmissionsPerYearProjectionsLabel3, setCarbonEmissionsPerYearProjectionsLabel3] = useState("Forecast 3 Emissions");

  const [carbonEmissionsPerYearProjectionsLabelScope3, setCarbonEmissionsPerYearProjectionsLabelScope3] = useState("Forecast 1 Emissions per 1000 Base Currency Units of Turnover");
  const [carbonEmissionsPerYearProjectionsLabelScope32, setCarbonEmissionsPerYearProjectionsLabelScope32] = useState("Forecast 2 Emissions per 1000 Base Currency Units of Turnover");
  const [carbonEmissionsPerYearProjectionsLabelScope33, setCarbonEmissionsPerYearProjectionsLabelScope33] = useState("Forecast 3 Emissions per 1000 Base Currency Units of Turnover");


  const [carbonEmissionsPerYearActualsScope3, setCarbonEmissionsPerYearActualsScope3] = useState([]);
  const [carbonEmissionsPerYearProjectionsScope3, setCarbonEmissionsPerYearProjectionsScope3] = useState([]);
  const [carbonEmissionsPerYearProjectionsScope32, setCarbonEmissionsPerYearProjectionsScope32] = useState([]);
  const [carbonEmissionsPerYearProjectionsScope33, setCarbonEmissionsPerYearProjectionsScope33] = useState([]);
  const [carbonEmissionsPerYearTargetsScope3, setCarbonEmissionsPerYearTargetsScope3] = useState([]);

  const [targetForThisYear, setTargetForThisYear] = useState(0)
  const [targetForThisYearScope3, setTargetForThisYearScope3] = useState(0)
  const [targetGBPTurnover, setTargetGBPTurnover] = useState(0)

  const [years, setYears] = useState([])

  const [animationForecast, setanimationForecast] = useState(false)

  const aggregateCategoriesArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.itemCategory === val.itemCategory);
      if (index === -1) {
        acc.push({
          itemCategory: val.itemCategory,
        });
      };
      return acc.sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : 0);
    }, []);
  };

  const aggregateSubCategoriesArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.itemSubCategory === val.itemSubCategory);
      if (index === -1) {
        acc.push({
          itemSubCategory: val.itemSubCategory,
        });
      };
      return acc.sort((item1, item2) => item1.itemSubCategory < item2.itemSubCategory ? -1 : item1.itemSubCategory > item2.itemSubCategory ? 1 : 0);
    }, []);
  };

  const aggregateDescriptionsArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.itemDescription === val.itemDescription);
      if (index === -1) {
        acc.push({
            itemDescription: val.itemDescription,
        });
      };
      return acc.sort((item1, item2) => item1.itemDescription < item2.itemDescription ? -1 : item1.itemDescription > item2.itemDescription ? 1 : 0);
    }, []);
  };


  const aggregateVerifiedArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.verified === val.verified);
      if (index === -1) {
        acc.push({
            verified: val.verified,
        });
      };
      return acc.sort((item1, item2) => item1.verified < item2.verified ? -1 : item1.verified > item2.verified ? 1 : 0);
    }, []);
  };

  const aggregateSubmittedArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.submitted === val.submitted);
      if (index === -1) {
        acc.push({
            submitted: val.submitted,
        });
      };
      return acc.sort((item1, item2) => item1.submitted < item2.submitted ? -1 : item1.submitted > item2.submitted ? 1 : 0);
    }, []);
  };

  const aggregateScenariosArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.scenario === val.scenario);
      if (index === -1) {
        acc.push({
            scenario: val.scenario,
        });
      };
      return acc.sort((item1, item2) => item1.scenario < item2.scenario ? -1 : item1.scenario > item2.scenario ? 1 : 0);
    }, []);
  };

  const aggregateGrowthRatesArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.growth === val.growth);
      if (index === -1) {
        acc.push({
            growth: val.growth,
        });
      };
      return acc.sort((item1, item2) => Number(item1.growth) < Number(item2.growth) ? -1 : Number(item1.growth) > Number(item2.growth) ? 1 : 0);
    }, []);
  };

  const aggregateMeasuresArray = arr => {
    return arr.reduce((acc, val) => {
      const index = acc.findIndex(obj => obj.measure === val.measure);
      if (index === -1) {
        acc.push({
            measure: val.measure,
        });
      };
      return acc.sort((item1, item2) => item1.measure < item2.measure ? -1 : item1.measure > item2.measure ? 1 : 0);
    }, []);
  };


  const styles = {
    option: provided => ({
      ...provided,
      color: 'black'
    }),
    control: provided => ({
      ...provided,
      color: 'black',
      width: 160,
      fontSize: 13
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black'
    }),
    menu: provided => ({
      ...provided,
      color: 'black',
      width: 160,
      fontSize: 13
    }),
  }

  const stylesThin = {
    option: provided => ({
      ...provided,
      color: 'black'
    }),
    control: provided => ({
      ...provided,
      color: 'black',
      width: 70,
      fontSize: 13
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black'
    }),
    menu: provided => ({
      ...provided,
      color: 'black',
      width: 70,
      fontSize: 13
    }),
  }

  const clearFilters = () => {

    // setSelectedItemCategory('All')
    setSelectedItemCategory(aggregateCategoriesArray(scopeItems).map((item) => {
        return {
          label: item.itemCategory,
          value: item.itemCategory
        }
      }))
    // setSelectedItemSubCategory('All')
    setSelectedItemSubCategory(aggregateSubCategoriesArray(scopeItems).map((item) => {
        return {
          label: item.itemSubCategory,
          value: item.itemSubCategory
        }
      }))

    setSelectedDescription(aggregateDescriptionsArray(scopeItems).map((item) => {
    return {
        label: item.itemDescription,
        value: item.itemDescription
    }
    }))

    setSelectedVerified('All')
    setSelectedSubmitted('All')

  }

  const chartColours = [
    "#0090CA",
    "#00A1AA",
    "#00518C",
    "#B9D3DC",
    "#05cfc0",
    "#0a4f4e",
    "#158a2c",
    "#78de4c",
    "#738a69",
    "#c2df7d",
    "#E89B26",
    "#FCE3BD",
    "#592c19",
    "#ff7276",
    "#FF7C40",
    "#FCF062",
    "#7BA7BC",
    "#5459C1",
    "#753fc2",
    "#827db8",
    "#90286b",
    "#ef66f0",
  ];
  const chartHoverColours = [
    "#10475C",
    "#007980",
    "#0E2343",
    "#96abb3",
    "#036860",
    "#0B4516",
    "#052827",
    "#3C6F26",
    "#3A4535",
    "#61703F",
    "#a16b1a",
    "#CEB691",
    "#2E211C",
    "#802741",
    "#732912",
    "#7A6009",
    "#4d6875",
    "#303372",
    "#3B2061",
    "#413F5C",
    "#481436",
    "#783378",
  ];

  const navigate = useNavigate()

  const deleteFootprints = async (footprintType) => {
    await axios.delete(process.env.REACT_APP_API_URL + '/organisations_footprints/' + organisationFootprint.organisationId + '/' + footprintType)
  }

  const addForm = async (form) => {
    await axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints', form)
  }

//   const uncertaintyRatingPerItem = (dataQualityTechnologyClassification, dataQualityTimeClassification, dataQualityGeographyClassification, dataQualityCompletenessClassification, dataQualityReliabilityClassification, emissionsFactorTechnologyClassification, emissionsFactorTimeClassification, emissionsFactorGeographyClassification, emissionsFactorCompletenessClassification, emissionsFactorReliabilityClassification) => {

//     var dataQualityTechnologyRating, dataQualityTimeRating, dataQualityGeographyRating, dataQualityCompletenessRating, dataQualityReliabilityRating, emissionsFactorTechnologyRating, emissionsFactorTimeRating, emissionsFactorGeographyRating, emissionsFactorCompletenessRating, emissionsFactorReliabilityRating
//     var overallRating

//     if (dataQualityTechnologyClassification === "Very Good") { dataQualityTechnologyRating = 1 }
//     if (dataQualityTechnologyClassification === "Good") { dataQualityTechnologyRating = 2 }
//     if (dataQualityTechnologyClassification === "Fair") { dataQualityTechnologyRating = 3 }
//     if (dataQualityTechnologyClassification === "Poor") { dataQualityTechnologyRating = 4 }

//     if (dataQualityTimeClassification === "Very Good") { dataQualityTimeRating = 1 }
//     if (dataQualityTimeClassification === "Good") { dataQualityTimeRating = 2 }
//     if (dataQualityTimeClassification === "Fair") { dataQualityTimeRating = 3 }
//     if (dataQualityTimeClassification === "Poor") { dataQualityTimeRating = 4 }

//     if (dataQualityGeographyClassification === "Very Good") { dataQualityGeographyRating = 1 }
//     if (dataQualityGeographyClassification === "Good") { dataQualityGeographyRating = 2 }
//     if (dataQualityGeographyClassification === "Fair") { dataQualityGeographyRating = 3 }
//     if (dataQualityGeographyClassification === "Poor") { dataQualityGeographyRating = 4 }

//     if (dataQualityCompletenessClassification === "Very Good") { dataQualityCompletenessRating = 1 }
//     if (dataQualityCompletenessClassification === "Good") { dataQualityCompletenessRating = 2 }
//     if (dataQualityCompletenessClassification === "Fair") { dataQualityCompletenessRating = 3 }
//     if (dataQualityCompletenessClassification === "Poor") { dataQualityCompletenessRating = 4 }

//     if (dataQualityReliabilityClassification === "Very Good") { dataQualityReliabilityRating = 1 }
//     if (dataQualityReliabilityClassification === "Good") { dataQualityReliabilityRating = 2 }
//     if (dataQualityReliabilityClassification === "Fair") { dataQualityReliabilityRating = 3 }
//     if (dataQualityReliabilityClassification === "Poor") { dataQualityReliabilityRating = 4 }

//     if (emissionsFactorTechnologyClassification === "Very Good") { emissionsFactorTechnologyRating = 1 }
//     if (emissionsFactorTechnologyClassification === "Good") { emissionsFactorTechnologyRating = 2 }
//     if (emissionsFactorTechnologyClassification === "Fair") { emissionsFactorTechnologyRating = 3 }
//     if (emissionsFactorTechnologyClassification === "Poor") { emissionsFactorTechnologyRating = 4 }

//     if (emissionsFactorTimeClassification === "Very Good") { emissionsFactorTimeRating = 1 }
//     if (emissionsFactorTimeClassification === "Good") { emissionsFactorTimeRating = 2 }
//     if (emissionsFactorTimeClassification === "Fair") { emissionsFactorTimeRating = 3 }
//     if (emissionsFactorTimeClassification === "Poor") { emissionsFactorTimeRating = 4 }

//     if (emissionsFactorGeographyClassification === "Very Good") { emissionsFactorGeographyRating = 1 }
//     if (emissionsFactorGeographyClassification === "Good") { emissionsFactorGeographyRating = 2 }
//     if (emissionsFactorGeographyClassification === "Fair") { emissionsFactorGeographyRating = 3 }
//     if (emissionsFactorGeographyClassification === "Poor") { emissionsFactorGeographyRating = 4 }

//     if (emissionsFactorCompletenessClassification === "Very Good") { emissionsFactorCompletenessRating = 1 }
//     if (emissionsFactorCompletenessClassification === "Good") { emissionsFactorCompletenessRating = 2 }
//     if (emissionsFactorCompletenessClassification === "Fair") { emissionsFactorCompletenessRating = 3 }
//     if (emissionsFactorCompletenessClassification === "Poor") { emissionsFactorCompletenessRating = 4 }

//     if (emissionsFactorReliabilityClassification === "Very Good") { emissionsFactorReliabilityRating = 1 }
//     if (emissionsFactorReliabilityClassification === "Good") { emissionsFactorReliabilityRating = 2 }
//     if (emissionsFactorReliabilityClassification === "Fair") { emissionsFactorReliabilityRating = 3 }
//     if (emissionsFactorReliabilityClassification === "Poor") { emissionsFactorReliabilityRating = 4 }

//     overallRating = ((dataQualityTechnologyRating + dataQualityTimeRating + dataQualityGeographyRating + dataQualityCompletenessRating + dataQualityReliabilityRating) / 5) * ((emissionsFactorTechnologyRating + emissionsFactorTimeRating + emissionsFactorGeographyRating + emissionsFactorCompletenessRating + emissionsFactorReliabilityRating) / 5)

//     if (overallRating <= 1) { return "Very Good" }
//     if (overallRating <= 2) { return "Good" }
//     if (overallRating <= 4) { return "Fair" }
//     if (overallRating > 4) { return "Poor" }

//   }

  const impactCategory = (carbonEmissions) => {

    if ((carbonEmissions / scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100 >= 25) {
      return "High"
    }

    if ((carbonEmissions / scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100 >= (100 / scopeItemsFiltered.length)) {
      return "Medium"
    }

    if ((carbonEmissions / scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100 >= 1) {
      return "Low"
    }

    return "Negligible"

  }

  const priorityCategory = (controlCategory, impactCategory) => {

    if (controlCategory === "Full Control") {

      if (impactCategory === "High") {
        return "High"
      }

      if (impactCategory === "Medium") {
        return "High"
      }

      if (impactCategory === "Low") {
        return "High"
      }

      if (impactCategory === "Negligible") {
        return "Medium"
      }
    }

    if (controlCategory === "Partial Control") {

      if (impactCategory === "High") {
        return "High"
      }

      if (impactCategory === "Medium") {
        return "High"
      }

      if (impactCategory === "Low") {
        return "Medium"
      }

      if (impactCategory === "Negligible") {
        return "Low"
      }
    }

    if (controlCategory === "No Control") {

      if (impactCategory === "High") {
        return "Medium"
      }

      if (impactCategory === "Medium") {
        return "Medium"
      }

      if (impactCategory === "Low") {
        return "Low"
      }

      if (impactCategory === "Negligible") {
        return "-"
      }
    }

    if (controlCategory === "N/A") {

      return "-"
    }

  }

//   const priorityCategoryDescription = (priorityCategory) => {

//     if (priorityCategory === "High") {
//       return "Reduction is required in the next year. Advised reduction target is around 5% per annum."
//     }

//     if (priorityCategory === "Medium") {
//         return "Reduction should be prioritised and detailed plan should be developed on how to reduce emissions in the short term"
//       }
  
//       if (priorityCategory === "Low") {
//         return "A long term reduction plan should be established to reduce emissions"
//       }

//     if (priorityCategory === "-") {
//       return "No action is required"
//     }

//   }

  const GSD = (technologyFactor, timeFactor, geographyFactor, completenessFactor, reliabilityFactor, basicUncertaintyFactor) => {

    return Math.sqrt(Math.exp(Math.sqrt((Math.log(technologyFactor) ** 2) + (Math.log(timeFactor) ** 2) + (Math.log(geographyFactor) ** 2) + (Math.log(completenessFactor) ** 2) + (Math.log(reliabilityFactor) ** 2) + (Math.log(basicUncertaintyFactor) ** 2))))

  }

  const contribution = (GSD, carbonEmissionImpactPercentage) => {

    return ((carbonEmissionImpactPercentage / 100) ** 2) * (Math.log(GSD) ** 2)

  }

  const qualitativeUncertainty = (GSD) => {

    var qualUncertainty = ""

    if (GSD > 1.25) {

        qualUncertainty = "Poor"

    }

    if (GSD <= 1.25) {

        qualUncertainty = "Fair"

    }

    if (GSD <= 1.15) {

        qualUncertainty = "Good"

    }

    if (GSD <= 1.05) {

        qualUncertainty = "Very Good"

    }

    return qualUncertainty


  }

//   const overallReductionPercentage = (priorityCategory) => {

//     if (priorityCategory === "High") {
//       return organisationFootprint.organisationFootprintOverallReductionTargets.High / 100
//     }

//     if (priorityCategory === "Medium") {
//       return organisationFootprint.organisationFootprintOverallReductionTargets.Medium / 100
//     }

//     if (priorityCategory === "Low") {
//       return organisationFootprint.organisationFootprintOverallReductionTargets.Low / 100
//     }

//     if (priorityCategory === "-") {
//       return 0
//     }
//   }

  const differenceInYears = (toYear, fromYear) => {

    if (toYear <= fromYear) {

      return 1

    } else {

      return toYear - fromYear

    }

  }

  const targetReductionPercentage = (priorityCategory, percentageOverride) => {

    if (priorityCategory === "High") {
      if (percentageOverride > 0) {
        return (((percentageOverride / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + 1)) * 100).toFixed(2)
      } else {
        return (((organisationFootprint.organisationFootprintOverallReductionTargets.High / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + 1)) * 100).toFixed(2)
      }
    }

    if (priorityCategory === "Medium") {
      if (percentageOverride > 0) {
        return (((percentageOverride / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 6)))) * 100).toFixed(2)
      } else {
        return (((organisationFootprint.organisationFootprintOverallReductionTargets.Medium / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 6)))) * 100).toFixed(2)
      }
    }

    if (priorityCategory === "Low") {
      if (percentageOverride > 0) {
        return (((percentageOverride / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 2)))) * 100).toFixed(2)
      } else {
        return (((organisationFootprint.organisationFootprintOverallReductionTargets.Low / 100) / differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear) + 1, Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 2)))) * 100).toFixed(2)
      }
    }

    if (priorityCategory === "-") {
      return "0"
    }
  }

  const targetReductionYear = (priorityCategory) => {


    if (priorityCategory === "High") {
      if (Number(organisationFootprint.organisationFootprintYear) + 1 < Number(organisationFootprint.organisationFootprintTargetYear)) {
        return Number(organisationFootprint.organisationFootprintYear) + 1
      } else {
        return Number(organisationFootprint.organisationFootprintTargetYear)
      }
    }

    if (priorityCategory === "Medium") {
      if (Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 6)) < Number(organisationFootprint.organisationFootprintTargetYear)) {
        return Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 6))
      } else {
        return Number(organisationFootprint.organisationFootprintTargetYear)
      }
    }

    if (priorityCategory === "Low") {
      if (Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 2)) < Number(organisationFootprint.organisationFootprintTargetYear)) {
        return Number(organisationFootprint.organisationFootprintYear) + Math.round((differenceInYears(Number(organisationFootprint.organisationFootprintTargetYear), Number(organisationFootprint.organisationFootprintYear)) / 2))
      } else {
        return Number(organisationFootprint.organisationFootprintTargetYear)
      }
    }

    if (priorityCategory === "-") {
      return Number(organisationFootprint.organisationFootprintTargetYear)
    }
  }

  const applyScenario = async () => {

    if (selectedGrowthRate !== "" && selectedScenario !== "" && selectedMeasure !== "") {

        var year
        var subCategoryItem

        var filteredDataset = scenarioDataset.filter(item => item.organisation === match.params.organisationId && item.scenario === selectedScenario.value && item.growth === selectedGrowthRate.value && item.measure === selectedMeasure.value)

        var finalTargetYear = filteredDataset.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0)[filteredDataset.length-1].year
        var newFootprint
        var baseFootprintYear = Number(organisationFootprint.organisationFootprintYear)
        var arrayItemsForThatYear = []
        var newTurnover = organisationFootprint.organisationTurnoverGBP

        await deleteFootprints(selectedForecast.value)
        setanimationForecast(true)
        for (year = baseFootprintYear; year <= finalTargetYear; year++) {

            if (year > baseFootprintYear) {
                newTurnover = newTurnover + (newTurnover * (Number(selectedGrowthRate.value)/100))
            }

            newFootprint = { ...organisationFootprint, organisationFootprintRef: year + " " + selectedForecast.label + ": " + selectedScenario.value + " / " + selectedGrowthRate.value + "% growth / " + selectedMeasure.value, organisationFootprintYear: year.toString(), organisationFootprintType: selectedForecast.value, organisationFootprintItems: [], organisationTurnoverGBP: newTurnover }

            arrayItemsForThatYear = filteredDataset.filter(item => Number(item.year) === year)

            for (subCategoryItem = 0; subCategoryItem < arrayItemsForThatYear.length; subCategoryItem++) {

                newFootprint.organisationFootprintItems.push({ ...organisationFootprint.organisationFootprintItems[0], itemCategory: arrayItemsForThatYear[subCategoryItem].subCategory.slice(0,1), itemSubCategory: arrayItemsForThatYear[subCategoryItem].subCategory, carbonEmissions: Number(arrayItemsForThatYear[subCategoryItem].emissions), footprintSubItems: [], id: uuidv4() })
            }

        await addForm({ ...newFootprint })

        }

        setFootprintsAdded(true)
        setanimationForecast(false)
    }

    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  }


  const dataChart7c = {
    labels: years,
    datasets: [
        {
            label: 'Target Emissions - Maximum acceptable carbon emissions in line with NCS protocol in order to achieve Net Zero Carbon as defined by SBTi',
            type: 'line',
            borderColor: chartColours[2],
            data: carbonEmissionsPerYearTargets,
            spanGaps: true,
            fill: 'origin'
        },
        {
            label: carbonEmissionsPerYearProjectionsLabel,
            type: 'line',
            borderColor: chartColours[1],
            data: carbonEmissionsPerYearProjections,
            spanGaps: true
        },
        {
            label: carbonEmissionsPerYearProjectionsLabel2,
            type: 'line',
            borderColor: chartColours[10],
            data: carbonEmissionsPerYearProjections2,
            spanGaps: true
        },
        {
            label: carbonEmissionsPerYearProjectionsLabel3,
            type: 'line',
            borderColor: chartColours[6],
            data: carbonEmissionsPerYearProjections3,
            spanGaps: true
        },
        {
            label: 'Actual Emissions - Verified carbon emissions per the reporting year in tCO2e',
            type: 'bar',
            fill: true,
            backgroundColor: chartColours[0],
            data: carbonEmissionsPerYearActuals
        },

    ]
  };

  const dataChart7d = {
    labels: years,
    datasets: [
        {
            label: 'Target Emissions per 1000 Base Currency Units of Turnover - Maximum acceptable carbon emissions in line with NCS protocol in order to achieve Net Zero Carbon as defined by SBTi',
            type: 'line',
            borderColor: chartColours[2],
            data: carbonEmissionsPerYearTargetsScope3,
            spanGaps: true,
            fill: 'origin'
        },
        {
            label: carbonEmissionsPerYearProjectionsLabelScope3,
            type: 'line',
            borderColor: chartColours[1],
            data: carbonEmissionsPerYearProjectionsScope3,
            spanGaps: true
        },
        {
            label: carbonEmissionsPerYearProjectionsLabelScope32,
            type: 'line',
            borderColor: chartColours[10],
            data: carbonEmissionsPerYearProjectionsScope32,
            spanGaps: true
        },
        {
            label: carbonEmissionsPerYearProjectionsLabelScope33,
            type: 'line',
            borderColor: chartColours[6],
            data: carbonEmissionsPerYearProjectionsScope33,
            spanGaps: true
        },
        {
            label: 'Actual Emissions per 1000 Base Currency Units of Turnover - Verified carbon emissions per the reporting year in tCO2e',
            type: 'bar',
            fill: true,
            backgroundColor: chartColours[0],
            data: carbonEmissionsPerYearActualsScope3
        },

    ]
  };

  const barAndLineOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Scope 1 and 2 Forecast vs Target vs Actual tCO2e',
        font: {
          size: 20
        }
      },
      legend: {
        display: true,
        maxWidth: 100,
        position: "bottom",
        labels: {
            font: {
                size: 15,
                family: 'TT Norms Pro'
            },
        },
        },
        datalabels: {
            display: false,
        }
    },
    responsive: true,
  }

  const barAndLineOptionsScope3 = {
    plugins: {
      title: {
        display: true,
        text: 'Scope 3 Forecast vs Target vs Actual tCO2e per 1000 Base Currency Units of Turnover',
        font: {
          size: 20
        }
      },
      legend: {
        display: true,
        maxWidth: 100,
        position: "bottom",
        labels: {
            font: {
                size: 15,
                family: 'TT Norms Pro'
            },
        },
        },
        datalabels: {
            display: false,
        }
    },
    responsive: true,
  }

  //Chart 2 (All s All Items)
  const itemDescriptionLabels = scopeItemsFiltered.map(item => item.itemDescription)
  const itemDescriptionData = scopeItemsFiltered.map(item => item.carbonEmissions / 1000)

  const dataChart2 = {
    labels: itemDescriptionLabels,
    datasets: [
      {
        data: itemDescriptionData,
        backgroundColor: chartColours,
        hoverBackgroundColor: chartHoverColours,
      }
    ]
  };


  //Chart 3 (All s by Sub Category)
  var result = [];
  scopeItemsFiltered.reduce(function (res, value) {
    if (!res[value.itemSubCategory]) {
      res[value.itemSubCategory] = { itemSubCategory: value.itemSubCategory, carbonEmissions: 0 };
      result.push(res[value.itemSubCategory])
    }
    res[value.itemSubCategory].carbonEmissions += value.carbonEmissions / 1000;
    return res;
  }, {});

  const scopeSubCategoryData = result.map(item => item.carbonEmissions)
  const scopeSubCategoryLabels = result.map(item => item.itemSubCategory)

  const dataChart3 = {
    labels: scopeSubCategoryLabels,
    datasets: [
      {
        data: scopeSubCategoryData,
        backgroundColor: chartColours,
        hoverBackgroundColor: chartHoverColours,
      }
    ]
  };

  result = [];
  scopeItemsFiltered.reduce(function (res, value) {
    if (!res[value.itemCategory]) {
      res[value.itemCategory] = { itemCategory: value.itemCategory, carbonEmissions: 0 };
      result.push(res[value.itemCategory])
    }
    res[value.itemCategory].carbonEmissions += value.carbonEmissions / 1000;
    return res;
  }, {});

  const scope3Data = result.map(item => item.carbonEmissions)
  const scope3Labels = result.map(item => item.itemCategory)

  const dataChart4 = {
    labels: scope3Labels,
    datasets: [
      {
        data: scope3Data,
        backgroundColor: chartColours,
        hoverBackgroundColor: chartHoverColours,
      }
    ]
  };

  const dataChart5 = {
    labels: [organisationFootprint.organisationFootprintYear],
    datasets: [
      {
        label: "Target",
        data: [targetForThisYear],
        borderColor: "rgba(0,0,0,1)",

        //important settings

        //set the width of the line ( or point )
        pointRadius: 100,
        // don´t show line betrween points
        showLine: false,
        //change points of line chart to line style ( little bit confusin why it´s named point anyway )
        pointStyle: 'line',

        rotation: 90,

        borderWidth: 5,

        //chart type
        type: "line",
      },
      {
        label: "Actual",
        data: [scopeItemsFiltered.filter(item => item.itemSubCategory.slice(0,2) === "1." || item.itemSubCategory.slice(0,2) === "2.").map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000],
        backgroundColor: chartColours[0],
        hoverBackgroundColor: chartHoverColours[0],
      },
      {
        label: "Estimated",
        data: [scopeItemsFiltered.filter(item => item.itemSubCategory.slice(0,2) === "1." || item.itemSubCategory.slice(0,2) === "2.").map(item => item.additionalEstimatedEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000],
        backgroundColor: chartColours[1],
        hoverBackgroundColor: chartHoverColours[1],
      }
    ]
  };

  const dataChart5AllScopes = {
    labels: [organisationFootprint.organisationFootprintYear],
    datasets: [
      {
        label: "Lower Bound",
        data: [(scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000) - ((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000) - ((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000) / ((Math.exp(((scopeItemsFilteredWithGSD.map(item => item.GSDValue).reduce((prev, curr) => Number(prev) + Number(curr), 0))) ** 0.5)) ** 2)))],
        borderColor: "rgba(60, 60, 60)",

        pointRadius: 100,

        pointStyle: 'line',

        rotation: 90,

        borderWidth: 5,

        //chart type
        type: "line",
      },
      {
        label: "Upper Bound",
        data: [((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * ((Math.exp(((scopeItemsFilteredWithGSD.map(item => item.GSDValue).reduce((prev, curr) => Number(prev) + Number(curr), 0))) ** 0.5)) ** 2)) / 1000],
        borderColor: "rgba(60, 60, 60)",

        pointRadius: 100,

        pointStyle: 'line',

        rotation: 90,

        borderWidth: 5,

        //chart type
        type: "line",
      },
      {
        label: "Actual",
        data: [(scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)],
        backgroundColor: chartColours[0],
        hoverBackgroundColor: chartHoverColours[0],
      }
    ]
  };

  const dataChart5Scope3 = {
    labels: [organisationFootprint.organisationFootprintYear],
    datasets: [
      {
        label: "Target",
        data: [targetForThisYearScope3 / (targetGBPTurnover / 1000)],
        borderColor: "rgba(0,0,0,1)",

        //important settings

        //set the width of the line ( or point )
        pointRadius: 100,
        // don´t show line betrween points
        showLine: false,
        //change points of line chart to line style ( little bit confusin why it´s named point anyway )
        pointStyle: 'line',

        rotation: 90,

        borderWidth: 5,

        //chart type
        type: "line",
      },
      {
        label: "Actual",
        data: [(scopeItemsFiltered.filter(item => item.itemSubCategory.slice(0,2) === "3.").map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000) / (organisationFootprint.organisationTurnoverGBP / 1000)],
        backgroundColor: chartColours[0],
        hoverBackgroundColor: chartHoverColours[0],
      },
      {
        label: "Estimated",
        data: [(scopeItemsFiltered.filter(item => item.itemSubCategory.slice(0,2) === "3.").map(item => item.additionalEstimatedEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000) / (organisationFootprint.organisationTurnoverGBP / 1000)],
        backgroundColor: chartColours[1],
        hoverBackgroundColor: chartHoverColours[1],
      }
    ]
  };

  const horizontalBarOptions = {
    indexAxis: 'y',
    scales: {
      y: {
        stacked: true,
      },
      x: {
        stacked: true,
      }
    },
    plugins: {
      title: {
        display: true,
        text: 'Scope 1 and 2 Actual and Estimated tCO2e',
        font: {
          size: 20,
          family: 'TT Norms Pro'
        }
      },
      datalabels: {
        display: false,
      }
    },
    responsive: true,
  }

  const horizontalBarOptionsAllScopes = {
    indexAxis: 'y',
    scales: {
      y: {
        stacked: false,
      },
      x: {
        stacked: false,
      }
    },
    plugins: {
      title: {
        display: true,
        text: 'tCO2e Emissions - Uncertainty Analysis',
        font: {
          size: 20
        }
      },
      datalabels: {
        display: false,
      }
    },
    responsive: true,
  }

  const horizontalBarOptionsScope3 = {
    indexAxis: 'y',
    scales: {
      y: {
        stacked: true,
      },
      x: {
        stacked: true,
      }
    },
    plugins: {
      title: {
        display: true,
        text: 'Scope 3 Actual and Estimated tCO2e per 1000 Base Currency Units of Turnover',
        font: {
          size: 20
        }
      },
      datalabels: {
        display: false,
      }
    },
    responsive: true,
  }

  //Pie Options
  const pieOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Carbon Emissions by Scope tCO2e',
      },
      legend: {
        display: true,
        maxWidth: 100,
        position: 'bottom',
        labels: {
          font: {
            size: 9,
          },
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map(data => {
                sum += data;
            });
            let percentage = (value*100 / sum).toFixed(0)+"%";
            if ((value*100 / sum) > 5) {
                return percentage;
            }

            return null
        },
        display: 'auto',
        color: 'white'

    },
    tooltip: {
        callbacks: {
          label: function(tooltipItem) {

            var total = tooltipItem.dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
              return previousValue + currentValue;
            });

            var valueAndpercentageLabel = tooltipItem.formattedValue + " (" + ((tooltipItem.raw / total) * 100).toFixed(2) + " %)"

            return valueAndpercentageLabel;
          }
        }
      } 
    },
  };

  const pieOptionsBySubCategory = {
    plugins: {
      title: {
        display: true,
        text: 'Carbon Emissions by Sub Category tCO2e',
      },
      legend: {
        display: true,
        maxWidth: 100,
        position: 'bottom',
        labels: {
          font: {
            size: 9,
          },
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map(data => {
                sum += data;
            });
            let percentage = (value*100 / sum).toFixed(0)+"%";
            if ((value*100 / sum) > 5) {
                return percentage;
            }

            return null
        },
        display: 'auto',
        color: 'white'

    },
    tooltip: {
        callbacks: {
          label: function(tooltipItem) {

            var total = tooltipItem.dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
              return previousValue + currentValue;
            });

            var valueAndpercentageLabel = tooltipItem.formattedValue + " (" + ((tooltipItem.raw / total) * 100).toFixed(2) + " %)"

            return valueAndpercentageLabel;
          }
        }
      } 
    },
  };

  const pieOptionsByItem = {
    plugins: {
      title: {
        display: true,
        text: 'Carbon Emissions by Item tCO2e',
      },
      legend: {
        display: true,
        maxWidth: 100,
        position: 'bottom',
        labels: {
          font: {
            size: 9,
          },
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map(data => {
                sum += data;
            });
            let percentage = (value*100 / sum).toFixed(0)+"%";
            if ((value*100 / sum) > 5) {
                return percentage;
            }

            return null
        },
        display: 'auto',
        color: 'white'

    },
    tooltip: {
        callbacks: {
          label: function(tooltipItem) {

            var total = tooltipItem.dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
              return previousValue + currentValue;
            });

            var valueAndpercentageLabel = tooltipItem.formattedValue + " (" + ((tooltipItem.raw / total) * 100).toFixed(2) + " %)"

            return valueAndpercentageLabel;
          }
        }
      } 
    },
  };
  useEffect(() => {

    setLoading(true)

    // axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_by_organisationId/' + match.params.organisationId)
    //   .then((res) => {
        setItemsFiltered(props.footprintsForCurrentOrganisation.filter((organisationFootprint) => organisationFootprint.id === match.params.footprintId)[0].organisationFootprintItems.sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0));
        setScopeItems(props.footprintsForCurrentOrganisation.filter((organisationFootprint) => organisationFootprint.id === match.params.footprintId)[0].organisationFootprintItems.sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0));
        setOrganisationFootprint(props.footprintsForCurrentOrganisation.find((organisationFootprint) => organisationFootprint.id === match.params.footprintId));
        
        setSelectedItemCategory(aggregateCategoriesArray(props.footprintsForCurrentOrganisation.filter((organisationFootprint) => organisationFootprint.id === match.params.footprintId)[0].organisationFootprintItems.sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0)).map((item) => {
            return {
              label: item.itemCategory,
              value: item.itemCategory
            }
          }))

        setSelectedItemSubCategory(aggregateSubCategoriesArray(props.footprintsForCurrentOrganisation.filter((organisationFootprint) => organisationFootprint.id === match.params.footprintId)[0].organisationFootprintItems.sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0)).map((item) => {
        return {
            label: item.itemSubCategory,
            value: item.itemSubCategory
        }
        }))

        setSelectedDescription(aggregateDescriptionsArray(props.footprintsForCurrentOrganisation.filter((organisationFootprint) => organisationFootprint.id === match.params.footprintId)[0].organisationFootprintItems.sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0)).map((item) => {
        return {
            label: item.itemDescription,
            value: item.itemDescription
        }
        }))

        //Code here to set array = scopeItemsFiltered, but with "Contribution to total uncertainty" field calculated and added
        //This array can then be used to calulate total contribution (and GSD of that total etc...)

        var arrayWithGSDField = []
        var GSDValue
        var item
        var impactPercentageValue
        var ratingResults
        var ratingResultsBasedOnImpact
        var dataQualityTechnologyRating, dataQualityTimeRating, dataQualityGeographyRating, dataQualityCompletenessRating, dataQualityReliabilityRating, emissionsFactorTechnologyRating, emissionsFactorTimeRating, emissionsFactorGeographyRating, emissionsFactorCompletenessRating, emissionsFactorReliabilityRating
        var footprintItems = props.footprintsForCurrentOrganisation.filter((organisationFootprint) => organisationFootprint.id === match.params.footprintId)[0].organisationFootprintItems

        for (item = 0; item < footprintItems.length; item++) {

          impactPercentageValue = (footprintItems[item].carbonEmissions / footprintItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100

          if (footprintItems[item].dataQualityTechnologyClassification === "Very Good") { dataQualityTechnologyRating = 1 }
          if (footprintItems[item].dataQualityTechnologyClassification === "Good") { dataQualityTechnologyRating = 2 }
          if (footprintItems[item].dataQualityTechnologyClassification === "Fair") { dataQualityTechnologyRating = 3 }
          if (footprintItems[item].dataQualityTechnologyClassification === "Poor") { dataQualityTechnologyRating = 4 }

          if (footprintItems[item].dataQualityTimeClassification === "Very Good") { dataQualityTimeRating = 1 }
          if (footprintItems[item].dataQualityTimeClassification === "Good") { dataQualityTimeRating = 2 }
          if (footprintItems[item].dataQualityTimeClassification === "Fair") { dataQualityTimeRating = 3 }
          if (footprintItems[item].dataQualityTimeClassification === "Poor") { dataQualityTimeRating = 4 }

          if (footprintItems[item].dataQualityGeographyClassification === "Very Good") { dataQualityGeographyRating = 1 }
          if (footprintItems[item].dataQualityGeographyClassification === "Good") { dataQualityGeographyRating = 2 }
          if (footprintItems[item].dataQualityGeographyClassification === "Fair") { dataQualityGeographyRating = 3 }
          if (footprintItems[item].dataQualityGeographyClassification === "Poor") { dataQualityGeographyRating = 4 }

          if (footprintItems[item].dataQualityCompletenessClassification === "Very Good") { dataQualityCompletenessRating = 1 }
          if (footprintItems[item].dataQualityCompletenessClassification === "Good") { dataQualityCompletenessRating = 2 }
          if (footprintItems[item].dataQualityCompletenessClassification === "Fair") { dataQualityCompletenessRating = 3 }
          if (footprintItems[item].dataQualityCompletenessClassification === "Poor") { dataQualityCompletenessRating = 4 }

          if (footprintItems[item].dataQualityReliabilityClassification === "Very Good") { dataQualityReliabilityRating = 1 }
          if (footprintItems[item].dataQualityReliabilityClassification === "Good") { dataQualityReliabilityRating = 2 }
          if (footprintItems[item].dataQualityReliabilityClassification === "Fair") { dataQualityReliabilityRating = 3 }
          if (footprintItems[item].dataQualityReliabilityClassification === "Poor") { dataQualityReliabilityRating = 4 }

          if (footprintItems[item].emissionsFactorTechnologyClassification === "Very Good") { emissionsFactorTechnologyRating = 1 }
          if (footprintItems[item].emissionsFactorTechnologyClassification === "Good") { emissionsFactorTechnologyRating = 2 }
          if (footprintItems[item].emissionsFactorTechnologyClassification === "Fair") { emissionsFactorTechnologyRating = 3 }
          if (footprintItems[item].emissionsFactorTechnologyClassification === "Poor") { emissionsFactorTechnologyRating = 4 }

          if (footprintItems[item].emissionsFactorTimeClassification === "Very Good") { emissionsFactorTimeRating = 1 }
          if (footprintItems[item].emissionsFactorTimeClassification === "Good") { emissionsFactorTimeRating = 2 }
          if (footprintItems[item].emissionsFactorTimeClassification === "Fair") { emissionsFactorTimeRating = 3 }
          if (footprintItems[item].emissionsFactorTimeClassification === "Poor") { emissionsFactorTimeRating = 4 }

          if (footprintItems[item].emissionsFactorGeographyClassification === "Very Good") { emissionsFactorGeographyRating = 1 }
          if (footprintItems[item].emissionsFactorGeographyClassification === "Good") { emissionsFactorGeographyRating = 2 }
          if (footprintItems[item].emissionsFactorGeographyClassification === "Fair") { emissionsFactorGeographyRating = 3 }
          if (footprintItems[item].emissionsFactorGeographyClassification === "Poor") { emissionsFactorGeographyRating = 4 }

          if (footprintItems[item].emissionsFactorCompletenessClassification === "Very Good") { emissionsFactorCompletenessRating = 1 }
          if (footprintItems[item].emissionsFactorCompletenessClassification === "Good") { emissionsFactorCompletenessRating = 2 }
          if (footprintItems[item].emissionsFactorCompletenessClassification === "Fair") { emissionsFactorCompletenessRating = 3 }
          if (footprintItems[item].emissionsFactorCompletenessClassification === "Poor") { emissionsFactorCompletenessRating = 4 }

          if (footprintItems[item].emissionsFactorReliabilityClassification === "Very Good") { emissionsFactorReliabilityRating = 1 }
          if (footprintItems[item].emissionsFactorReliabilityClassification === "Good") { emissionsFactorReliabilityRating = 2 }
          if (footprintItems[item].emissionsFactorReliabilityClassification === "Fair") { emissionsFactorReliabilityRating = 3 }
          if (footprintItems[item].emissionsFactorReliabilityClassification === "Poor") { emissionsFactorReliabilityRating = 4 }

          ratingResults = ((dataQualityTechnologyRating + dataQualityTimeRating + dataQualityGeographyRating + dataQualityCompletenessRating + dataQualityReliabilityRating) / 5) * ((emissionsFactorTechnologyRating + emissionsFactorTimeRating + emissionsFactorGeographyRating + emissionsFactorCompletenessRating + emissionsFactorReliabilityRating) / 5)

          ratingResultsBasedOnImpact = ratingResults * (impactPercentageValue / 100)

          GSDValue = contribution(GSD(footprintItems[item].dataQualityTechnologyFactor, footprintItems[item].dataQualityTimeFactor, footprintItems[item].dataQualityGeographyFactor, footprintItems[item].dataQualityCompletenessFactor, footprintItems[item].dataQualityReliabilityFactor, footprintItems[item].basicUncertaintyFactor), impactPercentageValue) + contribution(GSD(footprintItems[item].emissionsFactorTechnologyFactor, footprintItems[item].emissionsFactorTimeFactor, footprintItems[item].emissionsFactorGeographyFactor, footprintItems[item].emissionsFactorCompletenessFactor, footprintItems[item].emissionsFactorReliabilityFactor, footprintItems[item].basicUncertaintyFactor), impactPercentageValue)

          arrayWithGSDField.push({ ...footprintItems[item], GSDValue, ratingResultsBasedOnImpact })
        }

        setItemsFilteredWithGSD(arrayWithGSDField)
        setItemsWithGSD(arrayWithGSDField)

        var year
        var yearsArray = []

        for (year = Number(props.footprintsForCurrentOrganisation.find((organisationFootprint) => organisationFootprint.id === match.params.footprintId).organisationFootprintYear); year <= Number(props.footprintsForCurrentOrganisation.find((organisationFootprint) => organisationFootprint.id === match.params.footprintId).organisationFootprintTargetYear); year++) {

          yearsArray.push(year.toString())

        }
        setYears(yearsArray)

        // //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // //Code here to calculate Actuals, Projections and Targets per year
        // //Actuals

        // var filteredResActuals
        // var filteredResTargets
        // var filteredResProjections
        // var i
        // var concatenatedListOfItems
        // var k
        // var emissions
        // var carbonEmissionsByTurnoverGBP
        // var targetYear = props.currentOrganisationFootprint.organisationFootprintTargetYear

        // filteredResActuals = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType.slice(0, 6) === "Actual").filter(item => item.organisationFootprintCertification !== "")

        // var carbonEmissionsPerYear = [];

        // year = props.currentOrganisationFootprint.organisationFootprintYear

        // for (i = 0; i < (Number(targetYear) - Number(props.currentOrganisationFootprint.organisationFootprintYear)) + 1; i++) {
        //   concatenatedListOfItems = [];

        //   for (k = 0; k < filteredResActuals.length; k++) {
        //     if (
        //       filteredResActuals[k].organisationFootprintYear === year
        //     ) {
        //       concatenatedListOfItems =
        //         concatenatedListOfItems.concat(
        //           filteredResActuals[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
        //         );
        //     }
        //   }

        //   emissions = concatenatedListOfItems
        //     .map((item) => item.carbonEmissions)
        //     .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
        //   if (emissions === 0) {
        //     emissions = "N/A"
        //   }
        //   carbonEmissionsPerYear.push({
        //     year,
        //     emissions,
        //   });

        //   year = (Number(year) + 1).toString()

        // }

        // setCarbonEmissionsPerYearActuals(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

        // //Targets

        // year = props.currentOrganisationFootprint.organisationFootprintYear

        // filteredResTargets = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Target")

        // carbonEmissionsPerYear = [];

        // for (i = 0; i < filteredResTargets.length; i++) {
        //   concatenatedListOfItems = [];

        //   for (k = 0; k < filteredResTargets.length; k++) {
        //     if (
        //       filteredResTargets[k].organisationFootprintYear === year
        //     ) {
        //       concatenatedListOfItems =
        //         concatenatedListOfItems.concat(
        //           filteredResTargets[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
        //         );
        //     }
        //   }

        //   emissions = concatenatedListOfItems
        //     .map((item) => item.carbonEmissions)
        //     .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
        //   if (emissions === 0) {
        //     emissions = "N/A"
        //   }
        //   carbonEmissionsPerYear.push({
        //     year,
        //     emissions,
        //   });

        //   year = (Number(year) + 1).toString()

        // }

        // setCarbonEmissionsPerYearTargets(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));


        // //Projections

        // year = props.currentOrganisationFootprint.organisationFootprintYear

        // filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast")

        // carbonEmissionsPerYear = [];

        // for (i = 0; i < filteredResProjections.length; i++) {
        //   concatenatedListOfItems = [];

        //   for (k = 0; k < filteredResProjections.length; k++) {
        //     if (
        //       filteredResProjections[k].organisationFootprintYear === year
        //     ) {
        //       concatenatedListOfItems =
        //         concatenatedListOfItems.concat(
        //           filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
        //         );
        //     }
        //   }

        //   emissions = concatenatedListOfItems
        //     .map((item) => item.carbonEmissions)
        //     .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
        //   if (emissions === 0) {
        //     emissions = "N/A"
        //   }
        //   carbonEmissionsPerYear.push({
        //     year,
        //     emissions,
        //   });

        //   year = (Number(year) + 1).toString()

        // }

        // setCarbonEmissionsPerYearProjections(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

        // //Projections 2

        // year = props.currentOrganisationFootprint.organisationFootprintYear

        // filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast 2")

        // carbonEmissionsPerYear = [];

        // for (i = 0; i < filteredResProjections.length; i++) {
        //   concatenatedListOfItems = [];

        //   for (k = 0; k < filteredResProjections.length; k++) {
        //     if (
        //       filteredResProjections[k].organisationFootprintYear === year
        //     ) {
        //       concatenatedListOfItems =
        //         concatenatedListOfItems.concat(
        //           filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
        //         );
        //     }
        //   }

        //   emissions = concatenatedListOfItems
        //     .map((item) => item.carbonEmissions)
        //     .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
        //   if (emissions === 0) {
        //     emissions = "N/A"
        //   }
        //   carbonEmissionsPerYear.push({
        //     year,
        //     emissions,
        //   });

        //   year = (Number(year) + 1).toString()

        // }

        // setCarbonEmissionsPerYearProjections2(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

        // //Projections 3

        // year = props.currentOrganisationFootprint.organisationFootprintYear

        // filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast 3")

        // carbonEmissionsPerYear = [];

        // for (i = 0; i < filteredResProjections.length; i++) {
        //   concatenatedListOfItems = [];

        //   for (k = 0; k < filteredResProjections.length; k++) {
        //     if (
        //       filteredResProjections[k].organisationFootprintYear === year
        //     ) {
        //       concatenatedListOfItems =
        //         concatenatedListOfItems.concat(
        //           filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
        //         );
        //     }
        //   }

        //   emissions = concatenatedListOfItems
        //     .map((item) => item.carbonEmissions)
        //     .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
        //   if (emissions === 0) {
        //     emissions = "N/A"
        //   }
        //   carbonEmissionsPerYear.push({
        //     year,
        //     emissions,
        //   });

        //   year = (Number(year) + 1).toString()

        // }

        // setCarbonEmissionsPerYearProjections3(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));


        // //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        // //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // //Code here to calculate Actuals, Projections and Targets per year
        // //Actuals

        // filteredResActuals = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType.slice(0, 6) === "Actual").filter(item => item.organisationFootprintCertification !== "")

        // carbonEmissionsPerYear = [];

        // year = props.currentOrganisationFootprint.organisationFootprintYear

        // for (i = 0; i < (Number(targetYear) - Number(props.currentOrganisationFootprint.organisationFootprintYear)) + 1; i++) {
        //   concatenatedListOfItems = [];

        //   for (k = 0; k < filteredResActuals.length; k++) {
        //     if (
        //       filteredResActuals[k].organisationFootprintYear === year
        //     ) {
        //       concatenatedListOfItems =
        //         concatenatedListOfItems.concat(
        //           filteredResActuals[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
        //         );
        //     }
        //   }

        //   carbonEmissionsByTurnoverGBP = 0

        //   emissions = concatenatedListOfItems
        //     .map((item) => item.carbonEmissions)
        //     .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;

        //     if (filteredResActuals.filter(item => item.organisationFootprintYear === year).length > 0) {
        //         carbonEmissionsByTurnoverGBP = emissions / (filteredResActuals.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
        //       }

        //   if (carbonEmissionsByTurnoverGBP === 0) {
        //     carbonEmissionsByTurnoverGBP = "N/A"
        //   }

        //   carbonEmissionsPerYear.push({
        //     year,
        //     emissions: carbonEmissionsByTurnoverGBP,
        //   });

        //   year = (Number(year) + 1).toString()

        // }

        // setCarbonEmissionsPerYearActualsScope3(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

        // //Targets

        // year = props.currentOrganisationFootprint.organisationFootprintYear

        // filteredResTargets = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Target")

        // carbonEmissionsPerYear = [];

        // for (i = 0; i < filteredResTargets.length; i++) {
        //   concatenatedListOfItems = [];

        //   for (k = 0; k < filteredResTargets.length; k++) {
        //     if (
        //       filteredResTargets[k].organisationFootprintYear === year
        //     ) {
        //       concatenatedListOfItems =
        //         concatenatedListOfItems.concat(
        //           filteredResTargets[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
        //         );
        //     }
        //   }

        //   carbonEmissionsByTurnoverGBP = 0

        //   emissions = concatenatedListOfItems
        //     .map((item) => item.carbonEmissions)
        //     .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;

        //     if (filteredResTargets.filter(item => item.organisationFootprintYear === year).length > 0) {
        //         carbonEmissionsByTurnoverGBP = emissions / (filteredResTargets.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
        //     }

        //     if (carbonEmissionsByTurnoverGBP === 0) {
        //       carbonEmissionsByTurnoverGBP = "N/A"
        //     }
  
        //     carbonEmissionsPerYear.push({
        //       year,
        //       emissions: carbonEmissionsByTurnoverGBP,
        //     });

        //   year = (Number(year) + 1).toString()

        // }

        // setCarbonEmissionsPerYearTargetsScope3(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));


        // //Projections

        // year = props.currentOrganisationFootprint.organisationFootprintYear

        // filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast")

        // carbonEmissionsPerYear = [];

        // for (i = 0; i < filteredResProjections.length; i++) {
        //   concatenatedListOfItems = [];

        //   for (k = 0; k < filteredResProjections.length; k++) {
        //     if (
        //       filteredResProjections[k].organisationFootprintYear === year
        //     ) {
        //       concatenatedListOfItems =
        //         concatenatedListOfItems.concat(
        //           filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
        //         );
        //     }
        //   }

        //   carbonEmissionsByTurnoverGBP = 0

        //   emissions = concatenatedListOfItems
        //     .map((item) => item.carbonEmissions)
        //     .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;

        //     if (filteredResTargets.filter(item => item.organisationFootprintYear === year).length > 0) {
        //         carbonEmissionsByTurnoverGBP = emissions / (filteredResTargets.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
        //     }

        //   if (carbonEmissionsByTurnoverGBP === 0) {
        //     carbonEmissionsByTurnoverGBP = "N/A"
        //   }

        //   carbonEmissionsPerYear.push({
        //     year,
        //     emissions: carbonEmissionsByTurnoverGBP,
        //   });

        //   year = (Number(year) + 1).toString()

        // }

        // setCarbonEmissionsPerYearProjectionsScope3(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

        //  //Projections 2

        //  year = props.currentOrganisationFootprint.organisationFootprintYear

        //  filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast 2")
 
        //  carbonEmissionsPerYear = [];
 
        //  for (i = 0; i < filteredResProjections.length; i++) {
        //    concatenatedListOfItems = [];
 
        //    for (k = 0; k < filteredResProjections.length; k++) {
        //      if (
        //        filteredResProjections[k].organisationFootprintYear === year
        //      ) {
        //        concatenatedListOfItems =
        //          concatenatedListOfItems.concat(
        //            filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
        //          );
        //      }
        //    }
 
        //    carbonEmissionsByTurnoverGBP = 0
 
        //    emissions = concatenatedListOfItems
        //      .map((item) => item.carbonEmissions)
        //      .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
 
        //      if (filteredResTargets.filter(item => item.organisationFootprintYear === year).length > 0) {
        //          carbonEmissionsByTurnoverGBP = emissions / (filteredResTargets.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
        //      }
 
        //    if (carbonEmissionsByTurnoverGBP === 0) {
        //      carbonEmissionsByTurnoverGBP = "N/A"
        //    }
 
        //    carbonEmissionsPerYear.push({
        //      year,
        //      emissions: carbonEmissionsByTurnoverGBP,
        //    });
 
        //    year = (Number(year) + 1).toString()
 
        //  }
 
        //  setCarbonEmissionsPerYearProjectionsScope32(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
 
        //   //Projections 3

        // year = props.currentOrganisationFootprint.organisationFootprintYear

        // filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast 3")

        // carbonEmissionsPerYear = [];

        // for (i = 0; i < filteredResProjections.length; i++) {
        //   concatenatedListOfItems = [];

        //   for (k = 0; k < filteredResProjections.length; k++) {
        //     if (
        //       filteredResProjections[k].organisationFootprintYear === year
        //     ) {
        //       concatenatedListOfItems =
        //         concatenatedListOfItems.concat(
        //           filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
        //         );
        //     }
        //   }

        //   carbonEmissionsByTurnoverGBP = 0

        //   emissions = concatenatedListOfItems
        //     .map((item) => item.carbonEmissions)
        //     .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;

        //     if (filteredResTargets.filter(item => item.organisationFootprintYear === year).length > 0) {
        //         carbonEmissionsByTurnoverGBP = emissions / (filteredResTargets.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
        //     }

        //   if (carbonEmissionsByTurnoverGBP === 0) {
        //     carbonEmissionsByTurnoverGBP = "N/A"
        //   }

        //   carbonEmissionsPerYear.push({
        //     year,
        //     emissions: carbonEmissionsByTurnoverGBP,
        //   });

        //   year = (Number(year) + 1).toString()

        // }

        // setCarbonEmissionsPerYearProjectionsScope33(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));


        // //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   }).finally(() => {
    //     setLoading(false);
    //   });

  }, [match.params.footprintId])

  useEffect(() => {

    axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_by_organisationId/' + match.params.organisationId)
      .then((res) => {
        // setAllForecastFootprints(res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast"))
        // setAllTargetFootprints(res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Target"))
        // setAllActualFootprints(res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType.slice(0, 6) === "Actual"))

        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        //Code here to calculate Actuals, Projections and Targets per year
        //Actuals

        var filteredResActuals
        var filteredResTargets
        var filteredResProjections
        var i
        var concatenatedListOfItems
        var year
        var k
        var emissions
        var carbonEmissionsByTurnoverGBP
        var targetYear = props.currentOrganisationFootprint.organisationFootprintTargetYear

        filteredResActuals = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType.slice(0, 6) === "Actual").filter(item => item.organisationFootprintCertification !== "")

        var carbonEmissionsPerYear = [];

        year = props.currentOrganisationFootprint.organisationFootprintYear

        for (i = 0; i < (Number(targetYear) - Number(props.currentOrganisationFootprint.organisationFootprintYear)) + 1; i++) {
          concatenatedListOfItems = [];

          for (k = 0; k < filteredResActuals.length; k++) {
            if (
              filteredResActuals[k].organisationFootprintYear === year
            ) {
              concatenatedListOfItems =
                concatenatedListOfItems.concat(
                  filteredResActuals[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
                );
            }
          }

          emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
          if (emissions === 0) {
            emissions = "N/A"
          }
          carbonEmissionsPerYear.push({
            year,
            emissions,
          });

          year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearActuals(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

        //Targets

        year = props.currentOrganisationFootprint.organisationFootprintYear

        filteredResTargets = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Target")

        carbonEmissionsPerYear = [];

        for (i = 0; i < filteredResTargets.length; i++) {
          concatenatedListOfItems = [];

          for (k = 0; k < filteredResTargets.length; k++) {
            if (
              filteredResTargets[k].organisationFootprintYear === year
            ) {
              concatenatedListOfItems =
                concatenatedListOfItems.concat(
                  filteredResTargets[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
                );
            }
          }

          emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
          if (emissions === 0) {
            emissions = "N/A"
          }
          carbonEmissionsPerYear.push({
            year,
            emissions,
          });

          year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearTargets(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));


        //Projections

        year = props.currentOrganisationFootprint.organisationFootprintYear

        filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast")

        carbonEmissionsPerYear = [];

        for (i = 0; i < filteredResProjections.length; i++) {
          concatenatedListOfItems = [];

          for (k = 0; k < filteredResProjections.length; k++) {
            if (
              filteredResProjections[k].organisationFootprintYear === year
            ) {
              concatenatedListOfItems =
                concatenatedListOfItems.concat(
                  filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
                );
            }
          }

          emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
          if (emissions === 0) {
            emissions = "N/A"
          }
          carbonEmissionsPerYear.push({
            year,
            emissions,
          });

          year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearProjections(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
        if (filteredResProjections.length > 0) {
            setCarbonEmissionsPerYearProjectionsLabel(filteredResProjections[0].organisationFootprintRef.slice(5, filteredResProjections[0].organisationFootprintRef.length))
        }

        //Projections 2

        year = props.currentOrganisationFootprint.organisationFootprintYear

        filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast 2")

        carbonEmissionsPerYear = [];

        for (i = 0; i < filteredResProjections.length; i++) {
            concatenatedListOfItems = [];

            for (k = 0; k < filteredResProjections.length; k++) {
            if (
                filteredResProjections[k].organisationFootprintYear === year
            ) {
                concatenatedListOfItems =
                concatenatedListOfItems.concat(
                    filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
                );
            }
            }

            emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
            if (emissions === 0) {
            emissions = "N/A"
            }
            carbonEmissionsPerYear.push({
            year,
            emissions,
            });

            year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearProjections2(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
        if (filteredResProjections.length > 0) {
            setCarbonEmissionsPerYearProjectionsLabel2(filteredResProjections[0].organisationFootprintRef.slice(5, filteredResProjections[0].organisationFootprintRef.length))
        }

         //Projections 3

         year = props.currentOrganisationFootprint.organisationFootprintYear

         filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast 3")
 
         carbonEmissionsPerYear = [];
 
         for (i = 0; i < filteredResProjections.length; i++) {
             concatenatedListOfItems = [];
 
             for (k = 0; k < filteredResProjections.length; k++) {
             if (
                 filteredResProjections[k].organisationFootprintYear === year
             ) {
                 concatenatedListOfItems =
                 concatenatedListOfItems.concat(
                     filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "1" || item.itemCategory === "2")
                 );
             }
             }
 
             emissions = concatenatedListOfItems
             .map((item) => item.carbonEmissions)
             .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
             if (emissions === 0) {
             emissions = "N/A"
             }
             carbonEmissionsPerYear.push({
             year,
             emissions,
             });
 
             year = (Number(year) + 1).toString()
 
         }
 
         setCarbonEmissionsPerYearProjections3(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
         if (filteredResProjections.length > 0) {
            setCarbonEmissionsPerYearProjectionsLabel3(filteredResProjections[0].organisationFootprintRef.slice(5, filteredResProjections[0].organisationFootprintRef.length))
        }

        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        //Code here to calculate Actuals, Projections and Targets per year
        //Actuals

        filteredResActuals = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType.slice(0, 6) === "Actual").filter(item => item.organisationFootprintCertification !== "")

        carbonEmissionsPerYear = [];

        year = props.currentOrganisationFootprint.organisationFootprintYear

        for (i = 0; i < (Number(targetYear) - Number(props.currentOrganisationFootprint.organisationFootprintYear)) + 1; i++) {
          concatenatedListOfItems = [];

          for (k = 0; k < filteredResActuals.length; k++) {
            if (
              filteredResActuals[k].organisationFootprintYear === year
            ) {
              concatenatedListOfItems =
                concatenatedListOfItems.concat(
                  filteredResActuals[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
                );
            }
          }

          carbonEmissionsByTurnoverGBP = 0

          emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;

          if (filteredResActuals.filter(item => item.organisationFootprintYear === year).length > 0) {
            carbonEmissionsByTurnoverGBP = emissions / (filteredResActuals.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
          }

          if (carbonEmissionsByTurnoverGBP === 0) {
            carbonEmissionsByTurnoverGBP = "N/A"
          }

          carbonEmissionsPerYear.push({
            year,
            emissions: carbonEmissionsByTurnoverGBP,
          });

          year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearActualsScope3(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

        //Targets

        year = props.currentOrganisationFootprint.organisationFootprintYear

        filteredResTargets = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Target")

        carbonEmissionsPerYear = [];

        for (i = 0; i < filteredResTargets.length; i++) {
          concatenatedListOfItems = [];

          for (k = 0; k < filteredResTargets.length; k++) {
            if (
              filteredResTargets[k].organisationFootprintYear === year
            ) {
              concatenatedListOfItems =
                concatenatedListOfItems.concat(
                  filteredResTargets[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
                );
            }
          }

          carbonEmissionsByTurnoverGBP = 0

          emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;

            if (filteredResTargets.filter(item => item.organisationFootprintYear === year).length > 0) {
                carbonEmissionsByTurnoverGBP = emissions / (filteredResTargets.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
            }

            if (carbonEmissionsByTurnoverGBP === 0) {
              carbonEmissionsByTurnoverGBP = "N/A"
            }
  
            carbonEmissionsPerYear.push({
              year,
              emissions: carbonEmissionsByTurnoverGBP,
            });

          year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearTargetsScope3(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));


        //Projections

        year = props.currentOrganisationFootprint.organisationFootprintYear

        filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast")

        carbonEmissionsPerYear = [];

        for (i = 0; i < filteredResProjections.length; i++) {
          concatenatedListOfItems = [];

          for (k = 0; k < filteredResProjections.length; k++) {
            if (
              filteredResProjections[k].organisationFootprintYear === year
            ) {
              concatenatedListOfItems =
                concatenatedListOfItems.concat(
                  filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
                );
            }
          }

          carbonEmissionsByTurnoverGBP = 0

          emissions = concatenatedListOfItems
            .map((item) => item.carbonEmissions)
            .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;

            if (filteredResProjections.filter(item => item.organisationFootprintYear === year).length > 0) {
                carbonEmissionsByTurnoverGBP = emissions / (filteredResProjections.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
            }

          if (carbonEmissionsByTurnoverGBP === 0) {
            carbonEmissionsByTurnoverGBP = "N/A"
          }

          carbonEmissionsPerYear.push({
            year,
            emissions: carbonEmissionsByTurnoverGBP,
          });

          year = (Number(year) + 1).toString()

        }

        setCarbonEmissionsPerYearProjectionsScope3(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
        if (filteredResProjections.length > 0) {
            setCarbonEmissionsPerYearProjectionsLabelScope3(filteredResProjections[0].organisationFootprintRef.slice(5, filteredResProjections[0].organisationFootprintRef.length))
        }
         //Projections 2

         year = props.currentOrganisationFootprint.organisationFootprintYear

         filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast 2")
 
         carbonEmissionsPerYear = [];
 
         for (i = 0; i < filteredResProjections.length; i++) {
           concatenatedListOfItems = [];
 
           for (k = 0; k < filteredResProjections.length; k++) {
             if (
               filteredResProjections[k].organisationFootprintYear === year
             ) {
               concatenatedListOfItems =
                 concatenatedListOfItems.concat(
                   filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
                 );
             }
           }
 
           carbonEmissionsByTurnoverGBP = 0
 
           emissions = concatenatedListOfItems
             .map((item) => item.carbonEmissions)
             .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
 
             if (filteredResProjections.filter(item => item.organisationFootprintYear === year).length > 0) {
                 carbonEmissionsByTurnoverGBP = emissions / (filteredResProjections.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
             }
 
           if (carbonEmissionsByTurnoverGBP === 0) {
             carbonEmissionsByTurnoverGBP = "N/A"
           }
 
           carbonEmissionsPerYear.push({
             year,
             emissions: carbonEmissionsByTurnoverGBP,
           });
 
           year = (Number(year) + 1).toString()
 
         }
 
         setCarbonEmissionsPerYearProjectionsScope32(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
         if (filteredResProjections.length > 0) {
            setCarbonEmissionsPerYearProjectionsLabelScope32(filteredResProjections[0].organisationFootprintRef.slice(5, filteredResProjections[0].organisationFootprintRef.length))
        }
         //Projections 3

         year = props.currentOrganisationFootprint.organisationFootprintYear

         filteredResProjections = res.data.filter(item => item.organisationId === match.params.organisationId && item.organisationFootprintType === "Forecast 3")
 
         carbonEmissionsPerYear = [];
 
         for (i = 0; i < filteredResProjections.length; i++) {
           concatenatedListOfItems = [];
 
           for (k = 0; k < filteredResProjections.length; k++) {
             if (
               filteredResProjections[k].organisationFootprintYear === year
             ) {
               concatenatedListOfItems =
                 concatenatedListOfItems.concat(
                   filteredResProjections[k].organisationFootprintItems.filter(item => item.itemCategory === "3")
                 );
             }
           }
 
           carbonEmissionsByTurnoverGBP = 0
 
           emissions = concatenatedListOfItems
             .map((item) => item.carbonEmissions)
             .reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000;
 
             if (filteredResProjections.filter(item => item.organisationFootprintYear === year).length > 0) {
                 carbonEmissionsByTurnoverGBP = emissions / (filteredResProjections.find(item => item.organisationFootprintYear === year).organisationTurnoverGBP / 1000)
             }
 
           if (carbonEmissionsByTurnoverGBP === 0) {
             carbonEmissionsByTurnoverGBP = "N/A"
           }
 
           carbonEmissionsPerYear.push({
             year,
             emissions: carbonEmissionsByTurnoverGBP,
           });
 
           year = (Number(year) + 1).toString()
 
         }
 
         setCarbonEmissionsPerYearProjectionsScope33(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
         if (filteredResProjections.length > 0) {
            setCarbonEmissionsPerYearProjectionsLabelScope33(filteredResProjections[0].organisationFootprintRef.slice(5, filteredResProjections[0].organisationFootprintRef.length))
        }

        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });

    setFootprintsAdded(false)
    setanimationForecast(false)

  }, [footprintsAdded])

  useEffect(() => {

    setItemsFiltered(scopeItems)
    setItemsFilteredWithGSD(scopeItemsWithGSD)

    setItemsFiltered(scopeItemsFiltered => scopeItemsFiltered.filter((itemA) => {
        return selectedItemCategory.find((itemB) => {
            return itemA.itemCategory === itemB.value;
        })
        }).filter((itemA) => {
            return selectedItemSubCategory.find((itemB) => {
                return itemA.itemSubCategory === itemB.value;
            })
            }).filter((itemA) => {
                return selectedDescription.find((itemB) => {
                    return itemA.itemDescription === itemB.value;
                })
                }))

    // if (selectedItemCategory !== "All") {
    //   setItemsFiltered(scopeItemsFiltered => scopeItemsFiltered.filter((item) => item.itemCategory === selectedItemCategory.value))
    // }

    // if (selectedItemSubCategory !== "All") {
    //   setItemsFiltered(scopeItemsFiltered => scopeItemsFiltered.filter((item) => item.itemSubCategory === selectedItemSubCategory.value))
    // }

    if (selectedVerified !== "All") {
        setItemsFiltered(scopeItemsFiltered => scopeItemsFiltered.filter((item) => item.verified === selectedVerified.value))
    }

    if (selectedSubmitted !== "All") {
    setItemsFiltered(scopeItemsFiltered => scopeItemsFiltered.filter((item) => item.submitted === selectedSubmitted.value))
    }

    // if (selectedItemCategory !== "All") {
    //   setItemsFilteredWithGSD(scopeItemsFilteredWithGSD => scopeItemsFilteredWithGSD.filter((item) => item.itemCategory === selectedItemCategory.value))
    // }

    // if (selectedItemSubCategory !== "All") {
    //   setItemsFilteredWithGSD(scopeItemsFilteredWithGSD => scopeItemsFilteredWithGSD.filter((item) => item.itemSubCategory === selectedItemSubCategory.value))
    // }

    setItemsFilteredWithGSD(scopeItemsFilteredWithGSD => scopeItemsFilteredWithGSD.filter((itemA) => {
        return selectedItemCategory.find((itemB) => {
            return itemA.itemCategory === itemB.value;
        })
        }).filter((itemA) => {
            return selectedItemSubCategory.find((itemB) => {
                return itemA.itemSubCategory === itemB.value;
            })
            }).filter((itemA) => {
                return selectedDescription.find((itemB) => {
                    return itemA.itemDescription === itemB.value;
                })
                }))

    if (selectedVerified !== "All") {
        setItemsFilteredWithGSD(scopeItemsFilteredWithGSD => scopeItemsFilteredWithGSD.filter((item) => item.verified === selectedVerified.value))
    }

    if (selectedSubmitted !== "All") {
        setItemsFilteredWithGSD(scopeItemsFilteredWithGSD => scopeItemsFilteredWithGSD.filter((item) => item.submitted === selectedSubmitted.value))
    }
  

  }, [selectedItemCategory, selectedItemSubCategory, scopeItems, scopeItemsWithGSD, selectedVerified, selectedSubmitted, selectedDescription])

  useEffect(() => {

    if (organisationFootprint.organisationFootprintYear) {

      //Code here to go and get the target footprint for this year
      //so that it can be shown on the chart as the target
    //   axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_by_organisationId/' + match.params.organisationId)
    //     .then((res) => {
    //       setTargetForThisYear(res.data.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target").organisationFootprintItems.filter(item => item.itemSubCategory.slice(0,2) === "1." || item.itemSubCategory.slice(0,2) === "2.").map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)
    //       setTargetGBPTurnover(res.data.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target").organisationTurnoverGBP)
    //       setTargetForThisYearScope3(res.data.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target").organisationFootprintItems.filter(item => item.itemSubCategory.slice(0,2) === "3.").map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })

        if (props.footprintsForCurrentOrganisation.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target")) {

            setTargetForThisYear(props.footprintsForCurrentOrganisation.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target").organisationFootprintItems.filter(item => item.itemSubCategory.slice(0,2) === "1." || item.itemSubCategory.slice(0,2) === "2.").map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)
            setTargetGBPTurnover(props.footprintsForCurrentOrganisation.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target").organisationTurnoverGBP)
            setTargetForThisYearScope3(props.footprintsForCurrentOrganisation.find(item => item.organisationId === match.params.organisationId && item.organisationFootprintYear === organisationFootprint.organisationFootprintYear && item.organisationFootprintType === "Target").organisationFootprintItems.filter(item => item.itemSubCategory.slice(0,2) === "3.").map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)

        }

        axios.get(process.env.REACT_APP_API_URL + '/scenarios')
        .then((res) => {
            setScenarioDataset(res.data)
        })
        .catch((err) => {
          console.log(err);
        })
    }

  }, [organisationFootprint])

  if (scopeItems.length > 0 && !loading) {

    return (
      <div>

        {organisationFootprint.organisationFootprintType === "Actual (Baseline)" && organisationFootprint.organisationFootprintCertification !== "" && (
          <div>
                    {scenarioDataset.filter(item => item.organisation === match.params.organisationId).length > 0 && (
        <div className="grid gap-2 sm:gap-7 grid-cols-1 md:grid-cols-2 lg:grid-cols-6 p-2">
            
            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300 col-span-2">
                <div className="text-xs text-gray-500 ">This tool allows you to plot multiple carbon scenarios for your organisation. As each company is unique the variables that form part of the scenario analysis must be purpose-built. The variables have been built and quality assured by Eight Versa, should you require further variables to test and analyse please contact the team so these can be built to suit your context. </div>
            </div>
            <div>
            <p className="text-xs" data-tip data-for="scenarioTip"><ReactTooltip id="scenarioTip" place="top" effect="solid">
                              Shared Socioeconomic Pathways (SSPs) are a collection of scenarios defined in the IPCC’s Assessment Report on climate change.<br></br>
                              They present possible climate futures which have five emissions scenarios based on national population, urbanization and GDP per capita. 
                              <br></br>
                              <br></br>The scenarios are:

                            <br></br>•	SSP1: Sustainability ("Taking the Green Road")
                            <br></br>•	SSP2: "Middle of the Road"
                            <br></br>•	SSP3: Regional Rivalry ("A Rocky Road")
                            <br></br>•	SSP4: Inequality ("A Road Divided")
                            <br></br>•	SSP5: Fossil-fuelled Development ("Taking the Highway") 
                            <br></br>

                            <br></br>A minimum of three scenarios is required for TCFD disclosures, SSP1 is the 1.5C scenario and is mandatory for any disclosure. 
                            
                            </ReactTooltip>Shared Socioeconomic Pathway Scenarios <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" data-slot="icon" className="w-6 h-6 pb-2 float-right">
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg></p>
            <ReactSelect
            styles={{
                option: provided => ({
                    ...provided,
                    color: 'black'
                }),
                control: provided => ({
                    ...provided,
                    color: 'black',
                    width: '100%',
                    fontSize: 13
                }),
                singleValue: (provided) => ({
                    ...provided,
                    color: 'black'
                }),
                menu: provided => ({
                    ...provided,
                    color: 'black',
                    width: '100%',
                    fontSize: 13
                }),
                }}
            value={selectedScenario}
            onChange={setSelectedScenario}
            options={aggregateScenariosArray(scenarioDataset).map((item) => {
            return {
                label: item.scenario,
                value: item.scenario
            }
            })} />
            </div>
            <div>
            <p className="text-xs" data-tip data-for="growthRateTip"><ReactTooltip id="growthRateTip" place="top" effect="solid">
                              Growth and Decoupling rate:
                                    <br></br>As Organisations grow their carbon footprint increases. This factor must be included in all scenarios. 
                                    <br></br>
                                    <br></br>This value is a bespoke calculation which combines the rate of business growth (i.e. $ turnover or unit of output) and the rate at which the Organisation can achieve a decoupling of growth and its carbon footprint. 
                                    <br></br>For example, if a company achieves a 10% increase in its sales volume but through economies of scale in production is able to produce its product 8% more efficient in carbon terms 
                                    <br></br> then its Growth and Decoupling rate would be 2% (10% growth – 8% carbon efficiency = 2% annual increase in carbon footprint). 
                                    <br></br>
                                    <br></br>If a company is targeting an annual 5% absolute reduction in its carbon footprint in order to reach Net Zero, and has a Growth and Decoupling rate of 2%, then a total reduction of 7% is required. 
                                    
                            </ReactTooltip>Growth & Decoupling Rate <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" data-slot="icon" className="w-6 h-6 pb-2 float-right">
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg></p>
            <ReactSelect
            styles={{
                option: provided => ({
                  ...provided,
                  color: 'black'
                }),
                control: provided => ({
                  ...provided,
                  color: 'black',
                  width: '100%',
                  fontSize: 13
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: 'black'
                }),
                menu: provided => ({
                  ...provided,
                  color: 'black',
                  width: '100%',
                  fontSize: 13
                }),
              }}
            value={selectedGrowthRate}
            onChange={setSelectedGrowthRate}
            options={aggregateGrowthRatesArray(scenarioDataset).map((item) => {
            return {
                label: item.growth + "%",
                value: item.growth
            }
            })} />
            </div>
            <div>
            <p className="text-xs" data-tip data-for="measuresTip"><ReactTooltip id="measuresTip" place="top" effect="solid">
                              These are actions that your Organisation can make to reduce its Scope 1, 2 & 3 emissions. 
                              <br></br>
                                <br></br>The measures are tailored to your Organisation based on its energy consumption, geographical location and supply chain.
                                <br></br>
                                <br></br> If more measures are required to achieve a reduction target then an Eight Versa Advisor can calculate this for inclusion in your scenario analysis. 
                                
                            </ReactTooltip>Reduction Measures <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" data-slot="icon" className="w-6 h-6 pb-2 float-right">
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg>
</p>
            <ReactSelect
            styles={{
                option: provided => ({
                    ...provided,
                    color: 'black'
                }),
                control: provided => ({
                    ...provided,
                    color: 'black',
                    width: '100%',
                    fontSize: 13
                }),
                singleValue: (provided) => ({
                    ...provided,
                    color: 'black'
                }),
                menu: provided => ({
                    ...provided,
                    color: 'black',
                    width: '100%',
                    fontSize: 13
                }),
                }}
            value={selectedMeasure}
            onChange={setSelectedMeasure}
            options={aggregateMeasuresArray(scenarioDataset).map((item) => {
            return {
                label: item.measure,
                value: item.measure
            }
            })} />
            </div>

            <div>
            <ReactSelect
            styles={{
                option: provided => ({
                    ...provided,
                    color: 'black'
                }),
                control: provided => ({
                    ...provided,
                    color: 'black',
                    width: '100%',
                    fontSize: 13
                }),
                singleValue: (provided) => ({
                    ...provided,
                    color: 'black'
                }),
                menu: provided => ({
                    ...provided,
                    color: 'black',
                    width: '100%',
                    fontSize: 13
                }),
                }}
            value={selectedForecast}
            onChange={setSelectedForecast}
            options={[{label: "Forecast 1", value: "Forecast"}, {label: "Forecast 2", value: "Forecast 2"}, {label: "Forecast 3", value: "Forecast 3"}]} />
            <button className="rounded-full m-1 p-2 text-white t-10 bg-pacific-blue-600 text-center h-12" onClick={() => {applyScenario()}} data-tip data-for="ResetForecastFigures">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`float-left h-6 w-6 mr-3 text-white ${animationForecast === true ? "animate-spin" : ""}`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                Apply & Save Scenario Analysis
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-1-circle-fill float-right" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312V4.002Z" />
                </svg> */}
            </button>
            </div>
            

        </div>
        )
        }
            <div className="flex bg-white rounded-2xl shadow-md shadow-slate-300 m-3 p-3">
                <Line data={dataChart7c} options={barAndLineOptions} height={80} />
            </div>

            <div className="basis-full shadow-md shadow-slate-300 rounded-2xl m-3 p-3 bg-white">
                <Line data={dataChart7d} options={barAndLineOptionsScope3} height={80} />
            </div>
        </div>

        )}

        {organisationFootprint.organisationFootprintType === "Actual" && (
            <div>
            <div className="flex">
              <div className="basis-full shadow-md shadow-slate-300 rounded-2xl m-3 p-3 bg-white">
                <Bar data={dataChart5} options={horizontalBarOptions} height={70} />
              </div>
            </div>
            <div className="flex">
              <div className="basis-full shadow-md shadow-slate-300 rounded-2xl m-3 p-3 bg-white">
                <Bar data={dataChart5Scope3} options={horizontalBarOptionsScope3} height={70} />
              </div>
            </div>
          </div>
          

        )}

        <div className='flex p-2'>
          {/* <Button text="Add New Footprint Item" onClick={() => navigate(`/addOrUpdateOrganisationFootprintItemClientView/${match.params.organisationId}/${match.params.footprintId}/_add`)} /> */}
          <Button text="Clear Filters" onClick={() => clearFilters()} />
        </div>

        <div className="flex flex-col py-2">
          <div className="overflow-x-auto items-holder-8A">
            <div className="align-middle inline-block min-w-full px-3">
              <div className="shadow-md shadow-slate-300 rounded-2xl">
                {organisationFootprint.organisationFootprintType === "Actual (Baseline)" && (
                  <table className="objects-8A min-w-full divide-y divide-gray-200 ">
                    <thead><tr className="h-20"><th className="bg-bondi-blue text-white whitespace-nowrap p-5">Footprint Items</th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-bondi-blue"></th><th className="bg-eight-slate-600 text-white">Reduction Plan</th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-bondi-blue text-white">Uncertainty</th><th className="bg-eight-slate-600 text-white">Verification</th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th></tr></thead>
                    <thead className="bg-pacific-blue-50 z-20 sticky -top-1">

                      <tr >
                      <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Scope <div className="w-40"><MultiSelect
                              value={selectedItemCategory}
                              onChange={setSelectedItemCategory}
                              options={aggregateCategoriesArray(scopeItems).map((item) => {
                                return {
                                  label: item.itemCategory,
                                  value: item.itemCategory
                                }
                              })} 
                              labelledBy="Select"
                              /></div></th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Sub Category <div className="w-52"> <MultiSelect
                            styles={styles}
                            value={selectedItemSubCategory}
                            onChange={setSelectedItemSubCategory}
                            options={aggregateSubCategoriesArray(scopeItems).map((item) => {
                            return {
                                label: item.itemSubCategory,
                                value: item.itemSubCategory
                            }
                            })}
                            labelledBy="Select"
                            /></div></th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Description <div className="w-52"> <MultiSelect
                            styles={styles}
                            value={selectedDescription}
                            onChange={setSelectedDescription}
                            options={aggregateDescriptionsArray(scopeItems).map((item) => {
                            return {
                                label: item.itemDescription,
                                value: item.itemDescription
                            }
                            })}
                            labelledBy="Select"
                            /></div></th>
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consumption</th> */}
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Unit</th> */}
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> kgCO<sub>2</sub>e</th> */}
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> tCO<sub>2</sub>e</th>
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consultant Comment</th> */}
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Target %</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Target Year</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Forcast %</th>
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Annual kgCO<sub>2</sub>e Reduction</th> */}
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Forecast Year</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Measures</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Uncertainty Rating</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Submitted<ReactSelect
                              styles={styles}
                              value={selectedSubmitted}
                              onChange={setSelectedSubmitted}
                              options={aggregateSubmittedArray(scopeItems).map((item) => {
                                return {
                                  label: item.submitted === "" ? "NOT SUBMITTED" : item.submitted,
                                  value: item.submitted
                                }
                              })} /></th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Verification Status<ReactSelect
                              styles={styles}
                              value={selectedVerified}
                              onChange={setSelectedVerified}
                              options={aggregateVerifiedArray(scopeItems).map((item) => {
                                return {
                                  label: item.verified === "" ? "NOT REVIEWED" : item.verified,
                                  value: item.verified
                                }
                              })} /></th>
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-96"> Submission Comment</th> */}
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-96"> Verification Comment</th> */}
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Documents Uploaded</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                      </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-200">
                      {scopeItemsFiltered.sort((item1, item2) => Number(item1.itemCategory) < Number(item2.itemCategory) ? -1 : Number(item1.itemCategory) > Number(item2.itemCategory) ? 1 : Number(item1.itemSubCategory.slice(2,4)) < Number(item2.itemSubCategory.slice(2,4)) ? -1 : Number(item1.itemSubCategory.slice(2,4)) > Number(item2.itemSubCategory.slice(2,4)) ? 1 : item1.itemDescription < item2.itemDescription ? -1 : item1.itemDescription > item2.itemDescription ? 1 : 0).map((organisationFootprintItem) => (
                        <tr key={organisationFootprintItem.id + organisationFootprintItem.itemTag}>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.itemCategory}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"subCatTip" + organisationFootprintItem.id}><p className="w-48 2xl:w-72 truncate">{organisationFootprintItem.itemSubCategory}</p>
                            {showToolTip && <ReactTooltip id={"subCatTip" + organisationFootprintItem.id} place="top" effect="solid">
                              <div>{organisationFootprintItem.itemSubCategory === "1.1 Stationary Combustion" && <p>This category covers the direct emissions associated with stational fuel combustion across sites owned and operated by the organisation. <br></br> Stationary fuel combustion emission sources are typically associated with activities that combust solid, liquid or gaseous fuels.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "1.2 Mobile Combustion" && <p>1.2 Mobile Combustion covers the consumption of fuel from vehicles that are owned and operated by the organisation (including leased vehicles that are used on a daily basis). <br></br> The fuels used and distance travelled for the reporting year should be provided.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "1.3 Refrigerants" && <p>This category covers fugitive emissions associated with leaks across HVAC systems, chillers, refrigerators in operation across the organisation.<br></br>Most refrigerant gases contribute to global warming when leaked into the atmosphere. <br></br> The quantity of leaked gas is assumed to be equal to the amount of gas replaced in these systems by your HVAC or chiller maintenance company.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "2.1 Purchased Electricity" && <p>Scope 2 emissions cover the indirect emissions associated with electricity and other sources of energy purchased from your local utility. <br></br>These emissions are considered to be an indirect emissions source, because the emissions are a consequence of activities from your organisation, <br></br>but occur at sites controlled by your utility provider.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.1 Purchased Goods & Services" && <p>This category covers the upstream emissions associated with the extraction and production of goods and services purchased or acquired <br></br> by the reporting company across the reporting year (e.g., materials, components, key ingredients).<br></br> Where a product-level carbon footprint has been measured, please provide evidence of this footprint as per the information sent through from selected suppliers.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.2 Capital Goods" && <p>This category covers the upstream emissions associated with the extraction, production, and transportation of capital goods purchased or acquired <br></br>  by the reporting company across the reporting year. <br></br> Capital goods that are purchased during the reporting year but represent longer-term investments should be included in this category.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.3 Fuel & Energy" && <p>This category covers the upstream emissions associated with the extraction, production, and transportation of fuels and energy (gas and electricity) purchased or acquired  <br></br> by the reporting company across the reporting year.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.4 Upstream Transportation & Distribution" && <p>This category covers the emissions associated with the transportation and distribution of products purchased between a company’s tier 1 suppliers and its own operations<br></br> (in vehicles and facilities not owned or controlled by the reporting company). Data should cover the reporting year.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.5 Waste" && <p>This category covers the emissions associated with the disposal and treatment of waste, alongside the supply and treatment of water <br></br> across the reporting company's operations for the reporting year.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.6 Business Travel" && <p>This category covers the emissions associated with the transportation of employees for business-related activities during the reporting year <br></br> (in vehicles not owned or operated by the reporting company).</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.7 Employee Commuting" && <p>This category covers the emissions associated with the transportation of employees between their homes and their worksites during the reporting year <br></br> (in vehicles not owned or operated by the reporting company). </p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.8 Upstream Leased Assets" && <p>This category covers the emissions associated with the operation of assets across the organisation that were leased by the organisation during the reporting year <br></br> (e.g. leased buildings and vehicles).</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.9 Downstream Transportation & Distribution" && <p>This category covers the emissions associated with the transportation and distribution of sold products, after the point of sale <br></br> between the reporting company's operations and the end consumer across the reporting year.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.10 Processing of Sold Products" && <p></p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.11 Use of Sold Products" && <p>This category covers the emissions associated with the use of goods, services and products sold by the reporting company during the reporting year <br></br> (taking into consideration the Scope 1 and 2 emissions from end users who are using individual products). <br></br> This category includes either the direct or indirect use-phase emissions associated with sold products.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.12 End-of-Life Treatment of Sold Products" && <p>This category covers the emissions associated with the waste disposal and treatment of products sold by the reporting company <br></br> (during the reporting year), at the end of their life. </p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.13 Downstream Leased Assets" && <p>This category includes emissions from the operation of assets that are owned by the reporting company (acting as lessor) <br></br>and leased to other entities in the reporting year that are not already included in scope 1 or scope 2. <br></br> This category is applicable to lessors (i.e., companies that receive payments from lessees). <br></br>Companies that operate leased assets (i.e., lessees) should refer to category 8 (Upstream leased assets).</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.14 Franchises" && <p>Category 14 includes emissions from the operation of franchises not included in scope 1 or scope 2. <br></br>A franchise is a business operating under a license to sell or distribute another company’s goods or services within a certain location. <br></br>This category is applicable to franchisors (i.e., companies that grant licenses to other entities <br></br> to sell or distribute its goods or services in return for payments, such as royalties for the use of trademarks and other services). <br></br>Franchisors should account for emissions that occur from the operation of franchises <br></br>(i.e., the scope 1 and scope 2 emissions of franchisees) in this category.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.15 Investments" && <p>This category includes scope 3 emissions associated with the reporting company’s investments in the reporting year, not already included in scope 1 or scope 2.<br></br> This category is applicable to investors (i.e., companies that make an investment with the objective of making a profit) <br></br>and companies that provide financial services. This category also applies to investors that are not profit driven <br></br>(e.g. multilateral development banks), and the same calculation methods should be used. <br></br>Investments are categorized as a downstream scope 3 category because providing capital <br></br>or financing is a service provided by the reporting company.</p>}</div>
                            </ReactTooltip>}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}data-tip data-for={"descriptionTip" + organisationFootprintItem.id}> <p>{organisationFootprintItem.itemDescription}</p>{showToolTip && <ReactTooltip id={"descriptionTip" + organisationFootprintItem.id} place="top" effect="solid"><div>{organisationFootprintItem.itemDescription}</div></ReactTooltip>}</td>
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {internationalNumberFormat.format(organisationFootprintItem.consumption)} </td> */}
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.unit}</td> */}
                          {/* <td onClick={() => navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`)} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"openHelperToolTip"}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline mx-1 text-slate-600">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                            </svg>
                            {internationalNumberFormat.format(organisationFootprintItem.carbonEmissions.toFixed(2))}
                            <ReactTooltip id={"openHelperToolTip"} place="top" effect="solid">
                              <div>Click to Open Helper Tool and View Sub Items</div>
                            </ReactTooltip></td> */}
                          <td onClick={() => navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`)} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base text-right" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base text-right" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base text-right"} data-tip data-for={"openHelperToolTip"}>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline mx-1 text-slate-600">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                            </svg> */}
                            {internationalNumberFormat.format((organisationFootprintItem.carbonEmissions / 1000).toFixed(2))}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline mx-1 text-slate-600">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                            </svg>
                            {showToolTip && <ReactTooltip id={"openHelperToolTip"} place="top" effect="solid">
                              <div>Click to Open Helper Tool and View Sub Items</div>
                            </ReactTooltip>}</td>
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> <p className="w-48 2xl:w-72 truncate">{organisationFootprintItem.itemConsultantComment}</p></td> */}
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {organisationFootprintItem.reductionTarget}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {organisationFootprintItem.reductionTargetYear}</td> */}
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {targetReductionPercentage(priorityCategory(organisationFootprintItem.reductionControlCategory, impactCategory(organisationFootprintItem.carbonEmissions)), organisationFootprintItem.reductionTargetPercentageOverride)}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {targetReductionYear(priorityCategory(organisationFootprintItem.reductionControlCategory, impactCategory(organisationFootprintItem.carbonEmissions)))}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {Number(organisationFootprintItem.reductionProjection).toFixed(2)}</td>
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {internationalNumberFormat.format((((Number(organisationFootprintItem.reductionProjection) / 100) * organisationFootprintItem.carbonEmissions)).toFixed(2))}</td> */}
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.reductionProjectionYear}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.reductionMeasures}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{qualitativeUncertainty(GSD(organisationFootprintItem.emissionsFactorTechnologyFactor, organisationFootprintItem.emissionsFactorTimeFactor, organisationFootprintItem.emissionsFactorGeographyFactor, organisationFootprintItem.emissionsFactorCompletenessFactor, organisationFootprintItem.emissionsFactorReliabilityFactor, organisationFootprintItem.basicUncertaintyFactor))}</td>
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> <svg className={organisationFootprintItem.emissionsFactorReliabilityClassification === "Very Good" ? "inline-block fill-current text-lime" : organisationFootprintItem.emissionsFactorReliabilityClassification === "Good" ? "inline-block fill-current text-indigo-400" : organisationFootprintItem.emissionsFactorReliabilityClassification === "Poor" ? "inline-block fill-current text-red-500" : "inline-block fill-current text-amber-300"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z"/></svg><div className="inline-block pr-1">{organisationFootprintItem.emissionsFactorReliabilityClassification}</div></td> */}
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"submittedTip" + organisationFootprintItem.id}> {organisationFootprintItem.submitted !== "" ? <img className="h-10" src="/images/GreenTick.png" alt=""></img> : ""}
                          {showToolTip && <ReactTooltip id={"submittedTip" + organisationFootprintItem.id} place="top" effect="solid">
                              <div>{organisationFootprintItem.submitted}</div>
                            </ReactTooltip>}</td>
                            <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"verifiedTip" + organisationFootprintItem.id}> {organisationFootprintItem.verified === "Compliant" ? <img className="h-10" src="/images/GreenTick.png" alt=""></img>: organisationFootprintItem.verified === "Non Compliant" ? <img className="h-10" src="/images/Red-Cross.png" alt=""></img> : organisationFootprintItem.verified === "" ? "" : organisationFootprintItem.verified === "Recommendation" ? <img className="h-10" src="/images/BlueExclamation.png" alt=""></img> : <img className="h-10" src="/images/YellowExclamation.png" alt=""></img>}
                            {showToolTip &&<ReactTooltip id={"verifiedTip" + organisationFootprintItem.id} place="top" effect="solid">
                                    <div>{organisationFootprintItem.verified}</div>
                                </ReactTooltip>}</td>
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}><p className={organisationFootprintItem.commentThread.length > 0 && organisationFootprintItem.commentThread[organisationFootprintItem.commentThread.length-1].comment === organisationFootprintItem.submissionComment ? "w-80 truncate text-congress-blue font-extrabold" : "w-80 truncate"}>{organisationFootprintItem.submissionComment}</p></td> */}
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}><p className={organisationFootprintItem.commentThread.length > 0 && organisationFootprintItem.commentThread[organisationFootprintItem.commentThread.length-1].comment === organisationFootprintItem.verificationComment ? "w-80 truncate text-congress-blue font-extrabold" : "w-80 truncate"}>{organisationFootprintItem.verificationComment}</p></td> */}
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"filesUploadedTip" + organisationFootprintItem.id}> {organisationFootprintItem.filesUploaded !== 0 ? <img className="h-10" src="/images/GreenTick.png" alt=""></img> : ""}
                          {showToolTip && <ReactTooltip id={"filesUploadedTip" + organisationFootprintItem.id} place="top" effect="solid">
                              <div>{organisationFootprintItem.filesUploaded}</div>
                            </ReactTooltip>}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>
                            <Options edit={`/addOrUpdateOrganisationFootprintItemClientView/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`} ></Options>
                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                )}

                {organisationFootprint.organisationFootprintType === "Actual" && (
                  <table className="objects-8A min-w-full divide-y divide-gray-200">
                    <thead><tr className="h-20"><th className="bg-eight-slate-600 text-white whitespace-nowrap p-5">Footprint Items</th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-bondi-blue text-white">Uncertainty</th><th className="bg-eight-slate-600 text-white">Verification</th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th><th className="bg-eight-slate-600"></th></tr></thead>
                    <thead className="bg-pacific-blue-50 z-20 sticky -top-1">
                      <tr>
                      <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Scope <div className="w-40"><MultiSelect
                            value={selectedItemCategory}
                            onChange={setSelectedItemCategory}
                            options={aggregateCategoriesArray(scopeItems).map((item) => {
                            return {
                                label: item.itemCategory,
                                value: item.itemCategory
                            }
                            })} 
                            labelledBy="Select"
                            /></div></th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Sub Category <div className="w-52"><MultiSelect
                            value={selectedItemSubCategory}
                            onChange={setSelectedItemSubCategory}
                            options={aggregateSubCategoriesArray(scopeItems).map((item) => {
                            return {
                                label: item.itemSubCategory,
                                value: item.itemSubCategory
                            }
                            })} 
                            labelledBy="Select"
                            /></div></th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Description <div className="w-52"><MultiSelect
                            value={selectedDescription}
                            onChange={setSelectedDescription}
                            options={aggregateDescriptionsArray(scopeItems).map((item) => {
                            return {
                                label: item.itemDescription,
                                value: item.itemDescription
                            }
                            })} 
                            labelledBy="Select"
                            /></div></th>
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consumption</th> */}
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Unit</th> */}
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> kgCO<sub>2</sub>e</th> */}
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> tCO<sub>2</sub>e</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> No Of Months</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Additional Estimated Emissions tCO<sub>2</sub>e</th>
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consultant Comment</th> */}
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Target %</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Target Year</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Forcast %</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Annual kgCO<sub>2</sub>e Reduction</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Forecast Year</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Measures</th> */}
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Uncertainty Rating</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Submitted<ReactSelect
                              styles={styles}
                              value={selectedSubmitted}
                              onChange={setSelectedSubmitted}
                              options={aggregateSubmittedArray(scopeItems).map((item) => {
                                return {
                                  label: item.submitted === "" ? "NOT SUBMITTED" : item.submitted,
                                  value: item.submitted
                                }
                              })} /></th>
                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Verification Status<ReactSelect
                              styles={styles}
                              value={selectedVerified}
                              onChange={setSelectedVerified}
                              options={aggregateVerifiedArray(scopeItems).map((item) => {
                                return {
                                  label: item.verified === "" ? "NOT REVIEWED" : item.verified,
                                  value: item.verified
                                }
                              })} /></th>
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-96"> Submission Comment</th> */}
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-96"> Verification Comment</th> */}
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Documents Uploaded</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                      </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-200">
                      {scopeItemsFiltered.sort((item1, item2) => Number(item1.itemCategory) < Number(item2.itemCategory) ? -1 : Number(item1.itemCategory) > Number(item2.itemCategory) ? 1 : Number(item1.itemSubCategory.slice(2,4)) < Number(item2.itemSubCategory.slice(2,4)) ? -1 : Number(item1.itemSubCategory.slice(2,4)) > Number(item2.itemSubCategory.slice(2,4)) ? 1 : item1.itemDescription < item2.itemDescription ? -1 : item1.itemDescription > item2.itemDescription ? 1 : 0).map((organisationFootprintItem) => (
                        <tr key={organisationFootprintItem.id + organisationFootprintItem.itemTag}>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.itemCategory}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"subCatTip" + organisationFootprintItem.id}><p className="w-48 2xl:w-72 truncate">{organisationFootprintItem.itemSubCategory}</p>
                          {showToolTip && <ReactTooltip id={"subCatTip" + organisationFootprintItem.id} place="top" effect="solid">
                              <div>{organisationFootprintItem.itemSubCategory === "1.1 Stationary Combustion" && <p>This category covers the direct emissions associated with stational fuel combustion across sites owned and operated by the organisation. <br></br> Stationary fuel combustion emission sources are typically associated with activities that combust solid, liquid or gaseous fuels.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "1.2 Mobile Combustion" && <p>1.2 Mobile Combustion covers the consumption of fuel from vehicles that are owned and operated by the organisation (including leased vehicles that are used on a daily basis). <br></br> The fuels used and distance travelled for the reporting year should be provided.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "1.3 Refrigerants" && <p>This category covers fugitive emissions associated with leaks across HVAC systems, chillers, refrigerators in operation across the organisation.<br></br>Most refrigerant gases contribute to global warming when leaked into the atmosphere. <br></br> The quantity of leaked gas is assumed to be equal to the amount of gas replaced in these systems by your HVAC or chiller maintenance company.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "2.1 Purchased Electricity" && <p>Scope 2 emissions cover the indirect emissions associated with electricity and other sources of energy purchased from your local utility. <br></br>These emissions are considered to be an indirect emissions source, because the emissions are a consequence of activities from your organisation, <br></br>but occur at sites controlled by your utility provider.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.1 Purchased Goods & Services" && <p>This category covers the upstream emissions associated with the extraction and production of goods and services purchased or acquired <br></br> by the reporting company across the reporting year (e.g., materials, components, key ingredients).<br></br> Where a product-level carbon footprint has been measured, please provide evidence of this footprint as per the information sent through from selected suppliers.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.2 Capital Goods" && <p>This category covers the upstream emissions associated with the extraction, production, and transportation of capital goods purchased or acquired <br></br>  by the reporting company across the reporting year. <br></br> Capital goods that are purchased during the reporting year but represent longer-term investments should be included in this category.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.3 Fuel & Energy" && <p>This category covers the upstream emissions associated with the extraction, production, and transportation of fuels and energy (gas and electricity) purchased or acquired  <br></br> by the reporting company across the reporting year.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.4 Upstream Transportation & Distribution" && <p>This category covers the emissions associated with the transportation and distribution of products purchased between a company’s tier 1 suppliers and its own operations<br></br> (in vehicles and facilities not owned or controlled by the reporting company). Data should cover the reporting year.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.5 Waste" && <p>This category covers the emissions associated with the disposal and treatment of waste, alongside the supply and treatment of water <br></br> across the reporting company's operations for the reporting year.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.6 Business Travel" && <p>This category covers the emissions associated with the transportation of employees for business-related activities during the reporting year <br></br> (in vehicles not owned or operated by the reporting company).</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.7 Employee Commuting" && <p>This category covers the emissions associated with the transportation of employees between their homes and their worksites during the reporting year <br></br> (in vehicles not owned or operated by the reporting company). </p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.8 Upstream Leased Assets" && <p>This category covers the emissions associated with the operation of assets across the organisation that were leased by the organisation during the reporting year <br></br> (e.g. leased buildings and vehicles).</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.9 Downstream Transportation & Distribution" && <p>This category covers the emissions associated with the transportation and distribution of sold products, after the point of sale <br></br> between the reporting company's operations and the end consumer across the reporting year.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.10 Processing of Sold Products" && <p></p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.11 Use of Sold Products" && <p>This category covers the emissions associated with the use of goods, services and products sold by the reporting company during the reporting year <br></br> (taking into consideration the Scope 1 and 2 emissions from end users who are using individual products). <br></br> This category includes either the direct or indirect use-phase emissions associated with sold products.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.12 End-of-Life Treatment of Sold Products" && <p>This category covers the emissions associated with the waste disposal and treatment of products sold by the reporting company <br></br> (during the reporting year), at the end of their life. </p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.13 Downstream Leased Assets" && <p>This category includes emissions from the operation of assets that are owned by the reporting company (acting as lessor) <br></br>and leased to other entities in the reporting year that are not already included in scope 1 or scope 2. <br></br> This category is applicable to lessors (i.e., companies that receive payments from lessees). <br></br>Companies that operate leased assets (i.e., lessees) should refer to category 8 (Upstream leased assets).</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.14 Franchises" && <p>Category 14 includes emissions from the operation of franchises not included in scope 1 or scope 2. <br></br>A franchise is a business operating under a license to sell or distribute another company’s goods or services within a certain location. <br></br>This category is applicable to franchisors (i.e., companies that grant licenses to other entities <br></br> to sell or distribute its goods or services in return for payments, such as royalties for the use of trademarks and other services). <br></br>Franchisors should account for emissions that occur from the operation of franchises <br></br>(i.e., the scope 1 and scope 2 emissions of franchisees) in this category.</p>}</div>
                              <div>{organisationFootprintItem.itemSubCategory === "3.15 Investments" && <p>This category includes scope 3 emissions associated with the reporting company’s investments in the reporting year, not already included in scope 1 or scope 2.<br></br> This category is applicable to investors (i.e., companies that make an investment with the objective of making a profit) <br></br>and companies that provide financial services. This category also applies to investors that are not profit driven <br></br>(e.g. multilateral development banks), and the same calculation methods should be used. <br></br>Investments are categorized as a downstream scope 3 category because providing capital <br></br>or financing is a service provided by the reporting company.</p>}</div>
                            </ReactTooltip>}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"descriptionTip" + organisationFootprintItem.id}> <p>{organisationFootprintItem.itemDescription}</p>{showToolTip && <ReactTooltip id={"descriptionTip" + organisationFootprintItem.id} place="top" effect="solid"><div>{organisationFootprintItem.itemDescription}</div></ReactTooltip>}</td>
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {internationalNumberFormat.format(organisationFootprintItem.consumption)} </td> */}
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.unit}</td> */}
                          {/* <td onClick={() => navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`)} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"openHelperToolTip"}> 
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline mx-1 text-slate-600">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                          </svg>
                          {internationalNumberFormat.format(organisationFootprintItem.carbonEmissions.toFixed(2))}
                            <ReactTooltip id={"openHelperToolTip"} place="top" effect="solid">
                              <div>Click to Open Helper Tool and View Sub Items</div>
                            </ReactTooltip></td> */}
                          <td onClick={() => navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`)} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"openHelperToolTip"}> 
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline mx-1 text-slate-600">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                          </svg>
                          {internationalNumberFormat.format((organisationFootprintItem.carbonEmissions / 1000).toFixed(2))}
                          {showToolTip &&<ReactTooltip id={"openHelperToolTip"} place="top" effect="solid">
                              <div>Click to Open Helper Tool and View Sub Items</div>
                            </ReactTooltip>}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.numberOfMonths}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {internationalNumberFormat.format((organisationFootprintItem.additionalEstimatedEmissions/1000).toFixed(2))}</td>
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> <p className="w-48 2xl:w-72 truncate">{organisationFootprintItem.itemConsultantComment}</p></td> */}
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {organisationFootprintItem.reductionTarget}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {organisationFootprintItem.reductionTargetYear}</td> */}
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {targetReductionPercentage(priorityCategory(organisationFootprintItem.reductionControlCategory, impactCategory(organisationFootprintItem.carbonEmissions)))}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {targetReductionYear(priorityCategory(organisationFootprintItem.reductionControlCategory, impactCategory(organisationFootprintItem.carbonEmissions)))}</td> 
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {Number(organisationFootprintItem.reductionProjection).toFixed(2)}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {internationalNumberFormat.format((((Number(organisationFootprintItem.reductionProjection) / 100) * organisationFootprintItem.carbonEmissions)).toFixed(2))}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {organisationFootprintItem.reductionProjectionYear}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> {organisationFootprintItem.reductionMeasures}</td> */}

                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{qualitativeUncertainty(GSD(organisationFootprintItem.emissionsFactorTechnologyFactor, organisationFootprintItem.emissionsFactorTimeFactor, organisationFootprintItem.emissionsFactorGeographyFactor, organisationFootprintItem.emissionsFactorCompletenessFactor, organisationFootprintItem.emissionsFactorReliabilityFactor, organisationFootprintItem.basicUncertaintyFactor))}</td>
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base" }> <svg className={organisationFootprintItem.emissionsFactorReliabilityClassification === "Very Good" ? "inline-block fill-current text-lime" : organisationFootprintItem.emissionsFactorReliabilityClassification === "Good" ? "inline-block fill-current text-indigo-400" : organisationFootprintItem.emissionsFactorReliabilityClassification === "Poor" ? "inline-block fill-current text-red-500" : "inline-block fill-current text-amber-300"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z"/></svg><div className="inline-block pr-1">{organisationFootprintItem.emissionsFactorReliabilityClassification}</div></td> */}
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"submittedTip" + organisationFootprintItem.id}> {organisationFootprintItem.submitted !== "" ? <img className="h-10" src="/images/GreenTick.png" alt=""></img> : ""}
                          {showToolTip && <ReactTooltip id={"submittedTip" + organisationFootprintItem.id} place="top" effect="solid">
                              <div>{organisationFootprintItem.submitted}</div>
                            </ReactTooltip>}</td>
                            <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"verifiedTip" + organisationFootprintItem.id}> {organisationFootprintItem.verified === "Compliant" ? <img className="h-10" src="/images/GreenTick.png" alt=""></img>: organisationFootprintItem.verified === "Non Compliant" ? <img className="h-10" src="/images/Red-Cross.png" alt=""></img> : organisationFootprintItem.verified === "" ? "" : organisationFootprintItem.verified === "Recommendation" ? <img className="h-10" src="/images/BlueExclamation.png" alt=""></img> : <img className="h-10" src="/images/YellowExclamation.png" alt=""></img>}
                            {showToolTip && <ReactTooltip id={"verifiedTip" + organisationFootprintItem.id} place="top" effect="solid">
                                    <div>{organisationFootprintItem.verified}</div>
                                </ReactTooltip>}</td>
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}><p className={organisationFootprintItem.commentThread.length > 0 && organisationFootprintItem.commentThread[organisationFootprintItem.commentThread.length-1].comment === organisationFootprintItem.submissionComment ? "w-80 truncate text-congress-blue font-extrabold" : "w-80 truncate"}>{organisationFootprintItem.submissionComment}</p></td> */}
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}><p className={organisationFootprintItem.commentThread.length > 0 && organisationFootprintItem.commentThread[organisationFootprintItem.commentThread.length-1].comment === organisationFootprintItem.verificationComment ? "w-80 truncate text-congress-blue font-extrabold" : "w-80 truncate"}>{organisationFootprintItem.verificationComment}</p></td> */}
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"filesUploadedTip" + organisationFootprintItem.id}> {organisationFootprintItem.filesUploaded !== 0 ? <img className="h-10" src="/images/GreenTick.png" alt=""></img> : ""}
                          {showToolTip && <ReactTooltip id={"filesUploadedTip" + organisationFootprintItem.id} place="top" effect="solid">
                              <div>{organisationFootprintItem.filesUploaded}</div>
                            </ReactTooltip>}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>
                            <Options edit={`/addOrUpdateOrganisationFootprintItemClientView/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`} ></Options>
                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                )}


                {organisationFootprint.organisationFootprintType !== "Actual (Baseline)" && organisationFootprint.organisationFootprintType !== "Actual" && (
                  <table className="objects-8A min-w-full divide-y divide-gray-200">
                    <thead className="bg-pacific-blue-50 z-20 sticky -top-1">
                      <tr>
                      <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Sub Category <div className="w-40"><MultiSelect
                            styles={styles}
                            value={selectedItemSubCategory}
                            onChange={setSelectedItemSubCategory}
                            options={aggregateSubCategoriesArray(scopeItems).map((item) => {
                              return {
                                label: item.itemSubCategory,
                                value: item.itemSubCategory
                              }
                            })}
                            labelledBy="Select" 
                            /></div></th>
                          <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Description <div className="w-40"><MultiSelect
                            styles={styles}
                            value={selectedDescription}
                            onChange={setSelectedDescription}
                            options={aggregateDescriptionsArray(scopeItems).map((item) => {
                              return {
                                label: item.itemDescription,
                                value: item.itemDescription
                              }
                            })}
                            labelledBy="Select" 
                            /></div></th>
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consumption</th> */}
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Unit</th> */}
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> kgCO<sub>2</sub>e</th>
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> tCO<sub>2</sub>e</th>
                        {organisationFootprint.organisationFootprintType === "Actual" && <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> No of Months</th>}
                        {organisationFootprint.organisationFootprintType === "Actual" && <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Estimated Additional kgCO<sub>2</sub>e</th>}
                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consultant Comment</th> */}
                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                      </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-200">
                      {scopeItemsFiltered.sort((item1, item2) => Number(item1.itemCategory) < Number(item2.itemCategory) ? -1 : Number(item1.itemCategory) > Number(item2.itemCategory) ? 1 : Number(item1.itemSubCategory.slice(2,4)) < Number(item2.itemSubCategory.slice(2,4)) ? -1 : Number(item1.itemSubCategory.slice(2,4)) > Number(item2.itemSubCategory.slice(2,4)) ? 1 : item1.itemDescription < item2.itemDescription ? -1 : item1.itemDescription > item2.itemDescription ? 1 : 0).map((organisationFootprintItem) => (
                        <tr key={organisationFootprintItem.id + organisationFootprintItem.itemTag}>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.itemCategory}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.itemSubCategory}</td>
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"descriptionTip" + organisationFootprintItem.id}> <p>{organisationFootprintItem.itemDescription}</p>{showToolTip &&<ReactTooltip id={"descriptionTip" + organisationFootprintItem.id} place="top" effect="solid"><div>{organisationFootprintItem.itemDescription}</div></ReactTooltip>}</td>
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {internationalNumberFormat.format(organisationFootprintItem.consumption)} </td> */}
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.unit}</td> */}
                          <td onClick={() => navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`)} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"openHelperToolTip"}> {internationalNumberFormat.format(organisationFootprintItem.carbonEmissions.toFixed(2))}
                          {showToolTip &&<ReactTooltip id={"openHelperToolTip"} place="top" effect="solid">
                              <div>Click to Open Helper Tool and View Sub Items</div>
                            </ReactTooltip>}</td>
                          <td onClick={() => navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`)} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"openHelperToolTip"}> {internationalNumberFormat.format((organisationFootprintItem.carbonEmissions / 1000).toFixed(2))}
                          {showToolTip &&<ReactTooltip id={"openHelperToolTip"} place="top" effect="solid">
                              <div>Click to Open Helper Tool and View Sub Items</div>
                            </ReactTooltip>}</td>
                          {organisationFootprint.organisationFootprintType === "Actual" && <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.numberOfMonths}</td>}
                          {organisationFootprint.organisationFootprintType === "Actual" && <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.additionalEstimatedEmissions}</td>}
                          {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.itemConsultantComment}</td> */}
                          <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>
                            <Options edit={`/addOrUpdateOrganisationFootprintItem/${match.params.organisationId}/${match.params.footprintId}/${organisationFootprintItem.id}`} ></Options>
                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                )}
              </div>

            </div>
          </div>
        </div>
        <div className='flex p-2'>
          {/* <Button color="bondi-blue" text="Add New Footprint  Item" onClick={() => navigate(`/addOrUpdateOrganisationFootprintItemClientView/${match.params.organisationId}/${match.params.footprintId}/_add`)} /> */}
        </div>

        <div className="flex items-center">
          <div className="w-full mx-auto mb-5 px-3">
            <div className="grid gap-2 w-full sm:gap-7 sm:grid-cols-3">

              {/* <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                <div>
                  <div className="text-sm text-gray-500 ">Total Carbon Emissions (kgCO<sub>2</sub>e):  </div>
                  <div className="flex items-center pt-1">
                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{`${internationalNumberFormat.format((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}`}</p></div>
                  </div>
                </div>
                <div className="text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                  </svg>
                </div>
              </div> */}
              <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                <div>
                  <div className="text-sm text-gray-500 ">Total Carbon Emissions (tCO<sub>2</sub>e): </div>
                  <div className="flex items-center pt-1">
                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{`${internationalNumberFormat.format(((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / 1000).toFixed(2))}`}</p></div>
                  </div>
                </div>
                <div className="text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
              </div>

              <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                <div>
                  <div className="text-sm text-gray-500 ">Total Carbon Emissions (tCO<sub>2</sub>e) per Employee: </div>
                  <div className="flex items-center pt-1">
                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{`${internationalNumberFormat.format((((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / organisationFootprint.organisationNumberOfEmployees).toFixed(2)) / 1000)}`}</p></div>
                  </div>
                </div>
                <div className="text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
              </div>

              <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                <div>
                  <div className="text-sm text-gray-500 ">Total Carbon Emissions (tCO<sub>2</sub>e) per 1000 Base Currency Units of Turnover: </div>
                  <div className="flex items-center pt-1">
                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{`${internationalNumberFormat.format((((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / (organisationFootprint.organisationTurnoverGBP / 1000)).toFixed(2)) / 1000)}`}</p></div>
                  </div>
                </div>
                <div className="text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-full mx-auto mb-5 px-3">
            <div className="grid gap-2 w-full sm:gap-7 sm:grid-cols-3">

              <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                <Doughnut data={dataChart4} options={pieOptions} /></div>

              <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                <Doughnut data={dataChart3} options={pieOptionsBySubCategory} /></div>

              <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                <Doughnut data={dataChart2} options={pieOptionsByItem} /></div>

              {/* </div> */}
            </div>
          </div>
        </div>

        {(organisationFootprint.organisationFootprintType === "Actual (Baseline)" || organisationFootprint.organisationFootprintType === "Actual") && (
        <div>
            <div className="flex items-center bg-slate-100">
                <div className="w-full mx-auto mb-5 px-3">
                    <div className="grid gap-2 sm:gap-7 sm:grid-cols-2 md:grid-cols-4">

                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                <div>
                                    <div className="text-sm text-gray-500 ">Quantitative Uncertainty</div>
                                    <div className="flex items-center pt-1">
                                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{(((Math.exp(((scopeItemsFilteredWithGSD.map(item => item.GSDValue).reduce((prev, curr) => Number(prev) + Number(curr), 0))) ** 0.5)) ** 2) * 100).toFixed(0)}%</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                </div>

                            </div>

                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Qualitative Uncertainty</div>
                                    <div className="flex items-center pt-1">
                                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{scopeItemsFilteredWithGSD.map(item => item.ratingResultsBasedOnImpact).reduce((prev, curr) => Number(prev) + Number(curr), 0) <= 1 ? "Very Good" : scopeItemsFilteredWithGSD.map(item => item.ratingResultsBasedOnImpact).reduce((prev, curr) => Number(prev) + Number(curr), 0) <= 2 ? "Good" : scopeItemsFilteredWithGSD.map(item => item.ratingResultsBasedOnImpact).reduce((prev, curr) => Number(prev) + Number(curr), 0) <= 4 ? "Fair" : "Poor"}</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                </div>
                            </div>

                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                            <div>
                                <div className="text-sm text-gray-500 ">95% Lower bound: </div>
                                <div className="flex items-center pt-1">
                                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{(((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / ((Math.exp(((scopeItemsFilteredWithGSD.map(item => item.GSDValue).reduce((prev, curr) => Number(prev) + Number(curr), 0))) ** 0.5)) ** 2)) / 1000).toFixed(2)}</p></div>
                                </div>
                                </div>
                                <div className="text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                </div>
                            </div>

                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                            <div>
                                <div className="text-sm text-gray-500 ">95% Upper bound: </div>
                                <div className="flex items-center pt-1">
                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{(((scopeItemsFiltered.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * ((Math.exp(((scopeItemsFilteredWithGSD.map(item => item.GSDValue).reduce((prev, curr) => Number(prev) + Number(curr), 0))) ** 0.5)) ** 2)) / 1000).toFixed(2)}</p></div>
                                </div>
                                </div>
                                <div className="text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                </div>
                            </div>

                    </div>
                </div>
            </div>

            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
            <Bar data={dataChart5AllScopes} options={horizontalBarOptionsAllScopes} height={70} />
            </div>
        </div>

    )}

      </div>
    )

  } else {

    return (
      <div>
        {/* <Header title="Organisation Footprint Items" section="" showLogo={false}/> */}
        <p>Footprint Items Loading...</p>
        {/* <Button color="black" text="Add New Footprint Item" onClick={() => navigate(`/addOrUpdateOrganisationFootprintItemClientView/${match.params.organisationId}/${match.params.footprintId}/_add`)} /> */}
      </div>)

  }

}

export default ListOrganisationFootprintItems

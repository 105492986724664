import { useEffect, useState } from 'react'
import { useRef } from "react";
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import Sidebar from '../UI/Sidebar'
import ReactSelect from 'react-select'
import { ExportToCsv } from 'export-to-csv'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import { useNavigate } from 'react-router-dom'
import AuthService from '../../services/AuthService'
import { icon } from 'leaflet'
import { v4 as uuidv4 } from 'uuid'

const ProjectsTrackerIndividual = () => {

    const [showModal, setShowModal] = useState(false)

    const [invoicesBySvcMgr, setInvoicesBySvcMgr] = useState([])
    const [invoicesByProjMgr, setInvoicesByProjMgr] = useState([])
    const [invoicesAll, setInvoicesAll] = useState([])

    const [invoices, setInvoices] = useState([])
    const [invoicesFiltered, setInvoicesFiltered] = useState([])
    const [invoicesFilteredProject, setInvoicesFilteredProject] = useState([])

    const [users, setUsers] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)
    const [usersLoading, setUsersLoading] = useState(false)

    const [selectedManager, setSelectedManager] = useState('All')
    const [selectedProjectNumber, setSelectedProjectNumber] = useState('All')
    const [selectedInvoiceYear, setSelectedInvoiceYear] = useState('All')
    const [selectedDepartment, setSelectedDepartment] = useState('All')
    const [fromInvoiceDate, setFromInvoiceDate] = useState('')
    const [toInvoiceDate, setToInvoiceDate] = useState('')

    const serviceWindow = useRef(null);
    const projectWindow = useRef(null);

    const [sortedFieldSvc, setSortedFieldSvc] = useState(null);
    const [sortedOrderSvc, setSortedOrderSvc] = useState(null);
    const [sortedFieldPro, setSortedFieldPro] = useState(null);
    const [sortedOrderPro, setSortedOrderPro] = useState(null);

    const [siteArea, setSiteArea] = useState([])
    const [yearOptions, setYearOptions ]= useState([])
  
    const internationalNumberFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

    const navigate = useNavigate()

    const currentYear = date => {

        const dateConverted = new Date(date)
        const yyyy = dateConverted.getFullYear();
        const formattedYear = yyyy

        return formattedYear
    }

    //const yearOptions = () => {
    //     { value: 2022, label: '2022-2023' },
    //     { value: 2023, label: '2023-2024' },
    //     { value: 2024, label: '2024-2025' },
    //     { value: 2025, label: '2025-2026' },
    //     { value: 2026, label: '2026-2027' },
    //     { value: 2027, label: '2027-2028' },
    //     { value: 2028, label: '2028-2029' },
    //     { value: 2029, label: '2029-2030' },
    //     { value: 2030, label: '2030-2031' }
    //}

    const onMenuOpen = () => {
    setTimeout(()=>{
        const selectedEl = document.getElementsByClassName("MyDropdown__option--is-selected")[0];
        if(selectedEl){
        selectedEl.scrollIntoView({behavior:'smooth', block:'nearest'});
        }
    },15);
    };

    const viewProject = (id) => {
        window.open(`/viewClientProject/${id}`);
    }

    function stringToDate(_date,_format,_delimiter)
    {
        var formatLowerCase=_format.toLowerCase();
        var formatItems=formatLowerCase.split(_delimiter);
        var dateItems=_date.split(_delimiter);
        var monthIndex=formatItems.indexOf("mm");
        var dayIndex=formatItems.indexOf("dd");
        var yearIndex=formatItems.indexOf("yyyy");
        var month=parseInt(dateItems[monthIndex]);
        month-=1;
        var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
        return formatedDate;
    }

    const arrayToCsv = (data) => {

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Individual Tracker CSV',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data)

    }

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
    }

    const clearFilters = () => {

        setSelectedManager('All')
        setSelectedProjectNumber('All')
        setSelectedDepartment('All')
        setSortedFieldSvc('projectNumber')
        setSortedOrderSvc('asc')
        setSortedFieldPro('manager')
        setSortedOrderPro('asc')

        const dateStr2 = currentYear(Date()) + "-04-01";

        const date1 = new Date();
        const date2 = new Date(dateStr2);

        if (date1.getTime() >= date2.getTime()) {
            //set the current financial year to the one starting this April
            setSelectedInvoiceYear({ value: currentYear(Date()), label: currentYear(Date()) + "-" + Number(currentYear(Date())+1) })
            setFromInvoiceDate(currentYear(Date()) + "-04-01")
            setToInvoiceDate(currentYear(Date())+1 + "-03-31")
            //console.log("After April")
            //console.log(date1 + " vs " + date2)
        } else{
            //set the current financial year to the one starting last April
            setSelectedInvoiceYear({ value: Number(currentYear(Date())-1), label: Number(currentYear(Date())-1) + "-" + currentYear(Date()) })
            setFromInvoiceDate(currentYear(Date()) + "-04-01")
            setToInvoiceDate(currentYear(Date())+1 + "-03-31")
            //console.log("Before April")
            //console.log(date1 + " vs " + date2)
        }

    }

    const aggregateDepartmentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.department === val.department);
            if (index === -1) {
                acc.push({
                    department: val.department,
                });
            };
            return acc.sort((item1, item2) => item1.department < item2.department ? -1 : 0)
        }, []);
    };

    const aggregateProjectNumbersArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectNumber === val.projectNumber);
            if (index === -1) {
                acc.push({
                    projectNumber: val.projectNumber,
                });
            };
            return acc.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)
        }, []);
    };

    const sameAsPreviousRecord = e => {

        if (e>0) {
            if (invoicesAll[e].projectNumber === invoicesAll[e-1].projectNumber) {
                return true
            }

            else return false
        } else return true
        
    };

    const populateYears = () => {

        const tempArray = []
        const tempArray2 = []

        invoices.forEach(item => {

            if (!tempArray.includes( Number(item.invoiceDate.substring(0, 4)))) {
                tempArray.push( Number(item.invoiceDate.substring(0, 4)))
                let nextYear = parseInt(item.invoiceDate.substring(0, 4))+1
                tempArray2.push( { value: Number(item.invoiceDate.substring(0, 4)), label: Number(item.invoiceDate.substring(0, 4)) + "-" + nextYear} )
            }
            
        })     
        setYearOptions(tempArray2.sort((item1, item2) => item1.value < item2.value ? -1 : item1.value > item2.value ? 1 : 0))

    }

    useEffect(() => {

        setFromInvoiceDate(selectedInvoiceYear.value + "-04-01")
        setToInvoiceDate(parseInt(selectedInvoiceYear.value) + 1 + "-03-31")

    }, [selectedInvoiceYear])

    useEffect(() => {

        if (invoicesBySvcMgr.length > 0 && invoicesByProjMgr.length > 0) {

            setInvoicesAll(invoicesBySvcMgr.concat(invoicesByProjMgr).sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.projectManager < item2.projectManager ? -1 :item1.projectManager > item2.projectManager ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : 0))

        } else if (invoicesBySvcMgr.length > 0 && invoicesByProjMgr.length === 0) {

            setInvoicesAll(invoicesBySvcMgr.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.projectManager < item2.projectManager ? -1 :item1.projectManager > item2.projectManager ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : 0))

        } else if (invoicesByProjMgr.length > 0 && invoicesBySvcMgr.length === 0) {

            setInvoicesAll(invoicesByProjMgr.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.projectManager < item2.projectManager ? -1 :item1.projectManager > item2.projectManager ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : 0))

        } else {

            setInvoicesAll([])

        }

    }, [invoicesBySvcMgr, invoicesByProjMgr])

    useEffect(() => {

            let sortedDataS = [...invoicesAll];

            if (sortedFieldSvc==="manager" && sortedOrderSvc==="asc") {
                setInvoicesAll(sortedDataS.sort((item1, item2) => item1.managerFirstName < item2.managerFirstName ? -1 : item1.managerFirstName > item2.managerFirstName ? 1 : Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : item1.service < item2.service ? -1 :item1.service > item2.service ? 1 :0))
            }else if (sortedFieldSvc==="manager" && sortedOrderSvc==="desc") {
                setInvoicesAll(sortedDataS.sort((item1, item2) => item1.managerFirstName < item2.managerFirstName ? 1 : item1.managerFirstName > item2.managerFirstName ? -1 : Number(item1.projectNumber) < Number(item2.projectNumber) ? 1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? -1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : item1.service < item2.service ? -1 :item1.service > item2.service ? 1 :0))
            }else if (sortedFieldSvc==="projectNumber" && sortedOrderSvc==="asc") {
                setInvoicesAll(sortedDataS.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : item1.service < item2.service ? -1 :item1.service > item2.service ? 1 :0))
            }else if (sortedFieldSvc==="projectNumber" && sortedOrderSvc==="desc") {
                setInvoicesAll(sortedDataS.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? 1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? -1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : item1.service < item2.service ? -1 :item1.service > item2.service ? 1 :0))
            }

    }, [sortedFieldSvc, sortedOrderSvc])

    useEffect(() => {

        setLoading(true)
        setUsersLoading(true)

        // console.log("useEffect")

        axios.get(process.env.REACT_APP_API_URL + '/invoices_report')
            .then((res) => {

                setInvoices(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.invoiceLineStatus !== "Third Party Fee" && item.invoiceLineStatus !== "Sub-contractor Fee" && item.invoiceLineStatus !== "Project Expenses").sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 :item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 :item1.service > item2.service ? 1 :0))
                setInvoicesFiltered(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.invoiceLineStatus !== "Third Party Fee" && item.invoiceLineStatus !== "Sub-contractor Fee" && item.invoiceLineStatus !== "Project Expenses").sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 :item1.service > item2.service ? 1 :0))
                setInvoicesFilteredProject(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.invoiceLineStatus !== "Third Party Fee" && item.invoiceLineStatus !== "Sub-contractor Fee" && item.invoiceLineStatus !== "Project Expenses").sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.projectManager < item2.projectManager ? -1 :item1.projectManager > item2.projectManager ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 :  item1.service < item2.service ? -1 :item1.service > item2.service ? 1 :0))

            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
                //populateYears()
            });

        axios.get(process.env.REACT_APP_API_URL + '/users')
            .then((res) => {
                setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

            const dateStr2 = currentYear(Date()) + "-04-01";

            const date1 = new Date();
            const date2 = new Date(dateStr2);

            if (date1.getTime() >= date2.getTime()) {
                //set the current financial year to the one starting this April
                setSelectedInvoiceYear({ value: currentYear(Date()), label: currentYear(Date()) + "-" + Number(currentYear(Date())+1) })
                setFromInvoiceDate(currentYear(Date()) + "-04-01")
                setToInvoiceDate(currentYear(Date())+1 + "-03-31")
                //console.log("After April")
                //console.log(date1 + " vs " + date2)
            } else{
                //set the current financial year to the one starting last April
                setSelectedInvoiceYear({ value: Number(currentYear(Date())-1), label: Number(currentYear(Date())-1) + "-" + currentYear(Date()) })
                setFromInvoiceDate(currentYear(Date()) + "-04-01")
                setToInvoiceDate(currentYear(Date())+1 + "-03-31")
                //console.log("Before April")
                //console.log(date1 + " vs " + date2)
            }

    }, [])

    useEffect(() => {

        setInvoicesFiltered(invoices)
        if (selectedManager !== "All") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((item) => item.serviceManager === selectedManager.value))
        }
        if (selectedProjectNumber !== "All") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((item) => item.projectNumber === selectedProjectNumber.value))
        }
        if (fromInvoiceDate !== "") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((item) => stringToDate(item.invoiceDate,"YYYY-MM-DD","-") >= stringToDate(fromInvoiceDate,"YYYY-MM-DD","-")))
        }
        if (toInvoiceDate !== "") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((item) => stringToDate(item.invoiceDate,"YYYY-MM-DD","-") <= stringToDate(toInvoiceDate,"YYYY-MM-DD","-")))
        }
        if (selectedDepartment !== "All") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((item) => item.department === selectedDepartment.value))
        }

        setInvoicesFilteredProject(invoices.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.projectManager < item2.projectManager ? -1 :item1.projectManager > item2.projectManager ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 :  item1.service < item2.service ? -1 :item1.service > item2.service ? 1 :0))
        if (selectedManager !== "All") {
            setInvoicesFilteredProject(invoicesFilteredProject => invoicesFilteredProject.filter((item) => item.projectManagerId === selectedManager.value))
        }
        if (selectedProjectNumber !== "All") {
            setInvoicesFilteredProject(invoicesFilteredProject => invoicesFilteredProject.filter((item) => item.projectNumber === selectedProjectNumber.value))
        }
        if (fromInvoiceDate !== "") {
            setInvoicesFilteredProject(invoicesFilteredProject => invoicesFilteredProject.filter((item) => stringToDate(item.invoiceDate,"YYYY-MM-DD","-") >= stringToDate(fromInvoiceDate,"YYYY-MM-DD","-")))
        }
        if (toInvoiceDate !== "") {
            setInvoicesFilteredProject(invoicesFilteredProject => invoicesFilteredProject.filter((item) => stringToDate(item.invoiceDate,"YYYY-MM-DD","-") <= stringToDate(toInvoiceDate,"YYYY-MM-DD","-")))
        }
        if (selectedDepartment !== "All") {
            setInvoicesFilteredProject(invoicesFilteredProject => invoicesFilteredProject.filter((item) => item.department === selectedDepartment.value))
        }

        setSortedFieldSvc('projectNumber')
        setSortedOrderSvc('asc')        
        setSortedFieldPro('manager')
        setSortedOrderPro('asc')

        populateYears()

    }, [selectedManager, selectedDepartment, fromInvoiceDate, toInvoiceDate, invoices, selectedProjectNumber])

    useEffect(() => {

        //ServiceManager Calc
        let dummyArray = []
        let varUniqueKey="", varProjectNo="", varProjectName="", varManagerID="", varCurrency="", varProjectID="", varDepartment="", varManagerLastname="", varManagerFirstName="", varProjectProgrammeProvided=""
        let varInvTotal=0, varAprTotal=0, varMayTotal=0, varJunTotal=0, varJulTotal=0, varAugTotal=0, varSepTotal=0, varOctTotal=0, varNovTotal=0, varDecTotal=0, varJanTotal=0, varFebTotal=0, varMarTotal=0
        let iCount = 0

        const varDummyArray = invoicesFiltered.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.serviceManager < item2.serviceManager ? -1 :item1.serviceManager > item2.serviceManager ? 1 : 0)

        varDummyArray.forEach(item => {

            let varDummySvcMgr = item.serviceManager
            let varDummyDepartment = item.department

            // if (iCount !== 0 && varProjectNo !== item.projectNumber){
            if (iCount !== 0 && (varProjectNo !== item.projectNumber || varManagerID !== varDummySvcMgr || varDepartment !== varDummyDepartment)){
                //push to the array
                dummyArray.push({'uniqueKey' : varUniqueKey, 'projectID' : varProjectID, 'projectNumber' : varProjectNo, 'projectName' : varProjectName, 'department' : varDepartment, 'projectCurrency' : varCurrency, 'serviceManager' : varManagerID, 'invoiceTotal' : varInvTotal, 'aprTotal' : varAprTotal, 'mayTotal' : varMayTotal, 'junTotal' : varJunTotal, 'julTotal' : varJulTotal, 'augTotal' : varAugTotal, 'sepTotal' : varSepTotal, 'octTotal' : varOctTotal, 'novTotal' : varNovTotal, 'decTotal' : varDecTotal, 'janTotal' : varJanTotal, 'febTotal' : varFebTotal, 'marTotal' : varMarTotal, 'managerLastName' : varManagerLastname, 'managerFirstName' : varManagerFirstName, 'projectProgrammeProvided' : varProjectProgrammeProvided})
            
                //reset the totals
                varInvTotal=0
                varAprTotal=0
                varMayTotal=0
                varJunTotal=0
                varJulTotal=0
                varAugTotal=0
                varSepTotal=0
                varOctTotal=0
                varNovTotal=0
                varDecTotal=0
                varJanTotal=0
                varFebTotal=0
                varMarTotal=0
            }

            //add the inv total and assign it to the correct month bucket
            varInvTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            if (item.invoiceMonth.slice(-2)==="04"){
                varAprTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            }else if (item.invoiceMonth.slice(-2)==="05"){
                varMayTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            }else if (item.invoiceMonth.slice(-2)==="06"){
                varJunTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            }else if (item.invoiceMonth.slice(-2)==="07"){
                varJulTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            }else if (item.invoiceMonth.slice(-2)==="08"){
                varAugTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            }else if (item.invoiceMonth.slice(-2)==="09"){
                varSepTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            }else if (item.invoiceMonth.slice(-2)==="10"){
                varOctTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            }else if (item.invoiceMonth.slice(-2)==="11"){
                varNovTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            }else if (item.invoiceMonth.slice(-2)==="12"){
                varDecTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            }else if (item.invoiceMonth.slice(-2)==="01"){
                varJanTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            }else if (item.invoiceMonth.slice(-2)==="02"){
                varFebTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            }else if (item.invoiceMonth.slice(-2)==="03"){
                varMarTotal += (Number((item.invoiceLineValue) * (1-(Number(item.projectProgrammeProvided)/100))))
            }

            //update the projectID
            varUniqueKey = uuidv4()
            varProjectID = item.projectId
            varProjectNo = item.projectNumber
            varProjectName = item.projectName
            varDepartment = item.department
            varCurrency = item.projectCurrency
            varManagerID = item.serviceManager
            varProjectProgrammeProvided = item.projectProgrammeProvided
            varManagerLastname = users.find(user => user.id === item.serviceManager).lastName
            varManagerFirstName = users.find(user => user.id === item.serviceManager).firstName
            iCount++

            if (iCount === invoicesFiltered.length) {
                dummyArray.push({'uniqueKey' : varUniqueKey, 'projectID' : varProjectID, 'projectNumber' : varProjectNo, 'projectName' : varProjectName, 'department' : varDepartment, 'projectCurrency' : varCurrency, 'serviceManager' : varManagerID, 'invoiceTotal' : varInvTotal, 'aprTotal' : varAprTotal, 'mayTotal' : varMayTotal, 'junTotal' : varJunTotal, 'julTotal' : varJulTotal, 'augTotal' : varAugTotal, 'sepTotal' : varSepTotal, 'octTotal' : varOctTotal, 'novTotal' : varNovTotal, 'decTotal' : varDecTotal, 'janTotal' : varJanTotal, 'febTotal' : varFebTotal, 'marTotal' : varMarTotal, 'managerLastName' : varManagerLastname, 'managerFirstName' : varManagerFirstName, 'projectProgrammeProvided' : varProjectProgrammeProvided})
            }

        })

         setInvoicesBySvcMgr(dummyArray.sort((item1, item2) => item1.managerLastName < item2.managerLastName ? -1 : item1.managerLastName > item2.managerLastName ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.service < item2.service ? -1 :item1.service > item2.service ? 1 :0))

    }, [invoicesFiltered])

    useEffect(() => {

        //ProjectManager Calc
        let dummyArray = []
        let varUniqueKey="", varProjectNo="", varProjectName="", varManagerID="", varCurrency="", varProjectID="", varDepartment="", varManagerLastname="", varManagerFirstName="", varProjectProgrammeProvided=""
        let varInvTotal=0, varAprTotal=0, varMayTotal=0, varJunTotal=0, varJulTotal=0, varAugTotal=0, varSepTotal=0, varOctTotal=0, varNovTotal=0, varDecTotal=0, varJanTotal=0, varFebTotal=0, varMarTotal=0
        let iCount = 0

        // invoicesFilteredProject.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.projectManager < item2.projectManager ? -1 :item1.projectManager > item2.projectManager ? 1 : 0).forEach(item => {
        const varDummyArray = invoicesFilteredProject.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.projectManager < item2.projectManager ? -1 :item1.projectManager > item2.projectManager ? 1 : 0)
        // console.log(varDummyArray)
        varDummyArray.forEach(item => {

            let varDummyProjMgr = item.projectManagerId
            let varDummyDepartment = item.department

            // if ((iCount !== 0 && varProjectNo !== item.projectNumber) || (iCount !== 0 && varProjectNo === item.projectNumber && varManagerID !== varDummyProjMgr)){
            if (iCount !== 0 && (varProjectNo !== item.projectNumber || varManagerID !== varDummyProjMgr || varDepartment !== varDummyDepartment)){

                //push to the array
                dummyArray.push({'uniqueKey' : varUniqueKey, 'projectID' : varProjectID, 'projectNumber' : varProjectNo, 'projectName' : varProjectName, 'department' : varDepartment, 'projectCurrency' : varCurrency, 'projectManager' : varManagerID, 'invoiceTotal' : varInvTotal, 'aprTotal' : varAprTotal, 'mayTotal' : varMayTotal, 'junTotal' : varJunTotal, 'julTotal' : varJulTotal, 'augTotal' : varAugTotal, 'sepTotal' : varSepTotal, 'octTotal' : varOctTotal, 'novTotal' : varNovTotal, 'decTotal' : varDecTotal, 'janTotal' : varJanTotal, 'febTotal' : varFebTotal, 'marTotal' : varMarTotal, 'managerLastName' : varManagerLastname, 'managerFirstName' : varManagerFirstName, 'projectProgrammeProvided' : varProjectProgrammeProvided})
            
                //reset the totals
                varInvTotal=0
                varAprTotal=0
                varMayTotal=0
                varJunTotal=0
                varJulTotal=0
                varAugTotal=0
                varSepTotal=0
                varOctTotal=0
                varNovTotal=0
                varDecTotal=0
                varJanTotal=0
                varFebTotal=0
                varMarTotal=0
            }

            //add the inv total and assign it to the correct month bucket
            varInvTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            if (item.invoiceMonth.slice(-2)==="04"){
                varAprTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            }else if (item.invoiceMonth.slice(-2)==="05"){
                varMayTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            }else if (item.invoiceMonth.slice(-2)==="06"){
                varJunTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            }else if (item.invoiceMonth.slice(-2)==="07"){
                varJulTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            }else if (item.invoiceMonth.slice(-2)==="08"){
                varAugTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            }else if (item.invoiceMonth.slice(-2)==="09"){
                varSepTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            }else if (item.invoiceMonth.slice(-2)==="10"){
                varOctTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            }else if (item.invoiceMonth.slice(-2)==="11"){
                varNovTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            }else if (item.invoiceMonth.slice(-2)==="12"){
                varDecTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            }else if (item.invoiceMonth.slice(-2)==="01"){
                varJanTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            }else if (item.invoiceMonth.slice(-2)==="02"){
                varFebTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            }else if (item.invoiceMonth.slice(-2)==="03"){
                varMarTotal += (Number((item.invoiceLineValue) * (Number(item.projectProgrammeProvided)/100)))
            }

            //update the projectID
            varUniqueKey = uuidv4()
            varProjectID = item.projectId
            varProjectNo = item.projectNumber
            varProjectName = item.projectName
            varDepartment = item.department
            varCurrency = item.projectCurrency
            varManagerID = item.projectManagerId
            varProjectProgrammeProvided = item.projectProgrammeProvided
            varManagerLastname = users.find(user => user.id === item.projectManagerId).lastName
            varManagerFirstName = users.find(user => user.id === item.projectManagerId).firstName
            iCount++

            // if (varProjectNo.substring(0,4)==="7860"){
            //     console.log(item.projectManagerId)
            //     console.log(varManagerLastname)
            // }

            //insert the last row from the dataset
            // if (iCount === invoicesFilteredProject.length) {
            if (iCount === varDummyArray.length) {                
                dummyArray.push({'uniqueKey' : varUniqueKey, 'projectID' : varProjectID, 'projectNumber' : varProjectNo, 'projectName' : varProjectName, 'department' : varDepartment, 'projectCurrency' : varCurrency, 'projectManager' : varManagerID, 'invoiceTotal' : varInvTotal, 'aprTotal' : varAprTotal, 'mayTotal' : varMayTotal, 'junTotal' : varJunTotal, 'julTotal' : varJulTotal, 'augTotal' : varAugTotal, 'sepTotal' : varSepTotal, 'octTotal' : varOctTotal, 'novTotal' : varNovTotal, 'decTotal' : varDecTotal, 'janTotal' : varJanTotal, 'febTotal' : varFebTotal, 'marTotal' : varMarTotal, 'managerLastName' : varManagerLastname, 'managerFirstName' : varManagerFirstName, 'projectProgrammeProvided' : varProjectProgrammeProvided})
            }

        })

        //console.log(dummyArray.sort((item1, item2) => item1.managerLastName < item2.managerLastName ? -1 : item1.managerLastName > item2.managerLastName ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.service < item2.service ? -1 :item1.service > item2.service ? 1 :0))
        setInvoicesByProjMgr(dummyArray.sort((item1, item2) => item1.managerLastName < item2.managerLastName ? -1 : item1.managerLastName > item2.managerLastName ? 1 : item1.department < item2.department ? -1 :item1.department > item2.department ? 1 : Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.service < item2.service ? -1 :item1.service > item2.service ? 1 :0))

    }, [invoicesFilteredProject])


    if (invoices.length > 0 && users.length > 0 && !loading && !usersLoading) {

        return (

            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                            <Header title="Projects" section="Individual Tracker" showUserMenu='true' />

                            <hr></hr>
                            <br></br>


                            <div className="grid sm:grid-cols-2 lg:grid-cols-8 grid-rows-1">
                                <div className="pl-3">
                                    <p className="text-xs font-bold">Filters</p>
                                    <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                                </div>
                                <div className="pb-1">
                                    <p className="text-xs">Financial Year</p>
                                    <ReactSelect
                                        className ={"MyDropdown"}
                                        classNamePrefix={"MyDropdown"}
                                        styles={styles}
                                        onMenuOpen={onMenuOpen}
                                        value={selectedInvoiceYear}
                                        onChange={setSelectedInvoiceYear}
                                        options={yearOptions}
                                        />
                                </div>
                                <div>
                                    <p className="text-xs">Member of Staff</p>
                                    <ReactSelect
                                        styles={styles}
                                        value={selectedManager}
                                        onChange={setSelectedManager}
                                        options={users.map((item) => {
                                            return {
                                                label: item.firstName + ' ' + item.lastName,
                                                value: item.id
                                            }
                                        })} />
                                </div>
                                <div>
                                    <p className="text-xs">Department</p>
                                    <ReactSelect
                                        styles={styles}
                                        value={selectedDepartment}
                                        onChange={setSelectedDepartment}
                                        options={aggregateDepartmentsArray(invoicesAll).map((item) => {
                                            return {
                                                label: item.department,
                                                value: item.department
                                            }
                                        })} />
                                </div>
                                <div>
                                    <p className="text-xs">Project Number</p>
                                    <ReactSelect
                                        styles={styles}
                                        value={selectedProjectNumber}
                                        onChange={setSelectedProjectNumber}
                                        options={aggregateProjectNumbersArray(invoicesAll).map((item) => {
                                            return {
                                                label: item.projectNumber,
                                                value: item.projectNumber
                                            }
                                        })} />
                                </div>
                                {/* <div>
                                    <p className="text-xs">Department</p>
                                    <ReactSelect
                                        styles={styles}
                                        value={selectedDepartment}
                                        onChange={setSelectedDepartment}
                                        options={aggregateDepartmentsArray(invoices).map((item) => {
                                            return {
                                                label: item.department,
                                                value: item.department
                                            }
                                        })} />
                                </div> */}
                                <div >
                                <p className="text-xs">From Invoice Date</p>
                                    <input className="text-xs border-2 p-2 w-40" type="date" placeholder="Enter Invoice Date Here" value={fromInvoiceDate} onChange={(e) => setFromInvoiceDate(e.target.value)} disabled />
                                </div>
                                <div>
                                <p className="text-xs">To Invoice Date</p>
                                    <input className="text-xs border-2 p-2 w-40" type="date" placeholder="Enter Invoice Date Here" value={toInvoiceDate} onChange={(e) => setToInvoiceDate(e.target.value)} disabled />
                                </div>
                            </div>

                            <br></br>

                            <br></br>

                            <div className="flex flex-col">
                                <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8" ref={serviceWindow}>
                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow overflow-hidden border-jungle-mist-100 sm:rounded border-2">
                                            <table className="objects-8A min-w-full divide-y divide-gray-200 table-fixed">

                                                <thead className="bg-pacific-blue-50">
                                                    <tr>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-80"> Department</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-28"> <button className="uppercase" type="button" onClick={() => {setSortedFieldSvc('projectNumber'); sortedOrderSvc==='asc' ? setSortedOrderSvc('desc') : setSortedOrderSvc('asc')}}>{sortedFieldSvc ==='projectNumber' && sortedOrderSvc==='asc' ? 'Project Number ↑' : sortedFieldSvc==='projectNumber' && sortedOrderSvc==='desc' ? 'Project Number ↓' : 'Project Number ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-80"> Project Name</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-52"> <button className="uppercase" type="button" onClick={() => {setSortedFieldSvc('manager'); sortedOrderSvc==='asc' ? setSortedOrderSvc('desc') : setSortedOrderSvc('asc')}}>{sortedFieldSvc ==='manager' && sortedOrderSvc==='asc' ? 'Manager ↑' : sortedFieldSvc==='manager' && sortedOrderSvc==='desc' ? 'Manager ↓' : 'Manager ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-52"> Management</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-40"> Fee Apportionment</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-32"> April</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-32"> May</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-32"> June</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-32"> July</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-32"> August</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-32"> September</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-32"> October</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-32"> November</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-32"> December</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-32"> January</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-32"> February</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-32"> March</th>
                                                    </tr>
                                                </thead>

                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {invoicesAll.map((invoice, i) => (
                                                        <tr key={invoice.uniqueKey}>
                                                            {/* <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 min-w-[320px]" : "px-4 md:px-6 py-2 min-w-[320px] border-b-lime"}>{invoice.department}</td> */}
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 min-w-[320px]" : "px-4 md:px-6 py-2 min-w-[320px] border-t-4 "}>{invoice.department}</td>
                                                            <td onClick={() => viewProject(invoice.projectID)} className={sameAsPreviousRecord(i) ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-t-4 "}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"/>
                                                                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"/>
                                                                </svg> {invoice.projectNumber}</td>
                                                            {/*<td className="px-4 md:px-6 py-2 whitespace-nowrap">{invoice.projectNumber}</td> */}
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 w-80 truncate" : "px-4 md:px-6 py-2 border-t-4 w-80 truncate"}>{invoice.projectName}</td>
                                                            {/* <td className="px-4 md:px-6 py-2 whitespace-nowrap">{users.find(user => user.id === invoice.serviceManager).firstName + ' ' + users.find(user => user.id === invoice.serviceManager).lastName}</td> */}
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.managerFirstName + ' ' + invoice.managerLastName}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectManager ? "Project Manager" : "Service Manager"}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5" : "px-4 md:px-6 py-2 max-w-xs w-5 border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.invoiceTotal).toFixed(2))}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.aprTotal).toFixed(2))}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.mayTotal).toFixed(2))}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.junTotal).toFixed(2))}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.julTotal).toFixed(2))}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.augTotal).toFixed(2))}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.sepTotal).toFixed(2))}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.octTotal).toFixed(2))}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.novTotal).toFixed(2))}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.decTotal).toFixed(2))}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.janTotal).toFixed(2))}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.febTotal).toFixed(2))}</td>
                                                            <td className={sameAsPreviousRecord(i) ? "px-4 md:px-6 py-2 max-w-xs w-5 " : "px-4 md:px-6 py-2 max-w-xs w-5  border-t-4 "}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.marTotal).toFixed(2))}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                                <tfoot>
                                                <tr className="bg-pacific-blue-50">
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service Management Fee Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> April Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> May Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> June Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> July Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> August Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> September Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> October Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> November Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> December Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> January Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> February Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> March Total</th> 
                                                    </tr>
                                                    <tr>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">Service Management Fee Total</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.invoiceTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.aprTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.mayTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.junTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.julTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.augTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.sepTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.octTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.novTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.decTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.janTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.febTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.marTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">Service Management Fee Total</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.invoiceTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.aprTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.mayTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.junTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.julTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.augTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.sepTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.octTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.novTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.decTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.janTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.febTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.marTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                    </tr>
                                                    <tr className="bg-pacific-blue-50">
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Management Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> April Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> May Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> June Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> July Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> August Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> September Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> October Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> November Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> December Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> January Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> February Total</th>
                                                        <th scope="col" className="px-4 md:px-6  py-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> March Total</th> 
                                                    </tr>
                                                    <tr>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">Project Management Fee Total</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.invoiceTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.aprTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.mayTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.junTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.julTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.augTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.sepTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.octTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.novTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.decTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.janTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.febTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">£ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.marTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">Project Management Fee Total</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.invoiceTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.aprTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.mayTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.junTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.julTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.augTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.sepTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.octTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.novTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.decTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.janTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.febTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap"><p className="my-1 font-bold">€ {internationalNumberFormat.format((invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.marTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))}</p></td>
                                                    </tr>
                                                    <tr >
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">Service Management Fee + Project Management Fee Total £</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.invoiceTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.invoiceTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.aprTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.aprTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.mayTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.mayTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.junTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.junTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.julTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.julTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.augTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.augTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.sepTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.sepTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.octTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.octTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.novTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.novTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.decTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.decTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.janTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.janTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.febTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.febTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">£ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "£").map(item => item.marTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "£").map(item => item.marTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">Service Management Fee + Project Management Fee Total €</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200"></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.invoiceTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.invoiceTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.aprTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.aprTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.mayTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.mayTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.junTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.junTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.julTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.julTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.augTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.augTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.sepTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.sepTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.octTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.octTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.novTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.novTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.decTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.decTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.janTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.janTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.febTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.febTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                        <td className="px-4 md:px-6 whitespace-nowrap bg-slate-200 text-congress-blue"><p className="my-1 font-bold">€ {internationalNumberFormat.format(Number(parseFloat(invoicesByProjMgr.filter(item => item.projectCurrency === "€").map(item => item.marTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)) + parseFloat((invoicesBySvcMgr.filter(item => item.projectCurrency === "€").map(item => item.marTotal).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2))).toFixed(2))}</p></td>
                                                    </tr>
                                                </tfoot>

                                            </table>
                                            {/* <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToUpdate={collectionArrayToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} arrayToShow={invoices} onClose={() => setShowModal(false)} show={showModal} title="Update Invoice Line Item" /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br></br>

                            <hr></hr>

                            <br></br>
                            <Button color="bg-bondi-blue" text="Export Project Manager Data to CSV" onClick={() => arrayToCsv(invoicesByProjMgr)} />
                            <Button color="bg-bondi-blue" text="Export Service Manager Data to CSV" onClick={() => arrayToCsv(invoicesBySvcMgr)} />

                            <br></br>
                        </div>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1" onClick={() => setSidebarToggle(!sidebarToggle, setSiteArea("Projects"))}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1" onClick={() => setSidebarToggle(!sidebarToggle, setSiteArea("Projects"))}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Projects" section="Individual Tracker" showUserMenu='true' />

                        <br></br>
                        <hr></hr>
                        <br></br>
                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7">

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                
                                </div>
                            </div>
                        </div>

                        <br></br>
                        <hr></hr>
                        <br></br>

                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                    <div role="status" className=" bg-white w-full p-4 space-y-4 border border-slate-200 divide-y divide-slate-200 rounded-2xl shadow-slate-300 shadow-md animate-pulse ">

                                        <div className="flex items-center justify-between">
                                            <div>
                                                <div className="h-5 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-5 bg-slate-300 rounded-full w-48"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )

    }

}

export default ProjectsTrackerIndividual

import Header from '../UI/Header'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { useMatch, Navigate } from 'react-router-dom'
// import { useForm, useFormState } from "react-hook-form"
import Button from '../UI/Button'
import Sidebar from '../UI/Sidebar'
import authHeader from '../../services/AuthHeader'
import ReactSelect from 'react-select'
import Table from '../UI/Table'
// import { DevTool } from '@hookform/devtools'

const HubspotLink = () => {

    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [loading, setLoading] = useState(true)
    const [redirecting, setRedirecting] = useState(false)
    const [poResults, setPOResults] = useState([])
    const [invoiceResults, setInvoiceResults] = useState([])
    const [footprints, setFootprints] = useState([])
    const [services, setServices] = useState([])

    const [allFootprints, setAllFootprints] = useState([])
    const [allSubItems, setAllSubItems] = useState([])

    const [testData, setTestData] = useState([])

    const [systemRole, setSystemRole] = useState('You are a helpful assistant.');
    const [userRole, setUserRole] = useState('What is the capital of France?');
    const [temperature, setTemperature] = useState(0.7);
    const [response, setResponse] = useState(null);

    const [authCode, setAuthCode] = useState('')

    const [hubSpotNotes, setHubSpotNotes] = useState([])

    const airportCalc = async () => {

        console.log("ChatGPT Code")

        const airports = [
          { "airportname": "Charles de Gaull" },
          { "airportname": "Las Angels International" }
        ];

          axios.post(process.env.REACT_APP_API_URL + '/chatGPTAirports', airports)
          .then((res) => {
              console.log(res.data)
              setTestData(res.data)
          })
          .catch((err) => {
              console.log(err);
          }).finally(() => {
              setLoading(false);
          });

    }

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      setLoading(true);
  
      try {
        const result = await axios.post(process.env.REACT_APP_API_URL + '/chatGPT', {
          systemRole,
          userRole,
          temperature,
        });
  
        //setResponse(result.data);

        alert(result.data)

      } catch (error) {
        console.error('Error while fetching data from Flask API:', error);
      } finally {
        setLoading(false);
      }
    };

    function deg2rad(deg) {
        return deg * (Math.PI/180)
    }

    const XeroFirstAuth = async () => {

        console.log("XeroFirstAuth")
        const auth_url = await axios.get(process.env.REACT_APP_API_URL + '/XeroFirstAuth')
        console.log(auth_url.data)
        window.location.href=auth_url.data
    }

    const XeroSendAuth = async () => {

        console.log("XeroSendAuth")
        axios.get(process.env.REACT_APP_API_URL + '/XeroSendAuth/' + authCode)
        .then(() => {
            window.top.close();
            })

    }

    function removeTags(str) {
      if ((str === null) || (str === ''))
          return false;
      else
          str = str.toString();
  
      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(<([^>]+)>)/ig, '');
  }

    const getHubSpotNotes = async () => {

      await axios.post(API_URL + '/hubspotNotesGetID/6706395120')
      .then(async (res) => {
          var arrDealIds = []
          res.data.results.forEach(deal => {
            arrDealIds.push({ id: deal.id })
          });

          await axios.post(API_URL + '/hubspotNotesUseID', {
            params: { dealIds: arrDealIds },
            paramsSerializer: {
              indexes: true,
            }
          })
          .then((res2) => {
                res2.data.results.forEach(note => {
                console.log(note.properties.hs_note_body);
                console.log(removeTags(note.properties.hs_note_body));
              });
    
          })
          .catch((err) => {
              console.log(err);
          }).finally(() => {
              // setInvoicesLoading(false);
          });

      })
      .catch((err) => {
          console.log(err);
      }).finally(() => {
          // setInvoicesLoading(false);
      });

    }

    // const XeroInvoices = () => {

    //     console.log("XeroInvoices")
    //     axios.get(process.env.REACT_APP_API_URL + '/XeroInvoices')
    //         .then((res) => {
    //             //console.log(res)
    //             console.log(res.data.Invoices)
    //             setInvoiceResults(res.data.Invoices)
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         }).finally(() => {
    //             setLoading(false);
    //         });
    // }

    // const XeroPOs = () => {

    //     console.log("XeroPOs")
    //     axios.get(process.env.REACT_APP_API_URL + '/XeroPOs')
    //         .then((res) => {
    //             //console.log(res)
    //             console.log(res.data.PurchaseOrders)
    //             // setPOResults(JSON.stringify(res.data.PurchaseOrders))
    //             setPOResults(res.data.PurchaseOrders)
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         }).finally(() => {
    //             setLoading(false);
    //         });

    // }

    const linkDEFRA = async () => {

        console.log("Link DEFRA to SWC MRIO")

        let allCarbonFactors = []
        let defraData = []
        let mappingData = []

        //Get the SWC & DEFRA Data
        await axios.get(process.env.REACT_APP_API_URL + '/carbon_factors')
            .then(response => {
                allCarbonFactors = response.data
                defraData = response.data.filter(item => item.DataSource === "DEFRA")
            })
            .catch(error => {
                console.log(error.message)
            })

        // Get the mapping table data
        await axios.get(process.env.REACT_APP_API_URL + '/carbon_factors_database_mapping')
            .then(response => {
                
                mappingData = response.data
                mappingData.forEach(async record => {
                    
                    let defraFactor = record.descriptionA
                    let swcFactor = record.descriptionB

                    let idToUpdate = ''
                    let recordToUpdate = []
                    let defraKeywords = defraData.find(defraFactorName => defraFactorName.Level1.trim() === defraFactor.trim()).keyWordsAndPhrases

                    if (defraKeywords.length >0) {
                        recordToUpdate = allCarbonFactors.find(swcFactorName => swcFactorName.Level1.trim() === swcFactor.trim() && swcFactorName.DataSource === record.databaseB)
                        idToUpdate = recordToUpdate.id
                        await axios.put(process.env.REACT_APP_API_URL + '/carbon_factors/' + idToUpdate, {...recordToUpdate, keyWordsAndPhrases: defraKeywords})
                    }
                });
            })
            .catch(error => {
                console.log(error.message)
            })

            window.alert("Import complete")

    }
    
    const listOfServices = [
        {
          "serviceId": "634ed4eb0e0cf247177df095"
        },
        {
          "serviceId": "634fda5108eac6bf4b2c48db"
        },
        {
          "serviceId": "6357cc019c6232e27f31186e"
        },
        {
          "serviceId": "63593534449b191774c55705"
        },
        {
          "serviceId": "638524c0c9d02daa8ce92533"
        },
        {
          "serviceId": "63852717a2cb0e0bce2322a8"
        },
        {
          "serviceId": "6385278d4b7198fb6d87d27d"
        },
        {
          "serviceId": "63878af62f212cb74a4b4c45"
        },
        {
          "serviceId": "63878b072f212cb74a4b4c46"
        },
        {
          "serviceId": "63878b2e2f212cb74a4b4c49"
        },
        {
          "serviceId": "63878b420cd337d5275fffda"
        },
        {
          "serviceId": "63878f0e2f212cb74a4b4c4c"
        },
        {
          "serviceId": "63878fe02f212cb74a4b4c4f"
        },
        {
          "serviceId": "63b6a8ddee35647af79f0eb4"
        },
        {
          "serviceId": "64b1289463cdb875046fff45"
        },
        {
          "serviceId": "64b1292e63cdb875046fff46"
        },
        {
          "serviceId": "64b130ac14e8efb3b045c2fd"
        },
        {
          "serviceId": "635909c595c21bcbbb32a2e8"
        },
        {
          "serviceId": "63ea0165616df318814e667f"
        },
        {
          "serviceId": "63629f50aa509de1e3240741"
        },
        {
          "serviceId": "63629fb58d140af71209acc7"
        },
        {
          "serviceId": "638631e7248dfe90bf2e1e83"
        },
        {
          "serviceId": "638632212e9ff567d6c5eed4"
        },
        {
          "serviceId": "6364cf6361e04a843e737bdc"
        },
        {
          "serviceId": "6369433ddd2a16afb5ced366"
        },
        {
          "serviceId": "63654d493b5b270ad9743057"
        },
        {
          "serviceId": "63654d4dc7b5273b1708f870"
        },
        {
          "serviceId": "63fc6845d4ea11377acfb89d"
        },
        {
          "serviceId": "637781d3c30417cbb586f8e5"
        },
        {
          "serviceId": "636d1ba844059047a60052bb"
        },
        {
          "serviceId": "636d1c3e44059047a60052c0"
        },
        {
          "serviceId": "636d1e9244059047a60052c5"
        },
        {
          "serviceId": "63ceb179fbb47d268a25a9b0"
        },
        {
          "serviceId": "63a54a003a5d5548b42f554e"
        },
        {
          "serviceId": "637b8d805c0fe742fc1f51bb"
        },
        {
          "serviceId": "637bac88da7537966b23541e"
        },
        {
          "serviceId": "638479ab7d3f72fe2164f477"
        },
        {
          "serviceId": "638479b235da93b50ba5eec7"
        },
        {
          "serviceId": "63847a1ffe493b104d61baef"
        },
        {
          "serviceId": "63847a4c56c705828c6dc74f"
        },
        {
          "serviceId": "6400b1b86c72a25d6b63fab8"
        },
        {
          "serviceId": "6400b1dc6c72a25d6b63fab9"
        },
        {
          "serviceId": "642da8ea870f70931fb15652"
        },
        {
          "serviceId": "637e2942f579b0906029a489"
        },
        {
          "serviceId": "6426d54ea4069ddb472b6b94"
        },
        {
          "serviceId": "654b59ba36bc89259f48478c"
        },
        {
          "serviceId": "654b5a003053c0de995041ce"
        },
        {
          "serviceId": "638638b92e9ff567d6c5eed5"
        },
        {
          "serviceId": "63a4546e13d22b29c4a64664"
        },
        {
          "serviceId": "63a454760d90187fb717ea59"
        },
        {
          "serviceId": "63a48ce4452c4a757cea2b90"
        },
        {
          "serviceId": "63a490aa5eb85556849db5dd"
        },
        {
          "serviceId": "63b5b2c7f6ccaadd5b8dfed9"
        },
        {
          "serviceId": "63b5b4ceccb65fa59984c614"
        },
        {
          "serviceId": "63b5b69f9b8eeeca052cc0f6"
        },
        {
          "serviceId": "63b5b6adbde41e25fc240831"
        },
        {
          "serviceId": "63b6b31046fd27243ccc5272"
        },
        {
          "serviceId": "63b6b35e46fd27243ccc5276"
        },
        {
          "serviceId": "640b370b20e7be68a40b778f"
        },
        {
          "serviceId": "640b373c5daf094ab2d25c22"
        },
        {
          "serviceId": "63bda0942f186271bc83e73b"
        },
        {
          "serviceId": "641c87a11d3f4c788d8c84ed"
        },
        {
          "serviceId": "654285693b7e78427f7962cf"
        },
        {
          "serviceId": "6542859b3b7e78427f7962d0"
        },
        {
          "serviceId": "63bfdbae066dd58ffd9e1768"
        },
        {
          "serviceId": "63d7bdb7af293f54543ed7f5"
        },
        {
          "serviceId": "6407164d3a262761d18bfeb7"
        },
        {
          "serviceId": "63e530f88573ffa249cfa0d0"
        },
        {
          "serviceId": "63ebaef8c219bb843b4e60e5"
        },
        {
          "serviceId": "64429e521ad7b66668e93ecb"
        },
        {
          "serviceId": "64429e677d5642c70c242d05"
        },
        {
          "serviceId": "6421b58fbdef43c8a52d1533"
        },
        {
          "serviceId": "6421b5b7bdef43c8a52d1534"
        },
        {
          "serviceId": "6495788b7ed69861441c9a8e"
        },
        {
          "serviceId": "649578b56da6027912961c7c"
        },
        {
          "serviceId": "63ee5442263f0a37e2e76300"
        },
        {
          "serviceId": "63ee5451eafc07aff57fe8bf"
        },
        {
          "serviceId": "63ee5460e4ee8122538ac1ec"
        },
        {
          "serviceId": "63ee546be4ee8122538ac1ed"
        },
        {
          "serviceId": "63f4fc085c9051eaf071a411"
        },
        {
          "serviceId": "63ee1be6315726b0f47d64bd"
        },
        {
          "serviceId": "63f332d27904406f0b223699"
        },
        {
          "serviceId": "64412571cafc7263173fce95"
        },
        {
          "serviceId": "63f35af1d7a3e5a872136a14"
        },
        {
          "serviceId": "63f388666f3c604f7089b9ab"
        },
        {
          "serviceId": "63f3886b0fb819b4f97b453d"
        },
        {
          "serviceId": "63f388956f3c604f7089b9ad"
        },
        {
          "serviceId": "63f388a46f3c604f7089b9ae"
        },
        {
          "serviceId": "63f3751c6f33edb1a3f3b7fe"
        },
        {
          "serviceId": "640b2c7b20e7be68a40b778e"
        },
        {
          "serviceId": "641052b23bf779a32eb69505"
        },
        {
          "serviceId": "64536a399781bf7b99336e29"
        },
        {
          "serviceId": "648308933fe3002f6d9aaeb0"
        },
        {
          "serviceId": "63ff6a0f9dc592ae782be9f8"
        },
        {
          "serviceId": "63ff6aebca1e139d919623bb"
        },
        {
          "serviceId": "6401bab8825833c3d445ea67"
        },
        {
          "serviceId": "6401bf9b7bd84410cc16c2ac"
        },
        {
          "serviceId": "64520cc67b3a42a891a5f0bb"
        },
        {
          "serviceId": "64062c7ccec8bd1fc63cd4c5"
        },
        {
          "serviceId": "64062c8dcec8bd1fc63cd4c6"
        },
        {
          "serviceId": "640619d0af77b335ff786197"
        },
        {
          "serviceId": "646e194e50c2131b98067f3c"
        },
        {
          "serviceId": "6413724b8afda116966a24ac"
        },
        {
          "serviceId": "64137251545267bf91d25b76"
        },
        {
          "serviceId": "6413727bac47556b4f51bf72"
        },
        {
          "serviceId": "641032e9c2d2646e2d230b6b"
        },
        {
          "serviceId": "641038d3c2d2646e2d230b73"
        },
        {
          "serviceId": "64103a3caaf759d019d70ee7"
        },
        {
          "serviceId": "6410a9a656d027b8b801f52f"
        },
        {
          "serviceId": "649c1b71c271fd95b38ebc26"
        },
        {
          "serviceId": "6410ac80c92b6d83ced62e06"
        },
        {
          "serviceId": "649c1ce9c271fd95b38ebc27"
        },
        {
          "serviceId": "6410ae7823f95ec5981cd307"
        },
        {
          "serviceId": "649c1d88c271fd95b38ebc28"
        },
        {
          "serviceId": "6410afe523f95ec5981cd309"
        },
        {
          "serviceId": "649c1e6f689dab5cdead6e06"
        },
        {
          "serviceId": "64148edfcaf369c93d2e3fe6"
        },
        {
          "serviceId": "6414535cb385eed0ab21e7d5"
        },
        {
          "serviceId": "641453d63e2ed6181f7ae612"
        },
        {
          "serviceId": "641aee5a6da4bf59a432f184"
        },
        {
          "serviceId": "641b373dd48bd79c8db1598c"
        },
        {
          "serviceId": "641df0aabdc26681056a11eb"
        },
        {
          "serviceId": "641df0eba842f3c449ac296b"
        },
        {
          "serviceId": "645e5424626ee719bd0ebca6"
        },
        {
          "serviceId": "645e54455f02d7322136a8f5"
        },
        {
          "serviceId": "645e545d626ee719bd0ebcac"
        },
        {
          "serviceId": "642ea41026947d6a566614de"
        },
        {
          "serviceId": "64902fa4d1b3f7272e732c6c"
        },
        {
          "serviceId": "64357d51f7e059e2c7bd8fd5"
        },
        {
          "serviceId": "644a1de58ea9dfe71f3aa4c0"
        },
        {
          "serviceId": "644a1dea8ea9dfe71f3aa4c1"
        },
        {
          "serviceId": "64391238177d39149c84c8cb"
        },
        {
          "serviceId": "64391240c70755fb525e7cf9"
        },
        {
          "serviceId": "64394578c688b60773d61435"
        },
        {
          "serviceId": "643945b4a589e38731ed296c"
        },
        {
          "serviceId": "643954aec23432a07dfef6cf"
        },
        {
          "serviceId": "643954ebc23432a07dfef6d0"
        },
        {
          "serviceId": "6439550b8b148dd1f40b9f2b"
        },
        {
          "serviceId": "64395cb68b148dd1f40b9f2c"
        },
        {
          "serviceId": "643e85d688c4e8f7cebffe46"
        },
        {
          "serviceId": "644bb2c0edc90a758f04f4fc"
        },
        {
          "serviceId": "644bb2c9edc90a758f04f4fd"
        },
        {
          "serviceId": "644bb2d050ac710c2fe47bf2"
        },
        {
          "serviceId": "644bb2e1edc90a758f04f4fe"
        },
        {
          "serviceId": "644bb601edc90a758f04f4ff"
        },
        {
          "serviceId": "6483417d9a28c113bfd44dfe"
        },
        {
          "serviceId": "643d4d3982e001effba6a533"
        },
        {
          "serviceId": "643e76a2457645f39ce3faa9"
        },
        {
          "serviceId": "644288521f98c6632991af45"
        },
        {
          "serviceId": "64fed04e367285fd53671ae0"
        },
        {
          "serviceId": "6453c69a09d681c8ffc3053e"
        },
        {
          "serviceId": "6453c6a409d681c8ffc3053f"
        },
        {
          "serviceId": "6453c6ae09d681c8ffc30540"
        },
        {
          "serviceId": "6453c6ba27fde3ebc827ce8f"
        },
        {
          "serviceId": "6462446f2a09a9a9dc43460e"
        },
        {
          "serviceId": "6470d7736d884bdc179cf8e2"
        },
        {
          "serviceId": "646245aa2a09a9a9dc43460f"
        },
        {
          "serviceId": "6470d8f76d884bdc179cf8e3"
        },
        {
          "serviceId": "645b378df55238f4bd4544de"
        },
        {
          "serviceId": "645d12d11ade78be3f831dcf"
        },
        {
          "serviceId": "645d09221ade78be3f831dcd"
        },
        {
          "serviceId": "646383505fe84459bd43b01a"
        },
        {
          "serviceId": "646c4f2c0e80bc8d8d06ac22"
        },
        {
          "serviceId": "646cb63cf2585f4f049d1db2"
        },
        {
          "serviceId": "649062f3c8b1e018dfb8dd0f"
        },
        {
          "serviceId": "6490630006ee2d30eae950d7"
        },
        {
          "serviceId": "6477454efd1e45602840d03c"
        },
        {
          "serviceId": "64ef0c9847a812899f0e7ec4"
        },
        {
          "serviceId": "64ef0ca3e745d99d7e86f0d6"
        },
        {
          "serviceId": "646777ed6bab113fd0acae8c"
        },
        {
          "serviceId": "64675574228bd1c16c97c2dd"
        },
        {
          "serviceId": "64675587d2565e88a2c7d3b1"
        },
        {
          "serviceId": "646db188b1bb0efa1880bcbc"
        },
        {
          "serviceId": "646db23f1c2493f21ceb09d2"
        },
        {
          "serviceId": "646b3bb927d1fd0298042559"
        },
        {
          "serviceId": "647606c6ef037786627bfd06"
        },
        {
          "serviceId": "647606cd353a0eb6676c275c"
        },
        {
          "serviceId": "649ac98e7854175b6548acab"
        },
        {
          "serviceId": "64bfc98ccc9995c83bee95bb"
        },
        {
          "serviceId": "647dff939916523fccb130ed"
        },
        {
          "serviceId": "647e006dd47b013d5171732d"
        },
        {
          "serviceId": "647f369b50fde6ccf122ef9c"
        },
        {
          "serviceId": "64806220acaa3e0f6cb7954d"
        },
        {
          "serviceId": "6481f9d28e8feaac8824913e"
        },
        {
          "serviceId": "64a5897d8933f051091b07cf"
        },
        {
          "serviceId": "648c3a65e9b33b30edfb1479"
        },
        {
          "serviceId": "64917344e33b56403ae3a21f"
        },
        {
          "serviceId": "649080a706ee2d30eae950de"
        },
        {
          "serviceId": "649080e006ee2d30eae950e0"
        },
        {
          "serviceId": "64916c27e33b56403ae3a21d"
        },
        {
          "serviceId": "649c6585a87e767a45db3440"
        },
        {
          "serviceId": "6499b773f90eb26084e52876"
        },
        {
          "serviceId": "6499b7e23e705a2dc46e8a1f"
        },
        {
          "serviceId": "649a91752880a5447f6a4f2b"
        },
        {
          "serviceId": "649c4d938f039ab50c678b44"
        },
        {
          "serviceId": "649c4d9ea1a9223919c24319"
        },
        {
          "serviceId": "64f0727b95c5b3c02df9f266"
        },
        {
          "serviceId": "6508339c377bb62d22b03f42"
        },
        {
          "serviceId": "64ae72fbbe68d502079774e5"
        },
        {
          "serviceId": "64a67fa36dc159b8683ced4d"
        },
        {
          "serviceId": "65095ca24c8d82636d3af1f5"
        },
        {
          "serviceId": "650af569b890e84faee28bbf"
        },
        {
          "serviceId": "64b180e5db8728f932426507"
        },
        {
          "serviceId": "64c7673134b60cc92be814df"
        },
        {
          "serviceId": "64b02674b71d3eca5316922d"
        },
        {
          "serviceId": "64edc3cdc0cea18555260abb"
        },
        {
          "serviceId": "64edc3db7a2f591bb85e2102"
        },
        {
          "serviceId": "64baa5ccb2f4efb954231e76"
        },
        {
          "serviceId": "64be7e4bfc00c209ca962cec"
        },
        {
          "serviceId": "64be7ee3fc00c209ca962ced"
        },
        {
          "serviceId": "64be9877a4629360c15f0ec4"
        },
        {
          "serviceId": "64be9913763b283eaba1584c"
        },
        {
          "serviceId": "64be9929db52de272c9c717b"
        },
        {
          "serviceId": "64be993dc01bc183f69649a5"
        },
        {
          "serviceId": "64be997cc01bc183f69649a6"
        },
        {
          "serviceId": "64be9995c01bc183f69649a7"
        },
        {
          "serviceId": "64d0f1a3584475e659239aad"
        },
        {
          "serviceId": "64d0d6614748c80c0fa7b0e3"
        },
        {
          "serviceId": "64d0d6784748c80c0fa7b0e4"
        },
        {
          "serviceId": "64d0d6854748c80c0fa7b0e5"
        },
        {
          "serviceId": "64fb09b679323d345c4dfd9f"
        },
        {
          "serviceId": "64fb0a3079323d345c4dfda0"
        },
        {
          "serviceId": "64ef1521e745d99d7e86f0d9"
        },
        {
          "serviceId": "64e79b730b255f2e4d762dcd"
        },
        {
          "serviceId": "64e85cfb2d54b7da3bd50e1b"
        },
        {
          "serviceId": "64eda8d4cd7237739bf8fe9f"
        },
        {
          "serviceId": "64e79f4871149debecd70941"
        },
        {
          "serviceId": "64ef3f20484595a0a94eb99a"
        },
        {
          "serviceId": "64ef10f0e745d99d7e86f0d7"
        },
        {
          "serviceId": "6500805c4e2adbea1285c2d5"
        },
        {
          "serviceId": "64e768ee3945a63ee428fe19"
        },
        {
          "serviceId": "64f1b9f28ea6334771f8c940"
        },
        {
          "serviceId": "65031596d578682b257ec270"
        },
        {
          "serviceId": "6542a4cac2de7907c97b926c"
        },
        {
          "serviceId": "6503212047749594e1fa4808"
        },
        {
          "serviceId": "65097d5d9a23788ed245df72"
        },
        {
          "serviceId": "650c5f6f7352f132957e0d7f"
        },
        {
          "serviceId": "650d57638b872540b0b7d0b4"
        },
        {
          "serviceId": "6513016cc0d83271aca04281"
        },
        {
          "serviceId": "653274633e0e2dfae69a70e2"
        },
        {
          "serviceId": "6513f9a5155de414552318e1"
        },
        {
          "serviceId": "6513fb13155de414552318e3"
        },
        {
          "serviceId": "6512a6d0231680c5fae871b2"
        },
        {
          "serviceId": "65264efa67469576b00d36ec"
        },
        {
          "serviceId": "65264f0167469576b00d36ed"
        },
        {
          "serviceId": "65280fc66b5e45fb2a983833"
        },
        {
          "serviceId": "65325a04cdbc6058a2b4f11c"
        },
        {
          "serviceId": "65325a0a8acffbd0029a9f5a"
        },
        {
          "serviceId": "65326493969eb45f69e66615"
        },
        {
          "serviceId": "65377688f5f2eabf18583f24"
        },
        {
          "serviceId": "655b43a822e916431092428a"
        },
        {
          "serviceId": "659566ec4fb3724a617daa5e"
        },
        {
          "serviceId": "63177192b70212b3a02f1933"
        },
        {
          "serviceId": "64b9113b0453c10ce440e0f4"
        },
        {
          "serviceId": "63da311a9af9ba3948b33e4f"
        },
        {
          "serviceId": "631207b7c46c3c773cae729f"
        },
        {
          "serviceId": "63637ea1081772eb65bc94e9"
        },
        {
          "serviceId": "63637eeff2a21945ae2a7bc4"
        },
        {
          "serviceId": "63637ef31719e9dda0fe9f39"
        },
        {
          "serviceId": "63637ef51719e9dda0fe9f3a"
        },
        {
          "serviceId": "63cfb58e7e9da1fc33fb7ddc"
        },
        {
          "serviceId": "63ed06748d8601907f554ad0"
        },
        {
          "serviceId": "63ed068640d2d0aec8413736"
        },
        {
          "serviceId": "63ed068c8d8601907f554ad1"
        },
        {
          "serviceId": "63ed069140d2d0aec8413737"
        },
        {
          "serviceId": "63ed069540d2d0aec8413738"
        },
        {
          "serviceId": "63e3590b1f05d53e70da638b"
        },
        {
          "serviceId": "63e359461f05d53e70da638d"
        },
        {
          "serviceId": "641ae73fd82670437797120b"
        },
        {
          "serviceId": "64a823244798daa354acf0ac"
        },
        {
          "serviceId": "635ff8457154464fe267337d"
        },
        {
          "serviceId": "6315c8e7a8551862d7f416d4"
        },
        {
          "serviceId": "63e0c252db478349c9e6f092"
        },
        {
          "serviceId": "636e3b6632d64c360f4e4a2a"
        },
        {
          "serviceId": "63b6f6a07fecf514d20cb97d"
        },
        {
          "serviceId": "6360fda1c72d424707c601ea"
        },
        {
          "serviceId": "63623a20f68b87336f1341d9"
        },
        {
          "serviceId": "64d4a064e28299cb2ca1174d"
        },
        {
          "serviceId": "63d0ecb6f00302096e4be64a"
        },
        {
          "serviceId": "643e5a93c0106b3bb05c7422"
        },
        {
          "serviceId": "63db8b912bb728f39c5d580b"
        },
        {
          "serviceId": "63db8bb52bb728f39c5d580c"
        },
        {
          "serviceId": "64c3a94749793dff08393163"
        },
        {
          "serviceId": "6377af101f94f0e3697bc9b4"
        },
        {
          "serviceId": "641c5944d81730eb24a95727"
        },
        {
          "serviceId": "644642cfa5926e43db54b581"
        },
        {
          "serviceId": "644baea42b49e891546515e0"
        },
        {
          "serviceId": "635953c5201568b2a3ab72d8"
        },
        {
          "serviceId": "635a52d76b281a3689156e80"
        },
        {
          "serviceId": "63ff76585a421173e128d919"
        },
        {
          "serviceId": "64abd15090d5c1a3658817d2"
        },
        {
          "serviceId": "631205b6a31d3a2978f29822"
        },
        {
          "serviceId": "633ac10e923f05cb1113bee8"
        },
        {
          "serviceId": "6437e158e8b381addc9352b9"
        },
        {
          "serviceId": "6421acb4bdef43c8a52d1531"
        },
        {
          "serviceId": "63e51cba49a251f8150b2bdf"
        },
        {
          "serviceId": "63e51d0a1a06fc803ee4662e"
        },
        {
          "serviceId": "6324a5051ce65065681cb831"
        },
        {
          "serviceId": "636a4173099c2b9394e9c035"
        },
        {
          "serviceId": "633ae6776ba56560e41b3407"
        },
        {
          "serviceId": "63238fef83f5c68e428ec9fc"
        },
        {
          "serviceId": "63340dea375d7fbd6f872381"
        },
        {
          "serviceId": "64a53c7ea026403ddf9828e0"
        },
        {
          "serviceId": "635f9118938616f993279c44"
        },
        {
          "serviceId": "64da35b71c2c874c1ba36cb9"
        },
        {
          "serviceId": "632b0f7921014e9484f3e341"
        },
        {
          "serviceId": "63bbebf52672ab2e1ebe39c3"
        },
        {
          "serviceId": "63220edd30f1091c34dae037"
        },
        {
          "serviceId": "635025d41187f76669d1e0dd"
        },
        {
          "serviceId": "6362b4471c60754885310523"
        },
        {
          "serviceId": "63889d3691da350ceffdf71d"
        },
        {
          "serviceId": "638a2d86569b853216f63ee1"
        },
        {
          "serviceId": "638a2db534c30390fd3c6d74"
        },
        {
          "serviceId": "638a2dd1e171cdc88da05c4a"
        },
        {
          "serviceId": "63ce709d83f9652b5debc609"
        },
        {
          "serviceId": "64e78f713945a63ee428fe39"
        },
        {
          "serviceId": "649c5f90a87e767a45db343f"
        },
        {
          "serviceId": "636a45e6188c4f7ceda25c84"
        },
        {
          "serviceId": "652d02e783a09d083e18a718"
        },
        {
          "serviceId": "652d0311a87939b91b64aebc"
        },
        {
          "serviceId": "635fa4f35f306132e6b72b47"
        },
        {
          "serviceId": "635fa58d85396f05608d8583"
        },
        {
          "serviceId": "635fa5ad85396f05608d8584"
        },
        {
          "serviceId": "635fa5b785396f05608d8585"
        },
        {
          "serviceId": "635fa78c95b8eab7155a2023"
        },
        {
          "serviceId": "635fa9039176ff49c6660dae"
        },
        {
          "serviceId": "635fa90d85396f05608d8587"
        },
        {
          "serviceId": "635fa9128134275b1334cfd8"
        },
        {
          "serviceId": "644105c6db1201cad308b916"
        },
        {
          "serviceId": "633aae510255ea60879c4fcd"
        },
        {
          "serviceId": "63205d763c2aaf22382824fc"
        },
        {
          "serviceId": "63205d7c2152d94f0d3ca561"
        },
        {
          "serviceId": "632b1f4b9f03978d3806f697"
        },
        {
          "serviceId": "637b9b9745abc7ecff1e6145"
        },
        {
          "serviceId": "6425c864c124b61d483ad5c9"
        },
        {
          "serviceId": "642ee35846f5beb957977408"
        },
        {
          "serviceId": "65158ed3e30947de62d77201"
        },
        {
          "serviceId": "637524481fa466bc5b5ac886"
        },
        {
          "serviceId": "63122c4abd003e6567bf203d"
        },
        {
          "serviceId": "64465a79b882b01001a9727b"
        },
        {
          "serviceId": "63652d8ecb72d93bc3f4eccd"
        },
        {
          "serviceId": "63652da5bfd7d7acba23528c"
        },
        {
          "serviceId": "63652dccbfd7d7acba23528d"
        },
        {
          "serviceId": "63160eb3abd7de0f1bab055c"
        },
        {
          "serviceId": "6368e921ceb18b48fb932509"
        },
        {
          "serviceId": "631b24aa7ef5dcf3160a652d"
        },
        {
          "serviceId": "633ac090923f05cb1113bee7"
        },
        {
          "serviceId": "6331721a59bed264338ef520"
        },
        {
          "serviceId": "644b980444c8ff37d6ae146b"
        },
        {
          "serviceId": "644b9a300974151797d82066"
        },
        {
          "serviceId": "63878eaa2f212cb74a4b4c4b"
        },
        {
          "serviceId": "64b9609c23aefabe85184079"
        },
        {
          "serviceId": "631b7a59e85c4cdcc7953cf8"
        },
        {
          "serviceId": "632474066e1f0d11a51d0bb6"
        },
        {
          "serviceId": "632474d63cb87b7d029ea7ed"
        },
        {
          "serviceId": "63247552589c96b9a1ab06da"
        },
        {
          "serviceId": "632475f4d99abbc6dfec1d39"
        },
        {
          "serviceId": "632476ed6e1f0d11a51d0bb7"
        },
        {
          "serviceId": "632477fa6e1f0d11a51d0bb9"
        },
        {
          "serviceId": "6324795af699e504dd4077f4"
        },
        {
          "serviceId": "64ae8a9439f326df8dad36f1"
        },
        {
          "serviceId": "63d942d2ab22b9c1a768ca79"
        },
        {
          "serviceId": "640aeea84d0a5d9afc924acc"
        },
        {
          "serviceId": "655f3c52b14bc30da541f472"
        },
        {
          "serviceId": "63188b33614a8f6dc003f2da"
        },
        {
          "serviceId": "6357af310cb73ace898520b6"
        },
        {
          "serviceId": "648ae7341ba64f942d067438"
        },
        {
          "serviceId": "63873b2e90d359ca62a5a076"
        },
        {
          "serviceId": "6426eabf1e1ba26a1ea5a9e1"
        },
        {
          "serviceId": "639b2df54e37d80c7cae3f23"
        },
        {
          "serviceId": "6361044ac72d424707c601eb"
        },
        {
          "serviceId": "637cc05cc135d5ed9afe8ea4"
        },
        {
          "serviceId": "637cc4af9901382812d7f56d"
        },
        {
          "serviceId": "63c907addbf9bce325adc8dd"
        },
        {
          "serviceId": "634942aab86f37c8c16afbff"
        },
        {
          "serviceId": "633b0f8a649dfa434ee94fc7"
        },
        {
          "serviceId": "633b0f69300c038a718054b3"
        },
        {
          "serviceId": "631613dcd1ebb937a36cbbc0"
        },
        {
          "serviceId": "63b7ea1e0e19ae1aa015a482"
        },
        {
          "serviceId": "63b7f4862db68f2e7e1f92cd"
        },
        {
          "serviceId": "6332f89863b4e231d3cb9d7b"
        },
        {
          "serviceId": "6500938d3a1e1713db6f97b6"
        },
        {
          "serviceId": "650d540b68417afce3433c52"
        },
        {
          "serviceId": "634e64842b02b008a764b80b"
        },
        {
          "serviceId": "631227a0a20f670cfb31eca8"
        },
        {
          "serviceId": "64638f185fe84459bd43b01e"
        },
        {
          "serviceId": "635a966250ef92d7bceaaf08"
        },
        {
          "serviceId": "6364e037d408a344f78cddcc"
        },
        {
          "serviceId": "636b70ba66f982c75cff9316"
        },
        {
          "serviceId": "63723c7d12487ef732c6f7af"
        },
        {
          "serviceId": "64ac02ceec1ad8b5dc392088"
        },
        {
          "serviceId": "64ac02eb42c6795ff96c4356"
        },
        {
          "serviceId": "64ac0307800324c03435eff3"
        },
        {
          "serviceId": "64ac031625f2194c1175cf37"
        },
        {
          "serviceId": "64ac032f25f2194c1175cf38"
        },
        {
          "serviceId": "63404041253af920b1987350"
        },
        {
          "serviceId": "6343e899eb0345ede5c01123"
        },
        {
          "serviceId": "63c97d0f8596b4224eaebb86"
        },
        {
          "serviceId": "63edf1890c7ad05df93f0a88"
        },
        {
          "serviceId": "63906891140be31a3e16886d"
        },
        {
          "serviceId": "63906970fe0c4a827bfecfa8"
        },
        {
          "serviceId": "643cfa87a6856af513c6fab4"
        },
        {
          "serviceId": "643cfb059731e874c87c46c1"
        },
        {
          "serviceId": "643e58781c4ebc1b6ff83a26"
        },
        {
          "serviceId": "643e5880b1ab9bc5cecdfeac"
        },
        {
          "serviceId": "6454fe5301619ab055d8654f"
        },
        {
          "serviceId": "64edb1330e1da91f52a918ee"
        },
        {
          "serviceId": "633303a65e4228a7573c7018"
        },
        {
          "serviceId": "63404c6ec00cd86baa913fa6"
        },
        {
          "serviceId": "6555fd1bbd7de4e002601818"
        },
        {
          "serviceId": "655601fdbd7de4e00260181b"
        },
        {
          "serviceId": "655602b4bd7de4e00260181c"
        },
        {
          "serviceId": "65560337bd7de4e00260181d"
        },
        {
          "serviceId": "655605abbd7de4e002601820"
        },
        {
          "serviceId": "65560670aeaa11bffc01a2cf"
        },
        {
          "serviceId": "655606c3bd7de4e002601822"
        },
        {
          "serviceId": "6426ffd816676fd156dbc3df"
        },
        {
          "serviceId": "643548c3a47ff3d6003c1e18"
        },
        {
          "serviceId": "643548caa47ff3d6003c1e19"
        },
        {
          "serviceId": "6380a983280e3a4653c64849"
        },
        {
          "serviceId": "6380ab3b925d9ee39afd73b5"
        },
        {
          "serviceId": "634d1997b98936217e86b6b8"
        },
        {
          "serviceId": "639886741f4c0c8d29de40cc"
        },
        {
          "serviceId": "631ee5f9cc8fafb8b9806f89"
        },
        {
          "serviceId": "632ad9d8029a86fa2c72e023"
        },
        {
          "serviceId": "632adedcdaa00ed12df3cbb4"
        },
        {
          "serviceId": "638f56300cda321f5f5b756b"
        },
        {
          "serviceId": "63246a8dacc70ab198dd0637"
        },
        {
          "serviceId": "632342898cd1dcbd18a7299b"
        },
        {
          "serviceId": "64525c76047da855c0a5afd1"
        },
        {
          "serviceId": "63ecd2c44f3ba6d53e029a89"
        },
        {
          "serviceId": "63ecf200d9cb4c2bcf62de2f"
        },
        {
          "serviceId": "63ecf24795b04202778690f1"
        },
        {
          "serviceId": "63ecf27595b04202778690f2"
        },
        {
          "serviceId": "6331a8fb72e076a9b85efc18"
        },
        {
          "serviceId": "634fa20481295ad8ceedda4b"
        },
        {
          "serviceId": "645269176f3a28b68eac0253"
        },
        {
          "serviceId": "6452691e047da855c0a5afd2"
        },
        {
          "serviceId": "641b40d39e5c731c3562f64e"
        },
        {
          "serviceId": "6452666f754eda7883723a47"
        },
        {
          "serviceId": "6527fd7fbb5ccee2d16d6e11"
        },
        {
          "serviceId": "6527fd88bb5ccee2d16d6e12"
        },
        {
          "serviceId": "639361241a1fe5fde86dc651"
        },
        {
          "serviceId": "63936148bacdfb8c6b5371b3"
        },
        {
          "serviceId": "63bffd02203daf36619c1596"
        },
        {
          "serviceId": "635be3ee89c31092587c55a9"
        },
        {
          "serviceId": "635be41b92b24d82eed3f565"
        },
        {
          "serviceId": "63120ef6ee93ce27f8648b92"
        },
        {
          "serviceId": "633bee3f4c69145bb01d9de6"
        },
        {
          "serviceId": "633bee41353da959cc16c050"
        },
        {
          "serviceId": "633beeac34584e8cefa7aceb"
        },
        {
          "serviceId": "633beead34584e8cefa7acec"
        },
        {
          "serviceId": "6336dc5e32238ede096114eb"
        },
        {
          "serviceId": "636937c5d8958e08799f3cec"
        },
        {
          "serviceId": "636ce34b886020aac2ca91bb"
        },
        {
          "serviceId": "633fe6c3841540fb2f62aa8b"
        },
        {
          "serviceId": "64a80f0a29d3a3b88092fbba"
        },
        {
          "serviceId": "63dcfce43241eea47ce7683e"
        },
        {
          "serviceId": "63dcfd443241eea47ce7683f"
        },
        {
          "serviceId": "648b248d49f4648671e02588"
        },
        {
          "serviceId": "6490199cfa8f6a51104cd95f"
        },
        {
          "serviceId": "651e5c404e303076dd4cd0a3"
        },
        {
          "serviceId": "63a01d15a2182c2496dea2ea"
        },
        {
          "serviceId": "63a04437e32c0ff134e43a72"
        },
        {
          "serviceId": "6310b0cdb643ff7a083cfd3a"
        },
        {
          "serviceId": "64abe80d548b97053c318530"
        },
        {
          "serviceId": "64abe815548b97053c318531"
        },
        {
          "serviceId": "6310c204814b0a66e9083cb8"
        },
        {
          "serviceId": "64007d79ffe2a1d93aab15d3"
        },
        {
          "serviceId": "63467a999db9da108b6c99ae"
        },
        {
          "serviceId": "634ed0d9796d96cfe1bd2e30"
        },
        {
          "serviceId": "63501cd7597363ad0193ce34"
        },
        {
          "serviceId": "63501d507e85a28590198131"
        },
        {
          "serviceId": "63501dda7e85a28590198132"
        },
        {
          "serviceId": "639a0559ff30558fb7be45c6"
        },
        {
          "serviceId": "639b2b76f807aca7f03e69d5"
        },
        {
          "serviceId": "640b0a0eb7b4004df1cd6f6d"
        },
        {
          "serviceId": "644004a8175afeb463073740"
        },
        {
          "serviceId": "644004e1175afeb463073741"
        },
        {
          "serviceId": "6440051ff849eae19d792a23"
        },
        {
          "serviceId": "6308e7fdc0049b2f68d2117e"
        },
        {
          "serviceId": "6323316262bb976ce36314bf"
        },
        {
          "serviceId": "6332aa85e0fad53f8dff07e0"
        },
        {
          "serviceId": "6332ec71b2a864c6ddf199a6"
        },
        {
          "serviceId": "63c9102f53b64a8b8ba28cca"
        },
        {
          "serviceId": "63d016010939125e05247b8c"
        },
        {
          "serviceId": "6436a5127ab30a3e556a6246"
        },
        {
          "serviceId": "6436a517eb491e0eaacd4214"
        },
        {
          "serviceId": "6436a5233c4fa3833f881739"
        },
        {
          "serviceId": "639c94dc19f3777aa4a08121"
        },
        {
          "serviceId": "64c15532b98675379d431f60"
        },
        {
          "serviceId": "64c15593681f1b2ec16d8528"
        },
        {
          "serviceId": "64c26359da4575854138ff55"
        },
        {
          "serviceId": "650c1b5d3de21e8135886ea1"
        },
        {
          "serviceId": "63dcfbb53241eea47ce7683c"
        },
        {
          "serviceId": "63dcfc5d3241eea47ce7683d"
        },
        {
          "serviceId": "64805196e13c87781b2ac7dc"
        },
        {
          "serviceId": "648054925ef5beceb7e028a4"
        },
        {
          "serviceId": "648336acea16865f1819157f"
        },
        {
          "serviceId": "648336e9ea16865f18191580"
        },
        {
          "serviceId": "64a69b07f7b6c1dd9bb22bc9"
        },
        {
          "serviceId": "64e49524899605124049ba9c"
        },
        {
          "serviceId": "635659440865b6a4d56ac41b"
        },
        {
          "serviceId": "638dca93a0cd1b253c5186e1"
        },
        {
          "serviceId": "63a1ad3cb8b5a3d134449dea"
        },
        {
          "serviceId": "6340016479fb37b943ebaaeb"
        },
        {
          "serviceId": "63400169d33c019a5c9709c1"
        },
        {
          "serviceId": "63400184d33c019a5c9709c2"
        }
       ]

    const API_URL = process.env.REACT_APP_API_URL;

    const updateForm = async (form, serviceId) => {
        await axios.put(process.env.REACT_APP_API_URL + '/projects_services/' + serviceId, form)
    }

    const updateServices = async () => {

        var service

        for (service = 0; service < listOfServices.length; service++) {

            await axios.get(API_URL + '/projects_services/' + listOfServices[service].serviceId, { headers: authHeader() })

            .then((response) => {
                updateForm({...response.data, serviceClosedDate: "2024-02-16"}, listOfServices[service].serviceId)
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                console.log(service)
                console.log(listOfServices[service].serviceId)
            });

        }

        

    }

    const updateActivitiesToCompleted = async () => {

        var service, activity
        var activities = []

        console.log(services)

        for (service = 0; service < services.length; service++) {

                console.log(service)

                activities = services[service].activities

                for (activity = 0; activity < activities.length; activity++) {
                    activities[activity].activityComplete = "YES"
                }

                await updateForm({...services[service], activities: activities}, services[service].id)

                console.log(services[service].id)

        }

        console.log("Done")

    }

    const updateItemCommentForReportFields = async () => {

        var footprint, footprintItem

        for (footprint = 0; footprint < footprints.length; footprint++) {

            var newFootprint = {...footprints[footprint]}

            for (footprintItem = 0; footprintItem < newFootprint.organisationFootprintItems.length; footprintItem++) {
                newFootprint.organisationFootprintItems[footprintItem].itemCommentForReport = newFootprint.organisationFootprintItems[footprintItem].itemConsultantComment
            }

            await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + newFootprint.id, newFootprint)
            console.log(newFootprint.id + " updated")

        }

        console.log("Done")

    }

    const deleteOrphanedSubItems = async () => {

        var subItem

        var subItemsToDelete = []

        //Loop through all subItems
        for (subItem = 0; subItem < allSubItems.length; subItem++) {
            //If subItem.footprintItem is not in allFootprintItems then
            //add to subItemsToDelete

            if (!allFootprints.find(item => item.id === allSubItems[subItem].footprintItemId)) {

                subItemsToDelete.push(allSubItems[subItem].id)
            }

        }

        console.log(subItemsToDelete)

        await axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems_delete', {"subitems" : subItemsToDelete})

        console.log("Complete")

    }
    

    // const createSubItems = async () => {

    //     var footprint
    //     var footprintItem
    //     var footprintSubItem
    //     var newArrayOfSubItems = []

    //     console.log(footprints)


    //     for (footprint = 0; footprint < footprints.length; footprint++) {

    //         for (footprintItem = 0; footprintItem < footprints[footprint].organisationFootprintItems.length; footprintItem++) {

    //             for (footprintSubItem = 0; footprintSubItem < footprints[footprint].organisationFootprintItems[footprintItem].footprintSubItems.length; footprintSubItem++) {

    //                 newArrayOfSubItems.push({...footprints[footprint].organisationFootprintItems[footprintItem].footprintSubItems[footprintSubItem], footprintId: footprints[footprint].id, organisationId: footprints[footprint].organisationId, year: footprints[footprint].organisationFootprintYear, subCategory: footprints[footprint].organisationFootprintItems[footprintItem].itemSubCategory, footprintItemId: footprints[footprint].organisationFootprintItems[footprintItem].id })
    //                 console.log(footprintSubItem)
    //             }    

    //         }    

    //     }

    //     console.log(newArrayOfSubItems)
            
    //         //Step through each footprint
    //             //step through each footprintitem
    //                 //step through each subItem 
    //                     //add to array along with footprintItemId, footprintId, organisationId, year, subItem to each record

    //         //axios.put array into organisations_footprints_subItems


    //         window.alert("All Sub Item Records Created")

    // }

     useEffect(() => {

      setLoading(false);

    //     axios.get(API_URL + '/projects_services')
    //     .then((res) => {
    //         setServices(res.data.filter(item => item.serviceStatus === "Closed-completed"))
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     }).finally(() => {
    //     });

    //     // axios.get(API_URL + '/organisations_footprints')
    //     // .then((res) => {

    //     //     var footprint

    //     //     var footprintItems = []

    //     //     for (footprint = 0; footprint < res.data.length; footprint++) {

    //     //         footprintItems = footprintItems.concat(res.data[footprint].organisationFootprintItems)

    //     //     }

    //     //     setAllFootprints(footprintItems)
    //     // })
    //     // .catch((err) => {
    //     //     console.log(err);
    //     // }).finally(() => {
    //     //     console.log("footprints loaded")
    //     // });

    //     // axios.get(API_URL + '/organisations_footprints_subItems')
    //     // .then((res) => {
    //     //     setAllSubItems(res.data)
    //     // })
    //     // .catch((err) => {
    //     //     console.log(err);
    //     // }).finally(() => {
    //     //     console.log("sub items loaded")
    //     // });

    // //     console.log("useEffectAuthCode")
    // //     console.log(window.location.href)

    // //     let varURL = window.location.href
    // //     let start_number = varURL.search('code=') + 5
    // //     let end_number = varURL.search('&scope')
    // //     let auth_code = varURL.substring(start_number, end_number)

    // //     setAuthCode(auth_code)

    // //     if (start_number > 5) {
    // //         console.log("Auth Code is: " + auth_code)
    // //     }

    //     axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints')
    //     .then((res) => {
    //         setFootprints(res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual"))
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     }).finally(() => {
    //         setLoading(false);
    //     });

     }, [])

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (!loading) {

        return (

            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Sandbox" section="Sandbox/Testing Page" showUserMenu='true' />
                        
                        {/* <div className='mt-40'><p className='text-center text-lg'>Thank you for connecting to Xero</p></div> */}
                        {/* <div className='text-center hidden'><Button color="bg-bondi-blue" text="Airport Distance Calculation" onClick={airportCalc}></Button></div> */}

                        <div className="pb-1">
                            <table>
                                <tbody>
                                    <tr>
                                        {/* <td className='pr-20'><img src='../images/eight-versa-colour.png' width="200px"></img></td> */}
                                        {/* <td><img src='../images/xero_software_logo.svg' width="200px"></img></td> */}
                                    </tr>
                                </tbody>
                            </table>
                            <table className='mt-20 w-100%'>
                                <tbody>
                                    <tr>
                                        {/* <td><Button color="bg-bondi-blue" text="Click here to get the Xero Authorization Code" onClick={XeroFirstAuth}></Button></td> */}
                                        {/* <td className='pl-5 pb-10 text-center'>Thank you for connecting to Xero.</td> */}
                                    </tr>
                                    <tr>
                                        {/* <td className='text-center'><Button color="bg-bondi-blue" text="Cick the button below to complete the process" onClick={XeroSendAuth}></Button></td> */}
                                    </tr>
                                </tbody>
                            </table>
                            <table className='mt-20'>
                                <tbody>
                                    <tr>
                                        {/* <td><Button color="bg-bondi-blue" text="Click here to Get Xero Invoices" onClick={XeroInvoices}></Button></td>
                                        <td><Button color="bg-bondi-blue" text="Click here to Get Xero POs" onClick={XeroPOs}></Button></td> */}
                                        {/* <td ><Button color="bg-bondi-blue" text="Link DEFRA and SWC MRIO" onClick={linkDEFRA} ></Button></td> */}
                                        {/* <td ><Button color="bg-bondi-blue" text="Create All Sub Items from nested Footprint Arrays" onClick={createSubItems}></Button></td> */}
                                        <td ><Button color="bg-bondi-blue" text="Airport Dist Calc" onClick={airportCalc} ></Button></td>
                                        <td ><Button color="bg-bondi-blue" text="Link DEFRA and SWC MRIO Databases" onClick={linkDEFRA} ></Button></td>
                                        <td ><Button color="bg-bondi-blue" text="Hubspot Notes" onClick={getHubSpotNotes} ></Button></td>
                                        {/* <td ><Button color="bg-bondi-blue" text="updateItemCommentForReportFields" onClick={updateItemCommentForReportFields} ></Button></td> */}
                                        {/* <td ><Button color="bg-bondi-blue" text="Udpate Services" onClick={updateServices} ></Button></td> */}
                                        {/* <td ><Button color="bg-bondi-blue" text="Udpate Activites Complete for Closed-completed Services" onClick={updateActivitiesToCompleted} ></Button></td> */}
                                        {/* <td ><Button color="bg-bondi-blue" text="delete Orphaned Sub Item Sub Categories" onClick={deleteOrphanedSubItems} ></Button></td> */}
                                        {/* <td ><Button color="bg-bondi-blue" text="Create All Sub Items from nested Footprint Arrays" onClick={createSubItems}></Button></td> */}
                                    </tr>

                                    <tr>
                                        {/* <td><Button onClick={sendPayload}>Send Payload</Button><pre>{response}</pre></td> */}
                                    </tr>
                                </tbody>
                            </table>
                            <table className='mt-20 w-full'>
                                <tbody>
                                    <tr>
                                      <div className='mt-20 w-full'>
                                        <h1>ChatGPT Request Form</h1>
                                        <form onSubmit={handleSubmit}>
                                          <div className='mt-5'>
                                            <label>System Role:</label><br/>
                                            <textarea
                                              value={systemRole}
                                              onChange={(e) => setSystemRole(e.target.value)}
                                              required
                                              className='w-full'
                                            />
                                          </div>

                                          <div>
                                            <label>User Role:</label><br/>
                                            <textarea
                                              value={userRole}
                                              onChange={(e) => setUserRole(e.target.value)}
                                              required
                                              className='w-full'
                                            />
                                          </div>

                                          <div>
                                            <label>Temperature:</label>
                                            <select
                                              value={temperature}
                                              onChange={(e) => setTemperature(Number(e.target.value))}
                                            >
                                              <option value={0.1}>0.1</option>
                                              <option value={0.3}>0.3</option>
                                              <option value={0.5}>0.5</option>
                                              <option value={0.7}>0.7</option>
                                              <option value={1}>1.0</option>
                                            </select>
                                          </div>

                                          <div>
                                            <button
                                              type="submit"
                                              disabled={loading}
                                              style={{
                                                backgroundColor: 'blue',
                                                color: 'white',
                                                padding: '10px 20px',
                                                border: 'none',
                                                borderRadius: '5px',
                                                cursor: loading ? 'not-allowed' : 'pointer',
                                              }}
                                            >
                                              {loading ? 'Loading...' : 'Submit'}
                                            </button>
                                          </div>
                                        </form>

                                        {response && (
                                          <div>
                                            <h3>Response from ChatGPT:</h3>
                                            <p>{response}</p>
                                          </div>
                                        )}
                                      </div>
                                    </tr>

                                </tbody>
                            </table>
                            <div>
                                {/* <button className='text-xl' onClick={XeroFirstAuth}></button> */}
                                {/* <p className='text-xl'>2 - Copy the returned URL into the API</p>
                                <p className='text-xl'>3 - Copy the access_token into the variable at the top of the Xero API script</p>
                                <p className='text-xl'>4 - Copy the refresh_token into the variable at the top of the Xero API script</p>
                                <p className='text-xl'>5 - Run the reports</p> */}
                            </div>
                            {/* <button hidden className='pt-10 pl-20 text-xl' onClick={XeroPOs}>Click here to Get Xero Invoices</button> */}
                            {/* <button hidden className='pt-10 pl-20 text-xl' onClick={XeroPOs}>Click here to Get Xero POs</button> */}
                            <div hidden className='pt-20 text-4xl'>Invoice Summary from 1st June 2023</div>
                            <div hidden>
                                <table className="objects-8A min-w-full divide-y divide-gray-200">
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Invoice Number</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Date</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Due Date</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Customer</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Reference</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Status</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Sub Total</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Tax</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Total</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {invoiceResults.map((item) => (
                                            <tr key={item.InvoiceID}>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">{item.InvoiceNumber}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">{item.DateString.substring(0, 10)}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">{item.DueDateString.substring(0, 10)}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">{item.Contact.Name}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">{item.Reference}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">{item.Status}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">£{item.SubTotal}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">£{item.TotalTax}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">£{item.Total}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div hidden className='pt-10 text-4xl'>Purchase Orders from 1st June 2023</div>
                            <div hidden>
                                <table className="objects-8A min-w-full divide-y divide-gray-200">
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> PO Number</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Date</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Supplier</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Sub Total</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Tax</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Total</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Items</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {poResults.map((item) => (
                                            <tr key={item.PurchaseOrderID}>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">{item.PurchaseOrderNumber}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">{item.DateString.substring(0, 10)}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">{item.Contact.Name}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">£{item.SubTotal}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">£{item.TotalTax}</td>
                                                <td className="px-4 md:px-2  py-2 whitespace-nowrap">£{item.Total}</td>
                                                <td>
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <thead className="bg-pacific-blue-50 min-w-full">
                                                            <tr>
                                                                <th className="text-center text-xs font-medium text-congress-blue uppercase h-5 rounded-xl w-1/3 border-4 border-white"> Description</th>
                                                                <th className="text-center text-xs font-medium text-congress-blue uppercase h-5 rounded-xl w-1/3 border-4 border-white"> Qty</th>
                                                                <th className="text-center text-xs font-medium text-congress-blue uppercase h-5 rounded-xl w-1/12 border-4 border-white"> Line Amount</th>
                                                                <th className="text-center text-xs font-medium text-congress-blue uppercase h-5 rounded-xl w-1/12 border-4 border-white"> Tax</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item.LineItems.map((lineItem) => (
                                                                <tr key={lineItem.LineItemID}>
                                                                    <td className="px-4 md:px-2 py-2">{lineItem.Description}</td>
                                                                    <td className="px-4 md:px-2 py-2">{lineItem.Quantity}</td>
                                                                    <td className="px-4 md:px-2 py-2">£{lineItem.LineAmount}</td>
                                                                    <td className="px-4 md:px-2 py-2">£{lineItem.TaxAmount}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Xero API" section="Xero API Connection Page" showUserMenu='true' /> 
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

};

export default HubspotLink;

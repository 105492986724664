import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate, Navigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
// import UserService from '../../services/UserService'
// import authHeader from '../../services/AuthHeader'
// import Options from '../UI/Options'
// import ReactSelect from 'react-select'
import { MultiSelect } from "react-multi-select-component";

const ListClients = () => {

    const [clients, setClients] = useState([])

    const [users, setUsers] = useState([])

    const [clientsFiltered, setClientsFiltered] = useState([])

    const [startingRecordForPage, setStartingRecordForPage] = useState(0)

    const [clients100PerPage, setClients100PerPage] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(true)
    const [usersLoading, setUsersLoading] = useState(true)

    const [redirecting, setRedirecting] = useState(false)

    const [selectedClient, setSelectedClient] = useState([])

    const [selectedClientConfirmed, setSelectedClientConfirmed] = useState([])

    const [selectedKeyInternalPerson, setSelectedKeyInternalPerson] = useState([])

    const [selectedClientComments, setSelectedClientComments] = useState([])

    const [selectedClientBDStatus, setSelectedClientBDStatus] = useState([])

    const API_URL = process.env.REACT_APP_API_URL

    const clearFilters = () => {
        setSelectedClient(clients.map((item) => {
            return {
                label: item.clientName,
                value: item.id
            }
        }))
        setSelectedClientConfirmed([{label: "YES", value: "YES"}, {label: "NO", value: "NO"}])

        setSelectedKeyInternalPerson(aggregateKeyInternalPersonArray(clients).map((item) => {
            return {
                label: item.clientInternalKeyPersonName,
                value: item.clientInternalKeyPerson
            }
        }))

        setSelectedClientComments(aggregateClientCommentsArray(clients).map((item) => {
            return {
                label: item.clientComments,
                value: item.clientComments
            }
        }))

        setSelectedClientBDStatus(aggregateClientBDStatusArray(clients).map((item) => {
            return {
                label: item.clientBDStatus,
                value: item.clientBDStatus
            }
        }))

    }

    // const styles = {
    //     option: provided => ({
    //         ...provided,
    //         color: 'black'
    //     }),
    //     control: provided => ({
    //         ...provided,
    //         color: 'black',
    //         width: 160,
    //         fontSize: 13
    //     }),
    //     singleValue: (provided) => ({
    //         ...provided,
    //         color: 'black'
    //     }),
    //     menu: provided => ({
    //         ...provided,
    //         color: 'black',
    //         width: 160,
    //         fontSize: 13
    //     }),
    // }

    const aggregateKeyInternalPersonArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.clientInternalKeyPerson === val.clientInternalKeyPerson);
            if (index === -1) {
                acc.push({
                    clientInternalKeyPerson: val.clientInternalKeyPerson,
                });
            };
            return acc;
        }, []);
    };

    const aggregateClientCommentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.clientComments === val.clientComments);
            if (index === -1) {
                acc.push({
                    clientComments: val.clientComments
                });
            };
            return acc;
        }, []);
    };

    const aggregateClientBDStatusArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.clientBDStatus === val.clientBDStatus);
            if (index === -1) {
                acc.push({
                    clientBDStatus: val.clientBDStatus
                });
            };
            return acc;
        }, []);
    };


    useEffect(() => {

        setLoading(true)

        axios.get(API_URL + "/clients").then(
            (response) => {
                setClients(response.data.sort((item1, item2) => item1.clientName.toLowerCase() < item2.clientName.toLowerCase() ? -1 : 0));
                setClientsFiltered(response.data.sort((item1, item2) => item1.clientName.toLowerCase() < item2.clientName.toLowerCase() ? -1 : 0));

                setSelectedClient(response.data.map((item) => {
                    return {
                        label: item.clientName,
                        value: item.id
                    }
                }))

                setSelectedClientConfirmed([{label: "YES", value: "YES"}, {label: "NO", value: "NO"}])


                setSelectedClientComments(aggregateClientCommentsArray(response.data).map((item) => {
                    return {
                        label: item.clientComments,
                        value: item.clientComments
                    }
                }))

                setSelectedKeyInternalPerson(aggregateKeyInternalPersonArray(response.data).map((item) => {
                    return {
                        label: item.clientInternalKeyPerson,
                        value: item.clientInternalKeyPerson
                    }
                }))

                setSelectedClientBDStatus(aggregateClientBDStatusArray(response.data).map((item) => {
                    return {
                        label: item.clientBDStatus,
                        value: item.clientBDStatus
                    }
                }))

                var index
                var first100 = []

                for (index = 0; index < 100; index++) {
                    first100.push(response.data[index])
                }

                setClients100PerPage(first100)
                setStartingRecordForPage(0)
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(_content);
                setRedirecting(true)
            },
        ).finally(() => {
            setLoading(false)
        });

        axios.get(API_URL + '/users')
        .then((res) => {
            setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setUsersLoading(false);
        });

    }, [])

    useEffect(() => {

        setClientsFiltered(clients)

        var lastRecordIndex = 100
        var firstOneHundred = []
        var index

        var tempClientsFiltered = [...clients]

        tempClientsFiltered = tempClientsFiltered.filter((itemA) => {
            return selectedClient.find((itemB) => {
                return itemA.id === itemB.value;
            })
        }).filter((itemA) => {
            return selectedClientConfirmed.find((itemB) => {
                return itemA.clientFinanceConfirmed === itemB.value;
            })
        }).filter((itemA) => {
            return selectedKeyInternalPerson.find((itemB) => {
                return itemA.clientInternalKeyPerson === itemB.value;
            })
        }).filter((itemA) => {
            return selectedClientComments.find((itemB) => {
                return itemA.clientComments === itemB.value;
            })
        }).filter((itemA) => {
            return selectedClientBDStatus.find((itemB) => {
                return itemA.clientBDStatus === itemB.value;
            })
        })

        setClientsFiltered(tempClientsFiltered)

        if (tempClientsFiltered.length < 100) {
            lastRecordIndex = tempClientsFiltered.length
        }

        for (index = 0; index < lastRecordIndex; index++) {
            firstOneHundred.push(tempClientsFiltered[index])
        }

        setClients100PerPage(firstOneHundred)
        setStartingRecordForPage(0)
        
    }, [selectedClient, clients, selectedClientConfirmed, selectedKeyInternalPerson, selectedClientComments, selectedClientBDStatus])


    const nextPage = (startingRecordIndex) => {

        if (startingRecordIndex + 100 < clientsFiltered.length ) {

            var NewStartingRecordIndex = startingRecordIndex + 100
            var lastRecordIndex = NewStartingRecordIndex + 100

                if (lastRecordIndex > clientsFiltered.length) {

                    lastRecordIndex = clientsFiltered.length -1 

                }

            var index
            var firstOneHundred = []

            for (index = NewStartingRecordIndex; index < lastRecordIndex; index++) {
                firstOneHundred.push(clientsFiltered[index])
            }

            setClients100PerPage(firstOneHundred)
            setStartingRecordForPage(NewStartingRecordIndex)

        }
        
    }

    const previousPage = (startingRecordIndex) => {

        if (startingRecordIndex > 0 ) {

            var NewStartingRecordIndex = startingRecordIndex - 100

            var lastRecordIndex = NewStartingRecordIndex + 100

            var index
            var firstOneHundred = []

            for (index = NewStartingRecordIndex; index < lastRecordIndex; index++) {
                firstOneHundred.push(clientsFiltered[index])
            }

            setClients100PerPage(firstOneHundred)
            setStartingRecordForPage(NewStartingRecordIndex)
        }
    }


    const navigate = useNavigate()

    const deleteClient = (id) => {
        window.confirm('Are you sure you want to delete it?')
        // axios.delete(process.env.REACT_APP_API_URL + '/clients/' + id)
        // setClients(clients.filter((client) => client.id !== id))
    }

    const editClient = (id) => {
        navigate(`/addOrUpdateClient/${id}`);
    }

    const viewClient = (id) => {
        navigate(`/viewClient/${id}`);
    }

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (!loading && !redirecting && users.length > 0 && !usersLoading) {
        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                            <Header title="Clients" section="Select Client" showUserMenu='true' />

                            <hr></hr>

                            <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-8 pt-1">
                                <div className="pl-3">
                                    <p className="text-xs font-bold">Filters</p>
                                    <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                                </div>

                                <div className="pb-1 z-20">
                                        <p className="text-xs">Cient Name</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={clients.map((item) => {
                                                return {
                                                    label: item.clientName,
                                                    value: item.id
                                                }
                                            })}
                                            value={selectedClient}
                                            onChange={setSelectedClient}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div className="pb-1 z-20">
                                        <p className="text-xs">Key Internal Person</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateKeyInternalPersonArray(clients).map((item) => {
                                                return {
                                                    label: item.clientInternalKeyPerson === "" ? "" : users.find(user => user.id === item.clientInternalKeyPerson).firstName + " " + users.find(user => user.id === item.clientInternalKeyPerson).lastName,
                                                    value: item.clientInternalKeyPerson
                                                }
                                            })}
                                            value={selectedKeyInternalPerson}
                                            onChange={setSelectedKeyInternalPerson}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                <div className="pb-1 z-20">
                                        <p className="text-xs">Confirmed by Finance</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={[{label: "YES", value: "YES"}, {value: "NO", label: "NO"}]}
                                            value={selectedClientConfirmed}
                                            onChange={setSelectedClientConfirmed}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div className="pb-1 z-20">
                                        <p className="text-xs">Status</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateClientBDStatusArray(clients).map((item) => {
                                                return {
                                                    label: item.clientBDStatus,
                                                    value: item.clientBDStatus
                                                }
                                            })}
                                            value={selectedClientBDStatus}
                                            onChange={setSelectedClientBDStatus}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div className="pb-1 z-20">
                                        <p className="text-xs">Cient Comments</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateClientCommentsArray(clients).map((item) => {
                                                return {
                                                    label: item.clientComments,
                                                    value: item.clientComments
                                                }
                                            })}
                                            value={selectedClientComments}
                                            onChange={setSelectedClientComments}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>


                                <div>
                                <p className="text-xs">Prev</p>
                                    <Button color="bg-bondi-blue" text=" << Prev 100 Records" onClick={() => previousPage(startingRecordForPage)} />
                                </div>

                                <div className="text-right">
                                <p className="text-xs">Next</p>
                                    <Button color="bg-bondi-blue" text="Next 100 Records >>" onClick={() => nextPage(startingRecordForPage)} />
                                </div>
                            </div>
                            
                            <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full">
                                    <div className="shadow-md shadow-slate-300 rounded-2xl">
                                            <table className="objects-8A min-w-full divide-y divide-gray-200">

                                                <thead className="bg-pacific-blue-50 z-10 sticky top-0">
                                                    <tr>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Name</th>
                                                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Address 1</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Address 2</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Town</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client County</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Post Code</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Country</th> */}
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Key Internal Person</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Comments</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Status</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Req. PO For Invoicing</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Confirmed by Finance</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right"></th>
                                                    </tr>
                                                </thead>

                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {clients100PerPage.map((client) => (
                                                        <tr key={client.id}>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientName}</td>
                                                            {/* <td className="px-4 md:px-6  py-2 "> {client.clientAddress}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientAddress2}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientTown}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientCounty} </td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientPostCode}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientCountry}</td> */}
                                                            <td className="px-4 md:px-6  py-2 "> {users.find(user => user.id === client.clientInternalKeyPerson).firstName + ' ' + users.find(user => user.id === client.clientInternalKeyPerson).lastName}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientComments}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientBDStatus}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.requirePoForInvoicing}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientFinanceConfirmed}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right">
                                                                {/* <Options edit={`/addOrUpdateClient/${client.id}`} deleteFunction={deleteClient} deleteObject={client.id}></Options> */}
                                                                {/* <Options edit={`/addOrUpdateClient/${client.id}`}></Options> */}
                                                                <Button color="bg-bondi-blue" text="View Portfolio" onClick={() => viewClient(client.id)} />
                                                                <Button color="bg-bondi-blue" text="View / Edit Client" onClick={() => navigate(`/addOrUpdateClient/${client.id}`)} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Button color="bg-bondi-blue" text="Add New Client" onClick={() => navigate('/addOrUpdateClient/_add')} />
                        </div>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Clients" section="Select Cient" showUserMenu='true' />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

}

export default ListClients

import Header from '../UI/Header'
import axios from 'axios'
import { useState, useEffect} from 'react'
import { useMatch, Navigate } from 'react-router-dom'
import ListClientProjectActivities from './ListClientProjectActivities'
import ListClientProjectServices from './ListClientProjectServices'
import ListClientProjectInvoiceDetails from './ListClientProjectInvoiceDetails'
import Button from '../UI/Button'
import Sidebar from '../UI/Sidebar'
import authHeader from '../../services/AuthHeader'
import FileUploadAndDownload from '../FileUploadAndDownload'
import ReactTooltip from "react-tooltip"
import ModalUpdateValue from '../UI/ModalUpdateValue'
import ModalInfo from '../UI/ModalInfo'
import ModalEmailInfo from '../UI/ModalEmailInfo'
import AuthService from '../../services/AuthService';

const ViewClientProject = () => {

    const match = useMatch('/viewClientProject/:projectId')

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [tab, setTab] = useState("")
    const [billingCompany, setBillingCompany] = useState("All")

    const [loading, setLoading] = useState(true)
    const [clientsLoading, setClientsLoading] = useState(true)
    const [usersLoading, setUsersLoading] = useState(true)
    const [contactsLoading, setContactsLoading] = useState(true)
    const [projectServicesLoading, setProjectServicesLoading] = useState(true)
    const [timeSheetDataLoading, setTimeSheetDataLoading] = useState(true)

    const [projects, setProjects] = useState([])
    const [clients, setClients] = useState([])
    const [users, setUsers] = useState([])
    const [contacts, setContacts] = useState([])
    const [projectServices, setProjectServices] = useState([])
    const [timeSheetData, setTimeSheetData] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')

    const [showModalInfo, setShowModalInfo] = useState(false)
    const [dataToSendToModalInfo, setDataToSendToModalInfo] = useState('')

    const [showModalEmailInfo, setShowModalEmailInfo] = useState(false)
    
    const [redirecting, setRedirecting] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL;

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userDepartment, setUserDepartment] = useState("")
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)
    // const [hubSpotDealPropertiesLoading, setHubSpotDealPropertiesLoading] = useState(true)
    // const [deals, setDeals] = useState([])
    // const [dealName, setDealName] = useState ('')
    // const [hubSpotDeals, setHubSpotDeals] = useState([])

    const URL_REGEX =
	/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    
    const TextWithLinks = (content) =>  {

        const words = content.content.split('\n')
    
        return <div className="text-sm font-medium text-pacific-blue"><p className="my-1 whitespace-pre-line break-all" >{words.map((word) => {
            return word.match(URL_REGEX) ? (
                <span key={word}>
                    <a href={word} target="_blank" className="underline">{word}</a>{'\n'}
                </span>
            ) : (
                word + '\n'
            );
        })}</p></div>
    
    }

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
                setUserDepartment(response.data.department)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const getHubSpotNotes = async () => {
    
        //get the deal ids that we need to look up in the modal
        var dealIds = []

        projectServices.forEach(project => {
            if (project.dealNumber!=="") {
                if (!dealIds.some(e => e.dealNumber === project.dealNumber)) dealIds.push({dealNumber : project.dealNumber, dealName : project.dealName})
            }
        });

        if (dealIds.length>0) {
            setDataToSendToModalInfo(dealIds);            
            setShowModalInfo(true);
        }else{
            alert("There are no deal numbers assigned to the services on this project")
        }

    }

    const getEmails = async () => {
    
            setDataToSendToModalInfo(projects.projectNumber);            
            setShowModalEmailInfo(true);

    }

    const updateServices = (e) => {

        setProjectServices(e)

        if (e.filter(item => item.department.slice (0,2) === "20").length === 0) {
            setBillingCompany("All")
        }

    }

    // const getHubSpotDealProperties = async () => {

    //     const serviceLines = projectServices.filter((item) => item.toBeDeleted === "NO" && item.dealNumber.length!==0).sort((item1, item2) => item1.dealNumber < item2.dealNumber ? -1 : item1.dealNumber > item2.dealNumber ? 1 : 0)
    //         const dealNumbers = []

    //         serviceLines.forEach(serviceLine => {
    //             if (dealNumbers.includes(serviceLine.dealNumber)===false){
    //                 dealNumbers.push({serviceID:serviceLine.id, dealNumber:serviceLine.dealNumber})
    //             }
    //         });
                
    //         if (dealNumbers.length > 0) {
    //         //For each deal number, connect to HS and grab the deal name
    //         const tempDealNumbers = []

    //         var i

    //         for (i = 0; i < dealNumbers.length; i++) {

    //             await axios.get(process.env.REACT_APP_API_URL + '/hubspotDeals_onedata/'+dealNumbers[i].dealNumber)
    //             .then((response) => {
    //                 if (tempDealNumbers.includes(dealNumbers[i].dealNumber)===false){
    //                     tempDealNumbers.push({"dealNo": dealNumbers[i].dealNumber, "dealName": response.data.properties.dealname})
    //                 }
    //             })

    //         }

    //         setHubSpotDeals(tempDealNumbers)
    //         setHubSpotDealPropertiesLoading(false);

    //     } else {
    //         setHubSpotDealPropertiesLoading(false);
    //     }

    // }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            setLoading(true)
            setClientsLoading(true)
            setUsersLoading(true)
            setContactsLoading(true)
            setTimeSheetDataLoading(true)

            // axios.get(API_URL + '/timesheets')
            axios.get(API_URL + '/timesheets_by_projectId/'+match.params.projectId, { headers: authHeader() })
                .then((res) => {
                    // setTimeSheetData(res.data.filter((item) => item.projectId === match.params.projectId))
                    setTimeSheetData(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setTimeSheetDataLoading(false);
                });

            axios.get(API_URL + "/clients", { headers: authHeader() })
                .then((response) => {
                    setClients(response.data);
                },
                    (error) => {
                        const _content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(_content);
                        setRedirecting(true)
                    },
                ).finally(() => {
                    setClientsLoading(false);
                });

            axios.get(API_URL + '/users')
                .then((res) => {
                    setUsers(res.data.sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setUsersLoading(false);
                });

            axios.get(API_URL + '/contacts')
                .then((res) => {
                    setContacts(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setContactsLoading(false);
                });

            axios.get(API_URL + '/projects/' + match.params.projectId)
                .then((res) => {
                    setProjects(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        }

    }, [match.params.projectId, role, company, userPropertiesLoading])


    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            axios.get(API_URL + '/projects_services_by_projectId/' + match.params.projectId, { headers: authHeader() })
            .then((response) => {
                setProjectServices(response.data.filter((item) => item.toBeDeleted === "NO").sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
                if (response.data.filter((item) => item.toBeDeleted === "NO").sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0).filter(item => item.department.slice (0,2) === "20").length === 0) {
                    setBillingCompany("All")
                }
            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(_content);
                    setRedirecting(true)
                })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setProjectServicesLoading(false);
                if (tab === "Services") {
                    localStorage.setItem("tab", "Services")
                }
                if (tab === "Activities") {
                    localStorage.setItem("tab","Activities")
                }
                if (tab === "Billing Plan") {
                    localStorage.setItem("tab", "Billing Plan")
                }
            });

        }

    }, [match.params.projectId, tab, role, company, userPropertiesLoading, billingCompany])

    // useEffect(() => {

    //     if (projects.dealNumberSeed !== "") {
               
    //         if (projects.length!==0) {
    //             //setHSLoading(true)
    //             axios.get(process.env.REACT_APP_API_URL + '/hubspotDeals_onedata/'+projects.dealNumberSeed)
    //             .then((response) => {
    //                 setDeals(response.data)
    //                 setDealName(response.data.properties.dealname)
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             }).finally(() => {
    //                 setHSLoading(false);
    //             });
    //         }
    
    //     } else {
    //         setHSLoading(false);
    //     }

    // }, [projects, showModal])

    // useEffect(() => {
                
    //     if (deals.length!==0) {
    //         setDealName(deals.properties.dealname)
    //     }

    // }, [deals])

    // useEffect(() => {

    //         getHubSpotDealProperties()

    // }, [projectServices])

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (clients.length > 0 && !loading && !clientsLoading && !usersLoading && !contactsLoading && !projectServicesLoading && !timeSheetDataLoading && role && company && !userPropertiesLoading) {

        return (

            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Project - View" section="Project Main Details" showUserMenu='true' />

                        <br></br>

                        <div><div className="flex items-center">
                            <div className="w-full mx-auto mb-5 px-3">
                                <div className="grid gap-2 w-full sm:gap-7 sm:grid-cols-3">


                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Project Number</div>
                                            
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{projects.projectNumber}</p></div>

                                                {billingCompany === "All" && projectServices.filter(item => item.department.slice (0,2) === "20").length > 0 &&
                                                <span className="pl-5">
                                                <Button color="#01458f" text="Eight Versa & NCS" onClick={() => {setBillingCompany("All")}} />
                                                <Button color="bondi-blue" text="Eight Versa" onClick={() => {setBillingCompany("Eight Versa")}} />
                                                <Button color="bondi-blue" text="NCS" onClick={() => {setBillingCompany("NCS")}} />
                                                </span>
                                                }

                                                {billingCompany === "Eight Versa" && projectServices.filter(item => item.department.slice (0,2) === "20").length > 0 &&
                                                <span className="pl-5">
                                                <Button color="bondi-blue" text="Eight Versa & NCS" onClick={() => {setBillingCompany("All")}} />
                                                <Button color="#01458f" text="Eight Versa" onClick={() => {setBillingCompany("Eight Versa")}} />
                                                <Button color="bondi-blue" text="NCS" onClick={() => {setBillingCompany("NCS")}} />
                                                </span>
                                                }

                                                {billingCompany === "NCS" && projectServices.filter(item => item.department.slice (0,2) === "20").length > 0 &&
                                                <span className="pl-5">
                                                <Button color="bondi-blue" text="Eight Versa & NCS" onClick={() => {setBillingCompany("All")}} />
                                                <Button color="bondi-blue" text="Eight Versa" onClick={() => {setBillingCompany("Eight Versa")}} />
                                                <Button color="#01458f" text="NCS" onClick={() => {setBillingCompany("NCS")}} />
                                                </span>
                                                }

                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 "> Project Name</div>
                                            <div className="flex items-center pt-1">
                                                <div onClick={() => { setShowModal(true); setValueToSendToModal(projects.projectName); setFieldToSendToModal("projectName"); setObjectIdToSendToModal(projects.id); setCollectionToSendToModal("projects") }} className="text-xl font-medium text-pacific-blue hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg> {projects.projectName} </div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Project Status and Quality Assurance</div>
                                            <div className="items-center pt-1 grid grid-cols-4">
                                                <div onClick={() => { setShowModal(true); setValueToSendToModal(projects.projectStatus); setFieldToSendToModal("projectStatus"); setObjectIdToSendToModal(projects.id); setCollectionToSendToModal("projects") }} className="text-l font-medium text-pacific-blue hover:cursor-pointer pr-3"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg> {projects.projectStatus} </div>
                                        {/* <div className="items-center pt-1 grid grid-cols-2"> */}
                                            <div className={role.toString().slice(0,1) === "2" ? "hover:cursor-pointer whitespace-nowrap" : "whitespace-nowrap"} onClick={role.toString().slice(0,1) === "2" ? () => { setShowModal(true); setValueToSendToModal(projects.projectBillingPlanAndFeesChecked); setFieldToSendToModal("projectBillingPlanAndFeesChecked"); setObjectIdToSendToModal(projects.id); setCollectionToSendToModal("projects") } : null}>
                                            {projects.projectBillingPlanAndFeesChecked !== "NO" &&
                                            <div className={role.toString().slice(0,1) === "2" ? "text-l font-medium text-pacific-blue hover:cursor-pointer text" : "text-l font-medium text-pacific-blue"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#0BDA51" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>Fees
                                            </div>
                                            }

                                            {projects.projectBillingPlanAndFeesChecked === "NO" &&
                                                <div className={role.toString().slice(0,1) === "2" ? "text-l font-medium text-pacific-blue hover:cursor-pointer" : "text-l font-medium text-pacific-blue"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#FFBF00" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                </svg>Fees
                                                </div>
                                            }
                                            </div>
                                            <div className={role.toString().slice(0,1) === "2" ? "hover:cursor-pointer whitespace-nowrap" : "whitespace-nowrap"} onClick={role.toString().slice(0,1) === "2" ? () => { setShowModal(true); setValueToSendToModal(projects.projectInvoicingChecked); setFieldToSendToModal("projectInvoicingChecked"); setObjectIdToSendToModal(projects.id); setCollectionToSendToModal("projects") } : null}>
                                            {projects.projectInvoicingChecked !== "NO" &&
                                                <div className={role.toString().slice(0,1) === "2" ? "text-l font-medium text-pacific-blue hover:cursor-pointer" : "text-l font-medium text-pacific-blue"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#0BDA51" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg> Appointment
                                                </div>
                                            }

                                            {projects.projectInvoicingChecked === "NO" &&
                                                <div className={role.toString().slice(0,1) === "2" ? "text-l font-medium text-pacific-blue hover:cursor-pointer" : "text-l font-medium text-pacific-blue"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#FFBF00" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                </svg> Appointment
                                                </div>
                                            }
                                            </div>
                                        {/* </div> */}
                                            </div>
                                            
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                                            </svg>
                                        </div>

                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                        <div className="text-sm text-gray-500 ">Client</div>
                                            <div className="flex items-center pt-1">
                                                    {/* <div className="text-xl font-medium text-pacific-blue "> {contacts.find(contact => contact.id === projects.projectClientContactId).contactName} </div> */}
                                                    <div onClick={() => { setShowModal(true); setValueToSendToModal(projects.clientId); setFieldToSendToModal("projectClient"); setObjectIdToSendToModal(projects.id); setCollectionToSendToModal("projects") }} className="text-xl font-medium text-pacific-blue hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg> {clients.find(client => client.id === projects.clientId).clientName}
                                                    </div>
                                            </div>
                                        <div className="text-sm text-gray-500 pt-3">Client Contact</div>
                                                <div className="flex items-center pt-1">
                                                    {/* <div className="text-xl font-medium text-pacific-blue "> {contacts.find(contact => contact.id === projects.projectClientContactId).contactName} </div> */}
                                                    <div onClick={() => { setShowModal(true); setValueToSendToModal(projects.clientId); setFieldToSendToModal("projectClient"); setObjectIdToSendToModal(projects.id); setCollectionToSendToModal("projects") }} className="text-xl font-medium text-pacific-blue hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg> {contacts.find(contact => contact.id === projects.projectClientContactId).contactName}
                                                    </div>
                                                    <div onClick={() => {navigator.clipboard.writeText(contacts.find(contact => contact.id === projects.projectClientContactId).contactEmail)}} className="ml-5 mt-3 text-xs font-medium text-pacific-blue hover:cursor-pointer"> {contacts.find(contact => contact.id === projects.projectClientContactId).contactEmail}
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-right" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                                            </svg>
                                                    </div>
                                            </div>
                                            <div className="text-sm text-gray-500 pt-3 ">Project Manager</div>
                                            <div className="flex items-center pt-1">
                                                <div onClick={() => { setShowModal(true); setValueToSendToModal(projects.projectManagerId); setFieldToSendToModal("projectManagerId"); setObjectIdToSendToModal(projects.id); setCollectionToSendToModal("projects") }} className="text-xl font-medium text-pacific-blue hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg> {users.find(user => user.id === projects.projectManagerId).firstName + ' ' + users.find(user => user.id === projects.projectManagerId).lastName}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                                            </svg>
                                        </div>


                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            {/* !!!! projectLocation is actually now used as a "Project Status Comment" field !!!!! */}
                                            <div className="text-sm text-gray-500">BD Notes</div>
                                                <div className="flex items-center pt-1">
                                                    {/* <div className="text-xl font-medium text-pacific-blue "> {contacts.find(contact => contact.id === projects.projectClientContactId).contactName} </div> */}
                                                    <div onClick={() => { setShowModal(true); setValueToSendToModal(projects.projectLocation); setFieldToSendToModal("projectLocation"); setObjectIdToSendToModal(projects.id); setCollectionToSendToModal("projects") }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer text-ellipsis overflow-hidden ... h-16"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg> {projects.projectLocation}
                                                    </div>
                                            </div>
                                            {/* <Button color="bg-bondi-blue" text="View HubSpot Notes" onClick={getHubSpotNotes} /> */}
                                            <div onClick={getHubSpotNotes} className="text-m pt-5 font-medium text-pacific-blue hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="#6c6c6c" className="h-5 float-left w-5 mr-2">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                                                        </svg>View HubSpot Notes</div>
                                            <div onClick={getEmails} className="text-m pt-5 font-medium text-pacific-blue hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="#6c6c6c" className="h-5 float-left w-5 mr-2">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                                                        </svg>View Emails</div>
                                            <div className="text-sm text-gray-500 pt-5 ">Hubspot Deal Seed #</div>
                                                <div className="flex items-center pt-1">
                                                    <div onClick={() => { setShowModal(true); setValueToSendToModal(projects.dealNumberSeed); setFieldToSendToModal("dealNumberSeed"); setObjectIdToSendToModal(projects.id); setCollectionToSendToModal("projects")}} className="text-m font-medium text-pacific-blue hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
    </svg> {projects.dealNumberSeed ? projects.dealNumberSeed + " - (" + projects.dealNumberSeedName + ")" : ""}</div>
                                            </div>
                                            
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Invoicing Notes</div>
                                            <div className="flex items-center pt-1">
                                                {(AuthService.getCurrentUserName()==="stacey.cougill@eightversa.com" || AuthService.getCurrentUserName()==="yiota.paraskeva@eightversa.com" || AuthService.getCurrentUserName()==="chris.hocknell@eightversa.com" || AuthService.getCurrentUserName()==="violeta.kriauciunaite@eightversa.com" || AuthService.getCurrentUserName()==="amina.mustaeva@eightversa.com" || AuthService.getCurrentUserName()==="daniel.champ@eightversa.com") && <div onClick={() => { setShowModal(true); setValueToSendToModal(projects.projectInvoicingNotes); setFieldToSendToModal("projectInvoicingNotes"); setObjectIdToSendToModal(projects.id); setCollectionToSendToModal("projects") }} className="text-xl font-medium text-pacific-blue hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5 mr-1" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg></div>}
                                            <TextWithLinks content = {projects.projectInvoicingNotes}></TextWithLinks>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 47 46">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M41.536 30.456a19.21 19.21 0 01-5.675 7.4 19.771 19.771 0 01-8.557 3.937c-3.138.608-6.38.455-9.444-.447a19.673 19.673 0 01-8.129-4.725 19.1 19.1 0 01-4.92-7.902 18.775 18.775 0 01-.564-9.237 18.98 18.98 0 013.923-8.419 19.538 19.538 0 017.497-5.639"></path>
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M43.083 23c0-2.517-.506-5.01-1.49-7.335a19.142 19.142 0 00-4.246-6.218 19.617 19.617 0 00-6.353-4.155A19.953 19.953 0 0023.5 3.833V23h19.583z"></path> 
                                            </svg>
                                        </div>

                                    </div>
                                    {/* <ReactTooltip id="descriptionTip" place="top" effect="solid">
                                                <div style={{ maxWidth: 400 }}>1.	Sustainability targets/requirements:<br></br>
                                                    {projects.projectSustainabilityTargets}<br></br>
                                                    <br></br>2.	Location (incl. postcode): <br></br>
                                                    {projects.projectLocation}<br></br>
                                                    <br></br>3.	Drawings provided:<br></br>
                                                    {projects.projectDrawings}<br></br>
                                                    <br></br>4.	Primary use class of building :<br></br>
                                                    {projects.projectUseClassOfBuilding}<br></br>
                                                    <br></br>5.	Secondary use class of building :<br></br>
                                                    {projects.projectSecondaryUseClassOfBuilding}<br></br>
                                                    <br></br>6.	Project type:<br></br>
                                                    {projects.projectType}<br></br>
                                                    <br></br>7.	Gross internal area of building (approx.)<br></br>
                                                    {projects.projectGrossInternalArea}<br></br>
                                                    <br></br>8.	Gross Area of land (approx.)<br></br>
                                                    {projects.projectGrossInternalLandArea}<br></br>
                                                    <br></br>9.	Current RIBA Stage:<br></br>
                                                    {projects.projectCurrentRIBAStage}<br></br>
                                                    <br></br>10. Programme provided:<br></br>
                                                    {projects.projectProgrammeProvided }<br></br>
                                                </div>
                                            </ReactTooltip> */}
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300" data-tip data-for="descriptionTip">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Project Description</div>
                                            <div className="flex items-center pt-1">
                                                <div onClick={() => { setShowModal(true); setValueToSendToModal(projects.projectDescription); setFieldToSendToModal("projectDescription"); setObjectIdToSendToModal(projects.id); setCollectionToSendToModal("projects") }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer whitespace-pre-line"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5 mr-2" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg></div>
                                            <TextWithLinks content = {projects.projectDescription}></TextWithLinks>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1"><path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                                        </div>

                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                        <div className="text-sm text-gray-500 ">Internal Documents</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xs font-medium text-indigo-500 w-full"><FileUploadAndDownload id={match.params.projectId} allowDeletes={role.toString().slice(0,1) === "2" ? true : false}/></div>
                                            </div>
                                        </div>
                                        <div>
                                        <div className="text-sm text-gray-500 ">Appointment Documents</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xs font-medium text-indigo-500 w-full"><FileUploadAndDownload id={match.params.projectId} subFolder="appointmentDocs" allowDeletes={role.toString().slice(0,1) === "2" ? true : false}/></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
                                            </svg>
                                        </div>

                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                        <div className="text-sm text-gray-500 ">Documents Sent to Client</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xs font-medium text-indigo-500"><FileUploadAndDownload id={match.params.projectId} subFolder="finalVersions" allowDeletes={role.toString().slice(0,1) === "2" ? true : false}/></div>
                                            </div>
                                        </div>
                                        
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
                                            </svg>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div></div>
                        <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={[projects]} contacts={contacts.filter(item => item.clientId === projects.clientId)} onClose={() => setShowModal(false)} show={showModal} title="Update Project Information" />
                        <ModalInfo data={dataToSendToModalInfo} onClose={() => setShowModalInfo(false)} show={showModalInfo} title="HubSpot Deal Notes" />
                        <ModalEmailInfo data={dataToSendToModalInfo} onClose={() => setShowModalEmailInfo(false)} show={showModalEmailInfo} title={"Project Related Email Communication for " + projects.projectNumber} />

                        {/* <div className="grid grid-cols-3 gap-3">
                            <Button color="bg-bondi-blue" text="Services" onClick={() => setTab("Services")} />
                            <Button color="bg-bondi-blue" text="Activities" onClick={() => setTab("Activities")} />
                            <Button color="bg-bondi-blue" text="Billing Plan" onClick={() => setTab("Billing Plan")} />
                        </div> */}

                        <br></br>

                        {/* {tab === "Services" &&
                            <ListClientProjectServices contacts={contacts} clients={clients} users={users} services={projectServices} clientId={projects.clientId} projectClientContactId={projects.projectClientContactId} currency={projects.currency} projectNumber = {projects.projectNumber} projectName={projects.projectName} projectManager={projects.projectManagerId}/>
                        }

                        {tab === "Activities" &&
                            <ListClientProjectActivities contacts={contacts} clients={clients} users={users} services={projectServices} timesheets={timeSheetData} currency={projects.currency} />
                        }

                        {tab === "Billing Plan" &&
                            <ListClientProjectInvoiceDetails contacts={contacts} clients={clients} users={users} services={projectServices} currency={projects.currency} />
                        } */}

                        {localStorage.getItem("tab") === "Services" &&
                        <div className="grid grid-cols-3 gap-3 pl-1">
                            <Button color="#01458f" text="Services" onClick={() => {setTab("Services")}} />
                            <Button color="bondi-blue" text="Activities" onClick={() => {setTab("Activities")}} />
                            <Button color="bondi-blue" text="Billing Plan" onClick={() => {setTab("Billing Plan")}} />
                        </div>
                        }

                        {localStorage.getItem("tab") === "Activities" &&
                        <div className="grid grid-cols-3 gap-3">
                            <Button color="bondi-blue" text="Services" onClick={() => {setTab("Services")}} />
                            <Button color="#01458f" text="Activities" onClick={() => {setTab("Activities")}} />
                            <Button color="bondi-blue" text="Billing Plan" onClick={() => {setTab("Billing Plan")}} />
                        </div>
                        }

                        {localStorage.getItem("tab") === "Billing Plan" &&
                        <div className="grid grid-cols-3 gap-3">
                            <Button color="bondi-blue" text="Services" onClick={() => {setTab("Services")}} />
                            <Button color="bondi-blue" text="Activities" onClick={() => {setTab("Activities")}} />
                            <Button color="#01458f" text="Billing Plan" onClick={() => {setTab("Billing Plan")}} />
                        </div>
                        }

                        <br></br>

                        {localStorage.getItem("tab") === "Services" &&
                            <ListClientProjectServices role={role} userDepartment={userDepartment} contacts={contacts} clients={clients} users={users} services={projectServices} clientId={projects.clientId} projectClientContactId={projects.projectClientContactId} currency={projects.currency} projectNumber = {projects.projectNumber} projectName={projects.projectName} projectManager={projects.projectManagerId} projectBillingPlanAndFeesChecked={projects.projectBillingPlanAndFeesChecked} projectInvoicingChecked={projects.projectInvoicingChecked} projectDealNumberSeed={projects.dealNumberSeed} projectDealNumberSeedName={projects.dealNumberSeedName} timesheetData={timeSheetData} billingCompany={billingCompany} updateServices={updateServices} />
                        }

                        {localStorage.getItem("tab") === "Activities" &&
                            <ListClientProjectActivities role ={role} contacts={contacts} clients={clients} users={users} services={projectServices} timesheets={timeSheetData} currency={projects.currency} billingCompany={billingCompany} />
                        }

                        {localStorage.getItem("tab") === "Billing Plan" &&
                            <ListClientProjectInvoiceDetails role ={role} contacts={contacts} clients={clients} users={users} services={projectServices} currency={projects.currency} billingCompany={billingCompany} />
                        }

                    </div>

                </div>

            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Project - View" section="Project Main Details" showUserMenu='true' />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

};

export default ViewClientProject;